<template>
  <div ref="mstu">
    <div v-if="dataReady" class="mysub_details_root">
      <div class="mysub_details_progress_container" :id="mys_componentId">
        <div class="mysub_details_time mysub_details_start">0 h</div>
        <div class="mysub_details_progress" :style="progressBarSize_css">
          <div class="toBeUsedTime" :style="toBeUsedTime_css"></div>
          <div class="alreadyUsedTime" :style="aleadyUsedTime_css"></div>
        </div>
        <div class="mysub_details_time mysub_details_stop">
          {{ totalSubscriptionTime() }} h
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "myServiceTimeUsage",
  props: ["ClientId", "usagePeriodEnd", "mys_class", "mys_componentId"],

  setup() {
    const mstu = ref(null);
    console.log("AAAAAAAAAAAAAAA mstu:", mstu);
    return {
      mstu,
    };
  },
  data() {
    return {
      dataReady: false,
      mstuWidth: null,
      mstuHeight: null,
      mstuOffset: 0,

      currentPeriodEnd: this.usagePeriodEnd,

      progressBarSize_css: "",
      aleadyUsedTime_css: "",
      toBeUsedTime_css: "",
    };
  },
  created() {
    this.$_log("0 this.mstu:", this.mstu);
    this.$_log(
      "userServiceTimeUsage.vue created currentPeriodEnd:" +
        this.currentPeriodEnd
    );
    this.dataReady = true;
    /*
    if (this.isUserLoggedIn) {
      this.updateProgressBar();
    }
	*/
  },
  mounted() {
    this.$_log("1 this.mstu:", this.mstu);
    window.addEventListener("resize", this.onScreenResizeEventHandler);

    if (this.isUserLoggedIn) {
      this.updateProgressBar();
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onScreenResizeEventHandler);
  },
  computed: {
    ...mapGetters("userModule", [
      "userToken",
      "isUserLoggedIn",
      "currentUserId",
      "currentClientId",
      "currentClientFN",
      "currentClientLN",
      "currentSubscription",
    ]),

    ...mapGetters("subscriptionsModule", [
      "curentSubscriptionId",
      "subscriptionName",
      "subscriptionImage",
      "subscriptionAllowedTime",
      "subscriptionUsedTime",
      "subscriptionCurrency",
      "subscriptionStartDate",
      "subscriptionStatus",
      "subscriptionsPeriodStart",
      "subscriptionPeriodEnd",
      "subscriptionPrice",
      "subscriptionPriceId",
      "subscriptionProductId",
      "subscriptionInterval",
      "subscriptionStripeCustomerId",
    ]),
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),

    ...mapActions("clientModule", {
      setCurCliId: "setCurrentId",
      setCurCliName: "setCurrentName",
    }),

    ...mapActions("subscriptionsModule", {
      getClientSubscriptions: "getClientSubscriptions",
    }),

    remainingSeconds() {
      let rt = this.subscriptionAllowedTime; //(this.subscriptionAllowedTime - this.subscriptionUsedTime);
      return rt;
    },
    remainingTime() {
      let rt = this.subscriptionAllowedTime; //this.subscriptionAllowedTime - this.subscriptionUsedTime;
      return this.$toHHMMSS(rt);
    },

    totalSubscriptionTime() {
      let sec_num =
        parseInt(this.subscriptionAllowedTime, 10) +
        parseInt(this.subscriptionUsedTime, 10);
      let hours = Math.floor(sec_num / 3600);
      return hours;
    },

    allowedTime() {
      let sec_num = parseInt(this.subscriptionAllowedTime, 10);
      let hours = Math.floor(sec_num / 3600);
      return hours;
      //return this.$toHHMMSS(this.subscriptionAllowedTime);
    },

    onScreenResizeEventHandler(e) {
      if (e.currentTarget.outerWidth < 100) {
        this.$_log(
          "onScreenResizeEventHandler current screen width e:",
          e.currentTarget.outerWidth
        );
      }
      this.updateProgressBar();
    },

    updateProgressBar() {
      this.$_log("ok lets update usageBar ");
      this.progressBarSize();
      this.toBeUsedTime();
      this.aleadyUsedTime();
    },

    progressBarSize() {
      if (this.mstu !== null && this.dataReady == true) {
        let usageBar;
        if (
          this.mys_componentId == "mysUsrSet" &&
          document.getElementsByClassName("userSetingsForm").length > 0
        ) {
          console.log(document.getElementsByClassName("userSetingsForm")[0]);
          usageBar = document.getElementsByClassName("userSetingsForm")[0];
          this.mstuWidth = usageBar.offsetWidth - 244;
        } else {
          usageBar = this.$el.querySelector("#" + this.mys_componentId);
          this.mstuWidth = usageBar.offsetWidth;
        }
      } else {
        console.log("progressBarSize B this.mstu:", this.mstu);
      }
      this.$_log("w:" + this.mstuWidth + " h:" + this.mstuHeight);

      let space = this.mstuWidth - this.mstuOffset;
      this.progressBarSize_css = "width:" + space + "px; ";
    },
    toBeUsedTime() {
      let total = this.mstuWidth - this.mstuOffset;
      let space = parseInt(
        (this.subscriptionUsedTime * total) / this.subscriptionAllowedTime,
        10
      );
      let rest = total - space;

      let leftPercentOfTime = parseInt((rest * 100) / total, 10);

      this.$_log(
        "toBeUsedTime() this.subscriptionUsedTime:" +
          this.subscriptionUsedTime +
          " subscriptionAllowedTime:" +
          this.subscriptionAllowedTime +
          " total:" +
          total +
          " counted space:" +
          space +
          " counted rest:" +
          rest +
          " leftPercentOfTime:" +
          leftPercentOfTime
      );
      this.toBeUsedTime_css = "width:" + rest + "px; ";
      if (leftPercentOfTime <= 10) {
        this.toBeUsedTime_css +=
          "background-color: var(--main-errordark-color); ";
      }

      this.$_log("this.toBeUsedTime_css:" + this.toBeUsedTime_css);
    },
    aleadyUsedTime() {
      // in fact in current ui project this vaule should not be explicit displayed
      let total = this.mstuWidth - this.mstuOffset;

      let space = parseInt(
        (this.subscriptionUsedTime * total) / this.subscriptionAllowedTime,
        10
      );
      this.aleadyUsedTime_css = "width:" + space + "px; ";
    },

    //
    getSessionUrl() {
      return this.stripeSessionUrl;
    },
    // =====================================
  },
};
</script>

<style scoped>
.mysub_details_root {
  grid-column: 1/2;
  grid-row: 2 / 3;
  /*background-color: yellow;*/
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mysub_details_progress_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  grid-template-rows: 16px 1fr;
  align-items: center;
  width: 100%;
}

.mysub_details_time {
  margin: 0px 10px 0px 0px;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;

  color: var(--main-gray400-color);
}
.mysub_details_start {
  grid-column: 1/2;
  grid-row: 2 / 3;
  display: flex;
  justify-content: flex-start;
}
.mysub_details_stop {
  grid-column: 3/4;
  grid-row: 2 / 3;
  display: flex;
  justify-content: flex-end;
}

.mysub_details_progress {
  height: 7px;
  background-color: #ccc;
  display: flex;

  grid-column: 1/4;
  grid-row: 1 / 2;
  width: 150px;
  border-radius: 8px;
}
.alreadyUsedTime {
  /*background-color: var(--main-errordark-color);*/
  height: 3px;

  margin-top: 2px;
}

.toBeUsedTime {
  background-color: var(--main-sec-color);
  height: 3px;

  margin-top: 2px;
}
</style>
