<template>
  <div v-if="dataReady" class="mysub">
    <div class="mysub_container">
      <div class="mysub_title">
        <h3>My Plan</h3>
        <div @click="($event) => onManageClicked($event)" class="button_W">
          Manage
        </div>
      </div>
      <div class="mysub_board">
        <div class="mysub_name">
          <h4>{{ subscriptionName }}</h4>
          <div>
            <h6>{{ subscriptionCurrency }}{{ subscriptionPrice }}</h6>
            <p>/{{ subscriptionInterval }}</p>
          </div>
          <span v-if="subscriptionInterval == 'month'">Billed monthly</span>
          <span v-else>Billed yearly</span>
        </div>
        <div class="mysub_details_root" ref="msr">
          <div style="width: 100%; padding: 20px">
            <div class="mysub_details">
              <div class="mysub_details_header">Time left:</div>
              <div class="mysub_details_links">
                <MySubNavBar
                  :title="titleToBeDisplayed"
                  :stripeSessionUrl="getSessionUrl()"
                  :subscriptionName="subscriptionName"
                />
              </div>
            </div>
            <div>
              <div class="mysub_details_msg">
                You have {{ remainingTime() }} remaining this month.
              </div>

              <!--   -->
              <userServiceTimeUsage
                :usagePeriodEnd="currentPeriodEnd"
                :mys_class="mysub_details_progress_container"
                mys_componentId="mysUsrSet"
              />
              <!--
			<div class="mysub_details_progress_container">
                <div class="mysub_details_start">0</div>
                <div
                  class="mysub_details_progress"
                  :style="progressBarSize_css"
                >
                  <div
                    class="alreadyUsedTime"
                    :style="aleadyUsedTime_css"
                  ></div>
                  <div class="toBeUsedTime" :style="toBeUsedTime_css"></div>
                </div>
                <div class="mysub_details_stop">{{ allowedTime() }}</div>
              </div>
			  -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { watchEffect } from "vue";

import { mapGetters, mapActions } from "vuex";

import stripeService from "@/services/stripeService.js";
import userServiceTimeUsage from "@/components/elements/userServiceTimeUsage.vue";
import MySubNavBar from "@/components/navigation/MySubNavBar.vue";

export default {
  name: "userSubscriptionsCmp",
  props: ["ClientId"],
  components: { userServiceTimeUsage, MySubNavBar },
  emits: ["manageClicked"],
  setup() {
    const msr = ref(null);
    return {
      msr,
    };
  },
  data() {
    return {
      dataReady: false,
      msrWidth: null,
      msrHeight: null,
      stripeSessionUrl: null,
      license: "Free",

      progressBarSize_css: "",
      aleadyUsedTime_css: "",
      toBeUsedTime_css: "",
    };
  },
  created() {
    //this.$_log("0 this.msr:", this.msr);

    if (this.isUserLoggedIn) {
      watchEffect(() => {
        this.getData();
      });
    }
  },

  mounted() {
    //this.$_log("1 this.msr:", this.msr);
    window.addEventListener("resize", this.onScreenResizeEventHandler);
  },
  beforeUnmount() {
    this.$_log("!!!!!!!!!!!!!!!!! beforeUnmount !!!!!!!!!!!!!!!!!");
    window.removeEventListener("resize", this.onScreenResizeEventHandler);
  },
  computed: {
    ...mapGetters("userModule", [
      "userToken",
      "isUserLoggedIn",
      "currentUserId",
      "currentClientId",
      "currentClientFN",
      "currentClientLN",
    ]),

    ...mapGetters("subscriptionsModule", [
      "curentSubscriptionId",
      "subscriptionName",
      "subscriptionImage",
      "subscriptionAllowedTime",
      "subscriptionUsedTime",
      "subscriptionCurrency",
      "subscriptionStartDate",
      "subscriptionStatus",
      "subscriptionsPeriodStart",
      "subscriptionPeriodEnd",
      "subscriptionPrice",
      "subscriptionPriceId",
      "subscriptionProductId",
      "subscriptionInterval",
      "subscriptionStripeCustomerId",
    ]),
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),

    ...mapActions("clientModule", {
      setCurCliId: "setCurrentId",
      setCurCliName: "setCurrentName",
    }),

    ...mapActions("subscriptionsModule", {
      getClientSubscriptions: "getClientSubscriptions",
    }),

    onManageClicked(event) {
      this.$_log(
        "UserSubscriptionsCmp.vue] onManagedClicked(" + event.target.id + ")"
      );

      window.location.href = this.getSessionUrl();
      /*
      let action = "...";

      this.$emit("manageClicked", {
        elemId: event.target.id,
        action: action,
      });
	  */
    },

    remainingSeconds() {
      let rt = this.subscriptionAllowedTime - this.subscriptionUsedTime;
      return rt;
    },
    remainingTime() {
      let rt = this.subscriptionAllowedTime - this.subscriptionUsedTime;
      return this.$toHHMMSS(rt);
    },

    allowedTime() {
      return this.$toHHMMSS(this.subscriptionAllowedTime);
    },

    onScreenResizeEventHandler(e) {
      if (e.currentTarget.outerWidth < 100) {
        this.$_log(
          "onScreenResizeEventHandler current screen width e:",
          e.currentTarget.outerWidth
        );
      }
      if (this.msr !== null) {
        this.msrWidth = this.msr.offsetWidth;
        this.msrHeight = this.msr.offsetHeight;
        this.updateProgressBar();
      }
    },

    progressBarSize() {
      let space = this.msrWidth - 200;
      //return "width:" + space + "px; ";
      this.progressBarSize_css = "width:" + space + "px; ";
    },
    toBeUsedTime() {
      let total = this.msrWidth - 200;
      let space = parseInt(
        (this.subscriptionUsedTime * total) / this.subscriptionAllowedTime,
        10
      );

      let rest = total - space;

      //return "width:" + rest + "px; ";
      this.toBeUsedTime_css = "width:" + rest + "px; ";
    },
    aleadyUsedTime() {
      let total = this.msrWidth - 200;

      let space = parseInt(
        (this.subscriptionUsedTime * total) / this.subscriptionAllowedTime,
        10
      );

      //return "width:" + space + "px; ";
      this.aleadyUsedTime_css = "width:" + space + "px; ";
    },
    updateProgressBar() {
      this.progressBarSize();
      this.toBeUsedTime();
      this.aleadyUsedTime();
    },

    getSessionUrl() {
      return this.stripeSessionUrl;
    },
    // =====================================
    getData() {
      this.$_log(
        "Just before getClientDataForMysubscription....getData(" +
          this.currentClientId +
          ")..."
      );

      let qObj = {
        clientId: this.currentClientId,
      };
      this.getClientSubscriptions(qObj)
        .then((r) => {
          this.$_log("----123...");
          this.$_log("r:", r);
          this.$_log("----123a");
          /*this.$_log(
            "r.data.customerSubscriptions.data.length:" +
              r.data.customerSubscriptions.data.length
          );
		  */
          this.$_log("----123b:", typeof r.customerSubscriptions);
          this.$_log("----123bb");
          if (r.customerSubscriptions.data.length == 0) {
            this.license = "Free";
          } else {
            this.license = "Commercial";
          }
          this.$_log("==============================");
          this.$_log("----123c");
        })
        .then(() => {
          this.$_log(
            "this.subscriptionStripeCustomerId:" +
              this.subscriptionStripeCustomerId
          );
          if (this.license == "Commercial") {
            stripeService
              .getStripeBillingSessionUrl(
                this.subscriptionStripeCustomerId,
                this.currentClientId
              )
              .then((r) => {
                this.$_log("getStripeBillingSessionUrl r:", r);
                this.stripeSessionUrl = r.data.billingSessionUrl;

                this.dataReady = true;
              })
              .then(() => {
                //this.$_log("2 this.msr:", this.msr);
                this.msrWidth = this.msr.offsetWidth;
                this.msrHeight = this.msr.offsetHeight;
                this.updateProgressBar();
              })
              .catch((error) => {
                this.$_log(error);
              });
          } else {
            this.stripeSessionUrl = "/msSubscriptionsView/0"; //
            this.dataReady = true;
            this.$_log("Free test subscription ......");
            setTimeout(() => {
              if (this.msr === null) {
                this.$_log("no way !");
              } else {
                this.$_log(
                  "Free test subscription ......this.msr.offsetWidth:" +
                    this.msr.offsetWidth
                );
                this.msrWidth = this.msr.offsetWidth;
                this.msrHeight = this.msr.offsetHeight;
                this.updateProgressBar();
              }
            }, 500);
          }
        })
        .catch((error) => {
          this.$_log(error);
        });
    },
  },
};
</script>

<style scoped>
.mysub {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mysub_container {
  height: 600px;
  flex-grow: 1;
}

.mysub_title {
  margin-top: 44px;
  border-bottom: 1px solid #93939d47;
  margin-bottom: 26px;
  padding-bottom: 13px;
  display: grid;
  grid-template-rows: 40px;
  grid-template-columns: 1fr 96px;
}

.mysub_title h3 {
  margin: 0px;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
}
.mysub_title hr {
  margin: 16px 0px 32px;
  flex-shrink: 0;
  border-style: solid;
  width: 100%;

  border-width: 1px;
  border-color: rgb(235, 239, 243);
}

.mysub_board {
  border-radius: 10px;
  border: 1px solid black;
  display: inline-flex;
  height: 200px;
  width: 100%;
}

.mysub_name {
  width: 30%;
  background: linear-gradient(
    135deg,
    rgb(96, 238, 208) 0%,
    rgb(202, 252, 232) 100%
  );
  background-blend-mode: multiply;
  padding: 24px;
  border-radius: 10px 0px 0px 10px;

  display: block;
  text-align: left;
}

.mysub_name div {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;

  align-items: flex-end;
}

.mysub_name h4 {
  margin: 0px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0px;
}

.mysub_name h6 {
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0px;
}

.mysub_name p {
  margin: 0px;
  font-weight: 400;
  letter-spacing: 0px;

  font-size: 14px;
  line-height: 22px;
  padding-left: 4px;
}

.mysub_name span {
  margin: 0px;
  font-weight: 400;
  letter-spacing: 0px;

  line-height: 18px;
  font-size: 12px;
  white-space: pre-line;
}

.mysub_details_root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mysub_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mysub_details_header {
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  /*line-height: 28px;*/
  letter-spacing: 0px;
}

.mysub_details_links {
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0px;
}

.mysub_details_msg {
  margin: 12px 0px 16px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}

.mysub_details_progress_container {
  display: flex;
  align-items: center;
}

.mysub_details_start {
  margin: 0px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0px;

  color: rgb(99, 115, 129);
  margin-right: 10px;
}

.mysub_details_progress {
  height: 7px;
  background-color: #ccc;
  display: flex;
}

.alreadyUsedTime {
  background-color: red;
  height: 3px;

  margin-top: 2px;
}

.toBeUsedTime {
  background-color: #45d145;
  height: 3px;

  margin-top: 2px;
}

.mysub_details_stop {
  margin: 0px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0px;

  color: rgb(99, 115, 129);
  margin-left: 10px;
}

.msubLink {
  text-decoration: none;
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0px;
  color: #2c3e50;
}
</style>
