//var axios = require("axios");

var toolBox = function () {};

/*
 */
toolBox.prototype._log = function (str, obj, importance) {
  var enableLog = 1; //1|0
  var now = new Date();

  //if (typeof process.env.VUE_APP_DISABLE_LOGS != "undefined") {
  //if (process.env.VUE_APP_DISABLE_LOGS == 0) {
  let t = toolBox.prototype.formatDate(now, "yyyy-MM-dd hh:mm:ss");
  let prefix = "" + t + "] ";
  let resp = "";
  if (typeof importance !== "undefined") {
    resp = prefix + "" + str;
  } else {
    resp = prefix + "" + str;
  }

  if (enableLog == 1) {
    if (resp != "") {
      console.log(resp);
    }
    if (typeof obj != "undefined") {
      if (obj != null) console.log(prefix + "", obj);
    }
  }
  //}
  //return 1;
  //} else {
  //return 0;
  //}
};

toolBox.prototype.time2sec = function (s, expectedFormat) {
  if (typeof expectedFormat == "undefined") {
    expectedFormat = "hh:mm:ss";
  }
  // s is by default in format 'hh:mm:ss'
  if (expectedFormat == "hh:mm:ss") {
    try {
      let T = s.split(":");
      return (
        parseInt(T[0], 10) * (60 * 60) +
        parseInt(T[1], 10) * 60 +
        parseInt(T[2], 10)
      );
    } catch (e) {
      console.log("time2sec err:", e);
      return 0;
    }
  }
  if (expectedFormat == "mm:ss") {
    try {
      let T = s.split(":");
      return (
        parseInt(0, 10) * (60 * 60) +
        parseInt(T[0], 10) * 60 +
        parseInt(T[1], 10)
      );
    } catch (e) {
      console.log("time2sec err:", e);
      return 0;
    }
  }
};

toolBox.prototype.HHMMSStoTxt = function (time) {
  let T = time.split(":");
  let text = "";
  let h = "",
    m = "",
    s = "";
  h = Math.ceil(T[0]).toString();
  m = Math.ceil(T[1]).toString();
  s = Math.ceil(T[2]).toString();

  if (h != "" && h != "0") {
    text = text + h + " hours";
  }
  if (m != "" && m != "0") {
    if (h != "" && h != "0") {
      text += ", ";
    }
    text = text + m + " minutes";
  }
  if (s != "" && s != "0") {
    if (m != "" && m != "0") {
      text += ", ";
    }
    text = text + s + " seconds";
  }
  return text;
};

toolBox.prototype.toHHMMSS = function (s, t_format) {
  let tf = "hh:mm:ss";
  if (typeof t_format == "undefined") {
    t_format = tf;
  }
  var sec_num = parseInt(s, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  let r = "";
  switch (t_format) {
    case "hh:mm:ss": {
      r = hours + ":" + minutes + ":" + seconds;
      break;
    }
    case "mm:ss": {
      if (parseInt(hours, 10) > 0) {
        r = parseInt(hours, 10) * 60 + parseInt(minutes, 10) + ":" + seconds;
      } else {
        r = minutes + ":" + seconds;
      }

      break;
    }
    case "ss": {
      r = sec_num;
      break;
    }
  }
  return r;
};

toolBox.prototype.formatDate = function (x, y) {
  if (y == "en_locale") {
    const options = {
      // weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return x.toLocaleDateString("en-US", options);
  } else {
    var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
    });

    return y.replace(/(y+)/g, function (v) {
      return x.getFullYear().toString().slice(-v.length);
    });
  }
};

toolBox.prototype.toCamelCase = function (str) {
  /*
  return str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
	*/
  return str
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

//
toolBox.prototype.getImageUrl = function (imgName) {
  let images = require.context("@/assets/", false, /\.png$|\.jpg$|\.svg$/);
  return images("./" + imgName);
};

//
toolBox.prototype.replaceMainBackground = function (param, landscapeView) {
  let x;
  let changeBg = 1;
  toolBox.prototype._log("replaceMainBackground typeof param:" + typeof param);

  if (typeof landscapeView == "undefined") {
    landscapeView = 0;
  }
  if (param === null) return;

  if (typeof param == "string") {
    x = param; // path to file
    if (
      x.indexOf("data:image/jpeg;base64,") == -1 &&
      x.indexOf("/img/") == -1
    ) {
      x = "data:image/jpeg;base64," + x;
    }
  } else {
    try {
      if (typeof param.tagName != "undefined") {
        toolBox.prototype._log(
          "replaceMainBackground param:[" + param.tagName + "]"
        );
        if (param.tagName.toUpperCase() == "CANVAS") {
          //this.log("canvas:", param.toDataURL("image/jpeg"));
          x = param.toDataURL("image/jpeg");
        }
      } else {
        changeBg = 0;
      }
    } catch (e) {
      toolBox.prototype._log("try catch.... e:", e);
      x = "data:image/jpeg;base64," + param;
    }
  }
  if (changeBg == 1) {
    let d = document.querySelector(".mobileL");
    if (param.length > 200) {
      x = "data:image/jpeg;base64," + param;
    }

    //this.log("new bg:" + x);
    d.style.backgroundImage = "url('" + x + "')";
    if (landscapeView == 1) {
      d.style.backgroundPosition = "50% 0%";
    }
    /*
    if (this.mCurrentCreation.videoW > this.mCurrentCreation.videoH) {
      d.style.backgroundPosition = "50% 0%";
    }
    */
  }
};

module.exports = new toolBox();
