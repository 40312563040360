<template>
  <!-- ######################################################### > -->
  <div v-if="this.importingData == true" class="loading">
    <loader msg="Composing" bottomMsg="" showAdditionalMessage="studioLight" />
  </div>
  <!-- ######################################################### < -->

  <div ref="root_createMusicAdjust" class="adjust_cnt">
    <div v-if="dataReady" id="adjustblk" class="dlg_form_container">
      <div class="dlg_c_l1">
        <p class="dlg_form_header">Adjust the music</p>
      </div>

      <!-- sliders new version ==> -->
      <div id="adjustBlk">
        <div class="sl_cmprAdjust">
          <!-- sliders >> -->
          <div class="streamParamGridContainer">
            <!-- ++++++ -->
            <div class="sldBlk">
              <div class="sliderTitle">Intensity</div>
              <div class="streamParamLegend">
                <div>Relax</div>
                <div>Intense</div>
              </div>
              <div class="sliderCnt">
                <Slider
                  v-model="intensitySlider"
                  :min="parseInt(1)"
                  :max="parseInt(9)"
                  :tooltips="false"
                  showTooltip="drag"
                />
              </div>
            </div>

            <!-- ++++++ -->
            <div class="sldBlk">
              <div class="sliderTitle">
                Tempo
                <sup
                  class="gradient01"
                  @click="goToProRegistration"
                  style="cursor: pointer"
                  >PRO</sup
                >
              </div>
              <div class="streamParamLegend">
                <div>Slow</div>
                <div>Fast</div>
              </div>
              <div class="sliderCnt">
                <Slider
                  v-model="tempoSlider"
                  :min="parseInt(1)"
                  :max="parseInt(9)"
                  :tooltips="false"
                  :disabled="true"
                  showTooltip="drag"
                />
              </div>
            </div>

            <!-- ++++++ -->
            <div class="sldBlk">
              <div class="sliderTitle">
                Rhythm
                <sup
                  class="gradient01"
                  @click="goToProRegistration"
                  style="cursor: pointer"
                  >PRO</sup
                >
              </div>
              <div class="streamParamLegend">
                <div>Loose</div>
                <div>Rhythmic</div>
              </div>
              <div class="sliderCnt">
                <Slider
                  v-model="rythmSlider"
                  :min="parseInt(1)"
                  :max="parseInt(9)"
                  :tooltips="false"
                  :disabled="true"
                  showTooltip="drag"
                />
              </div>
            </div>

            <!-- ++++++ -->
            <div class="sldBlk">
              <div class="sliderTitle">
                Tone
                <sup
                  class="gradient01"
                  @click="goToProRegistration"
                  style="cursor: pointer"
                  >PRO</sup
                >
              </div>
              <div class="streamParamLegend">
                <div>Dark</div>
                <div>Bright</div>
              </div>
              <div class="sliderCnt">
                <Slider
                  v-model="toneSlider"
                  :min="parseInt(1)"
                  :max="parseInt(9)"
                  :tooltips="false"
                  :disabled="true"
                  showTooltip="drag"
                />
              </div>
            </div>

            <!-- ++++++ -->
            <div class="sldBlk">
              <div class="sliderTitle">
                Variation
                <sup
                  class="gradient01"
                  @click="goToProRegistration"
                  style="cursor: pointer"
                  >PRO</sup
                >
              </div>
              <div class="streamParamLegend">
                <div>Idle</div>
                <div>Vibrant</div>
              </div>
              <div class="sliderCnt">
                <Slider
                  v-model="variationSlider"
                  :min="parseInt(1)"
                  :max="parseInt(9)"
                  :tooltips="false"
                  :disabled="true"
                  showTooltip="drag"
                />
              </div>
            </div>
          </div>

          <!-- sliders >> -->
        </div>
      </div>
      <!-- sliders new version ==< -->

      <!-- ######################################################### -->

      <div class="dlg_c_l2">
        <form @submit.prevent="onSubmit">
          <div class="uvMsg_1">
            <signInGoPro msg="Want more options?" colorMsg="Sign up now!" />
          </div>

          <div class="idf_submit_line">
            <img
              src="@/assets/sl_back.png"
              @click="goToPrevStep"
              style="width: 55px; cursor: pointer"
            />
            <button
              type="submit"
              class="dlg_uploadVideo_4_submitIco idf_submit_btn idf_submit_btn_wide"
              :disabled="doNotLetHimGo"
              @click="goTo_trackIsReady_page"
            >
              Compose soundtrack
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";

import Slider from "@vueform/slider";
import loader from "@/components/elements/loader.vue";
import streamSrv from "@/services/streamService.js";
import signInGoPro from "@/components/elements/signInGoPro.vue";

import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd";

export default {
  name: "sl_createMusicAdjust",

  setup() {
    const root_createMusicAdjust = ref(null);
    const ffmpeg = new FFmpeg();
    return {
      root_createMusicAdjust,
      ffmpeg,
    };
  },

  components: { Slider, loader, signInGoPro },
  data() {
    return {
      trackDuration: null,
      trackName: null,
      videoImage: "",
      video: "",
      ClientId: "",

      // --
      intensitySlider: 5,
      tempoSlider: 5,
      rythmSlider: 5,
      toneSlider: 5,
      variationSlider: 5,
      // --

      audioList: [],
      submissionCounter: 0,
      receivedPlaylistsObj: {},
      receivedPlaylistsFlag: false,
      importingData: false,
      availableUnits: 60, // allowed track durtation in seconds
      containerDom: null,
      dataReady: false,
    };
  },
  created() {
    this.log("created");
    this.init();
  },
  mounted() {
    this.containerDom = this.root_createMusicAdjust;
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
      "currentSubscription",
    ]),
    ...mapGetters("streamModule", {
      strName: "streamName",
      strImage: "streamImage",
      strDuration: "streamDuration",
      strTags: "streamTags",
      strMotives: "streamMotives",
      strParams: "streamParams",
      strVideo: "streamVideo",
      strImage4db: "streamImage4db",
      receivedTags: "receivedTags",
      TagsToBeDisplayed: "filteredTags",
      streamUVFN: "streamUVFN",
      streamUVFT: "streamUVFT",
    }),

    doNotLetHimGo() {
      if (
        this.trackName != "" &&
        this.trackName != null &&
        this.trackDuration != "" &&
        this.trackDuration != null &&
        this.$time2sec(this.strDuration, "mm:ss") < this.availableUnits
      ) {
        let d = this.$time2sec(this.strDuration, "mm:ss");
        this.log(
          "doNotLetHimGo this.trackName:" +
            this.trackName +
            " this.trackDuration:" +
            this.trackDuration +
            " " +
            d +
            " < " +
            this.availableUnits
        );
        return false;
      } else {
        this.log("doNotLetHimGo he can go.... ");
        return true;
      }
    },
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("streamModule", {
      initStream: "initStream",
      setAudioStream: "setAudio",
      getMusicTags: "musicTags",
      getMusicMotives: "musicMotives",
      setStreamMotives: "setStreamMotives",
      setStreamTags: "setStreamTags",
      setGeneratedVideo: "setGenVideo",
    }),
    ...mapActions("clientModule", { getClientProduct: "getClientProduct" }),

    // ----->
    async transcode(generatedAudioFile, uploadedVideoFile, callback) {
      //message.value = "Loading ffmpeg-core.js";
      this.log(
        "Loading ffmpeg-core.js generatedAudioFile:" +
          generatedAudioFile +
          " uploadedVideoFile:" +
          uploadedVideoFile
      );
      this.ffmpeg.on("log", ({ message: msg }) => {
        //message.value = msg;
        this.log("Loading ffmpeg-core.js msg:" + msg);
      });
      this.ffmpeg.on("progress", ({ progress, time }) => {
        //message.value = `${progress * 100} %, time: ${time / 1000000} s`;
        this.log(`${progress * 100} %, time: ${time / 1000000} s`);
      });
      this.log("ASW 00000a");

      await this.ffmpeg.load({
        coreURL: `${baseURL}/ffmpeg-core.js`,
        wasmURL: `${baseURL}/ffmpeg-core.wasm`,
        workerURL: `${baseURL}/ffmpeg-core.worker.js`,
      });

      this.log("ASW 00000b");

      const vid_srcFileName = "video_" + parseInt(Math.random() * 100, 10);
      this.log("vid_srcFileName:" + vid_srcFileName);
      const aud_srcFileName = "audio_" + parseInt(Math.random() * 100, 10);
      this.log("aud_srcFileName:" + aud_srcFileName);

      let msg =
        "Start transcoding [" + vid_srcFileName + "] [" + aud_srcFileName + "]";
      this.log("msg:" + msg);
      //message.value = msg;
      await this.ffmpeg.writeFile(
        vid_srcFileName,
        await fetchFile(uploadedVideoFile)
      );
      await this.ffmpeg.writeFile(
        aud_srcFileName,
        await fetchFile(generatedAudioFile)
      );

      console.log("ASW 00000c this.streamUVFT:" + this.streamUVFT);
      let T = this.streamUVFT.split("/");

      //let srcFSufix = this.streamUVFN.split(".").pop();
      let srcFSufix = T[1];
      let fileOut =
        "" +
        vid_srcFileName +
        "_" +
        aud_srcFileName +
        "_byMuzaicStudio." +
        srcFSufix.toLowerCase();

      //let fileOut ="test_" + vid_srcFileName + "_" + aud_srcFileName + "_OUT.mp4";

      try {
        await this.ffmpeg.exec([
          "-i",
          vid_srcFileName,
          "-i",
          aud_srcFileName,
          "-c:v",
          "copy",
          "-c:a",
          "mp3",
          "-map",
          "0:v:0",
          "-map",
          "1:a:0",
          "-shortest", // to adjust audio length to video length
          fileOut,
        ]);
        this.log("ASW 00001");
        //message.value = "Complete transcoding";
        const data = await this.ffmpeg.readFile(fileOut);
        this.log("ASW 00002 typeof(data):" + typeof data);

        /// THE RESULT !!! >>
        let contentTypeStr = "";
        if (srcFSufix.toLowerCase() == "mov") {
          contentTypeStr = "video/quicktime";
        } else {
          contentTypeStr = "video/" + srcFSufix.toLowerCase();
        }

        let v = URL.createObjectURL(
          new Blob([data.buffer], { type: contentTypeStr })
        );
        console.log("typeof(v):" + typeof v);
        this.setGeneratedVideo(v);

        //document.querySelector("#testVideo").src = v;
        //video.value = URL.createObjectURL(new Blob([data.buffer], { type: "video/mp4" }));
        /// THE RESULT !!! <<

        this.log("ASW 00003");
        callback(null, v);
      } catch (e) {
        this.log("ASW 00003 error:", e);
        callback(e);
      }
    },
    // -----<

    goToPrevStep() {
      this.log("goToPrevStep ... A");
      this.$router.push({
        name: "sl_mob_moods",
      });
      this.log("goToPrevStep ... Z");
    },

    //
    goToProRegistration() {
      this.$router.push({
        name: "sl_mob_msSubscriptionsView",
        params: { id: 1 },
      });
    },

    //
    goTo_trackIsReady_page() {
      this.getSingleFile();
    },
    //
    log(str, obj) {
      if (typeof obj == "undefined") {
        this.$_log(this.$options.name + "] " + str);
      } else {
        this.$_log(this.$options.name + "] " + str, obj);
      }
    },

    //
    init() {
      this.log("init...");
      this.dataReady = true;
      this.$replaceMainBackground(this.strImage);

      this.trackDuration = this.strDuration;
      this.trackName = this.strName;
      this.videoImage = this.strImage;
      this.video = this.strVideo;
      this.ClientId = this.currentClientId;
      //
      this.adjustSlidersFormScratch();
    },

    //
    onSubmit() {
      const track = {
        //...this.mCurrentUser,
        name: this.strName,
        duration: this.strDuration,
        clientId: this.ClientId,
        userId: this.currentUserId,
      };

      this.log("onSubmit - track:", track);
    },

    //
    getSingleFile() {
      this.log(this.trackDuration);
      this.log(
        "getSingleFile [this.trackDuration:" +
          this.trackDuration +
          ", length:" +
          this.$time2sec(this.trackDuration, "mm:ss") +
          "]name -->" +
          this.trackName
      );

      //this.stopCurrentPlayer();

      // change styles >>>
      // disable sticky footer...
      //this.containerDom.querySelector("#cmpr_footer").style.position ="absolute";
      // <<<

      this.receivedPlaylistsFlag = false;
      var jsonD = {
        motiveId: parseInt(this.strMotives[0], 10),
        params: {
          intensity: parseInt(this.intensitySlider, 10),
          tempo: parseInt(this.tempoSlider, 10),
          rhythm: parseInt(this.rythmSlider, 10),
          tone: parseInt(this.toneSlider, 10),
          variance: parseInt(this.variationSlider, 10),
        },
        //  this.intensitySlider +"" + this.tempoSlider + "" +this.rythmSlider + "" + this.toneSlider + "" + this.variationSlider,
        time: this.$time2sec(this.trackDuration, "mm:ss"),
      };

      this.log("streamSrv.createSingleFile:", jsonD);
      this.importingData = true; // this flag enable 'loading' view that covers 'adjust' view

      streamSrv
        .createSingleFile(jsonD)
        .then((r) => {
          this.log("!!!!!!!! r:", r);

          if (typeof r.data != "undefined") {
            if (typeof r.data.errors != "undefined") {
              this.log("error:" + r.data.errors);
              var n = {
                type: "problem",
                message: "error:" + r.data.errors + "",
              };
              this.addNotificationMessage(n);
            } else {
              this.log("crateMusicDetails] getSingleFile before doSubmit");

              this.log(" START OF TRANSCODING ======================= >>");

              this.transcode(r.data.mp3, this.strVideo, (err, resultVideo) => {
                if (err) {
                  this.log("problem during video generation.... err:", err);
                  this.log("========================================== ===");
                } else {
                  this.log("file generated !!! huraaa :) r:", resultVideo);

                  this.importingData = false; // this flag disable 'loading' view that covers 'adjust' view
                  this.log(" END OF TRANSCODING ======================= <<");
                  this.doSubmit(r); // simply save data in the db ....
                }
              });
            }
          }
        })
        .finally(() => {
          this.log("getSingleFile finally ...");
        });
    },

    //
    collectTagsArray() {
      let musicTags = [];
      this.log("collectData typeof(this.strTags):" + typeof this.strTags);
      if (typeof this.strTags != "undefined" && this.strTags !== null) {
        for (const [key, value] of Object.entries(this.strTags)) {
          this.$_log(key);
          musicTags.push(value);
        }
      } else {
        this.log("collectData musicTags wasnt defined in previous step ....");
      }
      return musicTags;
    },
    //
    collectData(trackUrl) {
      let musicParams =
        this.intensitySlider +
        "" +
        this.tempoSlider +
        "" +
        this.rythmSlider +
        "" +
        this.toneSlider +
        "" +
        this.variationSlider;

      /*	
      let musicTags = [];
      this.log("collectData typeof(this.strTags):" + typeof this.strTags);
      if (typeof this.strTags != "undefined" && this.strTags !== null) {
        for (const [key, value] of Object.entries(this.strTags)) {
          this.$_log(key);
          musicTags.push(value);
        }
      } else {
        this.log("collectData musicTags wasnt defined in previous step ....");
      }
	  */
      let musicTags = this.collectTagsArray();

      let musicMotives = [];
      for (const [key, value] of Object.entries(this.strMotives)) {
        this.$_log(key);
        musicMotives.push(value);
      }

      let receivedTracks = [];
      receivedTracks.push(trackUrl);

      return [musicParams, musicTags, musicMotives, receivedTracks];
    },
    //
    // finally save data to the DB .... =>
    doSubmit(r) {
      this.log("crateMusicDetails] getSingleFile doSubmit r:", r);
      const [musicParams, musicTags, musicMotives, receivedTracks] =
        this.collectData(r.data.mixMp3);

      let trackName = this.trackName;
      if (this.submissionCounter > 0) {
        let regex = /__\(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\)/g;
        let R = this.trackName.match(regex);
        this.log("0 doSubmit R:", R);
        this.log("1 doSubmit this.trackName:" + this.trackName);
        if (R !== null) {
          this.trackName = this.trackName.substring(
            0,
            this.trackName.indexOf("__(")
          );
          this.log("2 doSubmit this.trackName:" + this.trackName);
        }
        this.trackName =
          this.trackName +
          "__(" +
          this.$formatDate(new Date(), "yyyy-MM-dd hh:mm:ss") +
          ")";
        this.log("3 doSubmit this.trackName:" + this.trackName);
      }
      this.submissionCounter++;
      /////////////////////////////////////
      // DATA TO BE SENT
      const obj = {
        // --
        Client_id: this.currentClientId,
        name: trackName,
        remarks: "(created using muzaic.studio.light ver:0.1) " + this.remarks,
        time: this.$time2sec(this.trackDuration, "mm:ss"),
        params: musicParams,
        tags: musicTags,
        motives: musicMotives,
        receivedTracks: receivedTracks,
        user_id: this.currentUserId,

        length: this.trackDuration,
        image: this.strImage4db,
        // --
      };
      /////////////////////////////////////

      this.log("stream:add new onSubmit - stream:", obj);

      streamSrv
        .createSingleStream(obj)
        .then((response) => {
          this.log(
            "createMusicDetails] doSubmit, response.data:",
            response.data
          );
          //todo: add notification !!!
          if (response.data.newStreamId !== null) {
            this.newStreamId = response.data.newStreamId;
            this.log(
              "createMusicDetails] doSubmit this.newStreamId:" +
                this.newStreamId
            );

            ///=>
            this.receivedPlaylistsFlag = true;
            this.receivedPlaylistsObj = r.data;

            this.log("doSubmit r.data:", r.data);
            this.setAudioStream({
              name: trackName,
              url: r.data.mp3,
              id: this.newStreamId,
              mixMp3: r.data.mixMp3,
            });
            // --
            this.log("Before 0 this.audioList:", this.audioList);
            this.audioList = [];
            // --
            this.log("Before 1 this.audioList:", this.audioList);
            this.audioList.push({
              name: trackName,
              url: r.data.mp3,
              id: this.newStreamId,
              mixMp3: r.data.mixMp3,
            });
            this.log("After this.audioList:", this.audioList);

            ///=<
          }
          var n = {
            type: "success",
            message: "You have just correctly created a new stream.",
          };
          this.addNotificationMessage(n);

          this.$router.push({
            name: "sl_mob_trackIsReady",
          });
        })
        .catch((error) => {
          var n;
          if (typeof error.response != "undefined") {
            this.log("asw error:", error.response.data);
            n = {
              type: "problem",
              message:
                "New stream cant be created. error:" + error.response.message,
            };
            this.addNotificationMessage(n);
          } else {
            this.log("asw error:", error);
            n = {
              type: "problem",
              message: "New stream cant be created. error:" + error,
            };
            this.addNotificationMessage(n);
          }
        });
    },

    // adjust sliders >>> //////////////////////////
    // ------------------------------------------------------------
    adjustSlidersFormScratch() {
      this.resetSliders();
      let musicTags = this.collectTagsArray();
      for (let x = 0; x < musicTags.length; x++) {
        this.adjustSliders(musicTags[x], 1);
      }
    },
    // ------------------------------------------------------------
    resetSliders() {
      this.intensitySlider = 5;
      this.tempoSlider = 5;
      this.rythmSlider = 5;
      this.toneSlider = 5;
      this.variationSlider = 5;
    },
    // ------------------------------------------------------------
    adjustSliders(tagId, actionDirrection) {
      //let currentValue = 0,
      //  currentChange = 0;
      let clickedTag = [];
      for (let x = 0; x < this.TagsToBeDisplayed.length; x++) {
        for (let y = 0; y < this.TagsToBeDisplayed[x].length; y++) {
          if (this.TagsToBeDisplayed[x][y].id == tagId) {
            clickedTag = this.TagsToBeDisplayed[x][y];
            this.$_log("Mam cię!:", this.TagsToBeDisplayed[x][y]);
          }
        }
      }

      //intensity ==>
      //this.$_log("adjustSliders actionDirrection:" + actionDirrection);
      //this.$_log("--------------------------------------------------");
      //this.$_log("this.intensitySlider");
      this.intensitySlider = this.countSliderChange(
        this.intensitySlider - 4,
        parseInt(clickedTag["int_bonus"], 10) * parseInt(actionDirrection, 10)
      );
      //this.$_log("this.tempoSlider");
      this.tempoSlider = this.countSliderChange(
        this.tempoSlider - 4,
        parseInt(clickedTag["tem_bonus"], 10) * parseInt(actionDirrection, 10)
      );
      //this.$_log("this.rythmSlider");
      this.rythmSlider = this.countSliderChange(
        this.rythmSlider - 4,
        parseInt(clickedTag["rhy_bonus"], 10) * parseInt(actionDirrection, 10)
      );
      //this.$_log("this.toneSlider");
      this.toneSlider = this.countSliderChange(
        this.toneSlider - 4,
        parseInt(clickedTag["ton_bonus"], 10) * parseInt(actionDirrection, 10)
      );
      //this.$_log("this.variationSlider");
      this.variationSlider = this.countSliderChange(
        this.variationSlider - 4,
        parseInt(clickedTag["var_bonus"], 10) * parseInt(actionDirrection, 10)
      );
    },

    // ------------------------------------------------------------
    countSliderChange(currentValue, currentChange) {
      //this.$_log("==----->[currentValue]:" + currentValue);
      //this.$_log("==----->[currentChange]:" + currentChange);

      if (currentValue >= 0 && currentChange >= 0) {
        //this.$_log("case 1");
        currentValue = Math.max(parseInt(currentValue, 10), currentChange);
      } else if (
        (currentValue < 0 && currentChange > 0) ||
        (currentValue > 0 && currentChange < 0)
      ) {
        //this.$_log("case 2");
        currentValue = Math.round(
          (parseInt(currentValue, 10) + currentChange) / 2
        );
      } else if (currentValue <= 0 && currentChange <= 0) {
        //this.$_log("case 3");
        currentValue = Math.min(parseInt(currentValue, 10), currentChange);
      }

      //this.$_log("==+++++>[countedValue]:" + currentValue);
      currentValue = currentValue + 4;
      if (currentValue > 9) {
        currentValue = 9;
      }
      if (currentValue < 1) {
        currentValue = 1;
      }
      //this.$_log("==+++++>[countedValue1]:" + currentValue);
      return currentValue;
    },

    // adjust sliders <<< //////////////////////////
  },
};
</script>

<style scope>
.sliderTitle {
  text-align: center;
  margin: 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0px;
}
.gradient01 {
  font-size: 11px;
}
.sldBlk {
  padding-top: 19px;
}
.sliderCnt {
  padding: 10px 0px 10px 0px;
}
.streamParamLegend {
  display: flex;
  justify-content: space-between;
}
.streamParamLegend div {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: right;
}

.streamParamGridContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 15fr;
  width: 310px;
}

.sl_cmprAdjust {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileL form div.idf_submit_line {
  gap: 24px 10px;
  height: auto;
  padding-bottom: 24px;
  padding-top: 10px;
}

/** ====== */

/*@media only screen and (max-width: 640px) .cmprAdjust*/
.mobileL .cmprAdjust {
}
.mobileL .sd_l {
  text-align: left;
}
.mobileL .sd_r {
  text-align: right;
}
.adjust_cnt {
  display: flex;
  width: 100%;
}

.adjust_cnt .dlg_form_container {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.9) 0%,
    #ffffff 100%
  );
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  width: 100%;
}

.adjust_cnt .dlg_uploadVideo_0 {
  height: 480px;
  border: 0px solid rgb(196, 205, 213);
}

.adjust_cnt .dlg_uploadVideo_1_Circle {
  border: 1px dashed var(--main-blue-color);
  width: 126px;
  height: 126px;
}

.adjust_cnt .button_uv {
  border: 1px dashed var(--main-blue-color);
}

.adjust_cnt .dlg_form_header {
  text-align: center;
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  padding: 24px 0px 0px 0px;
}

.adjust_cnt .dlg_form_header .cmh {
  padding: 24px 0px 20px 0px;
}

.adjust_cnt .dlg_text {
  text-align: center;
}

.adjust_cnt .ms_inputbox_0 {
  position: relative;
  padding-bottom: 21px;
}
.adjust_cnt .ms_inputbox_0a {
  position: relative;
  padding-bottom: 5px;
}

.adjust_cnt .ms_inputbox_0.uv_inp_msg {
  position: relative;
  padding: 0px 0px 0px 0px;
}

.adjust_cnt .dlg_form_container fieldset {
  inset: -5px 0px 0px;
}

.adjust_cnt .dlg_form_container input {
  background-color: white;
}

#idf_time_disabled {
  opacity: 25%;
}

#idf_time_disabled input:hover + fieldset {
  border-color: rgba(0, 0, 0, 0.23);
}
.adjust_cnt .uvMsg {
  font-size: 12px;
  line-height: 18px;
  color: var(--main-gray500-color);
  text-align: left;
  padding: 4px 0px 20px 20px;
}

.adjust_cnt .uvMsg_1 {
  font-size: 12px;
  line-height: 18px;
  color: var(--main-gray500-color);
  text-align: left;
  padding: 18px 0px 0px 20px;
}

.adjust_cnt .dlg_msg {
  margin-bottom: 11px;
}

.adjust_cnt .dlg_msg a {
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  line-height: 18px;
  color: var(--main-sec-color);
  padding-right: 30px;
}
.adjust_cnt .dlg_msg a:visited {
  color: var(--main-sec-color);
}

.adjust_cnt .dlg_lgn_row {
  padding-top: 4px;
  padding-bottom: 8px;

  color: var(--main-gray500-color);
}

.adjust_cnt .dlg_lgn_row p {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  text-decoration: none;

  margin: 9px 0px 16px 0px;
}

.mobileL .sliderRow {
  height: auto;
}

/* ################################################################# >>> */
/* ################################################################# >>> */

/* ################################################################# <<< */
</style>
