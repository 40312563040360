<template>
  <nav class="topNavBar">
    <!--
    <router-link to="/msSubscriptionsView/0">
      <div class="topNavBarItem_W">Subscription</div>
    </router-link>

    <router-link to="/createMusicHeaderView">
      <div class="topNavBarItem_B">Create Music</div>
    </router-link>
    -->

    <template v-if="isItPayWall"></template>
    <template v-else>
      <router-link to="/createMusicHeaderView">
        <div
          v-if="this.$route.name == 'createMusicHeaderView'"
          class="topNavBarItem active"
        >
          Composer
        </div>
        <div v-else class="topNavBarItem">Composer</div>
      </router-link>
      <router-link :to="'/userCreations/0/' + currentClientId">
        <div
          v-if="this.$route.name == 'userCreationsView'"
          class="topNavBarItem active"
        >
          My music
        </div>
        <div v-else class="topNavBarItem">My music</div>
      </router-link>
      <router-link to="/msSubscriptionsView/0/">
        <div
          v-if="this.$route.name == 'msSubscriptionsView'"
          class="topNavBarItem active"
        >
          Buy
        </div>
        <div v-else class="topNavBarItem">Buy</div>
      </router-link>
    </template>
  </nav>
</template>

<script>
//import dropdownMenu from "@/components/navigation/DropdownMenu_Layout.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserTopNavBar",
  props: { title: String },
  //components: {dropdownMenu,},
  data() {
    return {
      /////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////
      configServices: [
        {
          title: "Subscriptions",
          link: "msSubscriptionsView",
          icon: "",
          fun: () => {
            this.$router.push({
              name: "msSubscriptionsView",
              //params: { id: Number(this.currentUserId) },
              params: { pageNr: 0 },
            });
          },
        },
        {
          title: "Create Music",
          link: "createMusicHeaderView",
          icon: "",
          fun: () => {
            this.$router.push({
              name: "createMusicHeaderView",
              //params: { id: Number(this.currentUserId) },
              params: { pageNr: 0 },
            });
          },
        },
      ],
    };
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "userType",
      "currentClientFN",
      "currentClientLN",
      "currentClientId",
      "currentSubscription",
    ]),

    isItPayWall() {
      if (this.$route.path.indexOf("msSubscriptionsView") != -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.topNavBar {
  flex-wrap: wrap;
  gap: 20px;
}

.topNavBar a {
  text-decoration: none;
  display: inline-block;
}
.topNavBarItem {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  padding-bottom: 12px;
  padding-top: 12px;
  color: var(--main-gray500-color);
  border-bottom: 2px solid rgba(255, 255, 255, 1);
}

.topNavBarItem:hover {
  color: var(--main-gray900-color);
}
.topNavBarItem:active {
  border-bottom: 2px solid var(--main-blue-color);
}

.topNavBarItem.active {
  border-bottom: 2px solid var(--main-blue-color);
  color: var(--main-gray900-color);
}

.topNavBarItem_B {
  border: 1px solid var(--main-blue-color);
  background-color: var(--main-blue-color);
  padding: 8px 24px 8px 24px;
  margin: 0px 0px 0px 0px;
  border-radius: 8px;

  color: var(--main-bg-color);

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: none;
}
.topNavBarItem_B:hover {
  border: 1px solid var(--main-bluedarker-color);
  background-color: var(--main-bluedarker-color);
  /*color: var(--main-bg-color);*/
}

.topNavBarItem_W {
  border: 1px solid var(--main-blue-color);
  background-color: var(--main-bg-color);
  padding: 8px 24px 8px 24px;
  margin: 0px 0px 0px 0px;
  border-radius: 8px;

  color: var(--main-blue-color);

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: none;
}

.topNavBarItem_W:hover {
  border: 1px solid var(--main-blue-color);
  /*background-color: var(--main-blueDarker-color);*/
  background-color: var(--main-gray200-color);
  /*color: #fff;*/
}
</style>
