<template>
  <div class="mc2">
    <div v-if="this.dataReady == false" class="loading">
      <loader msg="" bottomMsg="" />
    </div>
    <template v-if="context == 0">
      <!-- view for SM -->
      <div v-if="dataReady == true" class="sub_container">
        <div>
          <h3 class="sub_title">Subscription</h3>
          <div class="sub_radiogroup">
            <BaseRadioGroup
              v-model="billingRecurringInterval.value"
              :options="RecurringIntervalObj"
              name="billingInterval"
              @radioUpdated="radioElemUpdated"
            />
          </div>
        </div>

        <div class="sub_details_container">
          <template v-for="(r, r_index) in Products" :key="r.id">
            <template v-if="r.prices.length > 0">
              <div :class="computedCssClass(r_index, currentPriceIdx)">
                <template
                  v-if="typeof r.prices[currentPriceIdx] != 'undefined'"
                >
                  <template v-if="r.prices[currentPriceIdx].sessionUrl == ''">
                    <div class="ribbon ribbon-top-right">
                      <span>Your choice</span>
                    </div>
                  </template>
                </template>

                <div class="sub_board_img">
                  <img :src="r.images[0]" />
                  <!--
              <template v-if="typeof r.prices[currentPriceIdx] != 'undefined'">
                <p
                  v-if="r.prices[currentPriceIdx].sessionUrl == ''"
                  class="yourChoice"
                >
                  Your choice
                </p>
              </template>
			  -->
                </div>
                <div class="sub_board_title">{{ r.name }}</div>
                <div class="sub_board_description">{{ r.description }}</div>
                <div
                  v-if="typeof r.prices[currentPriceIdx] != 'undefined'"
                  class="sub_board_price"
                >
                  <h1 class="sub_board_price_value">
                    $ {{ r.prices[currentPriceIdx].unit_amount / 100 }}
                  </h1>
                  <h6 class="sub_board_price_period">
                    / {{ r.prices[currentPriceIdx].recurring.interval }}
                  </h6>
                </div>
                <div v-else class="sub_board_price">
                  <h1 class="sub_board_price_value">Free</h1>
                </div>

                <hr class="sub_board_hr" />

                <div v-if="r.metadata.Feature1">
                  <ul class="sub_board_ul">
                    <li>
                      <img :src="$getImageUrl('Include.svg')" />{{
                        r.metadata.Feature1
                      }}
                    </li>
                    <li v-if="r.metadata.Feature2">
                      <img :src="$getImageUrl('Include.svg')" />{{
                        r.metadata.Feature2
                      }}
                    </li>
                    <li v-if="r.metadata.Feature3">
                      <img :src="$getImageUrl('Include.svg')" />{{
                        r.metadata.Feature3
                      }}
                    </li>
                    <li v-if="r.metadata.Feature4">
                      <img :src="$getImageUrl('Include.svg')" />{{
                        r.metadata.Feature4
                      }}
                    </li>
                  </ul>
                </div>

                <div v-if="r.name != 'Muzaic Studio FREE Plan'">
                  <!--<button@click="computedSessionLink(r_index, r.prices[currentPriceIdx].id)">computedSessionLink(r_index, r.prices[currentPriceIdx].id)</button>-->

                  <template
                    v-if="typeof r.prices[currentPriceIdx] != 'undefined'"
                  >
                    <template v-if="r.prices[currentPriceIdx].sessionUrl != ''">
                      <a :href="r.prices[currentPriceIdx].sessionUrl">
                        <button class="blueBtn">Checkout</button>
                      </a>
                    </template>
                    <template v-else>
                      <a href="/createMusicHeaderView">
                        <button class="blueBtn">Create Music</button>
                      </a></template
                    >
                  </template>
                  <template v-else>
                    <a href="/createMusicHeaderView">
                      <button class="blueBtn">Create Music</button>
                    </a></template
                  >
                </div>

                <div v-else>
                  <a href="/createMusicHeaderView">
                    <button class="blueBtn">Create Music</button>
                  </a>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </template>
    <template v-else-if="context == 1">
      <!-- view for SL -->
      <div v-if="dataReady == true" class="sub_container">
        <div>
          <h3 class="sub_title">Subscription [{{ context }}]</h3>
          <div class="sub_title_txt">
            Muzaic.studio Free is available only on mobile devices.
            Muzaic.studio Personal is available on desktop and mobile devices.
          </div>
          <div class="sub_radiogroup">
            <BaseRadioGroup
              v-model="billingRecurringInterval.value"
              :options="RecurringIntervalObj"
              name="billingInterval"
              @radioUpdated="radioElemUpdated"
            />
          </div>
        </div>

        <div class="sub_details_container">
          <template v-for="(r, r_index) in Products" :key="r.id">
            <template v-if="r.prices.length > 0">
              <div :class="computedCssClass(r_index, currentPriceIdx)">
                <div class="sub_board_title">Personal</div>
                <div class="sub_board_description">for your private use</div>
                <div class="sub_board_price">
                  <h1 class="sub_board_price_value">
                    $ {{ r.prices[currentPriceIdx].unit_amount / 100 }}
                  </h1>
                  <h6 class="sub_board_price_period">
                    / {{ r.prices[currentPriceIdx].recurring.interval }}
                  </h6>
                </div>

                <hr class="sub_board_hr" />

                <div>
                  <ul class="sub_board_ul">
                    <li>
                      <img :src="$getImageUrl('Include.svg')" />Extremely fast
                      soundtrack your video
                    </li>
                    <li>
                      <img :src="$getImageUrl('Include.svg')" />Access with
                      mobile and desktop
                    </li>
                    <li>
                      <img :src="$getImageUrl('Include.svg')" />20 minutes song
                      duration limit
                    </li>
                    <li>
                      <img :src="$getImageUrl('Include.svg')" />Up to 10 hrs of
                      music monthly
                    </li>
                    <li>
                      <img :src="$getImageUrl('Include.svg')" />All standard
                      moods & genres
                    </li>
                    <li>
                      <img :src="$getImageUrl('Include.svg')" />Private &
                      commercial license for social media
                    </li>
                    <li>
                      <img :src="$getImageUrl('Include.svg')" />Knowledge base
                      support
                    </li>
                  </ul>
                </div>

                <div>
                  <a id="sub_a_co" :href="r.prices[currentPriceIdx].sessionUrl">
                    <button class="blueBtn">Checkout</button>
                  </a>

                  <a id="sub_a_cf" href="/slmuv">
                    <button class="button_W">Continue Free</button>
                  </a>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </template>

    <template v-else-if="id == 2"><!-- view for SH --></template>

    <template v-else><!-- hmm... --></template>
  </div>
</template>

<script>
/*
STRIPE:
PRODUCTS ==>
stripe products list --limit=3

PAYMENT SESSIONS ==>
get user session (in case when user already have stripe subscription): 
stripe billing_portal sessions create   --customer=cus_NrDNcnrg5gkIpU   --return-url="https://example.com/account"

get user payment session:
stripe checkout sessions create   --success-url="https://example.com/success"   -d "line_items[0][price]"=price_1MvzFOIhLlnn3BvA3cOHHwGY   -d "line_items[0][quantity]"=1 -d "customer_email"=aa@b.com  --mode=subscription
*/

import { watchEffect } from "vue";
import { mapGetters, mapActions } from "vuex";

import BaseRadioGroup from "@/components/baseFormElements/BaseRadioGroup.vue";
import stripeSrv from "@/services/stripeService.js"; // access to the APIs
import loader from "@/components/elements/loader.vue";

export default {
  name: "msSubscriptions",
  props: { id: Number }, // context; 0 - StudioMedium, 1 - StudioLight
  components: {
    BaseRadioGroup,
    loader,
  },
  data() {
    return {
      dataReady: false,
      context: this.id,
      importingPrices: true,
      stripeCustomerId: "",
      StripePersonalProduct_id: "prod_NhO1EOCyW6emgt",
      Products: [],
      currentPriceIdx: 0,
      billingRecurringInterval: { value: 0 },
      RecurringIntervalObj: [
        { label: "Billed Monthly", value: 0 },
        { label: "Billed Annually", value: 1 },
      ],
    };
  },
  created() {
    this.$_log("msSubscriptions ...");

    if (this.isUserLoggedIn) {
      watchEffect(() => {
        this.getData();
      });
    }
  },
  mounted() {
    let mobileL = document.querySelector(".mobileL");
    console.log("mobileL:", mobileL);
    if (typeof mobileL != "undefined" && mobileL != null) {
      mobileL.style = "background-color: white;background-image: none;";
    }
  },
  computed: {
    ...mapGetters("userModule", [
      "userToken",
      "isUserLoggedIn",
      "currentUserId",
      "currentUserEmail",
      "currentClientId",
      "currentClientFN",
      "currentClientLN",
    ]),
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    radioElemUpdated(e) {
      this.$_log("radioElemUpdated e:", e);
      this.currentPriceIdx = e;
    },
    computedSessionLink(idx, productPriceId) {
      let str =
        "computedLink productPriceId:" +
        productPriceId +
        " stripeCustomerId:" +
        this.stripeCustomerId +
        " userEmail:" +
        this.currentUserEmail;
      this.$_log(str);
      stripeSrv
        .getStripePaymentSessionUrl(productPriceId, this.currentUserEmail)
        .then((r) => {
          this.$_log(r.data.paymentSessionUrl);
          window.location.href = r.data.paymentSessionUrl;
          //this.Products[idx].paymentUrl = r.data.paymentSessionUrl;
          //return r.data.paymentSessionUrl;
          //return "......" + str + ".....";
        })
        .catch((error) => {
          this.$_log(error);
        });
    },
    computedCssClass(i, idx) {
      let style = "sub_product_board";
      let extraStyle = "";
      let sessionUrl;
      try {
        sessionUrl = this.Products[i].prices[idx].sessionUrl;
      } catch (err) {
        this.$_log("computedCssClass err:", err);
      }

      if (sessionUrl == "") {
        extraStyle = "currentProduct_board";
      }
      if (i % 2 === 0) {
        style = style + " ";
      } else {
        style = style + " " + "odd";
      }
      style = style + " " + extraStyle;

      return style;
    },

    getData() {
      this.$_log("Just before getStripeCustomerId....getData()...");
      stripeSrv
        .getStripCustomerId(this.currentUserEmail)
        .then((r) => {
          this.stripeCustomerId = r.data.data;
          this.$_log("StripeCustomerId:" + this.stripeCustomerId);

          this.$_log("Just before getProductList....getData()...");

          stripeSrv
            .getStripeStudioProducts4Customer(
              this.stripeCustomerId,
              this.currentClientId,
              this.context
            )
            .then((r) => {
              this.$_log(
                "stripeSrv.getStripeStudioProducts4Customer response:",
                r
              );
              this.Products = r.data.Products.data;
              this.$_log("this.Products:", this.Products);
              if (this.context == 1) {
                // in case when context is "StudioLight" display only one product "Personal"
                this.$_log("get rid of other products then 'Personal'");
                let CCC = this.Products.filter(
                  (el) => el.id == this.StripePersonalProduct_id
                );
                this.$_log("CCC:", CCC);
                this.Products = CCC;
              }
              this.dataReady = true;
            })
            .catch((error) => {
              this.$_log("---Error:");
              this.$_log(error);
            });
        })
        .catch((error) => {
          this.$_log(error);
        });
    },
  },
};
</script>

<style src="@/assets/css/ribbon.css" />
<style scoped>
/** ************* SL >>  */
.mobileL .sub_container {
  padding: 21px 24px 10px 24px;
}
.mobileL h3 {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0px 0px 0px 0px;
}
.mobileL .sub_title_txt {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  padding: 0px 0px 24px 0px;
  color: var(--main-gray600-color);
}

.mobileL .sub_radiogroup {
  gap: 14px;
}
.mobileL .sub_board_price_value {
  font-size: 62px;
}

.mobileL .sub_board_hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mobileL .sub_board_ul li {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--main-gray600-color);
  padding-bottom: 12px;
}

.mobileL .sub_details_container .blueBtn {
  width: 290px;
  max-width: 290px;
  min-width: 100px;
  font-weight: 800;
}

.mobileL .sub_details_container .button_W {
  width: 290px;
  max-width: 290px;
  min-width: 100px;
  font-weight: 800;
}

.mobileL .sub_details_container #sub_a_cf {
  padding: 24px 0px 0px 0px;
  display: block;
}

.mobileL .sub_product_board {
  margin: 14px 0px 0px 0px;
}

/** ************* SL <<  */
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--main-gray900-color);
  opacity: 0.8;
  z-index: 1000;
}

.loadingInner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 88px;
  border-top: 1px solid var(--main-bg-color);

  height: 100%;
  width: 100%;
}

.sub_container {
  border: 0px solid gray;
}

.sub_period_container {
  border: 0px solid blue;
}

.sub_details_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sub_product_board {
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  max-width: 370px;
  min-width: 100px;
  min-height: 574px;
  border: 1px solid rgb(196, 205, 213);
  border-radius: 8px;
  padding: 24px 24px 32px;
  margin: 40px 12px 0px 12px;
}
.chosen {
  position: relative;
}

.sub_title {
  margin: 0px 0px 16px;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0px;
  /*font-family: Poppins;*/
  text-align: center;
}

.sub_board_title {
  margin: 0px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0px;
}

.sub_board_img {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
}

.sub_board_img img {
  width: 50px;
  padding-bottom: 20px;
}

.sub_board_description {
  margin: 0px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0px;

  color: rgb(99, 115, 129);
}

.sub_board_price {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.sub_board_price_value {
  margin: 0px;
  font-weight: 700;
  font-size: 64px;
  line-height: 90px;
  letter-spacing: 0px;
}

.sub_board_price_period {
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0px;

  padding-top: 46px;
  padding-left: 4px;
}

.sub_board_hr {
  margin: 0px;
  flex-shrink: 0;
  border-style: solid;
  width: 100%;
  border-width: 1px;
  border-color: rgb(196, 205, 213);
  margin-top: 50px;
  margin-bottom: 20px;
}

.sub_board_ul {
  padding-inline-start: 0px;
}
.sub_board_ul li {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-bottom: 20px;
  text-align: left;
}

.sub_board_ul li img {
  margin-right: 10px;
}

.currentProduct_board {
  background-color: hsla(0, 0%, 10%, 0.05);
  border: 1px solid sla(0, 0%, 10%, 0.1);
}

.yourChoice {
  border-style: solid;
  border-width: 1px;
  display: block;

  background: radial-gradient(
    circle at 0%,
    #fff,
    #fff 47%,
    #ddd 53%,
    #eee 54%,
    #fff 60%
  );
  animation: gradient-67215265 5s cubic-bezier(0.8, 0.1, 0.1, 1) infinite;
  background-size: 400% 100%;
  margin-left: 30px;
  width: 200px;
  border: 1px solid #ccc;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

@keyframes gradient {
  0% {
    background-position: 100% 10%;
  }
  100% {
    background-position: 0% 90%;
  }
  /*
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }*/
}

.sub_details_container .blueBtn {
  max-width: 250px;
  min-width: 100px;
  font-weight: 800;
}

.sub_radiogroup {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 0px 24px 0px 24px;
}
</style>
