<template>
  <div ref="root_cmp" class="root_cmp">
    <div id="cmpv">
      <div class="mc2">
        <div>
          <div v-if="dataReady" class="streamViewContainer">
            <listHeader
              pLabel="Streams"
              pLinkName="streamAdd"
              dataSrc="Stream"
              :searchVars="searchVariables"
              @searchValues="Search"
              showHeaderActions="0"
            />
            <div class="tabDataContainer">
              <table>
                <thead>
                  <tabHeader
                    :tableFields="tabFields"
                    :fieldsToHide="tabFieldsToHide"
                    :fieldsClass="tabFieldsClass"
                    :fieldsTranslation="tabFieldsTranslations"
                  />
                </thead>
                <tbody>
                  <tr
                    v-for="(r, r_index) in tableContent"
                    :key="r.id"
                    :class="computedCssClass(r_index)"
                  >
                    <td
                      class="indexColumn mm_id"
                      style="min-width: 100px; width: 120px"
                    >
                      <div class="mmIdxContent">
                        <div style="display: flex; justify-content: center">
                          {{ parseInt(pageNr) * nrElOnPage + r_index + 1 }}
                        </div>
                        <div class="iph" @click="switchSelectedTrack(r.id)">
                          <div
                            v-if="getBase64Img(r.image) == ''"
                            class="iconPlcHld"
                          >
                            <div
                              v-if="computedCssClass(r_index) == ''"
                              class="iconPlcHld_n"
                            >
                              <img
                                :id="'iph_' + r.id"
                                :src="$getImageUrl('simplePlayW.svg')"
                              />
                            </div>
                            <div v-else class="iconPlcHld_o">
                              <img
                                :id="'iph_' + r.id"
                                :src="$getImageUrl('simplePlayW.svg')"
                              />
                            </div>
                          </div>
                          <div
                            v-else
                            class="iconPlcHld_e"
                            :style="
                              'background-image: url(' +
                              getBase64Img(r.image) +
                              ');' +
                              '-webkit-clip-path: circle(30px at 50% 50%);' +
                              'clip-path: circle(30px at 50% 50%);' +
                              'background-position-x: center;'
                            "
                          >
                            <img
                              :id="'iph_' + r.id"
                              :src="$getImageUrl('simplePlayW.svg')"
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      class="trackNameColumn mm_name"
                      style="min-width: 100px"
                    >
                      <div class="alignedContent">
                        <div>
                          {{ nonBrHypens(r.name) }}
                        </div>

                        <p class="mm_duration_0">
                          {{ $toHHMMSS(r.duration, "hh:mm:ss") }}
                        </p>
                        <p class="mm_time_created_0">
                          {{ nonBrHypens(r.time_created) }}
                        </p>
                        <div>
                          <!-- plh -->
                        </div>
                      </div>
                    </td>

                    <td class="mm_selectedTags">
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                        "
                      >
                        <template v-for="s in r.selectedTags" :key="s.id"
                          ><p :id="'tag_' + s.id" class="trackCreation_tag">
                            {{ s.name }}
                          </p></template
                        >
                      </div>
                    </td>
                    <!--
					<td>
					<template v-for="t in r.selectedMotives" :key="t.id"
						><p :id="'motive_' + t.id">{{ t.name }}</p></template
					>
					</td>
					-->

                    <td class="trackPlayerColumn mm_duration">
                      {{ $toHHMMSS(r.duration, "hh:mm:ss") }}
                      <!--
                  <p class="compositionDuration">
                    
                    <template v-if="r.Tracks != ''">
                      <aswAudioPlayer
                        :id="r.id"
                        :audioList="fillPlayerObj(r.id, r.name, r.Tracks)"
                        playerVolume="1.0"
                        playerType="normalPlayer"
                        playerStyle="blue"
                        @playClicked="onTrackPlay"
                        @trackEnds="onTrackEnds"
                        @playerError="onPlayerError"
                      />
                    </template>
                    
                  </p>
				  -->
                    </td>

                    <td class="trackCreationColumn mm_time_created">
                      <div class="alignedContent">
                        <p>{{ nonBrHypens(r.time_created) }}</p>
                      </div>
                    </td>

                    <td class="trackActionColumn mm_actions">
                      <myMusicActions
                        :trackData="r"
                        @trackEdited="onTrackEdited"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <pagination
              :pageNr="pageNr"
              :totalPages="totalPages"
              :nrRowsTotal="nrRowsTotal"
              linkName="userCreationsView"
              :nrEOP="nrElOnPage"
              @nrEOPUpdated="updateNrElemOnPage"
              @pageUpdated="goToPage"
              @newPageRequested="beforeGoToPage"
            />

            <div class="additionalButtonAlligned">
              <div class="additionalButtonAllignedA">
                <div class="createTrackBtn" @click="goToComposer_0">
                  <div>
                    <img :src="$getImageUrl('Add.svg')" />
                  </div>

                  <div>Compose new track</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else></div>
        </div>
      </div>
    </div>
    <!-- footer player section -->
    <div id="cmpv_footer" ref="footer_cmpv">
      <div class="mc2">
        <FooterPlayer
          ref="root_cmp_fp"
          :videoImage="videoImage"
          :receivedPlaylistsFlag="receivedPlaylistsFlag"
          :trackName="trackName"
          :audioList="audioList"
          @playClicked="handlePlayClicked"
          @trackEnds="handleTrackEnds"
          @trackPause="handleTrackPause"
          @progressDragged="handleProgressDragged"
          @playerInitialized="handlePlayerInitialized"
          @componentInterface="getChildInterface"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from "vue";
//import { reactive } from "vue";
//import { watchEffect } from "vue";
import { mapGetters, mapActions } from "vuex";

import pagination from "@/components/listView/tabPagination.vue";
import listHeader from "@/components/listView/listHeader.vue";
import tabHeader from "@/components/listView/tabHeader.vue";

// ASW 2023-11-20 import streamSrv from "@/services/streamService.js"; // access to the APIs
// ASW 2023-11-20 import useConfirmBeforeAction from "@/features/useConfirmBeforeAction.js";
// ASW 2023-11-20 import useUpdateCreation_BeforeAction from "@/features/useUpdateCreation_BeforeAction.js";
// ASW 2023-11-20 import Popper from "vue3-popper";

import myMusicActions from "@/components/elements/myMusicActions.vue";
import FooterPlayer from "@/components/globalFooterPlayer.vue";
//import aswAudioPlayer from "@/components/elements/audioPlayer.vue";

export default {
  name: "userCreations",
  components: {
    listHeader,
    pagination,
    tabHeader,
    //aswAudioPlayer,

    myMusicActions,
    FooterPlayer,

    //Popper,
  },
  props: ["id", "msg", "pageNr", "ClientId"], //
  data() {
    return {
      users: null,

      usertype: null,
      tableName: "Stream",
      tableContent: null,
      responseFields: null,
      displayClientColumn: 0, //todo: probably all columns should be checked if they should be displayd

      tabFields: null,
      tabFieldsToHide: [],
      tabFieldsClass: [],
      tabFieldsTranslations: [],

      dataReady: false,

      // --> fields to be search
      searchVariables: {
        name: {
          label: "name",
          value: null,
          type: "text",
          showInSearchBar: true,
          width: 10,
        },
        remarks: {
          label: "remarks",
          value: null,
          type: "text",
          showInSearchBar: true,
          width: 10,
        },
      },
      // --< fields to be search

      nrRowsTotal: 0,
      nrElOnPage: 5,
      orderBy: "id desc",
      audioPlaying: false,
      audioPlayingId: -1,
      audioList: [],

      receivedPlaylistsFlag: false,
      videoImage: "",
    };
  },
  childInterface: {
    stopSelectedTrack: () => {},
    pauseSelectedTrack: () => {},
    startSelectedTrack: () => {},
  },
  created() {
    this.$_log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA pageNr:" + this.pageNr);

    if (this.Client != this.currentClientId) {
      this.Client = this.currentClientId;
    }

    if (this.ClientId == 0) {
      this.$_log(
        "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA this.ClientId:" + this.ClientId
      );
      this.displayClientColumn = 1;
      this.$_log("ASW reset data in store...");
      this.setCurCliId(0);
      this.setCurCliName("");
      this.setCurLocId(0);
      this.setCurLocName("");
      this.setCurZoneId(0);
      this.setCurZoneName("");
      this.setCurStreamId(0);
      this.setCurStreamName("");
    } else {
      this.displayClientColumn = 0;
    }

    if (this.isUserLoggedIn) {
      this.setCurrentPage(this.pageNr);

      // first time
      this.getData();

      // then each time page or nrElemOn page changes do this:
      watch(
        () => [this.pageNr, this.nrElOnPage],
        ([new_pageNr, new_nrElOnPage], [old_pageNr, old_nrElOnPage]) => {
          this.$_log(
            "UserCreations] watch; stop player, getData and change currentPage ---->"
          );
          this.stopSelectedTrack(this.audioPlayingId);

          this.$_log(
            "CCCCCC  old_pageNr:" + old_pageNr + " new_pageNr:" + new_pageNr
          );
          this.$_log(
            "CCCCCC  old_nrElOnPage:" +
              old_nrElOnPage +
              " new_nrElOnPage:" +
              new_nrElOnPage
          );
          this.$_log("CCCCCC this.pageNr:" + this.pageNr);
          this.dataReady = false;
          this.getData();
          this.$_log("UserCreations] watch ----<");
        }
      );

      /*
      watchEffect(() => {
        this.$_log("BBBBBBBBBBBBBBBBBBBBC this.pageNr:" + this.pageNr);

        this.getData();
      });
	  */
    }
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "currentUserId",
      "currentClientId",
    ]),
    ...mapGetters("streamModule", ["getCurrentPage"]),

    currUserAbrev() {
      let usrAbrev = "";
      if (this.currentClientFN != "") {
        usrAbrev += this.currentClientFN[0];
      }
      if (this.currentClientLN != "") {
        usrAbrev += this.currentClientLN[0];
      }

      if (usrAbrev == "") {
        usrAbrev = "...";
      }
      return usrAbrev;
    },
    totalPages() {
      return Math.ceil(this.nrRowsTotal / this.nrElOnPage);
    },

    showClientColumn() {
      let r = 0;
      this.$_log(
        "ASW showClientColumn -----this.displayClientColumn:" +
          this.displayClientColumn
      );
      if (this.displayClientColumn == 1) {
        this.$_log("ASW showClientColumn A");
        for (let i = 0; i < this.responseFields.length; i++) {
          if (this.responseFields[i].name == "clientName") {
            r = 1;
            break;
          }
          this.$_log("ASW showClientColumn C");
          r = 0;
        }
      } else {
        this.$_log("ASW showClientColumn B");
        r = 0;
      }
      return r;
    },
  },

  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),

    ...mapActions("clientModule", {
      setCurCliId: "setCurrentId",
      setCurCliName: "setCurrentName",
    }),

    ...mapActions("streamModule", {
      getStreamList: "getStreamList",
      setCurStreamId: "setCurrentId",
      setCurStreamName: "setCurrentName",
      setCurrentPage: "setCurrentPage",
    }),

    // ------------------------------------------
    // get access to the child functions
    getChildInterface(childInterface) {
      this.$_log("UserCreations] getChildInterface ... globalFooterPlayer ...");
      this.$options.childInterface = childInterface;
    },

    // ------------------------------------------
    // on mouse click on selected track row start / stop player
    switchSelectedTrack(trId) {
      this.$_log("UserCreations] switchSelectedTrack(" + trId + ")");
      // check if track other then now playing was clicked
      if (this.audioPlaying == true && trId != this.audioPlayingId) {
        this.stopSelectedTrack(this.audioPlayingId);
      }

      if (this.audioPlaying === false) {
        this.playSelectedTrack(trId);
      } else {
        this.pauseSelectedTrack(trId);
      }
    },

    // ------------------------------------------
    pauseSelectedTrack(trId) {
      this.$_log("UserCreations] pauseSelectedTrack(" + trId + ")");
      /*
      let p = this.$el.querySelector("#pab_mainPlayer");
      if (p !== null) {
        p.click();
      }
	  */
      this.$_log("************************************7>>>>>");
      this.$_log("************************************7>>>>>");
      this.$options.childInterface.pauseSelectedTrack(trId);
      this.$_log("************************************7<<<<<");
      this.$_log("************************************7<<<<<");
    },

    // ------------------------------------------
    stopSelectedTrack(trId) {
      this.$_log("UserCreations] stopSelectedTrack(" + trId + ")");

      this.$_log("************************************8>>>>>");
      this.$_log("************************************8>>>>>");
      this.$options.childInterface.stopSelectedTrack(trId);
      this.$_log("************************************8<<<<<");
      this.$_log("************************************8<<<<<");
    },

    // ------------------------------------------
    playSelectedTrack(trId) {
      this.$_log(
        "UserCreations] playSelectedTrack(" +
          trId +
          ", " +
          this.audioPlayingId +
          ") "
      );
      let trackChanges = true;
      if (this.audioPlayingId == trId) {
        trackChanges = false;
      }

      this.audioPlayingId = trId;
      //let trackData = this.tableContent.filter((el) => el.id == trId);
      let trackData = this.getTrackRowFromTable(trId);
      this.$_log("UserCreations] playSelectedTrack trackData:", trackData);

      this.videoImage = trackData.image;
      this.trackName = trackData.name;
      this.audioList = this.fillPlayerObj(
        trackData.id,
        trackData.name,
        trackData.Tracks
      );

      if (this.receivedPlaylistsFlag == false) {
        // THIS ==> triggers player initialization when player will be initialized
        // it will emit signal signal handled by handlePlayerInitialized
        // which will simulate click on player play icon and track will start play
        this.receivedPlaylistsFlag = true;
        // <==
      } else {
        if (!trackChanges) {
          this.$_log("************************************9>>>>>");
          this.$_log("************************************9>>>>>");
          this.$options.childInterface.startSelectedTrack(trId);
          this.$_log("************************************9<<<<<");
          this.$_log("************************************9<<<<<");
        } else {
          this.$_log("************************************9A>>>>>");
          this.$_log("************************************9A>>>>>");
          this.$_log(
            "trackChanges ... player will be started by footerPlayer wacher"
          );
          this.$_log("************************************9A<<<<<");
          this.$_log("************************************9A<<<<<");
        }
      }

      let x = this.$el.querySelector("#cmpv_footer");
      if (x.style.backgroundColor != "var(--main-gray900-color)") {
        this.$el.querySelector("#cmpv_footer").style.backgroundColor =
          "var(--main-gray900-color)";
      }
    },

    // ------------------------------------------
    handlePlayClicked(obj) {
      this.$_log("UserCreations handleTrackPlayClicked obj:", obj);

      this.updateRowPlayerIcon(obj.trackId);
    },

    // ------------------------------------------
    handleTrackEnds(obj) {
      this.$_log("UserCreations handleTrackEnds obj:", obj);

      this.updateRowPlayerIcon(obj.trackId);
      // clear player data

      this.audioPlaying = false;
      this.audioPlayingId = -1;

      this.videoImage = "";
      this.trackName = "";
      this.audioList = this.fillPlayerObj("", "", "");
      this.$_log(
        "UserCreations handleTrackEnds this.audioList:",
        this.audioList
      );
    },

    // ------------------------------------------
    handleTrackPause(obj) {
      this.$_log("UserCreations handleTrackPause obj:", obj);

      this.updateRowPlayerIcon(obj.trackId);
    },

    // ------------------------------------------
    handleProgressDragged(obj) {
      this.$_log("UserCreations handleProgressDragged obj:", obj);
    },

    // ------------------------------------------
    handlePlayerInitialized(obj) {
      this.$_log(
        "UserCreations handlePlayerInitialized playerInitialized obj:",
        obj
      );
      this.playerReady = true;
      this.$_log(
        "UserCreations handlePlayerInitialized lets auto start track! #pab_" +
          obj.elemId +
          " obj.trackId:" +
          obj.trackId
      );

      this.$_log(
        "UserCreations playerInitialized this.$options.childInterface",
        this.$options.childInterface
      );

      this.$_log("************************************10>>>>>");
      this.$_log("************************************10>>>>>");
      this.$options.childInterface.startSelectedTrack(obj.trackId);
      this.$_log("************************************10<<<<<");
      this.$_log("************************************10<<<<<");
    },

    // ------------------------------------------
    updateRowPlayerIcon(trId) {
      this.$_log(
        "UserCreations] updateRowPlayerIcon trId:" +
          trId +
          " audioPlaying:" +
          this.audioPlaying
      );
      if (this.audioPlaying === false) {
        this.audioPlaying = true;

        let i = this.$el.querySelector("#iph_" + trId + "");
        this.$_log("UserCreations] switch row icon to 'stop play'");
        i.src = this.$getImageUrl("simplePause.svg");
      } else {
        this.audioPlaying = false;

        let i = this.$el.querySelector("#iph_" + trId + "");
        this.$_log("UserCreations] switch row icon to 'start play'");
        i.src = this.$getImageUrl("simplePlayW.svg");
      }
    },

    // ------------------------------------------
    getTrackRowFromTable(trackId) {
      this.$_log("UserCreations getTrackRowFromTable trackId:" + trackId);
      let CCC = this.tableContent.filter((el) => el.id == trackId);
      this.$_log("UserCreations getTrackRowFromTable CCC[0]:" + CCC[0].id);
      return CCC[0];
    },

    // ------------------------------------------
    nonBrHypens(str) {
      let txt = str.replace(/-/g, "‑");
      return txt;
    },

    // ------------------------------------------
    beforeGoToPage(obj) {
      this.$_log("UserCreations] beforeGoToPage obj:", obj);
    },

    // ------------------------------------------
    goToPage(obj) {
      this.$_log("UserCreations] goToPage obj:", obj);

      this.setCurrentPage(obj.value);
      this.getData();
    },

    // ------------------------------------------
    updateNrElemOnPage(obj) {
      this.$_log("UserCreations] updateNrElemOnPage obj:", obj);
      this.nrElOnPage = parseInt(obj.value, 10);
      this.setCurrentPage(0);
      this.$router.push({
        name: "userCreationsView",
        params: {
          pageNr: 0,
          ClientId: this.currentClientId,
        },
      });
    },
    goToComposer_0() {
      this.$router.push({
        name: "createMusicHeaderView",
        //params: { pageNr: 0 },
      });
    },

    // --------------------------------------------
    onTrackEdited(obj) {
      console.log("UserCreations] onTrackEdited obj:", obj);
      if (obj.updateList) {
        this.getData();
      }
    },

    // player event functions =>
    onTrackPlay(elem) {
      this.$_log("UserCreations] onTrackPlay elem:", elem);
      if (elem.action == "play" && this.audioPlaying == true) {
        //stop currently playing track!!
        this.$_log(
          "stop track:" +
            this.audioPlayingId +
            " and start track:" +
            elem.trackId
        );
        let playingAudio = this.$el.querySelector(
          "#pab_" + this.audioPlayingId
        );
        this.$_log("playingAudio:", playingAudio);
        if (playingAudio == null) {
          this.$_log("!!!!");
        } else {
          playingAudio.click();
        }

        this.audioPlaying = true;
        this.audioPlayingId = elem.trackId; //this.audioPlayingId=elem.elemId;
      } else if (elem.action == "play") {
        this.$_log(
          "UserCreations] simply start playing current track:" + elem.trackId
        );
        this.audioPlaying = true;
        this.audioPlayingId = elem.trackId; //this.audioPlayingId=elem.elemId;
      } else {
        // simply start playing chosen track
        this.$_log(
          "UserCreations] simply stop playing current track:" +
            this.audioPlayingId
        );
        this.audioPlaying = false;
        this.audioPlayingId = -1;
      }

      this.$_log("===========================");
    },

    onTrackEnds() {
      this.$_log(
        "UserCreations] current track:" +
          this.audioPlayingId +
          " ends ... lest set local flags."
      );
      this.audioPlaying = false;
      this.audioPlayingId = -1;
    },

    onPlayerError(elem) {
      this.$_log(
        "UserCreations] onPlayerError elem:" +
          elem.trackId +
          " error:" +
          elem.error
      );
      let downloadButton = this.$el.querySelector("#dt_" + elem.trackId);
      downloadButton.style.display = "none";
    },
    // player event functions <=

    Search(data) {
      this.$_log("UserCreations] Search data:", data);
      this.searchVariables = data;
      this.getData();
    },
    getBase64Img(imgData) {
      if (imgData !== null && imgData != "") {
        if (imgData.indexOf("data:image/jpeg;base64,") == -1) {
          return "data:image/jpeg;base64," + imgData;
        } else {
          return imgData;
        }
      } else {
        return "";
      }
    },
    getData() {
      this.$_log(
        "UserCreations] Just before getData ... this.getCurrentPage:" +
          this.getCurrentPage +
          " this.pageNr:" +
          this.pageNr
      );
      var jo = {
        // search params ->
        name: this.searchVariables.name.value,
        remarks: this.searchVariables.remarks.value,
        Client_id: this.ClientId,
        // search params <-

        pageNr: this.pageNr,
        nrElOnPage: this.nrElOnPage,
        orderBy: this.orderBy,
      };
      this.$_log("UserCreations] object to be sent json:", jo);

      this.getStreamList(jo)
        .then((r) => {
          this.$_log("UserCreations] Just after getStreamList....");
          this.$_log("UserCreations] created response:", r);

          this.nrRowsTotal = r.nrRowsTotal;
          this.tableContent = r.data;
          this.responseFields = r.fields;

          this.tabFields = r.fields;
          this.tabFieldsToHide.push(
            { name: "Client_id" },
            { name: "streamURL" },
            { name: "Intensity" },
            { name: "Tempo" },
            { name: "Rhythm" },
            { name: "Tone" },
            { name: "Variance" },
            { name: "Tracks" },
            { name: "remarks" },
            { name: "params" },
            { name: "selectedMotives" },
            //{ name: "selectedTags" },
            { name: "image" }
          );
          if (this.displayClientColumn == 0) {
            this.tabFieldsToHide.push({ name: "clientName" });
          }
          this.tabFieldsClass.push({ name: "remarks", class: "wideTabColumn" });
          this.tabFieldsClass.push({ name: "id", class: "indexColumn" });

          this.tabFieldsTranslations.push({
            name: "functions",
            value: "actions",
          });
          this.tabFieldsTranslations.push({
            name: "time_created",
            value: "time created",
          });
          this.tabFieldsTranslations.push({
            name: "selectedTags",
            value: "Tags",
          });

          //this.setCurrentPage(this.pageNr);
          this.dataReady = true;

          this.$_log(
            "UserCreations] created[" +
              this.tableName +
              "] this.tableContent.length:" +
              this.tableContent.length +
              " nrRowsTotal:" +
              this.nrRowsTotal
          );
        })
        .catch((error) => {
          this.$_log(error);
        });
    },

    computedCssClass(i) {
      if (i % 2 === 0) {
        return "";
      } else {
        return "odd";
      }
    },

    fillPlayerObj(trackId, trackName, trackUrl) {
      let file_prefix = "";
      if (trackUrl.indexOf("https://api.muzaic.biz/mix/") == -1) {
        file_prefix = "https://api.muzaic.biz/mix/";
      }
      return [{ id: trackId, name: trackName, url: file_prefix + trackUrl }];
    },
  },
};
</script>

<style scoped>
.root_cmp {
  height: 100%;
  border: 0px solid red;
  display: grid;
  grid-template-rows: 1fr 88px;
  flex-grow: 1;
}

#cmpv {
  display: flex;
  justify-content: center;
  padding: 0px 24px 0px 24px;
}

#cmpv_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
  position: sticky;
  bottom: 0;
  z-index: 10;
  background-color: var(--main-gray300-color);
  padding: 0px 12px 0px 12px;
}

.streamViewContainer {
  display: grid;
  grid-template-rows: auto 1fr auto auto;
}

.createTrackBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--main-blue-color);
  background-color: var(--main-blue-color);
  gap: 12px;
  padding: 12px 16px 12px 16px;
  margin: 0px 0px 0px 0px;
  border-radius: 24px;

  font-size: 14px;
  color: white;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 0px 24px 0px #0038ff52;
}

.createTrackBtn:hover {
  letter-spacing: 0px;
  border: 1px solid var(--main-bluedarker-color);
  background-color: var(--main-bluedarker-color);
  color: white;
}

.tabText {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
.iconPlcHld {
  width: 64px;
  height: 64px;
  background: linear-gradient(
    90deg,
    rgb(0, 216, 255) 0%,
    rgb(96, 238, 208) 100%
  );

  margin-left: 15px;
}
.iconPlcHld_n {
  background-image: url("@/assets/trackIconBg.png");
  background-size: contain;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconPlcHld_o {
  background-image: url("@/assets/trackInonBg_odd.png");
  background-size: contain;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconPlcHld_e {
  width: 64px;
  height: 64px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.compositionDuration {
  margin: 0px 10px 0px 0px;
  letter-spacing: 0px;

  color: var(--main-gray900-color);
  border: 1px solid var(--main-gray400-color);
  padding: 4px 8px;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 42px;
  max-width: 330px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
}
.compositionDuration:hover {
  border: 1px solid var(--main-gray600-color);
}

.additionalButtonAlligned {
  display: flex;

  justify-content: center;
  align-items: center;
}

.additionalButtonAllignedA {
  border-top: 0px solid rgb(224, 224, 224);
  width: 214px;
  height: 48px;
}

.trackActionColumn {
  text-align: right;
}

.trackCreationColumn {
  /*white-space: nowrap;*/
  width: 150px;
}

.trackCreation_tag {
  border: 1px solid var(--main-gray800-color);
  border-radius: 15px;
  margin: 4px 4px 4px 4px;
  padding: 8px;
}

.iph {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mmIdxContent {
  display: grid;
  grid-template-columns: 20px 1fr;
}
</style>

<style>
@media only screen and (min-width: 1024px) {
  .mm_selectedTags {
    display: table-cell;
  }
}
@media only screen and (max-width: 1024px) {
  .mm_selectedTags {
    display: none;
  }
}

@media only screen and (min-width: 850px) {
  .mm_duration,
  .mm_time_created {
    display: table-cell;
  }
}
@media only screen and (max-width: 850px) {
  .mm_duration,
  .mm_time_created {
    display: none;
  }
}

@media only screen and (min-width: 480px) {
  .mm_duration_0,
  .mm_time_created_0 {
    display: none;
  }
  .alignedContent {
    display: flex;
  }
  .additionalButtonAlligned {
    padding: 0px 0px 0px 0px;
  }
}
@media only screen and (max-width: 480px) {
  .mm_duration_0,
  .mm_time_created_0 {
    display: table-cell;
    margin: 0px;
    font-size: 12px;
    color: var(--main-gray800-color);
  }
  .alignedContent {
    display: grid;
  }
  .additionalButtonAlligned {
    padding: 30px 0px 30px 0px;
  }
}

.mm_selectedTags {
  white-space: nowrap;
}
</style>
