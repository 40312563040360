<template>
  <div class="mc2">
    <div class="lgn_c0">
      <UserRegistration msg="Sign up" />
    </div>
  </div>
</template>

<script>
import UserRegistration from "@/components/dialogs/UserRegistration.vue";

export default {
  name: "RegisterUser",
  components: {
    UserRegistration,
  },
  created() {
    console.log("Created:", this.$posthog); //posthog accessible anywhere!
  },
  mounted() {
    this.$_log(
      " page:" +
        this.$options.name +
        " mounted .... lets send signal to the posthog"
    );
    //this.$posthog.capture("pagevisit " + this.$options.name); //this kind of logs are captured inside the router
  },
};
</script>

<style scoped>
.lgn_c0 {
  position: relative;
  text-align: center;
}
</style>
