<template>
  <div class="modal-container" ref="root_updateCreation">
    <div class="modal-body">
      <div class="idf_container">
        <!-- <span class="modal-close" @click="emit('cancel')">🗙</span> -->
        <div class="idf_cl1">
          <p class="idf_header" style="text-align: center">{{ title }}</p>
        </div>
        <div class="idf_cl2">
          <form @submit.prevent="onSubmit">
            <div class="ms_inputbox_0">
              <label>Name</label>
              <div class="ms_inputbox_1">
                <input
                  v-model="mCurrentCreation.name"
                  type="text"
                  placeholder="creation name"
                />
                <fieldset aria-hidden="true">
                  <legend><span>Enter new name</span></legend>
                </fieldset>
              </div>
            </div>

            <div v-if="duration != ''" class="ms_inputbox_0">
              <label>Duration</label>
              <div class="ms_inputbox_1 idf_time">
                <input
                  v-model="mCurrentCreation.duration"
                  type="text"
                  placeholder="00:00"
                  @input="checkDurationField()"
                />
                <fieldset aria-hidden="true">
                  <legend><span>Enter new duration</span></legend>
                </fieldset>
              </div>
            </div>
          </form>
          <div class="modal-action">
            <button
              class="idf_cancel_btn"
              @click="emit('cancel')"
              style="width: 83px"
            >
              Exit
            </button>
            <button
              id="ec_confirm_btn"
              class="idf_submit_btn"
              :disabled="doNotLetHimGo"
              @click="emit('confirm', mCurrentCreation, 'zzz')"
              style="width: 83px"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { integer } from "validatorjs/src/lang/en";
import { defineEmits } from "vue";
const emit = defineEmits(["confirm", "cancel"]);
const root_updateCreation = ref(null);
</script>

<script>
//import { ref } from "vue";
import { mapActions } from "vuex";
import Validator from "validatorjs";

export default {
  name: "UpdateCreation",
  props: {
    title: String,
    id: integer,
    name: String,
    duration: String,
  },
  data() {
    return {
      mCurrentCreation: {
        id: this.id,
        name: this.name,
        duration: this.duration,
        length: this.$time2sec(this.duration),
      },
      doNotLetHimGo: false,
      responseData: {},
    };
  },
  computed: {},
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),

    checkDurationField() {
      this.mCurrentCreation.length = this.$time2sec(
        this.mCurrentCreation.duration,
        "mm:ss"
      );
      this.$_log(
        "AAA mCurrentCreation.duration:" + this.mCurrentCreation.duration
      );
      this.$_log(
        "AAA this.mCurrentCreation.length:" + this.mCurrentCreation.length
      );
      let r = this.validateFormValues();
      if (r == "") {
        this.$_log("Ok  lets send this form");
        this.doNotLetHimGo = false;
      } else {
        this.doNotLetHimGo = true;
        this.$_log("No way we have errors:" + r);
        //let btn = this.root_updateCreation.querySelector("#ec_confirm_btn");

        this.$_log("button :", this.$el.querySelector("#ec_confirm_btn"));
        let n = {
          type: "problem",
          message: r,
        };
        this.addNotificationMessage(n);
      }
    },
    validateFormValues() {
      this.$_log("receiveFormValues......");
      let data = {
        name: this.mCurrentCreation.name,
        duration: this.mCurrentCreation.duration,
        length: this.mCurrentCreation.length,
      };
      this.$_log("data to validate:", data);

      let rules = {
        name: "required|string",
        //duration: ["required", "regex:/^\\d{2}:\\d{2}:*\\d{2}$/"], // hh:mm:ss
        duration: ["required", "regex:/^\\d{2}:\\d{2}$/"], // only mm:ss
        length: "integer|min:10|max:1200",
      };

      let validation = new Validator(data, rules);

      if (validation.passes()) {
        return "";
      } else {
        let str = "";
        for (const [value] of Object.entries(validation.errors.all())) {
          str = str + value + " ";
        }
        return str;
      }
      /////////////////////////////////////////////////////////////////////////////////

      /////////////////////////////////////////////////////////////////////////////////
    },
  },
};
</script>

<style src="@/assets/css/internal_dialogform_0.css"></style>
<style>
.modal-button {
  cursor: pointer;
  height: 30px;
  padding: 0 25px;
  border: 2px solid var(--main-blue-color);
  border-radius: 5px;
  background-color: var(--main-blue-color);
  color: #fff;
}
.modal-close {
  cursor: pointer;
  position: relative;
  align-self: end;
  right: -33px;
  top: -17px;
}
</style>
