<template>
  <div class="dlg_form_container">
    <div class="dlg_c_l1">
      <p class="dlg_form_header">{{ msg }}</p>
    </div>
    <div class="dlg_c_l2">
      <form @submit.prevent="onSubmit">
        <div class="ms_inputbox_0">
          <label>Enter E-mail</label>
          <div class="ms_inputbox_1">
            <input
              v-model="mCurrentUser.email"
              type="text"
              placeholder="test@test.com"
            />
            <fieldset aria-hidden="true">
              <legend><span>Enter E-mail</span></legend>
            </fieldset>
          </div>
        </div>
        <div id="email_Msg" class="errorMsg"></div>

        <div class="dlg_submit_line">
          <button type="submit" class="dlg_submit_btn pwd_reset">
            Send link to set password
          </button>
        </div>
      </form>
      <div class="dlg_lgn_row">
        <p>
          You have an account?
          <router-link to="/login">Sing In</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
//import { mapState } from "vuex";
import { mapGetters, mapActions } from "vuex";
import Validator from "validatorjs";
//import config from "@/config/config.js";

export default {
  name: "ResetPasswordRequestDialog",
  props: {
    msg: String,
  },
  data() {
    return {
      mCurrentUser: {
        email: "",
      },
    };
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
  },
  methods: {
    doValidation(usr) {
      return new Promise((resolve, reject) => {
        let data = {
          email: usr.email,
        };

        this.$_log("data to validate:", data);

        let rules = {
          email: "required|email",
        };

        for (const [key, value] of Object.entries(data)) {
          console.log(`${key}: ${value}`);
          let o = this.$el.querySelector("#" + key + "_Msg");
          o.innerText = "";
        }

        let validation = new Validator(data, rules);

        if (validation.passes()) {
          this.$_log("all good lets go ahead ....");
          resolve(usr);
        } else {
          this.$_log("ook we have problems ...:", validation);
          let str = "";
          for (const [key, value] of Object.entries(validation.errors.all())) {
            console.log("error key:" + key + "value:" + value);

            let o = this.$el.querySelector("#" + key + "_Msg");
            o.innerText = value;
            o.style.marginBottom = "25px";
            str = str + value + " ";
          }

          this.$_log("problems str:" + str);
          reject(str);
        }
      });
    },
    onSubmit() {
      const usr = {
        email: this.mCurrentUser.email,
      };

      this.doValidation(usr)
        .then(() => {
          this.$store
            .dispatch("userModule/resetPasswordRequest", usr)
            .then(() => {
              this.$_log(
                "ResetPasswordRequest.vue] onSubmit - then registered email:" +
                  usr.email
              );

              this.$router.push({
                name: "home",
              });
            })
            .catch((error) => {
              this.$_log("ResetPasswordRequest onSubmit error:", error);
            });
        })
        .catch((error) => {
          this.$_log(
            "uups form data did not pass validation .... error:",
            error
          );
        });

      //// >>>
      /*
      if (usr.email == "" || usr.email == "test@test.com") {
        var n = {
          type: "problem",
          message: "Please fill email filed.",
        };
        this.addNotificationMessage(n);
      } else {
        this.$_log("ResetPasswordRequest.vue] onSubmit - User:", usr);
        this.$store
          .dispatch("userModule/resetPasswordRequest", usr)
          .then(() => {
            this.$_log(
              "ResetPasswordRequest.vue] onSubmit - then registered email:" +
                usr.email
            );

            this.$router.push({
              name: "home",
            });
          })
          .catch((error) => {
            this.$_log("ResetPasswordRequest onSubmit error:", error);
          });
      }
	  */
    },
    //// <<<
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("userModule", ["login"]),
  },
};
</script>

<style src="@/assets/css/dialogform_0.css"></style>
<style scoped></style>
