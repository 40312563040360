import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiStripe = axios.create({
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  // ///////////////////////////////////////////////////////////////////
  // /// =>
  getStripCustomerId(userEmail) {
    vueApp.$_log("apiStripe ===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiStripe.get("/admin/ms/gstripectrid/" + userEmail, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  getStripeStudioProducts() {
    vueApp.$_log("apiStripe ===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiStripe.get("/admin/ms/gstdprd/", {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  getStripeStudioProducts4Customer(
    stripeCustomerId,
    currentClientId,
    usecaseContext = 0
  ) {
    vueApp.$_log(
      "apiStripe =========================== usecaseContext:" + usecaseContext
    );
    const userToken = GStore.getters["userModule/userToken"];

    return apiStripe.get(
      "/admin/ms/gstdprd4ctr/" +
        stripeCustomerId +
        "/" +
        currentClientId +
        "/" +
        usecaseContext,
      {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      }
    );
  },

  getStripeStudioProductsDetails(productId) {
    vueApp.$_log("apiStripe ===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiStripe.get("/admin/ms/gstdprd/" + productId, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  getStripeStudioProductPriceDetails(priceId) {
    vueApp.$_log("apiStripe ===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiStripe.get("/admin/ms/gstdprdpr/" + priceId, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  getStripeStudioProductPriceList(productId) {
    vueApp.$_log("apiStripe ===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiStripe.get("/admin/ms/gstdprdprlst" + productId, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  getStripeBillingSessionUrl(stripeCustomerId, ctrId) {
    vueApp.$_log("apiStripe ===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiStripe.get(
      "/admin/ms/getBillingSession/" + stripeCustomerId + "/" + ctrId,
      {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      }
    );
  },
  getStripePaymentSessionUrl(priceId, userEmail) {
    vueApp.$_log("apiStripe ===========================");
    const userToken = GStore.getters["userModule/userToken"];
    return apiStripe.get(
      "/admin/ms/getPaymentSession/" + priceId + "/" + userEmail,
      {
        headers: {
          Authorization: "Bearer " + userToken,
        },
      }
    );
  },
  //
  getClientDataForMysubscription(cltId) {
    vueApp.$_log("apiStripe ===========================");
    const userToken = GStore.getters["userModule/userToken"];
    return apiStripe.get("/admin/ms/gmysub/" + cltId, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  userOrderSuccess(checkoutSessionId) {
    vueApp.$_log("apiStripe ===========================");
    const userToken = GStore.getters["userModule/userToken"];
    return apiStripe.get("/admin/ms/userOrderSuccess/" + checkoutSessionId, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
