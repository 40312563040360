<template>
  <div class="mc2">
    <div
      v-if="dataReady"
      class="dlg_form_container"
      ref="root_createMusicHeader"
    >
      <div class="dlg_c_l1">
        <p class="dlg_form_header cmh">Let’s begin</p>
        <p class="dlg_text">
          Name your track, set duration and upload a video if needed.
        </p>
        <p class="dlg_text_small">
          You can create
          {{ $toHHMMSS(currentSubscription.UnitsAvailable) }} of music this
          month, with songs ranging from
          {{ $HHMMSStoTxt($toHHMMSS(currentSubscription.minTrackDuration)) }}
          ({{ $toHHMMSS(currentSubscription.minTrackDuration, "mm:ss") }}) to
          {{ $HHMMSStoTxt($toHHMMSS(currentSubscription.maxTrackDuration)) }}
          ({{ $toHHMMSS(currentSubscription.maxTrackDuration, "mm:ss") }}).
        </p>
      </div>

      <div class="dlg_c_l2">
        <form @submit.prevent="onSubmit">
          <div class="ms_inputbox_0">
            <label for="creationName">Track name</label>
            <div class="ms_inputbox_1">
              <input
                id="creationName"
                v-model="mCurrentCreation.name"
                type="text"
                placeholder="Your new creation name"
              />
              <fieldset aria-hidden="true">
                <legend><span>Track Name</span></legend>
              </fieldset>
            </div>
          </div>

          <div class="ms_inputbox_0">
            <label for="creationDuration">Duration</label>
            <div class="ms_inputbox_1 idf_time">
              <input
                id="creationDuration"
                v-model="mCurrentCreation.duration"
                type="text"
                placeholder="mm:ss"
                @input="checkDuration"
                @change="checkDuration"
              />
              <fieldset aria-hidden="true">
                <legend><span>Duration</span></legend>
              </fieldset>
            </div>
          </div>

          <div class="dlg_uploadVideo_0">
            <DropZone
              :videoDomId="videoDomId"
              cmpClass="drop-area"
              @files-dropped="addFiles"
              :acceptTypes="acceptTypes"
            >
            </DropZone>

            <span class="dlg_uploadVideo_3_text"
              >Allowed *MP4, *WebM, *Ogg, *Mov</span
            >
            <div
              v-if="this.selectedFile"
              style="
                display: none;
                align-items: center;
                justify-content: center;
                border: 1px dotted gray;
                /* margin: 1px; */
                padding: 10px;
                background-color: white;
                width: 150px;
                height: 200px;
                background-color: blue;
              "
            >
              <canvas
                id="canvas"
                style="
                  overflow: auto;
                  display: flex;
                  /*position: absolute;*/
                  align-items: center;
                  justify-content: center;
                  z-index: 100;
                "
              />
            </div>

            <div></div>
          </div>

          <div class="idf_submit_line">
            <button
              type="submit"
              class="dlg_uploadVideo_4_submitIco idf_submit_btn idf_submit_btn_wide"
              :disabled="doNotLetHimGo"
              @click="goToComposer_details"
            >
              Go to composer
              <!-- <img :src="$getImageUrl('rightArrow.svg')" class="rightArrow" /> -->

              <span>
                <svg
                  class="rightArrow"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="EastOutlinedIcon"
                >
                  <path
                    d="m15 5-1.41 1.41L18.17 11H2v2h16.17l-4.59 4.59L15 19l7-7-7-7z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- ++++++ -->

  <!-- ===< -->
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";

import streamSrv from "@/services/streamService.js";
//import cltSrv from "@/services/clientService.js";
import DropZone from "@/components/elements/DropZone.vue";
import FormData from "form-data";
import Validator from "validatorjs";

export default {
  name: "createMusicHeader",

  setup() {
    const root_createMusicHeader = ref(null);
    return {
      root_createMusicHeader,
    };
  },

  components: {
    //FileUpload,
    DropZone,
  },
  data() {
    return {
      mCurrentCreation: {
        name: "",
        duration: "", // in time format hh:mm:ss
        length: 0, // in seconds
        videoImage: "",
        video: null,
      },
      selectedFile: "",

      acceptTypes:
        "video/mp4,video/WebM,video/Ogg,video/quicktime,video/3gpp,video/3gpp2,video/3gp2",
      videoObj: null,
      videoDomId: "cmh_vPlh",
      containerDom: null,
      dataReady: false,
      availableUnits: 3600,
      showImage: 1,
      //root_createMusicHeader: ref(null), //??
    };
  },
  created() {
    this.getClientProduct(this.currentClientId)
      .then((r) => {
        this.$_log("Just after getClientProduct....");
        this.$_log("r:", r);
        this.availableUnits = r.UnitsAvailable;
        this.dataReady = true;
      })
      .then(() => {
        this.containerDom = this.root_createMusicHeader;
        this.videoObj = this.containerDom.querySelector("#" + this.videoDomId);
        this.$_log("OOOOOOk its time to go :) :", this.videoObj);
      })
      .catch((error) => {
        this.$_log(error);
      });
  },
  mounted() {},
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
      "currentSubscription",
    ]),
    doNotLetHimGo() {
      if (
        this.mCurrentCreation.name != "" &&
        this.mCurrentCreation.duration != "" &&
        this.mCurrentCreation.length < this.availableUnits
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("streamModule", { initStream: "initStream" }),
    ...mapActions("clientModule", { getClientProduct: "getClientProduct" }),

    //
    validateFormValues() {
      this.$_log("receiveFormValues......");
      let data = {
        name: this.mCurrentCreation.name,
        duration: this.mCurrentCreation.duration,
        length: this.mCurrentCreation.length,
      };
      this.$_log("data to validate:", data);

      let rules = {
        name: "required|string",
        //duration: ["required", "regex:/^\\d{2}:\\d{2}:*\\d{2}$/"], // hh:mm:ss
        duration: ["required", "regex:/^\\d{2}:*\\d{2}$/"], // only mm:ss
        length:
          "integer|min:" +
          this.currentSubscription.minTrackDuration +
          "|max:" +
          this.currentSubscription.maxTrackDuration +
          "",
      };

      let validation = new Validator(data, rules);

      this.$_log("validation:", validation);
      if (validation.passes()) {
        return "";
      } else {
        let str = "";
        for (const [value] of Object.entries(validation.errors.all())) {
          str = str + value + " ";
        }
        if (str.indexOf("duration") != -1 || str.indexOf("length") != -1) {
          str =
            "The expected duration format is mm:ss, where duration cannot be greater than " +
            this.$HHMMSStoTxt(
              this.$toHHMMSS(this.currentSubscription.maxTrackDuration)
            ) +
            " or less than " +
            this.$HHMMSStoTxt(
              this.$toHHMMSS(this.currentSubscription.minTrackDuration)
            ) +
            "";
        }
        return str;
      }
      /////////////////////////////////////////////////////////////////////////////////

      /////////////////////////////////////////////////////////////////////////////////
    },
    //

    checkDuration() {
      this.$_log("----- checkDuration -----");
      this.$_log(
        "this.mCurrentCreation.duration:" +
          this.mCurrentCreation.duration +
          "nr groups:" +
          this.mCurrentCreation.duration.split(":").length
      );

      this.mCurrentCreation.length = this.$time2sec(
        this.mCurrentCreation.duration,
        "mm:ss"
      );

      this.$_log("this.availableUnits:" + this.availableUnits);
      this.$_log(
        "ok your creation will use:" +
          this.mCurrentCreation.length +
          "seconds and you still have " +
          this.availableUnits +
          "seconds to be used for this month"
      );
      if (this.mCurrentCreation.length >= this.availableUnits) {
        this.$_log("You dont have enough available time usnits this month");
        let n = {
          type: "problem",
          message:
            "You dont have enough time usnits for this month. Please reduce creation duration.",
        };
        this.addNotificationMessage(n);
      } else {
        this.$_log("ok ");
      }
    },

    goToComposer_details() {
      let r = this.validateFormValues();
      if (r == "") {
        this.initStream({
          duration: this.mCurrentCreation.duration,
          name: this.mCurrentCreation.name,
          image: this.mCurrentCreation.videoImage,
          video: this.mCurrentCreation.video,
        });

        this.$router.push({
          name: "createMusicDetailsView",
        });
      } else {
        this.$_log("response from validation function:", r);
        let n = {
          type: "problem",
          message: r,
        };
        this.addNotificationMessage(n);
      }
    },
    // =>
    captureImage(jumpToSec) {
      this.$_log("ASW captureImage ");
      if (typeof jumpToSec == "undefined") {
        jumpToSec = 10;
      }
      let imageSize = 200;
      let canvas = this.containerDom.querySelector("#canvas");
      let video = this.containerDom.querySelector("#" + this.videoDomId);

      video.currentTime = video.currentTime + jumpToSec;

      this.videoObj.ontimeupdate = (event) => {
        if (event.isTrusted && this.mCurrentCreation.videoImage == "") {
          this.$_log(video.videoWidth + " --- " + video.videoHeight);
          if (this.showImage != 0) {
            this.$_log("show captured image ....");
            canvas.width = imageSize; //video.videoWidth;
            canvas.height = parseInt(
              (imageSize * video.videoHeight) / video.videoWidth
            ); //video.videoHeight;
            canvas
              .getContext("2d")
              .drawImage(
                video,
                0,
                0,
                imageSize,
                parseInt((imageSize * video.videoHeight) / video.videoWidth)
              ); //video.videoWidth, video.videoHeight);
            let image = new Image();
            image.src = canvas.toDataURL("image/jpeg");
          }
          /*
          this.$_log(
            canvas
              .toDataURL("image/jpeg")
              .replace(/^data:image\/jpeg;base64,/, "")
          );
		  */
          this.mCurrentCreation.videoImage = canvas
            .toDataURL("image/jpeg")
            .replace(/^data:image\/jpeg;base64,/, "");
        }
      };
    },
    addFiles(f) {
      //this.$_log("createMusicHeader.vue] addFiles - file:", f);
      this.$_log("name:" + f[0].name + " type:" + f[0].type);
      let n;

      let T = this.acceptTypes.split(",");
      if (T.includes(f[0].type)) {
        this.$_log("file type accepeted :)");
      }
      /*
      if (
        f[0].type.indexOf("video/mp4") != -1 ||
        f[0].type.indexOf("video/WebM") != -1 ||
        f[0].type.indexOf("video/Ogg") != -1 ||
        f[0].type.indexOf("video/quicktime") != -1
      )
	  */
      if (T.includes(f[0].type)) {
        this.mCurrentCreation.video = f[0];
        {
          this.$_log("AAAASW_0 =>", this.videoObj);
          this.videoObj.preload = "metadata";
          this.$_log("AAAASW_1 =>");
          this.videoObj.onloadedmetadata = () => {
            //window.URL.revokeObjectURL(video.src);
            this.$_log("this.videoObj.duration: " + this.videoObj.duration);

            if (this.videoObj.duration < 1) {
              this.$_log("Invalid Video! video is less than 1 second");
              n = {
                type: "problem",
                message: "Invalid Video! video is less than 1 second",
              };
              this.addNotificationMessage(n);
            } else if (this.videoObj.duration > 1200) {
              this.$_log("Invalid Video! video is more than 20 min");
              n = {
                type: "problem",
                message: "Invalid Video! video is more than 20 min",
              };
              this.addNotificationMessage(n);
            } else {
              this.$_log("video look fine .....");
              this.videoObj.autoplay = true;
              this.videoObj.volume = 0;
              const date = new Date(null);
              date.setSeconds(parseInt(this.videoObj.duration, 10)); // specify value for SECONDS here
              this.$_log("ZZZZZ:" + date.toISOString());
              this.mCurrentCreation.duration = date.toISOString().slice(14, 19);
              this.mCurrentCreation.length = parseInt(
                this.videoObj.duration,
                10
              );
              this.mCurrentCreation.name = f[0].name;

              this.captureImage(2);
              this.checkDuration();
            }
          };

          this.videoObj.src = URL.createObjectURL(f[0]); //f[0].name;

          this.$_log("AAAASW_2 =>");
          //this.$_log("BBBB");
          //this.$_log("CCCC this.videoObj.style:", this.videoObj.style);
          this.videoObj.style =
            "position: absolute; border: 0px solid red; height: 150px; width: 150px; border-radius: 100%; object-fit: cover; z-index:10;";
          //this.$_log("DDDD");
        }

        const selectedFile = f[0]; // accessing file
        this.selectedFile = selectedFile;
      } else {
        this.$_log("incorrect file type !!!!");
        n = {
          type: "problem",
          message:
            "Incorrect file type. Allowed video formats: *MP4, *WebM, *Ogg, *Mov ",
        };
        this.addNotificationMessage(n);
      }
    },

    onUploadFile() {
      const formData = new FormData();
      formData.append("title", "abc");
      formData.append("file", this.selectedFile); // appending file

      streamSrv
        .uploadFile(formData)
        .then((res) => {
          this.$_log("ASW response:", res);
        })
        .catch((err) => {
          this.$_log("ASW err:", err);
        });
    },
    // =<
    onUploadVideo(f) {
      this.$_log("createMusicHeader.vue] onUploadVideo - file:", f);
    },

    onSubmit() {
      const track = {
        //...this.mCurrentUser,
        name: this.mCurrentCreation.name,
        duration: this.mCurrentCreation.duration,
        clientId: this.currentClientId,
        userId: this.currentUserId,
      };

      this.$_log("createMusicHeader.vue] onSubmit - track:", track);

      //createMusicDetailsView

      /*
      this.$store
        .dispatch("streamModule/create", track)
        .then(() => {
          this.$_log(
            "ASW 01 createMusicHeader.vue] onSubmit - precreate client stream:" +
              this.currentUsername +
              " user_id:" +
              this.currentUserId 
          );

          this.$router.push({
            name: "userCreationsView",
            params: {
              pageNr: 0,
              ClientId: this.currentClientId,
            },
          });
        })
        .catch((error) => {
          this.$_log(error);
        });
	  */
    },
  },
};
</script>
<style scoped>
.rightArrow {
  font-size: 22px;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  margin-left: 8px;
}

.dlg_form_header.cmh {
  padding: 0px 0px 8px 0px;
}
</style>
