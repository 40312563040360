<template>
  <div class="mc2">
    <div class="dlg_c_0">
      <ResetPasswordRequestDialog msg="Reset password" />
    </div>
  </div>
</template>

<script>
import ResetPasswordRequestDialog from "@/components/dialogs/ResetPasswordRequest.vue";

export default {
  name: "PasswordResetRequest",
  components: {
    ResetPasswordRequestDialog,
  },
};
</script>

<style scoped></style>
