<template>
  <div class="reg_cnt">
    <div class="dlg_form_container">
      <div class="dlg_c_l1">
        <p class="dlg_form_header">Sign up {{ usageType }}</p>
      </div>

      <div class="dlg_c_l2">
        <idProv
          mode="register"
          msg=""
          :lgnGoogle="loginUsingGoogle"
          :lgnApple="loginUsingApple"
          :lgnFacebook="loginUsingFacebook"
        />

        <div class="dlg_lgn_row">or continue with</div>

        <form @submit.prevent="onSubmit" autocomplete="off">
          <div class="ms_inputbox_0">
            <div class="ms_inputbox_1">
              <input
                v-model="mCurrentUser.email"
                @change="chField($event)"
                type="text"
                placeholder="test@test.com"
                autocomplete="off"
              />
              <fieldset aria-hidden="true"></fieldset>
            </div>
          </div>
          <div id="email_Msg" class="errorMsg"></div>

          <div class="ms_inputbox_0">
            <div class="ms_inputbox_1">
              <input
                v-model="mCurrentUser.password"
                @change="chField($event)"
                type="password"
                placeholder="password"
                autocomplete="off"
              />
              <fieldset aria-hidden="true"></fieldset>
            </div>
          </div>
          <div id="defaultPassMsg">{{ passwordRequirementMsg }}</div>
          <div id="password_Msg" class="errorMsg"></div>

          <div class="ms_inputbox_0">
            <div class="ms_inputbox_1">
              <input
                v-model="mCurrentUser.passwordC"
                @change="chField($event)"
                type="password"
                placeholder="Confirm password"
                autocomplete="off"
              />
              <fieldset aria-hidden="true"></fieldset>
            </div>
          </div>
          <div id="passwordC_Msg" class="errorMsg"></div>

          <div class="dlg_submit_line">
            <button type="submit" class="dlg_submit_btn usr_register">
              Register new account
            </button>
          </div>
        </form>

        <div class="dlg_lgn_row">
          <p>
            Already have an account?
            <span
              class="gradient01"
              @click="goToLoginPage"
              style="cursor: pointer"
              >Sign in</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import idProv from "@/components/elements/idProviders.vue";
import Validator from "validatorjs";
import config from "@/config/config.js";

export default {
  name: "sl_mob_register",
  components: {
    idProv,
  },
  props: {
    usageType: String,
  },
  data() {
    return {
      mCurrentUser: {
        name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        passwordC: "",
      },
      passwordRequirement: config.passwordRequirement,
      passwordRequirementMsg: config.passwordRequirementMsg,

      //responseData: {},
      loginUsingGoogle: config.UniversalSocialauth_idProviders.google.enabled,
      loginUsingFacebook:
        config.UniversalSocialauth_idProviders.facebook.enabled,
      loginUsingApple: config.UniversalSocialauth_idProviders.apple.enabled,
    };
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
    ]),
  },
  mounted() {
    this.log("");
    //this.$posthog.capture("pagevisit " + this.$options.name); //this kind of logs are captured inside the router
    let x = this.$getImageUrl("mobileMainBg01.png");
    this.$replaceMainBackground(x);
    this.log("bg replaced ...");
  },
  methods: {
    //
    log(str, obj) {
      if (typeof obj == "undefined") {
        this.$_log(this.$options.name + "] " + str);
      } else {
        this.$_log(this.$options.name + "] " + str, obj);
      }
    },
    goToLoginPage() {
      this.log("sl_mob_register] goToLogin");

      this.$router.push({
        name: "sl_mob_login",
        params: {
          usageType: "free",
        },
      });
    },

    //passCh(event)
    chField(event) {
      const usr = {
        name: this.mCurrentUser.name,
        last_name: this.mCurrentUser.last_name,
        email: this.mCurrentUser.email,
        password: this.mCurrentUser.password,
        passwordC: this.mCurrentUser.passwordC,
        serviceName: "muzaic.studio", // dastination application: 'muzaic.studio', 'muzaic.biz', 'muzaic.app'
      };
      this.log("chField usr:", usr);
      this.doValidation(usr)
        .then(() => {})
        .catch((error) => {
          this.log("uups form data did not pass validation .... error:", error);
          event.target.focus();
        });
    },
    doValidation(usr) {
      return new Promise((resolve, reject) => {
        let data = {
          //name: usr.name,
          //last_name: usr.last_name,
          email: usr.email,
          password: usr.password,
          passwordC: usr.passwordC,
        };

        this.log("data to validate:", data);

        let rules = {
          //name: "required",
          //last_name: "required",
          password: ["required", this.passwordRequirement],
          passwordC: "same:password",
          email: "required|email",
        };

        for (const [key, value] of Object.entries(data)) {
          this.log(`${key}: ${value}`);
          let o = this.$el.querySelector("#" + key + "_Msg");
          o.innerText = "";
        }

        let validation = new Validator(data, rules, {
          "regex.password": this.passwordRequirementMsg,
          "required.password":
            "The password field is required. \n" + this.passwordRequirementMsg,
          "same.passwordC":
            "The password and confirm password fields must match...",
        });

        if (validation.passes()) {
          this.$_log("all good lets go ahead ....");
          resolve(usr);
        } else {
          this.$_log("ook we have problems ...:", validation);
          let str = "";
          let p = 0;
          for (const [key, value] of Object.entries(validation.errors.all())) {
            this.log("error key:" + key + "value:" + value);
            if (key == "password") {
              p = 1;
            }
            try {
              let o = this.$el.querySelector("#" + key + "_Msg");
              o.innerText = value;
              o.style.marginBottom = "25px";
            } catch (e) {
              this.log("doValidation error:", e);
            }

            str = str + value + " ";
          }

          let dpm = this.$el.querySelector("#defaultPassMsg");
          if (p == 1) {
            this.log("hide");
            dpm.style.display = "none";
          } else {
            this.log(".....");
            dpm.style.display = "block";
          }

          this.log("problems str:" + str);
          reject(str);
        }
      });
    },

    sendFormData(usr) {
      return new Promise((resolve, reject) => {
        this.log("UserRegistration.vue] onSubmit - User:", usr);
        this.$store
          .dispatch("userModule/registerTestUser", usr)
          .then(() => {
            this.log(
              "UserRegistration.vue] onSubmit - then registered email:" +
                this.currentUsername +
                " user_id:" +
                this.currentUserId +
                " user.token:" +
                this.userToken
            );

            resolve();
          })
          .catch((error) => {
            this.log(error);
            reject(error);
          });
      });
    },

    onSubmit() {
      const usr = {
        //name: this.mCurrentUser.name,
        //first_name: this.mCurrentUser.name,
        //last_name: this.mCurrentUser.last_name,
        email: this.mCurrentUser.email,
        password: this.mCurrentUser.password,
        passwordC: this.mCurrentUser.passwordC,
        serviceName: "muzaic.studio", // dastination application: 'muzaic.studio', 'muzaic.biz', 'muzaic.app'
      };

      ///
      // do validation ==>
      this.doValidation(usr)
        .then(() => {
          this.sendFormData(usr)
            .then(() => {
              this.log("OOOOOK we are done :)");
              //this.setCurrentUserDeviceType('desktop');
              if (this.currentSubscription.SubscriptionLevel != "Free") {
                this.setCurrentUserDeviceType("desktop");
                //this.$router.push({ name: "createMusicHeaderView" });
                this.$router.push({
                  name: "userCreationsView",
                  params: {
                    pageNr: 0,
                    ClientId: this.currentClientId,
                  },
                });
              } else {
                this.$router.push({
                  name: "sl_mob_postRegistration",
                });
              }
            })
            .catch((error) => {
              this.log("uups account was not created .... error:", error);
            });
        })
        .catch((error) => {
          this.log("uups form data did not pass validation .... error:", error);
        });
      // do validation ==<
    },

    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("userModule", [
      "login",
      "registerUsingIdProviderData",
      "setCurrentUserDeviceType",
    ]),
  },
};
</script>

<style src="@/assets/css/dialogform_0.css"></style>
<style scoped>
.reg_cnt {
  /*margin-top: 180px;*/
  display: flex;
  width: 100%;
}
.reg_cnt .dlg_form_container {
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 25px;
  width: auto;
}

.reg_cnt .dlg_form_header {
  text-align: center;
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
}

.reg_cnt .ms_inputbox_0 {
  position: relative;
  padding-bottom: 21px;
}
.reg_cnt .ms_inputbox_0a {
  position: relative;
  padding-bottom: 5px;
}

.reg_cnt .dlg_form_container fieldset {
  inset: 0px 0px 0px;
}

.reg_cnt .dlg_form_container input {
  background-color: white;
}

.reg_cnt .dlg_msg {
  margin-bottom: 11px;
}

.reg_cnt .dlg_msg a {
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  line-height: 18px;
  color: var(--main-sec-color);
  padding-right: 30px;
}
.reg_cnt .dlg_msg a:visited {
  color: var(--main-sec-color);
}

.reg_cnt .dlg_lgn_row {
  padding-top: 4px;
  padding-bottom: 8px;

  color: var(--main-gray500-color);
}

.reg_cnt .dlg_lgn_row p {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  text-decoration: none;

  margin: 9px 0px 16px 0px;
}

#defaultPassMsg {
  font-size: 12px;
  /*color: red;*/
  text-align: left;
  padding-bottom: 24px;
}
</style>
