<template>
  <div class="notFound">
    <h1>File not found.</h1>
  </div>
</template>
<script></script>
<style scoped>
.mobile-main-container .notFound {
  height: 100vh;
}
</style>
