import PageModule from "@/store/modules/generic/genericPageModule.js";
import userService from "@/services/userService.js";

var page = new PageModule();

const namespaced = true;

// ==============================================================================
// ==============================================================================
const state = {
  // currently LOGGED IN user
  currentLoggedUser: {
    userDeviceType: "",
    loginFormId: -1,
    loggedIn: false,

    token: "",
    refreshToken: "",

    user_id: -1,
    usertype_id: -1,
    userstatus_id: -1,
    credentials: {},
    name: "",
    email: "",
    expires: "",
    client_id: "",
    first_name: "",
    last_name: "",
    phone: "",
    companyName: "",
    subscription: {
      SubscriptionLevel: "Free",
      UnitsAvailable: 3600,
      current_period_end: "",
      current_period_start: "",
      maxTrackDuration: "1200",
      minTrackDuration: "10",
      nrSecondsToBeUsedMonthly: "3600",
      stripeSubscriptionId: "",
    },
  },
  newTestAccountName: "", // hmm .... what was it for ???? to be checked
  checkingState: 0,
  ...page.state,
};

// ==============================================================================
// ==============================================================================
const getters = {
  ...page.getters,
  getUserDevicetype(state) {
    return state.currentLoggedUser.userDeviceType;
  },
  currentSubscription(state) {
    return state.currentLoggedUser.subscription;
  },

  isTokenValid(state) {
    //var d = new Date().getTime() + new Date().getTimezoneOffset() * 60000;
    var d = new Date().getTime();
    if (typeof state.currentLoggedUser.expires != "undefined") {
      if (state.currentLoggedUser.expires > d) {
        return true;
      } else {
        return false;
      }
    }
  },
  isUserLoggedIn(state) {
    var x = 0;
    if (x == 1) {
      //tb._log("isUserLoggedIn??? no he is not!");
      return false;
    } else {
      if (state.currentLoggedUser.loggedIn === false) {
        return false;
      } else {
        return true;
      }
    }
  },

  userToken(state) {
    return state.currentLoggedUser.token;
  },
  userRefreshToken(state) {
    return state.currentLoggedUser.refreshToken;
  },
  userType(state) {
    return state.currentLoggedUser.usertype_id;
  },
  currentUsername(state) {
    return state.currentLoggedUser.name;
  },
  currentUserEmail(state) {
    return state.currentLoggedUser.email;
  },
  currentUserId(state) {
    return state.currentLoggedUser.user_id;
  },
  currentUserTypeId(state) {
    return state.currentLoggedUser.usertype_id;
  },
  currentUserStatusId(state) {
    return state.currentLoggedUser.userstatus_id;
  },
  currentClientId(state) {
    return state.currentLoggedUser.client_id;
  },

  currentClientFN(state) {
    return state.currentLoggedUser.first_name;
  },
  currentClientLN(state) {
    return state.currentLoggedUser.last_name;
  },
  currentClientPhone(state) {
    return state.currentLoggedUser.phone;
  },
  currentClientCompanyName(state) {
    return state.currentLoggedUser.companyName;
  },
};

// ==============================================================================
// ==============================================================================
const mutations = {
  ...page.mutations,
  SET_USERDEVICETYPE(state, userDeviceType) {
    state.currentLoggedUser.userDeviceType = userDeviceType;
  },
  SET_FNAME(state, fname) {
    state.currentLoggedUser.first_name = fname;
  },
  SET_LNAME(state, lname) {
    state.currentLoggedUser.last_name = lname;
  },
  SET_PHONE(state, phone) {
    state.currentLoggedUser.phone = phone;
  },
  SET_EMAIL(state, email) {
    state.currentLoggedUser.email = email;
  },
  SET_COMPANYNAME(state, companyName) {
    state.currentLoggedUser.companyName = companyName;
  },
  UPDATE_LOCALSTORAGE(state) {
    localStorage.setItem("user", JSON.stringify(state.currentLoggedUser));
  },
  LOG_IN(state, userData) {
    let userDeviceType = state.currentLoggedUser.userDeviceType;
    state.currentLoggedUser = userData;
    state.currentLoggedUser.loggedIn = true;
    state.currentLoggedUser.userDeviceType = userDeviceType;
    // register data also in localStorage !!
    localStorage.setItem("user", JSON.stringify(userData));
  },
  LOG_OUT(state) {
    //clear currentuser

    state.currentLoggedUser.loginFormId = -1;

    state.currentLoggedUser.user_id = -1;
    state.currentLoggedUser.usertype_id = -1;
    state.currentLoggedUser.credentials = {};
    state.currentLoggedUser.name = "";
    state.currentLoggedUser.email = "";
    state.currentLoggedUser.token = "";
    state.currentLoggedUser.refreshToken = "";

    state.currentLoggedUser.expires = "";
    state.currentLoggedUser.loggedIn = false;

    state.currentLoggedUser.client_id = "";
    state.currentLoggedUser.first_name = "";
    state.currentLoggedUser.last_name = "";

    localStorage.removeItem("user");
  },
  REGISTER_TEST_USER(state, data) {
    state.newTestAccountName = data.name;
  },
  SET_TOKEN(state, data) {
    // set state.currentLoggedUser based on localstorage
    const u = localStorage.getItem("user");
    let x = 1;
    if (x == 1) {
      if (u != null) {
        var u0 = JSON.parse(u);
        state.currentLoggedUser = u0;

        // then ->
        state.currentLoggedUser.token = data.token;
        state.currentLoggedUser.expires = data.expires;

        // then set localstorage based on currentUser ..
        localStorage.setItem("user", JSON.stringify(state.currentLoggedUser));
      }
    }
  },
  SET_STATE_BASED_ON_LOCAL_STORAGE(state) {
    /*
    tb._log(
      "lets update user state --------------------------------------"
    );
	*/
    var u = localStorage.getItem("user");
    if (u != null) {
      var u0 = JSON.parse(u);

      state.currentLoggedUser.token = u0.token;
      state.currentLoggedUser.refreshToken = u0.refreshToken; // ?

      state.currentLoggedUser.usertype_id = u0.usertype_id;
      state.currentLoggedUser.name = u0.name; // ?
      state.currentLoggedUser.email = u0.email;
      state.currentLoggedUser.user_id = u0.user_id;
      state.currentLoggedUser.usertype_id = u0.usertype_id;

      state.currentLoggedUser.client_id = u0.client_id;
      state.currentLoggedUser.first_name = u0.first_name;
      state.currentLoggedUser.last_name = u0.last_name;
    }
  },

  SET_SUBSCRIPTION(state, data) {
    state.currentLoggedUser.subscription = data;
  },

  SET_AVAILABLE_UNITS(state, au) {
    state.currentLoggedUser.subscription.UnitsAvailable = au;
    state.currentLoggedUser.subscription.alreadyUsedTime =
      state.currentLoggedUser.subscription.nrSecondsToBeUsedMonthly - au;
  },

  SET_CHECKING_STATE(state, s) {
    state.checkingState = s;
  },
};

// ==============================================================================
// ==============================================================================
const actions = {
  ...page.actions,
  setCurrentUserDeviceType({ commit }, userDeviceType) {
    commit("SET_USERDEVICETYPE", userDeviceType);
  },
  setAvailableUnits({ commit }, obj) {
    commit("SET_AVAILABLE_UNITS", obj.availableUnits);
  },
  setUserSubscription({ commit }, data) {
    return new Promise((resolve, reject) => {
      if (typeof data != "undefined") {
        commit("SET_SUBSCRIPTION", data); // <<< !!!!
        resolve();
      } else {
        reject("incorrect data");
      }
    });
  },
  //
  setUserProfileData({ commit }, data) {
    commit("SET_FNAME", data.first_name);
    commit("SET_LNAME", data.last_name);
    commit("SET_PHONE", data.phone);
    commit("SET_EMAIL", data.email);
    commit("SET_COMPANYNAME", data.companyName);
    commit("UPDATE_LOCALSTORAGE");
  },

  // register and login user to the system ....
  registerUsingIdProviderData({ commit, dispatch }, data) {
    let n = {};
    return new Promise((resolve, reject) => {
      userService
        .registerUsingIdProviderData(data)
        .then((r) => {
          dispatch(
            "asw_log",
            ["registerUsingIdProviderData response:", r.data],
            {
              root: true,
            }
          );

          //
          let tmpObj = r.data;
          commit("LOG_IN", tmpObj); // <<< !!!!

          n = {
            type: "success",
            message: "You are correctly registered and logged in",
          };
          dispatch("notificationModule/add", n, { root: true });
          resolve();
        })
        .catch((error) => {
          dispatch("asw_log", ["registerUsingIdProviderData error", error], {
            root: true,
          });

          if (typeof error.response != "undefined") {
            reject(error);
          } else {
            n = {
              type: "error",
              message: "Probably network issue.",
            };
            dispatch("notificationModule/add", n, { root: true });
            reject(error);
          }
        });
    });
  },

  //
  loginUsingIdProviderData({ commit, dispatch }, data) {
    let n = {};

    return new Promise((resolve, reject) => {
      userService
        .loginUsingIdProviderData(data)
        .then((r) => {
          dispatch("asw_log", ["loginUsingIdProviderData response:", r.data], {
            root: true,
          });

          commit("LOG_IN", r.data); // <<< !!!!

          n = {
            type: "success",
            message: "You are correctly logged in",
          };
          dispatch("notificationModule/add", n, { root: true });
          resolve();
        })
        .catch((error) => {
          //tb._log("ASW grrrr error:", error);
          dispatch(
            "asw_log",
            ["loginUsingIdProviderData error", error.response.data],
            {
              root: true,
            }
          );

          if (typeof error.response != "undefined") {
            if (error.response.status == 403 && error.response.data.status) {
              n = {
                type: "error",
                message: "The username or password is incorrect.",
              };
              dispatch("notificationModule/add", n, { root: true });
              reject("incorrect credentials");
            } else {
              reject(error.response.data.error);
            }
          } else {
            n = {
              type: "error",
              message: "Probably network issue.",
            };
            dispatch("notificationModule/add", n, { root: true });
            //throw error.response.data;
            reject(error);
          }
        });
    });
  },
  //
  async login({ commit, dispatch }, userCredentials) {
    var n = {};
    return await userService
      .login(userCredentials)
      .then((r) => {
        dispatch("asw_log", ["login response:", r.data], { root: true });
        commit("LOG_IN", r.data); // <<< !!!!
        n = {
          type: "success",
          message: "You are correctly logged in",
        };
        dispatch("notificationModule/add", n, { root: true });
      })
      .catch((error) => {
        dispatch("asw_log", ["login error", error], { root: true });

        if (typeof error.response != "undefined") {
          if (error.response.status == 403 && error.response.data.status) {
            n = {
              type: "error",
              message: "The username or password is incorrect.",
            };
            if (error.response.data.msg == "not confirmed user account") {
              n = {
                type: "error",
                message:
                  "The user account is not confirmed yet. Please check your email and click on the confirmation link to set up your account.",
              };
            }
            dispatch("notificationModule/add", n, { root: true });
            throw "incorrect credentials";
          }
        } else {
          n = {
            type: "error",
            message: "Probably network issue.",
          };
          dispatch("notificationModule/add", n, { root: true });
          throw error;
        }
      });
  },

  //
  async logout({ commit, dispatch, getters }) {
    let n = {};
    //let rt = getters.userRefreshToken;

    dispatch("asw_log", ["logout"], { root: true });
    var x = 1;

    if (x == 1) {
      return await userService
        .logout(getters.userRefreshToken)
        .then((r) => {
          dispatch("asw_log", ["logout response", r.data], { root: true });
          commit("LOG_OUT");

          //if (getters.userRefreshToken != "") { dispatch("asw_log", ["hmmm getters.userRefreshToken != ''"], { root: true }); }

          n = {
            type: "success",
            message: "You are correctly logged out",
          };
          dispatch("notificationModule/add", n, { root: true });
        })
        .catch((error) => {
          dispatch("asw_log", ["logout error"], { root: true });
          n = {
            type: "error",
            message: "There was a problem with logout, erro:",
            error,
          };
          dispatch("notificationModule/add", n, { root: true });
          throw error;
        });
    }
  },

  //
  async registerTestUser({ commit, dispatch }, data) {
    var n = {};
    return await userService
      .registerTestUser(data)
      .then((r) => {
        dispatch("asw_log", "registerTestUser response", { root: true });
        commit("REGISTER_TEST_USER", r.data);
        n = {
          type: "success",
          message:
            "Your account has been created please check your email to confirm.",
        };
        dispatch("notificationModule/add", n, { root: true });
      })
      .catch((error) => {
        dispatch("asw_log", "registerTestUser error", { root: true });

        if (typeof error.response != "undefined") {
          if (error.response.status == 403 && error.response.data.status) {
            throw "incorrect data";
          }
        } else {
          n = {
            type: "error",
            message: "Probably network issue.",
          };
          dispatch("notificationModule/add", n, { root: true });
          throw error;
        }
      });
  },

  tryToSetStateBasedOnLocalStorage({ commit, state }) {
    if (state.currentLoggedUser.refreshToken == "") {
      commit("SET_STATE_BASED_ON_LOCAL_STORAGE");
    }
  },

  // for some reason that i cant understand now getter.isUserLoggedIn is not checked each time when
  // this function is called !! i have to check it later one more time !! for now instead of using getters
  // im checking state dirrectly

  // isUserLoggedIn_Action_P({ dispatch, commit, getters, state }) {
  isUserLoggedIn_Action_P({ dispatch, commit, state }) {
    dispatch("asw_log", ["isUserLoggedIn_Action_P 0"], {
      root: true,
    });
    commit("SET_CHECKING_STATE", 1);
    //state.checkingState = 1;

    if (state.currentLoggedUser.refreshToken == "") {
      commit("SET_STATE_BASED_ON_LOCAL_STORAGE");
    }

    function iTV(expires) {
      //dispatch("asw_log", "iTV", { root: true });
      //var d = new Date().getTime() + new Date().getTimezoneOffset() * 60000;
      var d = new Date().getTime();
      if (typeof expires != "undefined") {
        dispatch("asw_log", ["expires:" + expires], { root: true });
        let exp = new Date(expires);
        let now = new Date(d);
        dispatch(
          "asw_log",
          [
            "expires:" +
              exp.toLocaleString("en-GB") +
              " now:" +
              now.toLocaleString("en-GB"),
          ],
          { root: true }
        );

        if (expires > d) {
          return true;
        } else {
          return false;
        }
      }
    }

    //return new Promise((resolve, reject) => {
    return new Promise((resolve) => {
      //var iULI = getters.isUserLoggedIn;
      let updateToken = 0;

      if (iTV(state.currentLoggedUser.expires) === true) {
        if (state.currentLoggedUser.loggedIn === true) {
          updateToken = 0;
        } else {
          updateToken = 1;
        }
      } else {
        updateToken = 1;
      }

      if (updateToken == 1) {
        if (state.currentLoggedUser.refreshToken != "") {
          //
          //userService.refreshUserToken(getters.userRefreshToken)
          userService
            .refreshUserToken(state.currentLoggedUser.refreshToken)
            .then((r) => {
              dispatch("asw_log", ["response", r.data], {
                root: true,
              });

              commit("SET_TOKEN", r.data);
              //state.checkingState = 0;
              commit("SET_CHECKING_STATE", 0);
              resolve(true);
            })
            .catch((error) => {
              dispatch("asw_log", ["ASW error::", error], { root: true });
              commit("LOG_OUT"); // << ??
              //reject(error);

              //state.checkingState = 0;
              commit("SET_CHECKING_STATE", 0);
              resolve(false);
            });
          //
        } else {
          dispatch("asw_log", ["there is no refresh token ..."], {
            root: true,
          });
          // there is no refresh token ... no way, you need to login man ...
          //state.checkingState = 0;
          commit("SET_CHECKING_STATE", 0);
          resolve(false);
        }
      } else {
        commit("SET_CHECKING_STATE", 0);
        resolve(true);
      }
    });
  },

  //
  async resetPasswordRequest({ commit, dispatch }, data) {
    var n = {};
    return await userService
      .resetPasswordRequest(data.email)
      .then((r) => {
        dispatch("asw_log", ["resetPasswordRequest response:", r], {
          root: true,
        });
        commit("REGISTER_TEST_USER", r.data);
        n = {
          type: "success",
          message: "Message with link to reset password has been sent.",
        };
        dispatch("notificationModule/add", n, { root: true });
      })
      .catch((error) => {
        dispatch("asw_log", ["resetPasswordRequest error:", error], {
          root: true,
        });
        if (typeof error.response != "undefined") {
          if (error.response.status >= 400) {
            n = {
              type: "error",
              message: "Incorrect data. We dont have such a mail in our db.",
            };
            dispatch("notificationModule/add", n, { root: true });
            throw "incorrect data";
          }
        } else {
          n = {
            type: "error",
            message: "Probably network issue.",
          };
          dispatch("notificationModule/add", n, { root: true });
          throw error;
        }
      });
  },

  //
  async resetPassword({ commit, dispatch }, data) {
    var n = {};
    return await userService
      .resetPassword(data)
      .then((r) => {
        dispatch("asw_log", ["resetPassword response:", r], { root: true });
        commit("REGISTER_TEST_USER", r.data);
        n = {
          type: "success",
          message: "Message with link to reset password has been sent.",
        };
        dispatch("notificationModule/add", n, { root: true });
      })
      .catch((error) => {
        dispatch("asw_log", ["resetPassword error:", error], { root: true });
        if (typeof error.response != "undefined") {
          if (error.response.status >= 400) {
            n = {
              type: "error",
              message: "Problem with saving password.",
            };
            dispatch("notificationModule/add", n, { root: true });
            throw "incorrect data";
          }
        } else {
          n = {
            type: "error",
            message: "Probably network issue.",
          };
          dispatch("notificationModule/add", n, { root: true });
          throw error;
        }
      });
  },
};

/////////////

export { namespaced, state, getters, mutations, actions };
