<template>
  <div class="mc2">
    <div v-if="dataReady" class="idf_container" ref="actionButtons">
      <div class="idf_grid_container">
        <div class="userSetingsForm">
          <div class="idf_cl1">
            <p class="idf_header">Account Settings</p>

            <div class="button_W" @click="logout">Logout</div>
          </div>
          <div class="idf_cl2">
            <form @submit.prevent="onSubmit">
              <div class="ms_inputbox_0">
                <label>First name</label>
                <div class="ms_inputbox_1">
                  <input
                    v-model="mCurrentUser.first_name"
                    type="text"
                    placeholder="First name"
                    @input="isModified"
                  />
                  <fieldset aria-hidden="true">
                    <legend><span>First name</span></legend>
                  </fieldset>
                </div>
              </div>

              <div class="ms_inputbox_0">
                <label>Last name</label>
                <div class="ms_inputbox_1">
                  <input
                    v-model="mCurrentUser.last_name"
                    type="text"
                    placeholder="Last name"
                    @input="isModified"
                  />
                  <fieldset aria-hidden="true">
                    <legend><span>Last name</span></legend>
                  </fieldset>
                </div>
              </div>

              <div class="ms_inputbox_0">
                <label>Company name</label>
                <div class="ms_inputbox_1">
                  <input
                    v-model="mCurrentUser.name"
                    type="text"
                    placeholder="Company name"
                    @input="isModified"
                  />
                  <fieldset aria-hidden="true">
                    <legend><span>Company name</span></legend>
                  </fieldset>
                </div>
              </div>

              <div class="ms_inputbox_0">
                <label>E-mail</label>
                <div class="ms_inputbox_1">
                  <input
                    v-model="mCurrentUser.email"
                    type="text"
                    placeholder="test@test.com"
                    disabled="true"
                    class="usrInitialsEmail"
                  />
                  <fieldset aria-hidden="true">
                    <legend><span>E-mail</span></legend>
                  </fieldset>
                </div>
              </div>

              <div class="ms_inputbox_0">
                <label>Phone number</label>
                <div class="ms_inputbox_1">
                  <input
                    v-model="mCurrentUser.phone"
                    type="text"
                    placeholder="123 123 123"
                    @input="isModified"
                  />
                  <fieldset aria-hidden="true">
                    <legend><span>Phone number</span></legend>
                  </fieldset>
                </div>
              </div>

              <div class="idf_submit_line">
                <button
                  type="button"
                  class="idf_cancel_btn idf_cancel_btn_wide cancel_action_disabled"
                  @click="onCancel"
                >
                  Cancel</button
                ><button
                  type="submit"
                  class="idf_submit_btn idf_submit_btn_wide submit_action_disabled"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="userSubscriptionsCmp">
          <userSubscriptionsCmp :ClientId="currentClientId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";
import userService from "@/services/userService.js"; // access to the APIs
//import DropDownList from "@/components/dialogs/formElements/DropDownList.vue";
import userSubscriptionsCmp from "@/components/dialogs/UserSubscriptions.vue";

export default {
  name: "UserDetails",
  props: ["msg", "id"],

  components: {
    userSubscriptionsCmp,
  },

  data() {
    return {
      user: null,
      mCurrentUser: {
        user_id: this.currentUserId,
        name: this.currentUsername,

        UserType_id: this.currentUserTypeId,
        userStatus_id: "",
        // remarks: "",
        first_name: this.currentClientFN,
        last_name: this.currentClientLN,

        email: this.currentUserEmail,
        phone: this.currentClientPhone,
      },
      dataReady: false,
      cbtn: null,
      sbtn: null,
      formDataModified: false,
    };
  },

  setup() {
    const actionButtons = ref(null);
    return {
      actionButtons,
    };
  },

  created() {
    /*
	  this.$_log(
		"User [" +
		  this.currentUserId +
		  "] Details TOKEN >>>>" +
		  this.$store.state.userModule.currentUser.token
	  );
	  */
    if (typeof this.mCurrentUser.first_name == "undefined") {
      this.mCurrentUser.first_name = "";
    }
    if (typeof this.mCurrentUser.last_name == "undefined") {
      this.mCurrentUser.last_name = "";
    }
    if (this.isUserLoggedIn) {
      // hmm but why the hell should it check it ???

      userService
        .getUserDetails(this.currentUserId)
        .then((response) => {
          this.$_log("asw response.data:", response.data.user_data[0]);
          this.mCurrentUser = response.data.user_data[0];
          this.dataReady = true;
        })
        .then(() => {
          this.$_log("===========>");
          this.$_log("this.actionButtons:", this.actionButtons);
          this.$_log("===========<");
          this.cbtn = this.actionButtons.querySelector(".idf_cancel_btn");
          this.sbtn = this.actionButtons.querySelector(".idf_submit_btn");
        })
        .catch((error) => {
          this.$_log("asw error:", error);
        });
    }
  },

  computed: {
    ...mapGetters("userModule", [
      "userToken",
      "isUserLoggedIn",
      "currentUserId",
      "currentUsername",
      "currentUserEmail",
      "currentUserTypeId",
      "currentClientId",
      "currentClientFN",
      "currentClientLN",
      "currentClientPhone",
    ]),
    currUserAbrev() {
      let usrAbrev = "";

      if (this.mCurrentUser.first_name == "") {
        if (this.currentClientFN != "") {
          usrAbrev += this.currentClientFN[0];
        }
      } else {
        usrAbrev += this.mCurrentUser.first_name[0];
      }

      if (this.mCurrentUser.last_name == "") {
        if (this.currentClientLN != "") {
          usrAbrev += this.currentClientLN[0];
        }
      } else {
        usrAbrev += this.mCurrentUser.last_name[0];
      }

      if (usrAbrev == "") {
        usrAbrev = "...";
      }
      return usrAbrev.toUpperCase();
    },
  },
  methods: {
    ...mapActions("userModule", { setUserProfileData: "setUserProfileData" }),
    enableActions() {
      if (!this.cbtn.classList.contains("action_enabled")) {
        this.cbtn.classList.add("action_enabled");
      }

      if (!this.sbtn.classList.contains("action_enabled")) {
        this.sbtn.classList.add("action_enabled");
      }

      if (this.cbtn.classList.contains("cancel_action_disabled")) {
        this.cbtn.classList.remove("cancel_action_disabled");
      }
      if (this.sbtn.classList.contains("submit_action_disabled")) {
        this.sbtn.classList.remove("submit_action_disabled");
      }
    },
    disableActions() {
      if (this.cbtn.classList.contains("action_enabled")) {
        this.cbtn.classList.remove("action_enabled");
      }

      if (this.sbtn.classList.contains("action_enabled")) {
        this.sbtn.classList.remove("action_enabled");
      }

      if (!this.cbtn.classList.contains("cancel_action_disabled")) {
        this.cbtn.classList.add("cancel_action_disabled");
      }
      if (!this.sbtn.classList.contains("submit_action_disabled")) {
        this.sbtn.classList.add("submit_action_disabled");
      }
    },

    logout() {
      this.$store
        .dispatch("userModule/logout")
        .then(() => {
          this.$router.push({
            name: "login",
            replace: true,
          });
        })
        .catch((error) => {
          this.$_log(error);
        });
    },
    isModified() {
      if (
        //this.mCurrentUser.user_id!=this.currentUserId ||
        this.mCurrentUser.name != this.currentUsername ||
        //|| this.mCurrentUser.UserType_id!=this.currentUserTypeId
        //|| this.mCurrentUser.userStatus_id!=""

        this.mCurrentUser.first_name != this.currentClientFN ||
        this.mCurrentUser.last_name != this.currentClientLN ||
        //|| this.mCurrentUser.email!=this.currentUserEmail
        this.mCurrentUser.phone != this.currentClientPhone
      ) {
        this.formDataModified = true;

        this.$_log("FORM MODIFIED");
        this.enableActions();
      } else {
        this.$_log("FORM NOT MODIFIED");
        this.formDataModified = false;
        this.disableActions();
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    setUserStatus(v) {
      this.$_log("setUserStatus elName:" + v.name + " elValue:" + v.value);
      this.mCurrentUser.userStatus_id = v.value;
      this.$_log(
        "setUserStatus this.mCurrentUser.userStatus_id:" +
          this.mCurrentUser.userStatus_id
      );
    },
    onFn001(v) {
      this.$_log("onFn001 elName:" + v.name + " elValue:" + v.value);
      //this.$_log("onFn001 elValue:" + v);
    },
    onCancel() {
      if (this.formDataModified) {
        this.mCurrentUser.name = this.currentUsername;
        this.mCurrentUser.first_name = this.currentClientFN;
        this.mCurrentUser.last_name = this.currentClientLN;
        this.mCurrentUser.phone = this.currentClientPhone;

        this.disableActions();
        this.$_log("changes canceled ....");
      }
    },
    onSubmit() {
      if (this.formDataModified) {
        this.setUserProfileData({
          name: this.mCurrentUser.name,
          email: this.mCurrentUser.email,

          first_name: this.mCurrentUser.first_name,
          last_name: this.mCurrentUser.last_name,
          phone: this.mCurrentUser.phone,
        });

        const usr = {
          user_id: this.currentUserId, // <- getter
          name: this.mCurrentUser.name,
          email: this.mCurrentUser.email,
          UserType_id: this.mCurrentUser.UserType_id,
          userStatus_id: this.mCurrentUser.userStatus_id,
          remarks: this.mCurrentUser.remarks,
          first_name: this.mCurrentUser.first_name,
          last_name: this.mCurrentUser.last_name,
          phone: this.mCurrentUser.phone,
        };

        this.$_log("UserDetails.vue] onSubmit - User:", usr);
        userService
          .setUserDetails(usr)
          .then((response) => {
            this.$_log("asw response.data:", response.data);
            //this.user = response.data.user_data[0];
          })
          .catch((error) => {
            this.$_log("asw error:", error);
          });
      }
    },
  },
};
</script>

<style scoped>
.usrInitialsContainer {
  position: relative;
  /*
  display: inline-block;
  padding: 0 0px 0 20px;
  */
  width: 300px;
  height: 300px;
}
.usrInitials {
  /* 
  border: 1px solid black;
  padding: 10px;
  */
  border-radius: 100%;
  background: linear-gradient(
    135deg,
    rgb(96, 238, 208) 0%,
    rgb(102, 142, 255) 100%
  );
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: rgb(255, 255, 255);
  user-select: none;
  font-weight: 600;
  font-size: 100px;
}

.usrInitialsEmail {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
}

.userSetingsForm,
.userSubscriptionsCmp {
  width: 100%;
  max-width: 684px;
  min-width: 300px;
  justify-self: center;
}
</style>
