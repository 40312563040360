<template>
  <tr>
    <template v-for="(r, r_index) in tableFields" :key="r.id + '_' + r_index">
      <th v-if="!this.hideField(r.name)" :class="fieldClassName(r.name)">
        {{ fieldTranslation(r.name) }}
      </th>
    </template>

    <th id="ch_functions">{{ fieldTranslation("functions") }}</th>
  </tr>
</template>

<script>
export default {
  name: "tabHeader",
  components: {},
  props: [
    "tableFields",
    "fieldsToHide",
    "fieldsClass",
    "funColName",
    "fieldsTranslation",
  ],
  created() {
    this.$_log("tabHeader created ...");
  },
  methods: {
    hideField(fieldName) {
      let x = 0;
      if (Array.isArray(this.fieldsToHide)) {
        if (this.fieldsToHide.length > 0) {
          x = 1;
        }
      }
      if (x == 1) {
        for (let i = 0; i < this.fieldsToHide.length; i++) {
          if (this.fieldsToHide[i].name == fieldName) return true;
        }
      } else {
        return false;
      }
    },
    fieldTranslation(fieldName) {
      let r = "";
      let x = 0;
      if (Array.isArray(this.fieldsTranslation)) {
        if (this.fieldsTranslation.length > 0) {
          x = 1;
        }
      }
      if (x == 1) {
        for (let i = 0; i < this.fieldsTranslation.length; i++) {
          if (this.fieldsTranslation[i].name == fieldName) {
            this.$_log(this.fieldsTranslation[i]);
            r = this.fieldsTranslation[i].value;
          }
        }
      }

      if (r == "" && fieldName == "functions") {
        r = "functions!";
      }

      // what to show in the column that contains id or index
      if (r == "" && fieldName == "id") {
        r = "#";
      }
      if (r != "") {
        return this.$toCamelCase(r);
      } else {
        return this.$toCamelCase(fieldName);
      }
    },
    fieldClassName(fieldName) {
      if (Array.isArray(this.fieldsClass)) {
        for (let i = 0; i < this.fieldsClass.length; i++) {
          if (this.fieldsClass[i].name == fieldName) {
            return "mm_" + fieldName + " " + this.fieldsClass[i].class;
          }
        }
        return "mm_" + fieldName;
      } else {
        return "mm_" + fieldName;
      }
    },
  },
};
</script>

<style>
#ch_functions {
  text-align: left;
}
</style>
