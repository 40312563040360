<template>
  <div class="mc2">
    <!--  -->
    <userSubscriptionsCmp :ClinetId="currentClientId" />
    <!--  -->
  </div>
</template>

<script>
import userSubscriptionsCmp from "@/components/dialogs/UserSubscriptions.vue";

export default {
  name: "userSubscriptions",
  props: ["ClientId"],
  components: {
    userSubscriptionsCmp,
  },
  data() {
    return {};
  },
  created() {
    //this.$_log("0 this.msr:", this.msr);
  },
};
</script>

<style scoped></style>
