<template>
  <div class="mc2">
    <div class="lgn_c0">
      <LoginDialog msg="Log in" />
    </div>
  </div>
</template>

<script>
import LoginDialog from "@/components/dialogs/LoginDialog.vue";

export default {
  name: "LoginView",
  components: {
    LoginDialog,
  },
};
</script>

<style scoped></style>
