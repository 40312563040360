<template>
  <div ref="root_createMusicMood" class="mood_cnt">
    <div v-if="dataReady" id="moodblk" class="dlg_form_container">
      <div class="dlg_c_l1">
        <p class="dlg_form_header">Select moods</p>
      </div>

      <div id="tagsBlk">
        <div class="cmprTags">
          <div class="streamTagTabContainer">
            <div class="streamTagElBlk">
              <template
                v-for="(tagRow, tr_index) in prepareArrayForTemplate('tags')"
                :key="tr_index"
              >
                <template v-for="(t, t_index) in tagRow" :key="t_index">
                  <div class="">
                    <button
                      :class="'streamButton tags ' + isTagSelected(t.id)"
                      @click="checkTagRelations($event, t.id)"
                      :value="t.id"
                      :id="'btnTag_' + t.id"
                    >
                      <div v-if="alreadySelected(t.id)">
                        <div class="svgContainerClicked">
                          <svg
                            width="12"
                            heigth="9"
                            viewBox="0 0 12 9"
                            fill="none"
                          >
                            <path
                              d="M11 0.833252L4.125 8.33325L1 4.92416"
                              stroke="white"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div v-else>
                        <div class="svgContainer">
                          <svg
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                              stroke="none"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      {{ capitalize(t.name) }}
                    </button>
                  </div>
                </template>
              </template>
            </div>
          </div>

          <div class="showMore">
            <template v-if="!tagsMore"
              ><a @click="toggleMore('tags')" class="cmprLink"
                >more moods</a
              ></template
            ><template v-else
              ><a @click="toggleMore('tags')" class="cmprLink"
                >less moods</a
              ></template
            >
          </div>

          <!-- >>> MoodOdTheWeek2 -->

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="312"
            height="95"
            viewBox="0 0 312 85"
            fill="none"
          >
            <path
              d="M14.435 1H9C4.58172 1 1 4.58172 1 9V76C1 80.4183 4.58172 84 9 84H303C307.418 84 311 80.4183 311 76V9C311 4.58172 307.418 1 303 1H151"
              stroke="url(#paint0_linear_2999_13418)"
              style="mix-blend-mode: multiply"
            ></path>

            <defs>
              <linearGradient
                id="paint0_linear_2999_13418"
                x1="1"
                y1="1"
                x2="311.498"
                y2="0.999998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#60EED0" />
                <stop offset="1" stop-color="#01A0FF" />
              </linearGradient>
            </defs>

            <foreignObject x="3" y="-10" width="160" height="40">
              <span class="motw">Mood of the week!</span>
            </foreignObject>
            <!-- moodOfTheWeek button > -->
            <foreignObject
              x="calc( 50% - 55px )"
              y="calc( 50% - 15px )"
              width="110px"
              height="40px"
            >
              <button
                :class="'streamButton tags ' + isTagSelected(tagOfTheWeek.id)"
                @click="checkTagRelations($event, tagOfTheWeek.id)"
                :value="tagOfTheWeek.id"
                :id="'btnTag_' + tagOfTheWeek.id"
              >
                <div v-if="alreadySelected(tagOfTheWeek.id)">
                  <div class="svgContainerClicked">
                    <svg width="12" heigth="9" viewBox="0 0 12 9" fill="none">
                      <path
                        d="M11 0.833252L4.125 8.33325L1 4.92416"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div v-else>
                  <div class="svgContainer">
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                        stroke="none"
                      ></path>
                    </svg>
                  </div>
                </div>
                {{ capitalize(tagOfTheWeek.name) }}
              </button>
            </foreignObject>
            <!-- moodOfTheWeek button < -->
          </svg>
          <!-- <<< -->
        </div>
      </div>
      <!-- MOTIVES ######################################################### -->
      <div id="moodsBlk" style="display: none">
        <div class="cmprMotives">
          <div class="streamMotiveTabContainer">
            <!--  <table> -->
            <div class="streamMotiveElBlk">
              <template
                v-for="(tagRow, tr_index) in prepareArrayForTemplate('motives')"
                :key="tr_index"
              >
                <!-- <tr> -->

                <template v-for="(t, t_index) in tagRow" :key="t_index">
                  <!-- <td> -->

                  <div class="motiveButtonContainer">
                    <button
                      :class="
                        'streamButton ' + isMotiveSelected(t.id) + ' motives'
                      "
                      @click="checkEnabledMotives($event, t.id)"
                      :value="t.id"
                      :id="'btnMotive_' + t.id"
                    >
                      <template v-if="isMotiveSelected(t.id) != 'clicked'">
                        <div>
                          <div class="disabledAudBtn blue"></div>
                        </div>
                      </template>
                      <template v-else> </template>

                      <div class="motiveDescCnt">
                        <div class="motiveDescName">{{ t.name }}</div>
                        <div class="motiveDescStyle">
                          {{ t.style }}
                        </div>
                      </div>
                    </button>
                  </div>
                  <!-- </td> -->
                </template>

                <!-- </tr> -->
              </template>
            </div>
            <!-- </table> -->
          </div>
          <div class="showMore">
            <template v-if="!motivesMore"
              ><a @click="toggleMore('motives')" class="cmprLink"
                >Show more</a
              ></template
            ><template v-else
              ><a @click="toggleMore('motives')" class="cmprLink"
                >Hide</a
              ></template
            >
          </div>
        </div>
      </div>
      <!-- ######################################################### -->

      <div class="dlg_c_l2">
        <form @submit.prevent="onSubmit">
          <div class="uvMsg_1">
            <signInGoPro msg="Want more options?" colorMsg="Sign up now!" />
          </div>

          <div class="idf_submit_line">
            <img
              src="@/assets/sl_back.png"
              @click="goToPrevStep"
              style="width: 55px; cursor: pointer"
            />

            <button
              type="submit"
              class="dlg_uploadVideo_4_submitIco idf_submit_btn idf_submit_btn_wide"
              :disabled="doNotLetHimGo"
              @click="goTo_adjust_page"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";

import useMoreMoodsDialog from "@/features/useMoreMoodsDialog.js";
import signInGoPro from "@/components/elements/signInGoPro.vue";

export default {
  name: "sl_createMusicMood",

  setup() {
    const root_createMusicMood = ref(null);
    return {
      root_createMusicMood,
    };
  },

  components: { signInGoPro },
  data() {
    return {
      trackDuration: null,
      trackName: null,
      videoImage: "",
      video: "",
      ClientId: "",

      tagOfTheWeek: {
        // moodOfTheWeek ?
        id: 2,
        name: "electronic",
      },

      clientSubscriptionType: "free",

      tagsMore: false,
      motivesMore: false,

      nrTagColumns: 3,
      nrTagRows: 2,
      nrMotiveColumns: 3,
      nrMotiveRows: 1,
      TagsToBeDisplayed: null,

      MotivesToBeDisplayed: null,
      selectedTags: [],
      selectedMotives: [],
      disabledTags: [],

      containerDom: null,
      dataReady: false,
    };
  },
  created() {
    this.log("created");
    this.init();
    this.getComponentData((err, r) => {
      if (err) {
        this.$_log("error:", err);
      } else {
        this.postGetComponentData(r);
      }
    });
  },
  mounted() {
    this.containerDom = this.root_createMusicMood;
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
      "currentSubscription",
    ]),
    ...mapGetters("streamModule", {
      strName: "streamName",
      strImage: "streamImage",
      strDuration: "streamDuration",
      strTags: "streamTags",
      strMotives: "streamMotives",
      strParams: "streamParams",
      receivedTags: "receivedTags",
      strVideo: "streamVideo",
    }),

    doNotLetHimGo() {
      let T = this.collectTagsArray();
      if (T.length > 0) return false;
      else return true;
    },
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("streamModule", {
      initStream: "initStream",
      getMusicTags: "musicTags",
      getMusicMotives: "musicMotives",
      setStreamMotives: "setStreamMotives",
      setStreamTags: "setStreamTags",
      setFilteredTags: "setFilteredTags",
    }),
    ...mapActions("clientModule", { getClientProduct: "getClientProduct" }),

    //
    goToProRegistration() {
      this.$router.push({
        name: "sl_mob_msSubscriptionsView",
        params: { id: 1 },
      });
    },
    goToPrevStep() {
      this.log("goToPrevStep ... A");
      this.$router.push({
        name: "sl_mob_uploadVideo",
      });
      this.log("goToPrevStep ... Z");
    },
    //
    goTo_adjust_page() {
      this.$router.push({
        name: "sl_mob_adjust",
      });
    },

    //
    log(str, obj) {
      if (typeof obj == "undefined") {
        this.$_log(this.$options.name + "] " + str);
      } else {
        this.$_log(this.$options.name + "] " + str, obj);
      }
    },

    //
    // --------------------------------------------------------------------
    init() {
      this.log("init ");

      this.trackDuration = this.trackDuration == null ? "" : this.strDuration;
      this.trackName = this.trackName == null ? "" : this.strName;
      this.videoImage = this.strImage;
      this.video = "";
      this.ClientId = this.currentClientId;

      //
      this.selectedTags = this.collectTagsArray();
    },

    //
    collectTagsArray() {
      let musicTags = [];
      this.log("collectData typeof(this.strTags):" + typeof this.strTags);
      if (typeof this.strTags != "undefined" && this.strTags !== null) {
        for (const [key, value] of Object.entries(this.strTags)) {
          this.$_log(key);
          musicTags.push(value);
        }
      } else {
        this.log("collectData musicTags wasnt defined in previous step ....");
      }
      return musicTags;
    },

    // --------------------------------------------------------------------
    prepareArrayForTemplate(type) {
      this.log("prepareArrayForTemplate A ====>");
      let T = [];

      switch (type) {
        case "tags": {
          T = this.TagsToBeDisplayed;
          this.log(
            "prepareArrayForTemplate this.TagsToBeDisplayed:",
            this.TagsToBeDisplayed
          );

          if (this.tagsMore == false) {
            T = T.slice(0, this.nrTagRows);
          }
          this.log("prepareArrayForTemplate ++++ Tags ", T);
          break;
        }
        case "motives": {
          T = this.MotivesToBeDisplayed;

          if (this.motivesMore == false) {
            T = T.slice(0, this.nrMotiveRows);
          }
          this.log("prepareArrayForTemplate ++++ Motives ", T);
          break;
        }
      }
      this.log("prepareArrayForTemplate Z ====<");
      return T;
    },
    // ------------------------------------------------------------
    capitalize(str) {
      this.log("capitalize");
      let r = "";
      r = str.charAt(0).toUpperCase() + str.slice(1);
      return r;
    },
    // ------------------------------------------------------------
    getComponentData(callback) {
      this.log("getComponentData getMusicTags");
      this.getMusicTags()
        .then((response) => {
          this.log("getComponentData response.data:", response.data);
          //response.data.tags = this.doTagsFiltring(response.data.tags);
          response.data.tags = this.filterTagsByFeaturedTags(
            response.data.tags,
            response.data.FeaturedTags
          );
          this.log("getComponentData response.data.tags:", response.data.tags);

          this.tagOfTheWeek.id = response.data.tagOfTheWeek.id;
          this.tagOfTheWeek.name = response.data.tagOfTheWeek.name;
          this.log("getComponentData this.tagOfTheWeek:", this.tagOfTheWeek);

          this.TagsToBeDisplayed = this.formatData(
            response.data.tags,
            this.nrTagColumns
          );
          this.setFilteredTags(this.TagsToBeDisplayed);
        })
        .then(() => {
          this.log("getComponentData musicMotives ....");

          this.getMotives(this.selectedTags, (error, r) => {
            if (error) {
              this.log("getComponentData musicMotives error:", error);
              callback(error);
            } else {
              callback(null, { status: r });
            }
          });
        })
        .catch((error) => {
          this.$_log("getComponentData error:", error);
          callback(error);
        });
    },
    // ------------------------------------------------------------
    filterTagsByFeaturedTags(Tags, FeaturedTags) {
      let ftFilter = FeaturedTags.map((itemY) => {
        return itemY.id;
      });
      let res = Tags.filter((item) => ftFilter.includes(item.id));
      return res;
    },
    // ------------------------------------------------------------
    doTagsFiltring(Tags) {
      this.log("doTagsFiltring before display ...");
      let el = this.containerDom.querySelector("#tagFilter");
      if (el != null) {
        if (el.value != "") {
          this.log("OOK :) we can filter tags list ... by text:" + el.value);

          let filteredTags = Tags.filter(
            (elem) => elem.name.indexOf(el.value) != -1
          );
          //this.$_log("filteredTags:", filteredTags);
          return filteredTags;
        } else {
          return Tags;
        }
      } else {
        return Tags;
      }
    },
    // ------------------------------------------------------------
    doMotivesFiltering(Motives) {
      this.log("doMotivesFiltering");
      let el = this.containerDom.querySelector("#motiveFilter");
      if (el != null) {
        if (el.value != "") {
          //this.$_log("OOK :) we can filter motive list ... by text:" + el.value);

          let filteredMotives = [];
          for (const [k, t] of Object.entries(Motives)) {
            if (typeof k != "undefined") {
              if (t.name.toLowerCase().indexOf(el.value.toLowerCase()) != -1) {
                filteredMotives.push(t);
              }
            }
          }

          //this.$_log("filteredMotives:", filteredMotives);
          return filteredMotives;
        } else {
          return Motives;
        }
      } else {
        return Motives;
      }
    },

    // ------------------------------------------------------------
    formatData(srcTab, nrColumns) {
      this.log("formatData");
      var respData = [],
        tmpRow = [];
      var i = 1;
      //this.$_log(Object.entries(srcTab).length);

      for (const [k, t] of Object.entries(srcTab)) {
        if (typeof k != "undefined") tmpRow.push(t);
        if (i % nrColumns == 0) {
          respData.push(tmpRow);
          tmpRow = [];
        }
        i++;
      }
      if (tmpRow.length > 0) {
        respData.push(tmpRow);
      }
      return respData;
    },

    // ------------------------------------------------------------
    getMotives(Tags, callback) {
      var jsonD = { tags: Tags };
      // set stream motives in store via mutation call
      this.getMusicMotives(jsonD)
        .then((response) => {
          this.log("getMotives response:", response.data.tagsMotives);
          response.data.tagsMotives = this.doMotivesFiltering(
            response.data.tagsMotives
          );
          this.log(
            "getMotives after filtering response:",
            response.data.tagsMotives
          );

          this.MotivesToBeDisplayed = this.formatData(
            response.data.tagsMotives,
            this.nrMotiveColumns
          );
          //this.dataReady = true;

          this.log(
            "getMotives this.MotivesToBeDisplayed:",
            this.MotivesToBeDisplayed
          );
          this.log(
            "getMotives first eement from the motive list:" +
              this.MotivesToBeDisplayed[0][0].id
          );
          //callback(null, "ok");
        })

        .then(() => {
          // todo: setDefaultMotives when all elements are displayed !!!
          setTimeout(() => {
            if (this.selectedMotives[0] != this.MotivesToBeDisplayed[0][0].id)
              this.setDefaultMotive(this.MotivesToBeDisplayed[0][0].id);
          }, 50);

          callback(null, "ok");
        })

        .catch((error) => {
          this.log("getMotives error:", error);
          callback(error);
        });
    },

    // ------------------------------------------------------------
    setDefaultMotive(MotiveId) {
      //this.resetMotives();
      this.log("setDefaultMotive A");
      this.containerDom.querySelector("#btnMotive_" + MotiveId).click();
      //.querySelector("#btnMotive_" + this.defaultMotiveId)
      this.log("setDefaultMotive Z");
    },
    // --------------------------------------------------------------------
    checkTagRelations(event, tagId) {
      this.$_log(
        "checkTagRelations name -->" +
          event.target.name +
          " value-->" +
          event.target.value +
          " | tagId:" +
          tagId
      );

      //this.stopCurrentPlayer(); // it doesnt have sense in this place

      event.currentTarget.classList.toggle("non-clicked");
      event.currentTarget.classList.toggle("clicked");

      let changeCounter = 1;
      if (Array.isArray(this.selectedTags)) {
        const found = this.selectedTags.find((elem) => elem == tagId);
        if (!found) {
          // dodaj
          this.selectedTags.push(tagId);
        } else {
          // usuń
          this.selectedTags = this.selectedTags.filter((elem) => elem != tagId);
          changeCounter = -1;
        }
      } else {
        this.selectedTags.push(tagId);
        //this.selectedTags.push(tagId, changeCounter);
      }

      //set sliders ...
      //this.adjustSlidersFormScratch(); // it doesnt have sense in this place

      // show selected tags and mark those that cant be used
      {
        let x = 0,
          tmpObj = null;
        // find tags that has to be disabled/enabled
        for (const [k, t] of Object.entries(this.receivedTags.tagsRelations)) {
          if (k == tagId) {
            x = 1;
            tmpObj = t;
            break;
          }
        }
        if (x == 1) {
          // find elements that has to be disabled
          for (const [k1, rt] of Object.entries(tmpObj)) {
            //
            if (rt == -1) {
              // change disabled Tags counter
              let y = 0;
              let disableElem = 1;
              if (Array.isArray(this.disabledTags)) {
                for (let l = 0; l < this.disabledTags.length; l++) {
                  if (this.disabledTags[l].TagId == k1) {
                    if (changeCounter == 1) {
                      this.disabledTags[l].counter++;
                      y = 1;
                    } else {
                      this.disabledTags[l].counter--;
                      if (this.disabledTags[l].counter == 0) {
                        //remove element form disabledTags
                        this.disabledTags = this.disabledTags.filter(
                          (elem) => elem.TagId != k1
                        );
                        disableElem = 0;
                      }
                      y = 1;
                    }
                    break;
                  }
                }
              }

              if (y == 0) {
                this.disabledTags.push({ TagId: k1, counter: 1 });
              }

              // find DOM element
              let el = this.containerDom.querySelector("#btnTag_" + k1);
              this.log("find DOM element to be toggled.. el:", el);
              let E = this.containerDom.querySelectorAll("#btnTag_" + k1);
              this.log("find DOM element to be toggled.. E:", E);
              if (E.length > 0) {
                for (let i = 0; i < E.length; i++) {
                  if (disableElem == 1) {
                    E[i].classList.add("disabled");
                    E[i].disabled = true;
                  } else {
                    E[i].classList.remove("disabled");
                    E[i].disabled = false;
                  }
                }
              }
            }
          }
        }
        this.$_log("====================================");
        this.$_log("this.disabledTags:", this.disabledTags);
        this.$_log("====================================");
        this.setStreamTags(this.selectedTags);

        // get music motives based on selected tags
        this.getMotives(
          this.selectedTags,
          function (err, r) {
            if (err) {
              this.$_log("error:", err);
            } else {
              this.$_log("r:", r);
            }
          }.bind(this)
        );
      }
      //<-
    },

    // ------------------------------------------------------------
    alreadySelected(tagId) {
      /*
      this.log(
        "alreadySelected tagId:" + tagId + "this.selectedTags:",
        this.selectedTags
      );
	  */
      if (Array.isArray(this.selectedTags)) {
        const found = this.selectedTags.find((elem) => elem == tagId);
        if (typeof found != "undefined") {
          return found;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    // --------------------------------------------------------------------
    alreadySelectedMotive(motiveId) {
      if (Array.isArray(this.selectedMotives)) {
        const found = this.selectedMotives.find((elem) => elem == motiveId);
        if (typeof found != "undefined") {
          return found;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    // --------------------------------------------------------------------
    isMotiveSelected(id) {
      if (this.selectedMotives.length > 0) {
        if (this.selectedMotives[0] == id) {
          return "clicked";
        }
      }
      return "non-clicked";
    },

    // ------------------------------------------------------------
    isTagSelected(tagId) {
      if (this.selectedTags.length > 0) {
        const found = this.selectedTags.find((elem) => elem == tagId);
        if (typeof found != "undefined") {
          return "clicked";
        } else {
          return "non-clicked";
        }
      }
      return "non-clicked";
    },

    // ------------------------------------------------------------
    checkEnabledMotives(event, motiveId) {
      this.log(
        "checkEnabledMotives name -->" +
          event.target.name +
          " value-->" +
          event.target.value +
          " | motiveId:" +
          motiveId
      );
      if (this.isMotiveSelected(motiveId) == "clicked") {
        return;
      }

      //this.stopCurrentPlayer();

      let el = this.containerDom.querySelector(
        "#btnMotive_" + parseInt(motiveId, 10)
      );
      el.classList.toggle("non-clicked");
      el.classList.toggle("clicked");

      if (Array.isArray(this.selectedMotives)) {
        const found = this.selectedMotives.find((elem) => elem == motiveId);
        // in case of muzaic.studio only one motive is allowed !!! ==>
        for (let x = 0; x < this.selectedMotives.length; x++) {
          if (this.selectedMotives[x] != motiveId) {
            this.log(
              "--- lets unmark already selected #btnMotive_id:" +
                this.selectedMotives[x]
            );
            let el = this.containerDom.querySelector(
              "#btnMotive_" + this.selectedMotives[x]
            );
            if (el != null) {
              el.classList.toggle("clicked");
              el.classList.toggle("non-clicked");
            }
          }
        }
        // <==
        if (!found) {
          this.log(
            "ook new motive can be added but first lets remove previous"
          );
          this.selectedMotives = [];
          this.selectedMotives.push(motiveId);
        } else {
          this.log("oups already selected ... lets removeit from the list");
          this.selectedMotives = this.selectedMotives.filter(
            (elem) => elem != motiveId
          );
        }
      } else {
        this.selectedMotives.push(motiveId);
      }

      if (this.selectedMotives.length != 0) {
        this.log("ooooooook we can go ahead !");
        //this.showCreateButton = true;
      } else {
        //this.showCreateButton = false;
        this.log("we cant go ahead !");
      }

      this.setStreamMotives(this.selectedMotives);
      this.log("this.selectedMotives:", this.selectedMotives);
    },

    // ------------------------------------------------------------
    postGetComponentData(r) {
      this.log(
        "postGetComponentData callback[" + r.status + "] context:",
        this.containerDom
      );
      this.dataReady = true;

      // todo: setDefaultMotives when all elements are displayed
      this.$replaceMainBackground(this.strImage);
      setTimeout(() => {
        if (this.selectedMotives[0] != this.MotivesToBeDisplayed[0][0].id)
          this.setDefaultMotive(this.MotivesToBeDisplayed[0][0].id);
      }, 50);
    },

    //
    onSubmit() {
      const track = {
        //...this.mCurrentUser,
        name: this.trackName,
        duration: this.trackDuration,
        clientId: this.ClientId,
        userId: this.currentUserId,
      };

      this.log(" onSubmit - track:", track);
    },

    // --------------------------------------------------------------------
    toggleMore(type) {
      this.log("toggleMore type:" + type);
      if (this.clientSubscriptionType == "free") {
        /* in free version we shold only display dialog box .... */
        useMoreMoodsDialog(
          function (resp) {
            this.log("action confirmed! resp:", resp);
            if (resp.upgrade == true) {
              this.$router.push({
                name: "sl_mob_msSubscriptionsView",
                params: { id: 0 },
              });
            }
          }.bind(this), // context has been changed so i have to "bind(this)"" to the function ....
          function () {
            this.log("action canceled ...");
          }.bind(this), // context has been changed so i have to "bind(this)"" to the function ....
          {
            // parametrs to be displayed at dialog box
            title: "Premium moods",
            message: "",
            blueOnWhiteButtonMsg: "Upgrade to premium",
            whiteOnBlueButtonMsg: "I want to use only free ones",
          }
        );
        /* ===================================================== */
      } else {
        switch (type) {
          case "tags": {
            if (this.tagsMore == false) {
              this.tagsMore = true;
            } else {
              this.tagsMore = false;
            }
            break;
          }
          case "motives": {
            if (this.motivesMore == false) {
              this.motivesMore = true;
            } else {
              this.motivesMore = false;
            }
            break;
          }
        }
      }
    },

    //
  },
};
</script>

<style scope>
.mobileL form div.idf_submit_line {
  gap: 24px 10px;
  height: auto;
  padding-bottom: 24px;
  padding-top: 10px;
}

.mood_cnt {
  display: flex;
  width: 100%;
}

.mood_cnt .dlg_form_container {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.9) 0%,
    #ffffff 100%
  );
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  width: 100%;
}

.mood_cnt .dlg_uploadVideo_0 {
  height: 480px;
  border: 0px solid rgb(196, 205, 213);
}

.mood_cnt .dlg_uploadVideo_1_Circle {
  border: 1px dashed var(--main-blue-color);
  width: 126px;
  height: 126px;
}

.mood_cnt .button_uv {
  border: 1px dashed var(--main-blue-color);
}

.mood_cnt .dlg_form_header {
  text-align: center;
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  padding: 24px 0px 20px 0px;
}
.mood_cnt .dlg_form_header .cmh {
  padding: 24px 0px 20px 0px;
}

.mood_cnt .dlg_text {
  text-align: center;
}

.mood_cnt .ms_inputbox_0 {
  position: relative;
  padding-bottom: 21px;
}
.mood_cnt .ms_inputbox_0a {
  position: relative;
  padding-bottom: 5px;
}

.mood_cnt .ms_inputbox_0.uv_inp_msg {
  position: relative;
  padding: 0px 0px 0px 0px;
}

.mood_cnt .dlg_form_container fieldset {
  inset: -5px 0px 0px;
}

.mood_cnt .dlg_form_container input {
  background-color: white;
}

#idf_time_disabled {
  opacity: 25%;
}

#idf_time_disabled input:hover + fieldset {
  border-color: rgba(0, 0, 0, 0.23);
}
.mood_cnt .uvMsg {
  font-size: 12px;
  line-height: 18px;
  color: var(--main-gray500-color);
  text-align: left;
  padding: 4px 0px 20px 20px;
}

.mood_cnt .uvMsg_1 {
  font-size: 12px;
  line-height: 18px;
  color: var(--main-gray500-color);
  text-align: left;
  padding: 14px 0px 0px 20px;
}

.mood_cnt .dlg_msg {
  margin-bottom: 11px;
}

.mood_cnt .dlg_msg a {
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  line-height: 18px;
  color: var(--main-sec-color);
  padding-right: 30px;
}
.mood_cnt .dlg_msg a:visited {
  color: var(--main-sec-color);
}

.mood_cnt .dlg_lgn_row {
  padding-top: 4px;
  padding-bottom: 8px;

  color: var(--main-gray500-color);
}

.mood_cnt .dlg_lgn_row p {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  text-decoration: none;

  margin: 9px 0px 16px 0px;
}

/* ############################################################# */
.trackInfo {
  align-items: inherit;
  justify-content: inherit;
  font-size: inherit;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--main-gray900-color);
  opacity: 0.8;
  z-index: 1000;
}

.loadingInner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 89px;
  border-top: 1px solid var(--main-bg-color);

  height: 100%;
  width: 100%;
}

/* ======================================================== */
.streamParamTabContainer {
  display: inline-table;
}
.streamTagTabContainer {
}
.streamTagElBlk {
  display: flex;
  row-gap: 12px;
  column-gap: 6px;
  flex-wrap: wrap;
  width: 100%;
}

.streamMotiveTabContainer {
  display: inline-table;
}
.streamMotiveElBlk {
  display: flex;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
  width: 100%;
}

.streamButton {
  /*background: #68d061 0% 0% no-repeat padding-box;*/
  width: 90px;
  height: 40px;
  /*border: 1px solid var(--main-blue-color);*/
  border-radius: 9px;
  color: white;

  text-align: center;
  margin: 0px;
  font-size: 12px; /* 12px */
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;

  cursor: pointer;
}
.streamButton.motives {
  margin: 0px 0px 0px 0px;
}

#btnPlt_:hover {
  border: 1px solid var(--main-warningdark-color);
  background-color: var(--main-warningdark-color);
}

#btnPlt_ {
  display: flex;
  width: 175px;
  height: 60px;

  padding: 18px 24px 18px 24px;
  border-radius: 32px;
  gap: 10px;
  background-color: var(--main-warning-color);
  border: 1px solid var(--main-warning-color);
  color: var(--main-bg-color) !important;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;

  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 24px 0px #ff8e1e33;
}

.clicked {
  /*background-color: var(--main-blue-color);*/
  border: 1px solid var(--main-blue-color);
}

.non-clicked {
  /*background: #68d061 0% 0% no-repeat padding-box;*/
  border: 1px solid var(--main-gray500-color);
  color: var(--main-gray600-color);
}

.disabled {
  color: var(--main-gray500-color);
  border: 1px solid var(--main-gray500-color);
  cursor: not-allowed;
  opacity: 0.4;
}

/* ===================================== */
.root_cmp {
  height: 100%;
  border: 0px solid red;
  display: grid;
  grid-template-rows: 1fr 88px;
  flex-grow: 1;
}

#cmpr {
  display: flex;
  justify-content: center;
  padding: 0px 24px 0px 24px;
}

#cmpr form {
  width: 100%;
  max-width: 1800px;
  padding: 0px 0px 0px 0px;
}

.cmprHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cmprHr {
  margin: 16px 0 24px 0px;
  flex-shrink: 0;
  border-style: solid;
  border-width: 1px;
  border-color: var(--main-gray300-color);
}

.truncate130px {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
}

.cmprHeaderTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding-bottom: 4px;
}
.cmprHeaderInfoContainer {
  /*width: 70%;*/
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--main-gray500-color);
}

.cmprHeaderInfoContainer .cmprTextValue {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 20px;
}

.cmprHeaderActionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 22px;
  font-weight: 400;

  color: var(--main-gray600-color);
}

.cmprHeaderActionsContainer svg {
}
.cmprHeaderActionsContainer p {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  flex-shrink: 0;
}

.cmprDetailsLeft {
  background-color: var(--main-gray200-color);
  padding: 0px 24px 0px 24px;
  /*flex: 1;*/
  border-radius: 8px;

  min-height: 490px;
  max-width: 500px;
  min-width: 250px;
  /*height: 490px;
  overflow-x: auto;*/
}
.cmprTags,
.cmprMotives {
  /*background-color: var(--main-gray200-color);*/
  padding-bottom: 24px;
  overflow-y: auto;
  max-height: 420px;
}

.cmprTagsHeader,
.cmprMotivesHeader,
.cmprAdjustHeader {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 20px 0px 10px 0px;
}
.cmprTagsHeader h6,
.cmprMotivesHeader h6,
.cmprAdjustHeader h6 {
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  padding-right: 50px;
}

.cmprAdjustTypeTab {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 20px;
}

.cmprAdjustTypeTab button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;

  font-weight: 500;
  font-size: 0.875rem;
  max-width: 360px;
  min-width: 90px;
  position: relative;
  min-height: 48px;
  flex-shrink: 0;
  padding: 12px 16px;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  flex-direction: column;
  color: var(--main-gray900-color);
  line-height: 24px;
  text-transform: none;
}

.cmprLink {
  color: var(--main-gray900-color);
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.cmprReset {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;

  min-width: 30px;
  padding: 8px 24px 8px 24px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: none;
  /*color: rgb(99, 115, 129);*/
}

.cmprReset:hover {
  text-decoration: none;
  background-color: var(--main-gray300-color); /*rgba(0, 56, 255, 0.04);*/
}

.cmpre_disabled {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: default;

  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;

  min-width: 30px;
  padding: 8px 24px;
  border-radius: 8px;

  color: var(--main-gray500-color);
  background-color: var(--main-gray300-color);

  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: none;
}
.cmprExport {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;

  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;

  min-width: 30px;
  padding: 8px 24px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(249, 250, 251);
  background-color: var(--main-blue-color);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: none;
}

.cmprExport:hover {
  text-decoration: none;
  background-color: var(--main-bluedark-color);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}
.cmprSearchBlock {
  display: inline-flex;
  /* flex-direction: column; */
  /* position: relative; */
  min-width: 0px;
  padding: 0px;
  margin: 0px;
  border: 0px;
  vertical-align: top;
  max-width: 209px;
  height: 23px;
}
.cmprSearchText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0px;

  color: rgb(69, 79, 91);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  border-bottom: 2px solid var(--main-gray400-color);
  transition: border-bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.cmprSearchText:hover {
  border-bottom: 2px solid var(--main-gray900-color);
}

.cmprSearchText:focus-within {
  border-bottom: 2px solid var(--main-blue-color);
}

.cmprTextValue {
  color: var(--main-gray700-color);
}
.cmprTxtInput {
  padding: 4px 0px 5px;
  border: 0px;
  margin: 0px;
  outline: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;

  -webkit-tap-highlight-color: transparent;
  display: block;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--main-gray500-color);
}

.cmprSearchBtn {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.cmprSearchBtn:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.sliderRow {
  height: 40px;
}
.sliderCell {
  width: 200px;
}
.sliderTitle {
  text-align: center;
  margin: 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
}

.sliderDescription {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;

  width: 60px;
}
.sd_l {
  text-align: right;
  padding-right: 24px;
}
.sd_r {
  text-align: left;
  padding-left: 24px;
}
.tags {
  height: 36px;
  width: auto;
  padding-left: 8px;
  padding-right: 16px;

  background-color: var(--main-gray200-color);

  border-radius: 24px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.tags:hover {
  border: 1px solid var(--main-blue-color);
}

.clicked.tags {
  color: var(--main-blue-color);
}

.svgContainerClicked {
  width: 18px;
  height: 18px;
  margin-right: 6px;
  background-color: var(--main-blue-color);
  border: 1px solid var(--main-blue-color);
  border-radius: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.svgContainer {
  width: 18px;
  height: 18px;
  margin-right: 6px;
  /*background-color: rgb(0, 56, 255);*/
  border: 1px solid var(--main-gray500-color);
  border-radius: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.motives {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 320px;
  width: 100%;
  min-width: 240px;
  height: 52px;
  background-color: var(--composer-section-bg-color);
}
.motives:hover {
  border: 1px solid var(--main-blue-color);
}
.clicked.motives {
  color: var(--main-gray900-color);
}

.motiveDescCnt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.motiveDescName {
}
.motiveDescStyle {
  color: rgb(145, 158, 171);
}

/* show more; tags, motives */
.showMore {
  margin-top: 16px;
  margin-bottom: 22px;
}

#moodOfTheWeek {
  background: linear-gradient(90deg, #60eed0 0%, #01a0ff 100.16%);
  background-blend-mode: multiply;
  border: 1px solid;

  border-image-source: linear-gradient(90deg, #60eed0 0%, #01a0ff 100.16%);
  margin-top: 24px;
  padding: 1px;
  border-radius: 24px;
}

.moodOfTheWeek_1 {
  background-color: var(--main-bg-color);

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  width: auto;
  height: 90px;
}

.motw {
  font-family: "Poppins", Sans-serif;
  font-size: 14px;

  background: linear-gradient(270deg, #01a0ff 23.18%, #60eed0 76.22%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
}

.cmprTags,
.cmprMotives {
  padding-bottom: 0px;
}
</style>
