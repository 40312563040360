import { createConfirmDialog } from "vuejs-confirm-dialog";
import MoreMoods from "@/components/dialogs/modal/MoreMoods.vue";

const useMoreMoodsDialog = (action, cancelAction, props) => {
  const { reveal, onConfirm, onCancel } = createConfirmDialog(MoreMoods, props);

  reveal();

  onConfirm(action);
  onCancel(cancelAction);
};

export default useMoreMoodsDialog;
