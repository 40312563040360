<template>
  <div ref="root_createMusicHeader" class="uv_cnt">
    <div v-if="dataReady" id="uvblk_1" class="dlg_form_container">
      <!-- <img id="testImg" style="border: 1px solid red" /> -->
      <!-- upload video section > -->
      <div class="dlg_uploadVideo_0">
        <DropZone
          :videoDomId="videoDomId"
          cmpClass="mobile_drop_zone"
          @files-dropped="addFiles"
          :acceptTypes="acceptTypes"
        >
        </DropZone>

        <span class="dlg_uploadVideo_3_text"
          >Allowed *MP4, *WebM, *Ogg, *MOV</span
        >
        <div
          v-if="this.selectedFile"
          style="
            display: none;
            align-items: center;
            justify-content: center;
            border: 1px dotted gray;
            /* margin: 1px; */
            padding: 10px;
            background-color: white;
            width: 150px;
            height: 200px;
            background-color: blue;
          "
        >
          <canvas
            id="canvas"
            style="
              overflow: auto;
              display: flex;
              /*position: absolute;*/
              align-items: center;
              justify-content: center;
              z-index: 100;
            "
          />
        </div>
      </div>
      <!-- upload video section < -->

      <div class="dlg_c_l1">
        <p class="dlg_form_header cmh">Upload a video</p>

        <p class="dlg_text">
          <signInGoPro msg="Want more options?" colorMsg="Sign up now!" />
        </p>
      </div>

      <!-- form data > -->
      <div class="dlg_c_l2">
        <form @submit.prevent="onSubmit">
          <div class="idf_submit_line">
            <button
              type="submit"
              class="dlg_uploadVideo_4_submitIco idf_submit_btn idf_submit_btn_wide"
              :disabled="doNotLetHimGo"
              @click="displayGetSettings"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
      <!-- form data < -->
    </div>

    <!-- ########################################################### -->

    <div id="uvblk_2" style="display: none" class="dlg_form_container">
      <!-- form data video settings > -->
      <div class="dlg_c_l1">
        <p class="dlg_form_header">Create a soundtrack</p>
      </div>
      <div class="dlg_c_l2">
        <form @submit.prevent="onSubmit">
          <div class="ms_inputbox_0 uv_inp_msg">
            <div id="idf_time_disabled">
              <label for="creationDuration">Duration</label>
              <div class="ms_inputbox_1 idf_time">
                <input
                  id="creationDuration"
                  v-model="mCurrentCreation.duration"
                  type="text"
                  placeholder="mm:ss"
                  @input="checkDuration"
                  @change="checkDuration"
                  disabled
                />
                <fieldset aria-hidden="true">
                  <legend><span>Duration</span></legend>
                </fieldset>
              </div>
            </div>
          </div>
          <div class="uvMsg">
            Soundtrack duration limit is
            <span class="gradient01">1 minute</span>
          </div>
          <div class="ms_inputbox_0 uv_inp_msg">
            <label for="creationName">Track name</label>
            <div class="ms_inputbox_1">
              <input
                id="creationName"
                v-model="mCurrentCreation.name"
                type="text"
                placeholder="Your new creation name"
              />
              <fieldset aria-hidden="true">
                <legend><span>Track Name</span></legend>
              </fieldset>
            </div>
          </div>

          <div class="uvMsg_1">
            <signInGoPro msg="Want more options?" colorMsg="Sign up now!" />
          </div>

          <div class="idf_submit_line">
            <img
              src="@/assets/sl_back.png"
              @click="goToPrevStep"
              style="width: 55px; cursor: pointer"
            />

            <button
              type="submit"
              class="dlg_uploadVideo_4_submitIco idf_submit_btn idf_submit_btn_wide"
              :disabled="doNotLetHimGo"
              @click="goTo_moods_page"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
      <!-- from data video settings < -->
    </div>
  </div>

  <!-- ++++++ -->

  <!-- ===< -->
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";

import streamSrv from "@/services/streamService.js";
//import cltSrv from "@/services/clientService.js";
import DropZone from "@/components/elements/DropZone.vue";
import signInGoPro from "@/components/elements/signInGoPro.vue";
import FormData from "form-data";
import Validator from "validatorjs";

export default {
  name: "sl_createMusicHeader",

  setup() {
    const root_createMusicHeader = ref(null);
    return {
      root_createMusicHeader,
    };
  },

  components: {
    //FileUpload,
    DropZone,
    signInGoPro,
  },
  data() {
    return {
      mCurrentCreation: {
        name: "",
        duration: "", // in time format hh:mm:ss
        length: 0, // in seconds
        videoImage: "",
        videoImage4db: null,
        video: null,
        uVFN: null, //uploaded video file name
        uVFT: null, //uploaded video file type
        videoW: null,
        videoH: null,
        landscapeeView: 0,
      },
      selectedFile: "",

      maxVideoDuration: 60, // in seconds
      acceptTypes:
        "video/mp4,video/WebM,video/Ogg,video/quicktime,video/3gpp,video/3gpp2,video/3gp2",
      videoObj: null,
      videoDomId: "cmh_vPlh",
      scaleVideoImgTo: 390, // width in pixels
      containerDom: null,
      dataReady: true, //false,
      availableUnits: 3600,
      showImage: 1,
      fileDropActionCounter: 0,
    };
  },
  created() {
    this.log(" created");
    this.getClientProduct(this.currentClientId)
      .then((r) => {
        this.log(
          "sl_createMusicHeader created Just after getClientProduct...."
        );
        this.log("r:", r);
        this.availableUnits = r.UnitsAvailable;
        this.dataReady = true;
      })
      .then(() => {
        //this.videoObj = this.containerDom.querySelector("#" + this.videoDomId);
        this.log(
          "created and clientProducts taken ... OOOOOOk its time to go :) :"
        );
      })
      .catch((error) => {
        this.log(error);
      });
  },
  mounted() {
    let x = this.$getImageUrl("mobileVideoBg00.png");
    this.$replaceMainBackground(x, 0);
    this.containerDom = this.root_createMusicHeader;

    this.videoObj = document.querySelector("#" + this.videoDomId);
    this.log(
      "sl_createMusicHeader mounted  OOOOOOk its time to go :) :",
      this.videoObj.tagName
    );
    // ------------
    try {
      if (this.strName !== null && this.strImage !== null) {
        this.log("================================================>");
        this.$replaceMainBackground(
          this.strImage,
          this.mCurrentCreation.landscapeeView
        );

        this.initVideoObj(this.videoObj, this.strVideo);
        this.mCurrentCreation.name = this.strName;
        this.mCurrentCreation.duration = this.strDuration;
        this.mCurrentCreation.length = this.$time2sec(
          this.strDuration,
          "mm:ss"
        );
        this.mCurrentCreation.videoImage = this.strImage;
        this.log("================================================<");
      }
    } catch (e) {
      this.log("mounted initUploadSettings error:", e);
    }
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
      "currentSubscription",
    ]),
    ...mapGetters("streamModule", {
      strName: "streamName",
      strImage: "streamImage",
      strDuration: "streamDuration",
      strTags: "streamTags",
      strMotives: "streamMotives",
      strParams: "streamParams",
      strVideo: "streamVideo",
      strGenVideo: "streamGenVideo",
      strAudio: "streamAudio",
      receivedTags: "receivedTags",
      streamUVFN: "streamUVFN",
      streamUVFT: "streamUVFT",
    }),
    doNotLetHimGo() {
      if (
        (this.mCurrentCreation.name != "" &&
          this.mCurrentCreation.duration != "" &&
          this.mCurrentCreation.length < this.availableUnits) ||
        (this.strName != "" &&
          this.strDuration != "" &&
          this.$time2sec(this.strDuration, "mm:ss") < this.availableUnits)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("streamModule", {
      initStream: "initStream",
      clearStreamData: "clearStreamData",
      setUVFN: "setUVFN",
      setUVFT: "setUVFN",
      setName: "setName",
    }),
    ...mapActions("clientModule", { getClientProduct: "getClientProduct" }),

    //
    log(str, obj) {
      if (typeof obj == "undefined") {
        this.$_log(this.$options.name + "] " + str);
      } else {
        this.$_log(this.$options.name + "] " + str, obj);
      }
    },
    //
    validateFormValues() {
      this.log("validateFormValues......");
      let data = {
        name: this.mCurrentCreation.name,
        duration: this.mCurrentCreation.duration,
        length: this.mCurrentCreation.length,
        userId: this.currentUserId,
      };
      this.log("data to validate:", data);

      let rules = {
        name: "required|string",
        //duration: ["required", "regex:/^\\d{2}:\\d{2}:*\\d{2}$/"], // hh:mm:ss
        duration: ["required", "regex:/^\\d{2}:*\\d{2}$/"], // only mm:ss
        length:
          "integer|min:" +
          this.currentSubscription.minTrackDuration +
          "|max:" +
          this.currentSubscription.maxTrackDuration +
          "",
        userId: "numeric",
      };

      let validation = new Validator(data, rules);

      this.log("validation:", validation);
      if (validation.passes()) {
        return "";
      } else {
        let str = "";
        for (const [value] of Object.entries(validation.errors.all())) {
          str = str + value + " ";
        }
        if (str.indexOf("duration") != -1 || str.indexOf("length") != -1) {
          str =
            "The expected duration format is mm:ss, where duration cannot be greater than " +
            this.$HHMMSStoTxt(
              this.$toHHMMSS(this.currentSubscription.maxTrackDuration)
            ) +
            " or less than " +
            this.$HHMMSStoTxt(
              this.$toHHMMSS(this.currentSubscription.minTrackDuration)
            ) +
            "";
        }
        return str;
      }
      /////////////////////////////////////////////////////////////////////////////////

      /////////////////////////////////////////////////////////////////////////////////
    },
    //

    checkDuration() {
      this.log(" checkDuration ... A");
      this.log(
        "checkDuration this.mCurrentCreation.duration:" +
          this.mCurrentCreation.duration +
          "nr groups:" +
          this.mCurrentCreation.duration.split(":").length
      );

      this.mCurrentCreation.length = this.$time2sec(
        this.mCurrentCreation.duration,
        "mm:ss"
      );

      this.log("checkDuration this.availableUnits:" + this.availableUnits);
      this.log(
        "checkDuration ok your creation will use:" +
          this.mCurrentCreation.length +
          "seconds and you still have " +
          this.availableUnits +
          "seconds to be used for this month"
      );
      if (this.mCurrentCreation.length >= this.availableUnits) {
        this.log(
          "checkDuration You dont have enough available time usnits this month"
        );
        let n = {
          type: "problem",
          message:
            "You dont have enough time usnits for this month. Please reduce creation duration.",
        };
        this.addNotificationMessage(n);
      } else {
        this.log("checkDuration ok you can go ahead ... ");
      }
      this.log(" checkDuration ... Z");
    },

    //
    goToPrevStep() {
      this.log("goToPrevStep ... A");
      this.hideGetSettings();
      this.log("goToPrevStep ... Z");
    },
    //
    goToProRegistration() {
      this.$router.push({
        name: "sl_mob_msSubscriptionsView",
        params: { id: 1 },
      });
    },
    //
    goTo_moods_page() {
      let r = this.validateFormValues();
      if (r == "") {
        if (this.streamUVFN != null) {
          // video is already uploaded and initialized !!! simlpy go ahead
          this.setName(this.mCurrentCreation.name).then(() => {
            this.$router.push({
              name: "sl_mob_moods",
            });
          });
        } else {
          this.initStream({
            duration: this.mCurrentCreation.duration,
            name: this.mCurrentCreation.name,
            image: this.mCurrentCreation.videoImage,
            image4db: this.mCurrentCreation.videoImage4db,
            video: this.mCurrentCreation.video,
            uploadedVideoName: this.uVFN,
            uploadedVideoType: this.uVFT,
            videoW: this.mCurrentCreation.videoW,
            videoH: this.mCurrentCreation.videoH,
          }).then(() => {
            this.$router.push({
              name: "sl_mob_moods",
            });
          });
        }
      } else {
        this.log("response from validation function:", r);
        let n = {
          type: "problem",
          message: r,
        };
        this.addNotificationMessage(n);
      }
    },
    // todo: detect mobile screen orienation !!
    // =>
    captureImage(jumpToSec, replaceImage, callback) {
      this.log(
        " captureImage jumpToSec:" + jumpToSec + " replaceImage:" + replaceImage
      );
      if (typeof jumpToSec == "undefined") {
        jumpToSec = 10;
      }
      let imageSize = this.scaleVideoImgTo;
      this.log(" captureImage scale video image to:" + imageSize + "px width");
      let canvas = this.containerDom.querySelector("#canvas");
      let video = this.containerDom.querySelector("#" + this.videoDomId);

      video.currentTime = video.currentTime + jumpToSec;

      this.videoObj.onplay = async (event) => {
        this.log(
          "captureImage this.videoObj.onplay =================================="
        );
        this.log("captureImage this.videoObj.onplay event:", event);

        if (event.isTrusted) {
          this.log(
            "captureImage this.videoObj.onplay: " +
              video.videoWidth +
              "px width --- " +
              video.videoHeight +
              "px height"
          );
          if (this.showImage != 0) {
            let imageSizeH = parseInt(
              (imageSize * video.videoHeight) / video.videoWidth
            );
            canvas.width = imageSize; //video.videoWidth;
            canvas.height = imageSizeH; //video.videoHeight;

            this.log(
              "captureImage this.videoObj.onplay 1 ------------------------"
            );

            await canvas
              .getContext("2d")
              .drawImage(video, 0, 0, imageSize, imageSizeH);

            this.log(
              "captureImage this.videoObj.onplay 2 image captured ---------"
            );

            await this.getCanvasBlob(canvas).then((blob) => {
              this.log(
                "captureImage this.videoObj.onplay  current image size:" +
                  this.bytesToSize(blob.size) +
                  " - before compression"
              );
            });

            this.log(
              "captureImage this.videoObj.onplay 3 ------------------------"
            );

            let compresionLevel = 0.6;
            await this.getCompressedImage(
              canvas,
              "image/jpeg",
              compresionLevel
            ).then((obj) => {
              //imgDataUrl, imageDataBlob, imageSize
              this.log(
                "captureImage after getCompressedImage current image size:" +
                  obj.size
              );
              this.log(
                "captureImage after getCompressedImage imageData:",
                URL.createObjectURL(obj.blob)
              );

              // TMP ONLY !!! TO BE REMOVED >>>
              /*
              document.querySelector("#testImg").src = URL.createObjectURL(
                obj.blob
              );
			        */
              // TMP ONLY !!! TO BE REMOVED <<<

              this.mCurrentCreation.videoImage4db = obj.dataUrl;
              this.log(
                "captureImage after getCompressedImage ... Z:",
                this.mCurrentCreation.videoImage4db
              );
            });

            this.log(
              "captureImage this.videoObj.onplay 4 ------------------------"
            );
            //--<
          }

          this.mCurrentCreation.videoImage = canvas
            .toDataURL("image/jpeg")
            .replace(/^data:image\/jpeg;base64,/, "");

          callback(null, canvas);
        } else {
          callback("untrusted event");
        }
      };
    },

    // --------------------------------------------------------
    // get compressed image blob from given canvas --
    getCompressedImage(canvas, type, quality) {
      this.log("getCompressedImage  ... A");
      let resizingFactor = 1.0;
      return new Promise((resolve, reject) => {
        let image = new Image();
        if (image) {
          image.src = canvas.toDataURL("image/jpeg");

          image.onload = async () => {
            this.log("getCompressedImage image.onload .............");
            //resolve(image);
            await this.compressImage(image, resizingFactor, quality, type)
              .then((imageDataBlob) => {
                this.convertBlobToDataUrl(imageDataBlob).then((obj) => {
                  this.log("imageDataBlob:", obj.blob);

                  let size = this.bytesToSize(obj.blob.size);
                  resolve({ dataUrl: obj.dataUrl, blob: obj.blob, size: size });
                });
              })
              .catch((err) => {
                reject(err);
              });
          };
        } else {
          reject("image cant be created...");
        }
      });
    },

    // --------------------------------------------------------
    //convertBlobToDataUrl --
    convertBlobToDataUrl(blob) {
      return new Promise((resolve, reject) => {
        try {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => {
            let dataUrl = reader.result;
            //console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX:", blob); //DataURL
            //console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ:", dataUrl); //DataURL
            resolve({ dataUrl: dataUrl, blob: blob });
          };
        } catch (e) {
          reject(e);
        }
      });
    },

    // --------------------------------------------------------
    //compress image
    compressImage(imgToCompress, resizingFactor, quality) {
      return new Promise((resolve, reject) => {
        if (imgToCompress !== null) {
          this.log("compressImage ... A");
          // showing the compressed image
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          const originalWidth = imgToCompress.width;
          const originalHeight = imgToCompress.height;
          this.log(
            "compressImage ... originalWidth:" +
              originalWidth +
              " originalHeight:" +
              originalHeight
          );

          const canvasWidth = originalWidth * resizingFactor;
          const canvasHeight = originalHeight * resizingFactor;

          canvas.width = canvasWidth;
          canvas.height = canvasHeight;

          context.drawImage(
            imgToCompress,
            0,
            0,
            originalWidth * resizingFactor,
            originalHeight * resizingFactor
          );

          // reducing the quality of the image
          this.getCanvasBlob(canvas, "image/jpeg", quality).then((blob) => {
            resolve(blob);
          });
        } else {
          reject("image to be compressed not defined");
        }
      });
    },

    //
    getCanvasBlob(canvas, type, quality) {
      return new Promise((resolve, reject) => {
        if (typeof type == "undefined" && typeof quality == "undefined") {
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject("getCanvasBlob: blob is undefined ....");
            }
          });
        } else {
          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob);
              } else {
                this.log("compressImage image blob is not defined...");
                reject("compressImage image blob is not defined...");
              }
            },
            "image/jpeg",
            quality
          );
        }
      });
    },

    //
    bytesToSize(bytes) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

      if (bytes === 0) {
        return "0 Byte";
      }

      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    //
    initVideoObj(videoObj, videoContent) {
      this.log("initVideoObj on screen ... A");
      this.mCurrentCreation.video = videoContent; //save in vuex

      videoObj.style =
        "position: absolute; border: 0px solid red;" +
        "height: 126px; width: 126px; " +
        "border-radius: 100%; object-fit: cover;" +
        "z-index:10;";
      videoObj.src = URL.createObjectURL(videoContent);
      videoObj.preload = "metadata";
      videoObj.autoplay = true;
      videoObj.volume = 0;
      this.log("initVideoObj ... Z");
    },

    //
    addFiles(f) {
      this.log("addFiles name:" + f[0].name + " type:" + f[0].type);
      this.uVFN = f[0].name;
      this.uVFT = f[0].type;
      this.setUVFN(this.uVFN);
      this.setUVFT(this.uVFT);
      //////
      this.clearStreamData();
      //////
      let n;
      let T = this.acceptTypes.split(",");

      if (T.includes(f[0].type)) {
        this.log("addFiles file type accepeted :)");

        this.initVideoObj(this.videoObj, f[0]);

        this.videoObj.onloadedmetadata = () => {
          this.log(
            "addFiles onloadedmetadata this.videoObj.duration: " +
              this.videoObj.duration
          );

          this.log(
            "addFiles onloadedmetadata this.videoObj.width:" +
              this.videoObj.videoWidth
          );
          this.log(
            "addFiles onloadedmetadata this.videoObj.height:" +
              this.videoObj.videoHeight
          );
          this.mCurrentCreation.videoW = this.videoObj.videoWidth;
          this.mCurrentCreation.videoH = this.videoObj.videoHeight;

          if (this.videoObj.duration < 10) {
            this.log(
              "addFiles onloadedmetadata Invalid Video! video is less than 10 second"
            );
            n = {
              type: "problem",
              message: "Invalid Video! video is less than 10 second",
            };
            this.addNotificationMessage(n);
          } else if (this.videoObj.duration > this.maxVideoDuration) {
            this.log(
              "addFiles onloadedmetadata Invalid Video! video is more than allowed limit"
            );
            n = {
              type: "problem",
              message: "Invalid Video! video is more than allowed limit",
            };
            this.addNotificationMessage(n);
          } else {
            let replaceImage = 0;
            const date = new Date(null);

            date.setSeconds(parseInt(this.videoObj.duration, 10)); // specify value for SECONDS here
            this.mCurrentCreation.duration = date.toISOString().slice(14, 19);
            this.log(
              "addFiles onloadedmetadata video duration:" +
                this.mCurrentCreation.duration +
                " f[0].name:" +
                f[0].name
            );
            this.mCurrentCreation.length = parseInt(this.videoObj.duration, 10);

            if (this.mCurrentCreation.name != f[0].name) {
              replaceImage = 1;
            }
            this.mCurrentCreation.name = f[0].name;

            this.captureImage(5, replaceImage, (err, imageFromVideo) => {
              if (err) {
                this.log(
                  "addFiles onloadedmetadata problem with image capturing err:" +
                    err
                );
              } else {
                if (
                  this.mCurrentCreation.videoW > this.mCurrentCreation.videoH
                ) {
                  this.mCurrentCreation.landscapeeView = 1;
                } else {
                  this.mCurrentCreation.landscapeeView = 0;
                }
                this.$replaceMainBackground(
                  imageFromVideo,
                  this.mCurrentCreation.landscapeeView
                );
                this.checkDuration();
              }
            });
          }
        };

        const selectedFile = f[0]; // accessing file
        this.selectedFile = selectedFile;
        this.log("addFiles ZZZ");
      } else {
        this.log("addFiles incorrect file type !!!!");
        n = {
          type: "problem",
          message:
            "Incorrect file type. Allowed video formats: *MP4, *WebM, *Ogg, *Mov ",
        };
        this.addNotificationMessage(n);
      }
    },

    //
    updateUploadVideoView(viewStep) {
      switch (viewStep) {
        case 1: {
          // upload video
          this.toggleSetParamsElements(0);
          this.toggleUploadVideoElements(1);
          break;
        }
        case 2: {
          // get name
          this.toggleSetParamsElements(1);
          this.toggleUploadVideoElements(0);
          break;
        }
        default: {
          // upload video
          this.toggleSetParamsElements(0);
          this.toggleUploadVideoElements(1);
          break;
        }
      }
    },

    //
    toggleSetParamsElements(showElements) {
      this.log("toggleSetParamsElements this.containerDom:", this.containerDom);
      let el = this.containerDom.querySelector("#uvblk_2");
      this.log("toggleSetParamsElements el:", el);
      if (showElements == 1) {
        this.log("toggleSetParamsElements showElements");
        el.style.display = "";
      } else if (showElements == 0) {
        this.log("toggleSetParamsElements hideElements");
        el.style.display = "none";
      } else {
        this.log(
          "toggleSetParamsElements incorrect showElements:" + showElements
        );
      }
    },

    //
    toggleUploadVideoElements(showElements) {
      if (showElements == 1) {
        this.log("toggleUploadVideoElements showElements");
        this.containerDom.querySelector("#uvblk_1").style.display = "";
      } else if (showElements == 0) {
        this.log("toggleUploadVideoElements hideElements");
        this.containerDom.querySelector("#uvblk_1").style.display = "none";
      } else {
        this.log(
          "toggleUploadVideoElements incorrect showElements:" + showElements
        );
      }
    },

    //////////////////////////////////////////////
    // not in use >>
    onUploadFile() {
      const formData = new FormData();
      formData.append("title", "abc");
      formData.append("file", this.selectedFile); // appending file

      streamSrv
        .uploadFile(formData)
        .then((res) => {
          this.log("ASW response:", res);
        })
        .catch((err) => {
          this.log("ASW err:", err);
        });
    },
    //
    onUploadVideo(f) {
      this.log(" onUploadVideo - file:", f);
    },
    // not in use <<
    //////////////////////////////////////////////

    //
    displayGetSettings() {
      this.log("displayGetSettings");
      this.updateUploadVideoView(2);
    },
    //
    hideGetSettings() {
      this.log("hideGetSettings");
      this.updateUploadVideoView(1);
    },
    //
    onSubmit() {
      const track = {
        //...this.mCurrentUser,
        name: this.mCurrentCreation.name,
        duration: this.mCurrentCreation.duration,
        clientId: this.currentClientId,
        userId: this.currentUserId,
      };

      this.log(" onSubmit - track:", track);
    },
  },
};
</script>
<style scoped>
.mobileL form div.idf_submit_line {
  gap: 24px 10px;
  height: auto;
  padding-bottom: 24px;
  padding-top: 10px;
}

.uv_cnt {
  display: flex;
  width: 100%;
}

.uv_cnt .dlg_form_container {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.9) 0%,
    #ffffff 100%
  );
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  width: 100%;
}

.uv_cnt .dlg_uploadVideo_0 {
  border: 0px solid rgb(196, 205, 213);
}

.uv_cnt .dlg_uploadVideo_1_Circle {
  border: 1px dashed var(--main-blue-color);
  width: 126px;
  height: 126px;
}

.uv_cnt .button_uv {
  border: 1px dashed var(--main-blue-color);
}

.uv_cnt .dlg_form_header {
  text-align: center;
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  padding: 24px 0px 20px 0px;
}
.uv_cnt .dlg_form_header .cmh {
  padding: 24px 0px 20px 0px;
}

.uv_cnt .dlg_text {
  text-align: center;
}

.uv_cnt .ms_inputbox_0 {
  position: relative;
  padding-bottom: 21px;
}
.uv_cnt .ms_inputbox_0a {
  position: relative;
  padding-bottom: 5px;
}

.uv_cnt .ms_inputbox_0.uv_inp_msg {
  position: relative;
  padding: 0px 0px 0px 0px;
}

.uv_cnt .dlg_form_container fieldset {
  inset: -5px 0px 0px;
}

.uv_cnt .dlg_form_container input {
  background-color: white;
}

#idf_time_disabled {
  opacity: 25%;
}

#idf_time_disabled input:hover + fieldset {
  border-color: rgba(0, 0, 0, 0.23);
}
.uv_cnt .uvMsg {
  font-size: 12px;
  line-height: 18px;
  color: var(--main-gray500-color);
  text-align: left;
  padding: 4px 0px 20px 20px;
}

.uv_cnt .uvMsg_1 {
  font-size: 12px;
  line-height: 18px;
  color: var(--main-gray500-color);
  text-align: left;
  padding: 18px 0px 0px 20px;
}

.uv_cnt .dlg_msg {
  margin-bottom: 11px;
}

.uv_cnt .dlg_msg a {
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  line-height: 18px;
  color: var(--main-sec-color);
  padding-right: 30px;
}
.uv_cnt .dlg_msg a:visited {
  color: var(--main-sec-color);
}

.uv_cnt .dlg_lgn_row {
  padding-top: 4px;
  padding-bottom: 8px;

  color: var(--main-gray500-color);
}

.uv_cnt .dlg_lgn_row p {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  text-decoration: none;

  margin: 9px 0px 16px 0px;
}
</style>
