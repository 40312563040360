<template>
  <div class="mma">
    <div @click="displayActionsMenu(trackData.id)" class="mmat">
      <img id="mm_actions_trigger" src="@/assets/dots.png" />
    </div>
    <div class="mmab">
      <div
        style="min-width: 150px"
        :id="'trId_' + trackData.id"
        class="mm_actions_0"
        v-click-out-side="asw_clickedOutsideOfTheMenu"
      >
        <!-- download -->
        <Popper arrow :hover="true" content="Download">
          <a
            :id="'dt_' + trackData.id"
            @click="
              downloadElement(
                $event,
                trackData.id,
                trackData.name,
                trackData.Tracks
              )
            "
            class="actionBtn"
            alt="download"
          >
            <button class="actionBtn_l2">
              <img :src="$getImageUrl('Download.svg')" />
            </button>
          </a>
        </Popper>
        <!-- edit -->
        <Popper arrow :hover="true" content="Rename">
          <a
            @click="
              editElement(
                $event,
                trackData.id,
                trackData.name,
                $toHHMMSS(trackData.duration)
              )
            "
            class="actionBtn"
            alt="rename"
          >
            <button class="actionBtn_l2">
              <img :src="$getImageUrl('Edit.svg')" />
            </button>
          </a>
        </Popper>
        <!-- delete -->
        <Popper arrow :hover="true" content="Delete">
          <a
            @click="deleteElement($event, trackData.id, trackData.name)"
            class="actionBtn"
            alt="delete"
          >
            <button class="actionBtn_l2">
              <img :src="$getImageUrl('Delete.svg')" />
            </button>
          </a>
        </Popper>
        <div @click="displayActionsMenu(trackData.id)" class="mmat">
          <img id="mm_actions_trigger" src="@/assets/dots.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import streamSrv from "@/services/streamService.js"; // access to the APIs
import useConfirmBeforeAction from "@/features/useConfirmBeforeAction.js";
import useUpdateCreation_BeforeAction from "@/features/useUpdateCreation_BeforeAction.js";
import Popper from "vue3-popper";
import clickOutSide from "@mahdikhashan/vue3-click-outside";

export default {
  name: "myMusicActions",
  components: {
    Popper,
  },
  props: {
    trackData: { type: Object, required: true },
  }, //
  emits: ["trackEdited"],
  data() {
    return {
      isOpen: false,
      openMenuId: 0,
      counter: 0,
    };
  },
  directives: {
    clickOutSide,
  },
  computed: {
    ...mapGetters("userModule", [
      "userToken",
      "isUserLoggedIn",
      "currentUserId",
      "currentClientId",
      "currentClientFN",
      "currentClientLN",
      "currentSubscription",
    ]),

    ...mapGetters("subscriptionsModule", [
      "curentSubscriptionId",
      "subscriptionName",
      "subscriptionImage",
      "subscriptionAllowedTime",
      "subscriptionUsedTime",
      "subscriptionCurrency",
      "subscriptionStartDate",
      "subscriptionStatus",
      "subscriptionsPeriodStart",
      "subscriptionPeriodEnd",
      "subscriptionPrice",
      "subscriptionPriceId",
      "subscriptionProductId",
      "subscriptionInterval",
      "subscriptionStripeCustomerId",
    ]),
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("streamModule", {
      getStreamList: "getStreamList",
      setCurStreamId: "setCurrentId",
      setCurStreamName: "setCurrentName",
      setCurrentPage: "setCurrentPage",
    }),

    asw_clickedOutsideOfTheMenu() {
      //
      if (this.isOpen == true) {
        this.counter++;
      }
      if (this.counter > 1) {
        this.displayActionsMenu(this.openMenuId);
        this.counter = 0;
      }
    },

    // --------------------------------------------------
    displayActionsMenu(trId) {
      let am = this.$el.querySelector("#trId_" + trId);

      if (am.style.display == "none" || am.style.display == "") {
        am.style =
          "display: flex; align-items: center; width: 180px; border-radius: 8px; border: 1px solid var(--main-gray400-color); background-color: var(--main-bg-color);";
        this.openMenuId = trId;
      } else {
        am.style = "";
        this.openMenuId = 0;
      }
      if (this.isOpen == true) this.isOpen = false;
      else {
        this.isOpen = true;
      }
    },

    // --------------------------------------------------
    editElement(event, elemId, elemName, elemDuration) {
      this.setCurStreamId(elemId);
      this.setCurStreamName(elemName);
      this.$_log("streamId:" + elemId + " streamDuration:" + elemDuration);

      useUpdateCreation_BeforeAction(
        function (creation) {
          this.$_log(
            "action confirmed! id:" +
              creation.id +
              " a:" +
              creation.name +
              " b:" +
              creation.duration
          );

          //let CCC = this.tableContent.filter((el) => el.id == creation.id);

          const CCC = this.trackData;
          console.log("CCC:", CCC);
          // DATA TO BE SENT
          const obj = {
            // --
            stream_id: creation.id,
            Client_id: CCC.Client_id,
            name: creation.name,
            remarks: CCC.remarks,
            params: CCC.params.replaceAll(",", ""),
            tags:
              CCC.selectedTags != "" ? CCC.selectedTags.map((x) => x.id) : [], //CCC[0].selectedTags,
            motives: CCC.selectedMotives.map((x) => x.id),
            receivedTracks: [CCC.Tracks], //this is not required param ...
            user_id: this.currentUserId,
            // --
          };
          /////////////////////////////////////
          this.$_log("stream:add new doSubmit - stream:", obj);

          streamSrv
            .updateSingleStream(obj)
            .then((response) => {
              this.$_log("asw response.data:", response.data);
              //todo: add notification !!!

              var n = {
                type: "success",
                message: "You just correctly updated creation.",
              };
              this.addNotificationMessage(n);

              this.$emit("trackEdited", {
                updateList: true,
              });

              //this.getData();
            })
            .catch((error) => {
              var n;
              if (typeof error.response != "undefined") {
                this.$_log("asw error:", error.response.data);
                n = {
                  type: "problem",
                  message:
                    "Creation cant be updated... error:" +
                    error.response.message,
                };
                this.addNotificationMessage(n);
              } else {
                this.$_log("asw error:", error);
                n = {
                  type: "problem",
                  message: "Creation cant be updated.. error:" + error,
                };
                this.addNotificationMessage(n);
              }

              this.$emit("trackEdited", {
                updateList: false,
              });
            });
        }.bind(this), // context has been changed so i have to "bind(this)"" to the function ....
        function () {
          this.$_log("action canceled ...");
        }.bind(this), // context has been changed so i have to "bind(this)"" to the function ....
        {
          // message to be displayed
          title: "Rename soundtrack",
          id: elemId,
          name: elemName,

          duration: "", // elemDuration, //because we allow only name modificetion ...
        }
      );
    },

    // --------------------------------------------------
    downloadElement(event, elemId, elemName, elemUrl) {
      this.$_log(
        "lets download elemId: " +
          elemId +
          " as elemName:" +
          elemName +
          " elemUrl:" +
          elemUrl
      );
      let fO = this.fillPlayerObj(elemId, elemName, elemUrl);
      fO[0].name = fO[0].name.replace(/\s/g, "_") + ".mp3";
      this.$_log("fO:", fO[0]);
      streamSrv.getAudioFile(fO[0].url, fO[0].name);
    },

    // ---------------------------------------------------
    deleteElement(event, elemId, elemName) {
      ///
      this.$_log("deleteElement elemId:" + elemId);
      useConfirmBeforeAction(
        function () {
          this.$_log("action confirmed!");
          streamSrv
            .setStreamVisibility({ id: elemId, hide: 1 })
            .then((response) => {
              this.$_log("response:", response.data);
              var n = {
                type: "success",
                message: "Your creation has been deleted.",
              };
              this.addNotificationMessage(n).catch((err) => {
                this.$_log("addNotificationMessage 00 error:", err);
              });
            })
            .then(() => {
              this.$_log(
                "AAAAA this.$router.currentRoute:",
                this.$router.currentRoute
              );
              this.getData();
            })
            .catch((error) => {
              this.$_log("asw deleteStream error:", error);
            });
        }.bind(this), // context has been changed so i have to "bind(this)"" to the function ....
        function () {
          this.$_log("action canceled ...");
        }.bind(this), // context has been changed so i have to "bind(this)"" to the function ....
        {
          // message to be displayed
          question:
            'Do you really want to delete creation: "' + elemName + '" ?',
        }
      );
      ///
    },

    // -----------------------------------------------------
    fillPlayerObj(trackId, trackName, trackUrl) {
      let file_prefix = "";
      if (trackUrl.indexOf("https://api.muzaic.biz/mix/") == -1) {
        file_prefix = "https://api.muzaic.biz/mix/";
      }
      return [{ id: trackId, name: trackName, url: file_prefix + trackUrl }];
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 480px) {
  .mm_actions_0 {
    display: table-cell;
  }
  #mm_actions_trigger {
    display: none;
  }
  .mmat {
    display: none;
    align-items: center;
    justify-content: flex-end;
  }
  .mma,
  .mmab {
    position: initial;
  }
}
@media only screen and (max-width: 480px) {
  .mm_actions_0 {
    display: none;
  }
  #mm_actions_trigger {
    display: flex;
  }
  .mmat {
    display: block;
  }
  .mma {
    position: absolute;
  }
  .mmab {
    position: relative;
    left: -150px;
    top: -32px;
  }
}

#mm_actions_trigger {
  width: 24px;
  height: 5px;
  cursor: pointer;
}

.actionBtn {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;

  /** */
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 5px;
  font-size: 1.125rem;
}

.actionBtn:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.actionBtn_l2 {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.actionBtn_l2:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
</style>
