import tb from "@/features/toolBox.js";

//ASW 2023-12-14 >

import mitt from "mitt";
const emitter = mitt();

//ASW 2023-12-14 <
/*
app.addListener("appUrlOpen", function (data) {
  const slug = data.url.split(".com").pop();
  if (slug) {
    /// redirectUri: 'https://myapp.com/auth/github/callback'
    const callback = "/callback"; //string from reirectUri make this unique
    const code = slug.split("code=").pop();
    const checker = slug?.toString().includes(callback) && code;
    if (checker) {
      emitter.emit("OauthCall", code);
    } else {
      app.router.push({
        path: slug,
      });
    }
  }
});
*/

export default {
  install: (app, options) => {
    // available for all components '$_log' method ('$' - is a naming convention)
    //just wrap function from features toolBox...
    app.config.globalProperties.$_log = tb._log;
    app.config.globalProperties.$formatDate = tb.formatDate;
    app.config.globalProperties.$toHHMMSS = tb.toHHMMSS;
    app.config.globalProperties.$HHMMSStoTxt = tb.HHMMSStoTxt;
    app.config.globalProperties.$time2sec = tb.time2sec;
    app.config.globalProperties.$toCamelCase = tb.toCamelCase;
    app.config.globalProperties.$replaceMainBackground =
      tb.replaceMainBackground;
    app.config.globalProperties.$getImageUrl = tb.getImageUrl;

    // ASW 2023-12-14 >>

    app.config.globalProperties.emitter = emitter;

    // ASW 2023-12-14 <<

    app.provide("tb", options);
  },
};
