<template>
  <div id="root_cmr" ref="root_createMusicReady" class="ready_cnt">
    <!-- ######################################################### -->
    <div id="video_container">
      <video id="sl_vPlh" ref="sl_vPlh_0">
        <source id="sl_vPlhSrc" src="" type="" />
      </video>
      <div class="videoGridCnt">
        <div id="videoPlayCnt">
          <button class="vToggleBtnPlay" @click="toggleVideo($event)"></button>
        </div>
        <div id="videoTVM">
          <button class="tvm" @click="toggleViewMode($event)"></button>
        </div>
      </div>
    </div>
    <!-- ######################################################### -->
    <div id="readyblk" class="dlg_form_container">
      <div class="dlg_c_l1">
        <p class="dlg_form_header"></p>
      </div>

      <div class="dlg_c_l2">
        <!-- ++++++++++++ >> -->
        <div>
          <aswAudioPlayer
            v-if="receivedPlaylistsFlag"
            id="mainPlayer"
            :audioList="audioList"
            playerVolume="0.5"
            playerStyle="wb_studioLight"
            playerType="normalPlayer"
            playerTimeFormat="mm:ss"
            @playClicked="handlePlayClicked"
            @trackEnds="handleTrackEnds"
            @trackPause="handleTrackPause"
            @progressDragged="handleProgressDragged"
            @playerInitialized="handlePlayerInitialized"
            @componentInterface="getChildInterface"
          />
          <div v-else id="playerInactive">
            inactivePlayer
            <div id="playInactive"></div>
            <div id="playDetailsInactive">
              <div id="timeStartInactive">--:--:--</div>
              <div id="progressInactive"></div>
              <div id="timeEndInactive">--:--:--</div>
            </div>
          </div>
        </div>
        <!-- ++++++++++++ << -->
        <form @submit.prevent="onSubmit">
          <div
            class="idf_submit_line"
            style="padding-top: 28px; padding-bottom: 3px"
          >
            <button
              type="submit"
              class="idf_submit_btn idf_submit_btn_wide sl_share_btn"
              style="
                align-items: center;
                display: flex;
                justify-content: center;
              "
              @click="shareIt"
            >
              <img
                src="@/assets/sl_share.png"
                style="width: 24px; height: 24px; margin-right: 10px"
              />Share
            </button>
          </div>

          <div class="idf_submit_line" style="padding-bottom: 24px">
            <button
              type="submit"
              class="idf_cancel_btn idf_cancel_btn_wide"
              style="
                border: 1px solid var(--main-gray900-color);
                color: var(--main-gray900-color);
              "
              @click="goTo_uploadVideo_page"
            >
              Try again
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";

import aswAudioPlayer from "@/components/elements/audioPlayer.vue";
//import streamSrv from "@/services/streamService.js";
import config from "@/config/config.js";

export default {
  name: "sl_createMusicTrackIsReady",
  childInterface: {
    pauseTrack: () => {},
    startTrack: () => {},
    rewindTrack: () => {},
  },
  setup() {
    const root_createMusicAdjust = ref(null);
    return {
      root_createMusicAdjust,
    };
  },
  emits: ["componentInterface"],
  components: { aswAudioPlayer },
  data() {
    return {
      trackDuration: null,
      trackName: null,
      videoImage: "",
      video: "",
      ClientId: "",

      receivedPlaylistsFlag: false,
      playerReady: false,
      vToggleBtn: null,
      audioList: [],
      audioPlaying: false,
      audioPlayingId: null,

      containerDom: null,
      dataReady: false,
      videoViewMode: 0,
      newFileName: "",
    };
  },
  created() {
    this.log("created");
    this.init();
  },
  mounted() {
    //this.containerDom = this.root_createMusicAdjust;
    this.containerDom = document.querySelector("#root_cmr");
    this.log("mounted: ", this.containerDom);
    this.setupVideo();
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
      "currentSubscription",
    ]),
    ...mapGetters("streamModule", {
      strName: "streamName",
      strImage: "streamImage",
      strDuration: "streamDuration",
      strTags: "streamTags",
      strMotives: "streamMotives",
      strParams: "streamParams",
      strVideo: "streamVideo",
      strVw: "streamVideoW",
      strVh: "streamVideoH",
      strGenVideo: "streamGenVideo",
      strAudio: "streamAudio",
      receivedTags: "receivedTags",
      streamUVFN: "streamUVFN",
      streamUVFT: "streamUVFT",
    }),
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("streamModule", {
      initStream: "initStream",
      setAudioStream: "setAudio",
      getMusicTags: "musicTags",
      getMusicMotives: "musicMotives",
      setStreamMotives: "setStreamMotives",
      setStreamTags: "setStreamTags",
    }),
    ...mapActions("clientModule", { getClientProduct: "getClientProduct" }),

    // I N T E R F A C E >>
    // ---------------------------------------------------------
    emitInterface() {
      this.log("emitInterface()");
      this.$emit("componentInterface", {
        stopSelectedTrack: (trId) => this.stopSelectedTrack(trId),
        pauseSelectedTrack: (trId) => this.pauseSelectedTrack(trId),
        startSelectedTrack: (trId) => this.startSelectedTrack(trId),
      });
    },

    // ---------------------------------------------------------
    stopSelectedTrack(trId) {
      this.log("stopSelectedTrack(" + trId + ") if it works.... ");
      this.stopCurrentPlayer();
    },
    // ---------------------------------------------------------
    pauseSelectedTrack(trId) {
      this.log("pauseSelectedTrack(" + trId + ") .... ");
      this.pauseCurrentPlayer();
    },
    // ---------------------------------------------------------
    startSelectedTrack(trId) {
      this.log("startSelectedTrack(" + trId + ") .... ");
      this.startCurrentPlayer();
    },
    // I N T E R F A C E <<

    // ---------------------------------------------------------
    getChildInterface(childInterface) {
      this.log("getChildInterface ...");
      this.$options.childInterface = childInterface;
    },

    //
    setupVideo() {
      this.log("setupVideo .... A");

      this.videoObj = this.containerDom.querySelector("#sl_vPlh");
      this.log("setupVideo this.videoObj.tagName:" + this.videoObj.tagName);
      this.log("setupVideo this.videoObj:", this.videoObj);
      this.log("setupVideo this.strVw:" + this.strVw);
      this.log("setupVideo this.strVh:" + this.strVh);
      //this.videoObj.style =
      //  "position: absolute; border: 1px solid red; height: 126px; width: 126px; border-radius: 100%; object-fit: cover; z-index:10;";

      //this.videoObj.src = URL.createObjectURL(this.strVideo);

      this.log("this.strGenVideo:" + this.strGenVideo);
      this.videoObj.src = this.strGenVideo;

      if (this.strVw > this.strVh) {
        this.videoObj.classList.remove("adjToH");
        this.videoObj.classList.add("adjToW");
      } else {
        this.videoObj.classList.remove("adjToW");
        this.videoObj.classList.add("adjToH");
      }

      this.vToggleBtn = this.containerDom.querySelector("#vToggleBtn");
      this.receivedPlaylistsFlag = true;
      this.log("setupVideo .... Z");
    },
    //
    toggleViewMode(event) {
      this.log("toggleViewMode this.videoViewMode:" + this.videoViewMode);
      let v = this.containerDom.querySelector("#sl_vPlh");
      this.log("toggleViewMode v:", v);

      let x = this.$getImageUrl("mobileVideoBg00.png");
      this.$replaceMainBackground(x);

      if (this.videoViewMode == 0) {
        //video w tle; przeskalowane i wycentrowane
        this.videoViewMode = 1;
        // change button
        event.currentTarget.classList.toggle("tvm");
        event.currentTarget.classList.toggle("utvm");

        //change video
        if (this.strVw > this.strVh) {
          v.classList.toggle("plytvm");
        } else {
          v.classList.toggle("plytvm_H");
        }
      } else {
        //video wyswietlane centralnie dopasowane do wielkosci wyswietlacza (szerokoscią)
        this.videoViewMode = 0;
        // change button
        event.currentTarget.classList.toggle("utvm");
        event.currentTarget.classList.toggle("utvm");

        //change video
        if (this.strVw > this.strVh) {
          v.classList.toggle("plytvm");
        } else {
          v.classList.toggle("plytvm_H");
        }
      }
    },
    //
    toggleVideo(event) {
      //>
      let audioObj = document.querySelector("#apObj_mainPlayer");
      let audioPlayerPlayButton = document.querySelector("#pab_mainPlayer");
      audioObj.volume = 0;
      audioPlayerPlayButton.click();
      audioPlayerPlayButton.style = "display:none;";
      //<
      if (this.videoObj.paused) {
        this.videoObj.play();
        //this.vToggleBtn.innerHTML = "Pause";
        event.currentTarget.classList.toggle("vToggleBtnPlay");
        event.currentTarget.classList.toggle("vToggleBtnPause");
      } else {
        this.videoObj.pause();
        //this.vToggleBtn.innerHTML = "Play";
        event.currentTarget.classList.toggle("vToggleBtnPlay");
        event.currentTarget.classList.toggle("vToggleBtnPause");
      }
    },
    //
    toggleFullScreen() {
      this.log("toggleFullScreen .....");
    },

    //
    async shareIt() {
      //srcEvent.target.addEventListener("click", (event) => {
      this.log("shareIt ... A");
      let receivedVideo = await window.fetch(this.strGenVideo);
      this.log("shareIt ... B");
      let videoBuffer = await receivedVideo.arrayBuffer();
      this.log("shareIt ... C");
      let fileArray = [
        new File([videoBuffer], this.strName, {
          type: "video/mp4", // video/mp4, audio/mpeg
          lastModified: Date.now(),
        }),
      ];

      if (
        window.navigator &&
        window.navigator.canShare &&
        window.navigator.canShare({ files: fileArray })
      ) {
        this.log("shareIt ... D");
        navigator
          .share({
            title: this.strName,
            text: "text to be displayed",
            files: fileArray,
          })
          .then(() => {
            console.log("Thanks for sharing!");
          })
          .catch(console.error);
      } else {
        //shareDialog.classList.add("is-open");
        this.log(
          "shareIt for some reason share dialog cant be open .... so what to do ? simply download ??"
        );
        this.downloadElement();
      }

      //});
    },

    //
    goTo_uploadVideo_page() {
      this.$router.push({
        name: "sl_mob_uploadVideo",
      });
    },
    //
    log(str, obj) {
      if (typeof obj == "undefined") {
        this.$_log(this.$options.name + "] " + str);
      } else {
        this.$_log(this.$options.name + "] " + str, obj);
      }
    },

    //
    init() {
      this.log("init... A");

      this.$replaceMainBackground(this.strImage);

      this.trackDuration = this.strDuration;
      this.trackName = this.strName;
      this.videoImage = this.strImage;
      this.video = this.strVideo;
      this.ClientId = this.currentClientId;

      let prefix = this.strName;
      let T = this.streamUVFT.split("/");
      let sufix = T[1];
      this.newFileName = prefix + "_byMuzaicStudio." + sufix;

      this.audioList.push(this.strAudio);

      this.dataReady = true;
      this.log("init... Z");
    },

    // ========================================================
    // ---------------------------------------------------------
    handleProgressDragged(obj) {
      //this.$_log("handleProgressDragged e:", e);

      let v = this.containerDom.querySelector("#sl_vPlh");
      if (v != null) {
        v.currentTime = obj.currentTime;
      }
      let vFS = document.querySelector("#sl_vPlh_fs");
      if (vFS != null) {
        vFS.currentTime = obj.currentTime;
      }

      this.$emit("progressDragged", obj);
    },

    // ---------------------------------------------------------
    handleTrackPause(obj) {
      this.$_log("handleTrackPause received obj:", obj);

      this.audioPlaying = false;
      //this.audioPlayingId = -1;
      this.$emit("trackPause", obj);
    },

    // ---------------------------------------------------------
    handleTrackEnds(obj) {
      this.log("handleTrackEnds received obj:", obj);

      this.audioPlaying = false;
      this.audioPlayingId = -1;

      // replace icons
      let b = this.containerDom.querySelector("#videoPlayCnt button");
      this.log("b:", b);
      b.classList.toggle("vToggleBtnPlay");
      b.classList.toggle("vToggleBtnPause");

      this.$emit("trackEnds", obj);
    },

    // ---------------------------------------------------------
    handlePlayClicked(elem) {
      // stop other playing track or track with video AND register new playing track
      // TODO: !!!! check what is going on here !!!
      this.$_log("globalFooterPlayer] handlePlayClicked elem:", elem);
      if (elem.action == "play") {
        this.$_log("globalFooterPlayer] handlePlayClicked set local variables");
        this.audioPlaying = true;
        this.audioPlayingId = elem.trackId;
      } else {
        this.$_log(
          "globalFooterPlayer] handlePlayClicked but received action is NOT !!! play !!!"
        );
        this.audioPlaying = false;
        this.audioPlayingId = -1;
      }

      // VIDEO PART ==> should we stop related video ?
      if (this.videoImage != "" && elem.trackId == "mainPlayer") {
        this.$_log("globalFooterPlayer] handlePlayClicked VIDEO ============>");
        // lets play video
        let v = this.containerDom.querySelector("#sl_vPlh");
        console.log("v:", v);
        if (v !== null && this.videoIsPlaying == false) {
          if (v.paused === false) {
            v.src = URL.createObjectURL(this.video);

            v.load();
          }
          v.volume = 0.0;
          v.play();

          if (this.videoFullScreen == true) {
            let vFS = document.querySelector("#sl_vPlh_fs");

            if (vFS.paused === false) {
              vFS.src = URL.createObjectURL(this.video);

              vFS.load();
            }

            vFS.volume = 0.0;
            vFS.currentTime = v.currentTime;
            vFS.play();
          }

          this.videoIsPlaying = true;
        } else {
          //this.stopCurrentPlayer();
          this.$_log(
            "globalFooterPlayer] handlePlayClicked else ..... pauseCurrentPlayer "
          );
          this.pauseCurrentPlayer();
        }
        this.$_log("globalFooterPlayer] handlePlayClicked VIDEO ============<");
      }
      // VIDEO PART ==<

      this.$emit("playClicked", elem);
    },

    // --------------------------------------------------------------------
    handlePlayerInitialized(obj) {
      this.$emit("playerInitialized", obj);
      this.playerReady = true;
    },

    // ---------------------------------------------------------
    pauseCurrentPlayer() {
      this.$_log("globalFooterPlayer] pauseCurrentPlayer if it works.... ");
      if (this.audioPlaying == true) {
        this.$_log(
          "globalFooterPlayer] pauseCurrentPlayer yes it works so im stopping it. this.audioPlayingId:" +
            this.audioPlayingId
        );
        this.$options.childInterface.pauseTrack(); // call child function <<
      }

      // video part -->
      this.pauseRelatedVideo();
      // video part --<
    },
    // ---------------------------------------------------------
    // V I D E O functions =>

    // ---------------------------------------------------------
    stopRelatedVideo() {
      if (this.videoImage != "") {
        let v = this.containerDom.querySelector("#sl_vPlh");
        if (this.videoIsPlaying != false) {
          v.currentTime = 0;

          if (this.videoFullScreen == true) {
            let vFS = document.querySelector("#sl_vPlh_fs");
            vFS.currentTime = 0;
          }
        }
      }
    },

    // ---------------------------------------------------------
    pauseRelatedVideo() {
      if (this.videoImage != "") {
        let v = this.containerDom.querySelector("#sl_vPlh");
        if (this.videoIsPlaying != false) {
          v.pause();

          if (this.videoFullScreen == true) {
            let vFS = document.querySelector("#sl_vPlh_fs");
            vFS.pause();
          }
          this.videoIsPlaying = false;
        }
      }
    },
    // V I D E O functions =<
    // ---------------------------------------------------------

    // --------------------------------------------------------------------
    fillPlayerObj(trackId, trackName, trackUrl) {
      this.log(
        "fillPlayerObj config.api_host :" +
          config.api_host +
          " trackUrl:" +
          trackUrl
      );
      let file_prefix = "";

      return [{ id: trackId, name: trackName, url: file_prefix + trackUrl }];
    },

    // --------------------------------------------------------------------
    async downloadElement() {
      // get generated audio file -->
      /*
      this.log("this.audioList:", this.audioList);

      this.log(
        "lets download elem as elemName:" +
          this.audioList[0].name +
          " elemUrl:" +
          this.audioList[0].url
      );

      let fO = this.fillPlayerObj(
        this.audioPlayingId,
        this.trackName,
        this.audioList[0].url
      );
      fO[0].name = fO[0].name.replace(/\s/g, "_") + ".mp3";
      this.$_log("fO:", fO);

      streamSrv.getAudioFile(fO[0].url, fO[0].name);
	  */
      // get generated audio file --<

      // get generated video file ->
      /*
      let prefix = this.strName;
      let T = this.streamUVFT.split("/");
      let sufix = T[1];
      let newFileName = prefix + "_byMuzaicStudio." + sufix;
      this.log("newFileName:" + newFileName);
	  */

      let blob = await fetch(this.strGenVideo).then((r) => r.blob());
      this.downloadBlob(blob, this.newFileName);
      // get generated video file -<
    },

    // >>>
    downloadBlob(blob, name = "file.txt") {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");

      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = name;

      // Append link to the body
      document.body.appendChild(link);

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    },
    // <<<
  },
};
</script>

<style scope>
/*
.mobileL form div.idf_submit_line {
  gap: 24px 10px;
  height: auto;
  padding-bottom: 0px;
  padding-top: 10px;
}
*/

@media only screen and (max-width: 640px) {
  .wb_studioLight #currentTrackParams {
    display: grid;
    grid-template-areas:
      "sl_tct sl_ttt"
      "sl_progress sl_progress";
    border: 0px solid red;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    max-width: 310px;
    min-width: 200px;
    width: 100%;
  }
  .wb_studioLight #trackCurrentTime {
    grid-area: sl_tct;
    text-align: left;
  }
  .wb_studioLight #trackTotalTime {
    grid-area: sl_ttt;
    text-align: right;
  }
  .wb_studioLight .audioPlayerBarContainer {
    grid-area: sl_progress;
  }
}

.wb_studioLight {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ready_cnt {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 260px;
  grid-gap: 24px;
}

.ready_cnt .dlg_form_container {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 25px;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.9) 0%,
    #ffffff 100%
  );
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  width: auto;
}

.ready_cnt .dlg_uploadVideo_0 {
  height: 480px;
  border: 0px solid rgb(196, 205, 213);
}

.ready_cnt .dlg_uploadVideo_1_Circle {
  border: 1px dashed var(--main-blue-color);
  width: 126px;
  height: 126px;
}

.ready_cnt .button_uv {
  border: 1px dashed var(--main-blue-color);
}

.ready_cnt .dlg_form_header {
  text-align: center;
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  padding: 24px 0px 20px 0px;
}
.ready_cnt .dlg_form_header .cmh {
  padding: 24px 0px 20px 0px;
}

.ready_cnt .dlg_text {
  text-align: center;
}

.ready_cnt .ms_inputbox_0 {
  position: relative;
  padding-bottom: 21px;
}
.ready_cnt .ms_inputbox_0a {
  position: relative;
  padding-bottom: 5px;
}

.ready_cnt .ms_inputbox_0.uv_inp_msg {
  position: relative;
  padding: 0px 0px 0px 0px;
}

.ready_cnt .dlg_form_container fieldset {
  inset: -5px 0px 0px;
}

.ready_cnt .dlg_form_container input {
  background-color: white;
}

#idf_time_disabled {
  opacity: 25%;
}

#idf_time_disabled input:hover + fieldset {
  border-color: rgba(0, 0, 0, 0.23);
}
.ready_cnt .uvMsg {
  font-size: 12px;
  line-height: 18px;
  color: var(--main-gray500-color);
  text-align: left;
  padding: 4px 0px 20px 20px;
}

.ready_cnt .uvMsg_1 {
  font-size: 12px;
  line-height: 18px;
  color: var(--main-gray500-color);
  text-align: left;
  padding: 18px 0px 0px 20px;
}

.ready_cnt .dlg_msg {
  margin-bottom: 11px;
}

.ready_cnt .dlg_msg a {
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  line-height: 18px;
  color: var(--main-sec-color);
  padding-right: 30px;
}
.ready_cnt .dlg_msg a:visited {
  color: var(--main-sec-color);
}

.ready_cnt .dlg_lgn_row {
  padding-top: 4px;
  padding-bottom: 8px;

  color: var(--main-gray500-color);
}

.ready_cnt .dlg_lgn_row p {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  text-decoration: none;

  margin: 9px 0px 16px 0px;
}

.mobileL #video_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 88px;
  width: 100%;
  max-width: 380px;
  min-height: 200px;
  height: 490px;
  max-height: 500px;
  border: 0px solid green;
}

/* ################################################################# >>> */
/* ################################################################# >>> */

.mobileL #root_cmr #sl_vPlh.adjToW {
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  /*border: 2px solid rgb(6, 237, 33);*/
  position: fixed;
  left: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  transform: translateX(-50%);
  z-index: -1;
}

.mobileL #root_cmr #sl_vPlh.plytvm {
  position: absolute;
  width: 80vw;
  min-width: auto;
  min-height: auto;
  top: 25%;
  left: auto;
  transform: translateX(0%);
}

.mobileL #root_cmr #sl_vPlh.adjToH {
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
  /*border: 2px solid #f90d0d;*/
  position: fixed;
  left: 0;
  bottom: 0;

  height: 100vmax;

  z-index: -1;
}

.mobileL #root_cmr #sl_vPlh.plytvm_H {
  position: absolute;
  width: 58vw;
  min-width: auto;
  min-height: auto;
  top: -45%;
  left: calc(100% - 310px);
  transform: translateX(0%);
}

.mobileL #root_cmr .videoGridCnt {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 44px;
  width: 100%;
  height: 100%;
}

.mobileL #root_cmr #videoPlayCnt {
  /*position: initial;*/
  display: flex;
  align-items: center;
  justify-content: center;
  /*background: rgba(0, 0, 0, 0.5);*/
  color: #f1f1f1;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  /* padding: 20px; */
  z-index: 1;
}
.mobileL #root_cmr #videoTVM {
  display: flex;
  justify-content: right;
  /*padding-right: 20px;*/
}

.tvm {
  background-image: url("@/assets/videoFS.png");
  background-size: cover;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  font-size: 18px;
  padding: 10px;
  border: none;

  color: #fff;
  cursor: pointer;
}
.utvm {
  background-image: url("@/assets/videoUFS.png");
  background-size: cover;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  font-size: 18px;
  padding: 10px;
  border: none;

  color: #fff;
  cursor: pointer;
}

.vToggleBtnPlay {
  /*background: #000;*/
  background-image: url("@/assets/sl_playVideo.png");
  background-size: cover;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  font-size: 18px;
  padding: 10px;
  border: none;

  color: #fff;
  cursor: pointer;
}

.vToggleBtnPause {
  background: rgba(0, 0, 0, 0);
  background-image: url("@/assets/sl_playVideo_p.png");
  background-size: cover;
  width: 60px;
  height: 60px;
  font-size: 18px;
  padding: 10px;
  border: none;

  color: #fff;
  cursor: pointer;
  opacity: 0.4;
}

.idf_submit_btn .idf_submit_btn_wide .sl_share_btn {
  background-image: url("@/assets/sl_share.png");
}
/* ################################################################# <<< */
</style>
