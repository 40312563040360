import posthog from "posthog-js";

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthog.init(
      "phc_lBFFjRWLO078RqGqox1YSHFNSeP0W6LM0GIwvP2PBE9",
      {
        api_host: "https://eu.posthog.com",
      }
    );
  },
};
