<template>
  <div v-if="!dataReady" id="main_ldc" class="ldc">
    <div class="loader"></div>
  </div>
  <div v-else class="wlc_cnt">
    <!-- ==> -->

    <div class="dlg_form_container">
      <div class="dlg_c_l1">
        <p class="dlg_form_header">
          Add soundtrack to your video with just few clicks.
        </p>
      </div>
      <div class="dlg_c_l2">
        <form @submit.prevent="onSubmit">
          <div class="ms_textbox">
            <ol>
              <li><b>Upload</b> a video</li>
              <li>Select fitting music <b>moods</b>.</li>
              <li><b>Adjust</b> intensity of your soundtrack.</li>
              <li><b>Download</b> or <b>Share</b> with friends.</li>
            </ol>
          </div>

          <div class="dlg_submit_line">
            <button
              type="button"
              class="dlg_submit_btn sign_in_free"
              @click="freeAccountLogin"
            >
              Compose For Free
            </button>
          </div>
          <!--
          <div class="dlg_submit_line">
            <button
              type="button"
              class="dlg_submit_btn sign_in_free"
              @click="goToFreeAccountLogin"
            >
              Compose For Free
            </button>
          </div>
          <div class="dlg_submit_line">
            <button
              type="button"
              class="dlg_submit_btn sign_in_pro"
              @click="goToProAccountLogin"
            >
              Start Premium Version
            </button>
          </div>
          -->
        </form>

        <div class="dlg_wlc_row">
          <span
            class="gradient01"
            @click="goToLoginPage"
            style="cursor: pointer"
            >Sign in</span
          >
          to your account
        </div>
      </div>
    </div>

    <!-- ==< -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "sl_mob_welcome",
  props: {
    msg: String,
  },
  data() {
    return {
      dataReady: true,
    };
  },
  mounted() {
    let d = document.querySelector(".mobileL");
    this.$_log("sl_createMusicHeader mounted d:", d);

    d.style = "";
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
      "getUserDevicetype",
      "currentSubscription",
    ]),
  },
  methods: {
    //
    log(str, obj) {
      if (typeof obj == "undefined") {
        this.$_log(this.$options.name + "] " + str);
      } else {
        this.$_log(this.$options.name + "] " + str, obj);
      }
    },
    onSubmit() {
      this.$_log("sl_mob_welcome] onSubmit");
    },
    freeAccountLogin() {
      this.$_log("sl_mob_welcome] freeAccountLogin");

      // >>
      const usr = {
        username: "",
        email: "freeaccount@muzaic.studio",
        password: "MKQTRdHfncAQ6srf",
        loginFormId: -10,
      };

      this.log("sl_mob_welcome] onSubmit - User:", usr);
      this.$store
        .dispatch("userModule/login", usr)
        .then(() => {
          this.log(
            "ASW 01 sl_mob_welcome] auto login as test user logged username:" +
              this.currentUsername +
              " user_id:" +
              this.currentUserId +
              " user.token:" +
              this.userToken
          );

          this.log(
            "SubscriptionLevel:" + this.currentSubscription.SubscriptionLevel
          );
          if (this.currentSubscription.SubscriptionLevel != "Free") {
            this.setCurrentUserDeviceType("desktop");
            //this.$router.push({ name: "createMusicHeaderView" });
            this.$router.push({
              name: "userCreationsView",
              params: {
                pageNr: 0,
                ClientId: this.currentClientId,
              },
            });
          } else {
            this.$router.push({
              name: "sl_mob_uploadVideo",
            });
          }
        })
        .catch((error) => {
          this.log("Error:" + error);
        });

      // <<
    },
    goToFreeAccountLogin() {
      this.$_log("sl_mob_welcome] goToFreeAccountLogin");
      this.$router.push({
        name: "sl_mob_register",
        params: {
          usageType: "free",
        },
      });
    },
    goToProAccountLogin() {
      this.$_log("sl_mob_welcome] goToProAccountLogin");
      this.$router.push({
        name: "sl_mob_register",
        params: {
          usageType: "premium",
        },
      });
    },
    goToLoginPage() {
      this.$_log("sl_mob_welcome] goToLoginPage");
      this.$router.push({
        name: "sl_mob_login",
        params: {
          usageType: "free",
        },
      });
    },
  },
};
</script>

<style scoped>
.wlc_cnt {
  display: flex;
  width: 100%;
}
.wlc_cnt .dlg_form_container {
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 25px;
  width: auto;
}

.ms_textbox {
  display: flex;
  align-items: center;
  justify-content: left;
}

.ms_textbox ol {
  margin-top: 0px;
  margin-bottom: 10px;
  list-style-position: outside;
  padding-left: 26px;
}

.ms_textbox li {
  text-align: left;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400px;
}

.ms_textbox li b {
  text-align: left;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600px;
}

.sign_in_free {
  width: 100%;
  height: 56px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  cursor: pointer;
}
.sign_in_pro {
  width: 100%;
  height: 56px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  /*background-color: var(--main-bg-color);*/
  background-color: rgba(255, 255, 255, 0.25);
  color: var(--main-blue-color);
  cursor: pointer;
}
.dlg_wlc_row {
  padding-bottom: 17px;
  padding-top: 10px;
  text-align: center;
  margin: 0px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: var(--main-gray500-color);
}

/** loader */
.ldc {
  display: flex;
  align-items: center;
}

.loader {
  color: #fff;
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
</style>
