<template>
  <div class="tabPaginationContainer">
    <div class="paginationParams">
      <BaseSelect
        listName="nr_elop"
        listClass="selectNrElOP"
        label="Elements on page: "
        :modelValue="nrEOP"
        :Options="[5, 10, 50]"
        @selectUpdated="onInfoFromSelect"
      />
    </div>
    <div v-if="totalPages > 0" class="pagination">
      <table>
        <tr>
          <template v-if="displayPagesLike == 'csharks'">
            <td>
              <p class="paginationText">
                {{ parseInt(pageNr) * parseInt(nrEOP, 10) + 1 }}-
                <template
                  v-if="
                    parseInt(pageNr) * parseInt(nrEOP, 10) +
                      parseInt(nrEOP, 10) <
                    nrRowsTotal
                  "
                >
                  {{
                    parseInt(pageNr) * parseInt(nrEOP, 10) + parseInt(nrEOP, 10)
                  }}
                  of
                  {{ nrRowsTotal }}
                </template>
                <template v-else>
                  {{ nrRowsTotal }}
                  of
                  {{ nrRowsTotal }}
                </template>
              </p>
            </td>
            <td>
              <div class="paginationActions">
                <template v-if="pageNr != 0">
                  <router-link
                    id="page_prev"
                    :to="{
                      name: linkName,
                      params: { pageNr: parseInt(pageNr) - 1 },
                    }"
                    rel="prev page"
                  >
                    <div class="goToPage">
                      <img :src="$getImageUrl('pagePrev.svg')" />
                    </div>
                  </router-link>
                </template>
                <template v-else>
                  <div class="elemDisabled goToPage" disabled>
                    <img
                      class="iconDisabled"
                      :src="$getImageUrl('pagePrevDisabled.svg')"
                    />
                  </div>
                </template>

                <template v-if="pageNr != totalPages - 1">
                  <router-link
                    id="page_next"
                    :to="{
                      name: linkName,
                      params: { pageNr: parseInt(pageNr) + 1 },
                    }"
                    rel="next page"
                  >
                    <div class="goToPage">
                      <img :src="$getImageUrl('pageNext.svg')" />
                    </div>
                  </router-link>
                </template>
                <template v-else>
                  <div class="elemDisabled goToPage" disabled>
                    <img
                      class="iconDisabled"
                      :src="$getImageUrl('pageNextDisabled.svg')"
                    />
                  </div>
                </template>
              </div>
            </td>
          </template>
          <template v-else>
            <td>
              <router-link
                id="page_prev"
                :to="{
                  name: linkName,
                  params: { pageNr: parseInt(pageNr) - 1 },
                }"
                rel="prev page"
                v-if="pageNr != 0"
                >&lt;</router-link
              >
            </td>
            <template v-if="totalPages > nrEOP">
              <td v-for="(p, i) in 10" :key="p">
                <template v-if="Math.floor(pageNr / 10) * 10 + i == pageNr">
                  <p>[{{ Math.floor(pageNr / 10) * 10 + i + 1 }}]</p>
                </template>
                <template v-else>
                  <template
                    v-if="Math.floor(pageNr / 10) * 10 + i + 1 <= totalPages"
                  >
                    <router-link
                      :to="{
                        name: linkName,
                        params: {
                          pageNr: Math.floor(pageNr / 10) * 10 + i,
                        },
                      }"
                      >{{ Math.floor(pageNr / 10) * 10 + i + 1 }}</router-link
                    >
                  </template>
                </template>
              </td>
            </template>
            <template v-else>
              <td v-for="(p, i) in totalPages" :key="p">
                <router-link
                  v-if="i != pageNr"
                  :to="{ name: linkName, params: { pageNr: i } }"
                  >{{ p }}</router-link
                >
                <p v-else>[{{ p }}]</p>
              </td>
            </template>
            <td>
              <router-link
                id="page_next"
                :to="{
                  name: linkName,
                  params: { pageNr: parseInt(pageNr) + 1 },
                }"
                rel="next page"
                v-if="pageNr != totalPages - 1"
                >&gt;
              </router-link>
            </td>

            <td v-if="totalPages > nrEOP">
              go to page
              <!-- <BaseInput v-model="currentPage" label="go to page " type="text" />  -->
              <input
                name="goToPage"
                :value="this.pageNr + 1"
                @input="goToPage($event)"
                type="text"
                class="field"
              />

              of all {{ totalPages }} pages ||
            </td>
          </template>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

//import BaseInput from "@/components/baseFormElements/BaseInput.vue";
import BaseSelect from "@/components/baseFormElements/BaseSelect.vue";

export default {
  name: "tabPagination",
  emits: ["nrEOPUpdated", "pageUpdated", "newPageRequested"],
  props: ["pageNr", "totalPages", "nrRowsTotal", "linkName", "nrEOP"],

  components: {
    //BaseInput,
    BaseSelect,
  },
  data() {
    return {
      currentPage: null,
      displayPagesLike: "csharks", // "csharks", "asw"
    };
  },
  created() {
    //this.currentPage = this.pageNr + 1;
  },
  beforeMount() {
    this.$_log("tabPagination beforeMount ....");
  },
  mounted() {
    this.$_log("tabPagination mounted ....");
  },
  updated() {
    this.$_log("tabPagination updated ....");
  },

  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
  },
  methods: {
    onInfoFromSelect(obj) {
      this.$emit("nrEOPUpdated", {
        value: obj.value,
      });
    },

    goToPage01(pNr) {
      this.$emit("pageUpdated", {
        value: pNr,
      });
      /*
      this.$router.push({
        name: linkName,
        params: { pageNr: pNr },
      });
	  */
    },
    goToPage(event) {
      this.$_log(event.target.name + " - " + event.target.value);

      if (!isNaN(parseInt(event.target.value, 10))) {
        this.currentPage = event.target.value;
        this.$router.push({
          name: this.linkName,
          //params: { id: Number(this.currentUserId) },
          params: { pageNr: this.currentPage - 1 },
        });
      }
    },
  },
};
</script>
<style scoped>
.tabPaginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid var(--main-gray300-color);
  width: 100%;
  max-width: var(--mm-max-width);
  margin: 22px auto 0px auto;
  padding: 8px 0px 0px 0px;
}

.tabPaginationContainer table {
  margin: 0 auto;
  text-align: left;

  font-size: 14px;
  font-weight: 200;
  text-decoration: none;
  letter-spacing: 0px;

  /* color: #000; */
  padding-left: 5px;
  border-collapse: collapse;
}

.paginationParams > label {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  flex-shrink: 0;
}
.pagination {
}
.paginationText {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  flex-shrink: 0;
  margin: 0px;
}

.elemDisabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.26);
  pointer-events: none;
  cursor: default;
}

.iconDisabled svg path {
  fill: gray !important;
}

#page_prev {
  text-align: left;
}

#page_next {
  text-align: right;
}

.goToPage {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 0px 8px 0px 8px;
  border-radius: 50%;
  overflow: visible;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: inherit;
}
.goToPage:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.goToPage img {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

label.selectNrElOP {
}
</style>
