<template>
  <label v-if="label" :class="listClass">{{ label }}</label>
  <select
    :class="listClass"
    :value="modelValue"
    :name="listName"
    @change="onChange($event)"
  >
    <option v-for="option in Options" :value="option" :key="option">
      {{ option }}
    </option>
  </select>
</template>

<script>
import UniqueID from "@/features/UniqueID";

export default {
  name: "DropDownSimpleList",
  emits: ["selectUpdated"],
  props: {
    listName: {
      type: String,
      default: "",
    },
    listClass: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    Options: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const uuid = UniqueID().getID();
    return { uuid };
  },
  methods: {
    onChange(event) {
      this.$_log(
        "elemName:" + event.target.name + " elemValue:" + event.target.value
      );

      this.$emit("selectUpdated", {
        name: event.target.name,
        value: event.target.value,
      });
    },
    //...mapActions("dbToolsModule", ["getDictionaryTable"]),
  },
};
</script>

<style scoped>
label {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

select.selectNrElOP {
  border-radius: 15px;
  padding: 3px;
  border: 2px solid var(--main-gray900-color);
  background-color: var(--main-bg-color);
}
</style>
