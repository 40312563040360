// ASW place for global variables and functions ....
const namespaced = true;

// ----
const state = {
  pageLoadingStatus: -1,
};

// ----
const getters = {
  getPageLoadingStatus(state) {
    return state.pageLoadingStatus;
  },
};
// ----
const mutations = {
  SET_PAGE_LOADING_STATUS(state, pls) {
    state.pageLoadingStatus = pls;
  },
};
// ----
const actions = {
  setPageLoadingStatus({ commit }, pls) {
    commit("SET_PAGE_LOADING_STATUS", pls);
  },
};

export { namespaced, state, getters, mutations, actions };
