<template>
  <div class="dlg_uploadVideo_1_Circle">
    <!-- add `data-active` and the event listeners -->
    <div
      :data-active="active"
      @dragenter.prevent="setActive"
      @dragover.prevent="setActive"
      @dragleave.prevent="setInactive"
      @drop.prevent="onDrop"
    >
      <!-- share state with the scoped slot -->
      <slot :dropZoneActive="active">
        <!-- d0 -->
        <label
          :id="'lbl_' + cmpClass"
          :class="'button_uv dlg_uploadVideo_2_Btn ' + cmpClass"
          for="fileElem"
        >
          <video
            :id="videoDomId"
            :ref="videoDomId + '_0'"
            style="border: 0px solid red; height: 0px; width: 0px"
          >
            <source id="dz_vPlhSrc" src="" type="" />
          </video>
          <span
            v-if="cmpClass != 'mobile_drop_zone'"
            class="dlg_uploadVideo_3_text"
            >Upload Video <br />(Optional)</span
          >

          <input
            type="file"
            id="fileElem"
            @change="onFileChange"
            :accept="props.acceptTypes"
          />
          <!-- </button> -->
          <!-- </div> -->
        </label>
        <!-- 0b -->
      </slot>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
const emit = defineEmits(["files-dropped"]);
const props = defineProps({
  videoDomId: { type: String, required: true },
  acceptTypes: { type: String, required: false },
  cmpClass: { type: String, required: false },
});

// Create `active` state and manage it with functions
let active = ref(false);
let inActiveTimeout = null;

function setActive() {
  active.value = true;
  clearTimeout(inActiveTimeout); // clear the timeout
}
function setInactive() {
  // wrap it in a `setTimeout`
  inActiveTimeout = setTimeout(() => {
    active.value = false;
  }, 50);
}

function onDrop(e) {
  //console.log("ASW FILE DROPED! 00");
  setInactive(); // add this line too
  emit("files-dropped", [...e.dataTransfer.files]);
  //console.log("ASW FILE DROPED! 01");
}

function preventDefaults(e) {
  console.log("ASW prevent default action :)");
  e.preventDefault();
}

const events = ["dragenter", "dragover", "dragleave", "drop"];

onMounted(() => {
  events.forEach((eventName) => {
    document.body.addEventListener(eventName, preventDefaults);
  });
});

onUnmounted(() => {
  events.forEach((eventName) => {
    document.body.removeEventListener(eventName, preventDefaults);
  });
});

function onFileChange(e) {
  console.log("onFileChange event:", e);
  emit("files-dropped", [...e.target.files]);
}
</script>

<style scoped>
#fileElem {
  display: none;
}

.button_uv {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px;
  border-radius: 100%;
  border: 1px dashed var(--main-gray200-color);

  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;

  transform: translate(0%, 0%);
}

#dz_vPlhSrc {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

#lbl_mobile_drop_zone {
  background-image: url("@/assets/addVideoMobVer.png");
  background-size: 70px;
  background-position: 50% 30px;
  border: 1px dashed var(--main-blue-color);
  background-color: var(--main-blue-color);

  padding: 5px;
}
</style>
