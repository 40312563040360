<template>
  <div class="rpr_cnt" a2="234">
    <div class="dlg_form_container">
      <div class="dlg_c_l1">
        <p class="dlg_form_header">Reset password</p>
      </div>
      <div class="dlg_c_l2">
        <form @submit.prevent="onSubmit">
          <div class="ms_inputbox_0">
            <div class="ms_inputbox_1">
              <input
                v-model="mCurrentUser.email"
                type="text"
                placeholder="test@test.com"
              />
              <fieldset aria-hidden="true"></fieldset>
            </div>
          </div>
          <div id="email_Msg" class="errorMsg"></div>

          <div class="dlg_submit_line">
            <button type="submit" class="dlg_submit_btn pwd_reset">
              Send link to set password
            </button>
          </div>
        </form>
        <div class="dlg_lgn_row">
          <p>
            You have an account?
            <span
              class="gradient01"
              @click="goToLoginPage"
              style="cursor: pointer"
              >Sign in</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { mapState } from "vuex";
import { mapGetters, mapActions } from "vuex";
import Validator from "validatorjs";
//import config from "@/config/config.js";

export default {
  name: "ResetPasswordRequestDialog",
  props: {
    msg: String,
  },
  data() {
    return {
      mCurrentUser: {
        email: "",
      },
    };
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
  },
  methods: {
    //
    goToLoginPage() {
      this.$_log("sl_mob_register] goToLogin");
      this.$router.push({
        name: "sl_mob_login",
        params: {
          usageType: "free",
        },
      });
    },
    //
    doValidation(usr) {
      return new Promise((resolve, reject) => {
        let data = {
          email: usr.email,
        };

        this.$_log("data to validate:", data);

        let rules = {
          email: "required|email",
        };

        for (const [key, value] of Object.entries(data)) {
          console.log(`${key}: ${value}`);
          let o = this.$el.querySelector("#" + key + "_Msg");
          o.innerText = "";
        }

        let validation = new Validator(data, rules);

        if (validation.passes()) {
          this.$_log("all good lets go ahead ....");
          resolve(usr);
        } else {
          this.$_log("ook we have problems ...:", validation);
          let str = "";
          for (const [key, value] of Object.entries(validation.errors.all())) {
            console.log("error key:" + key + "value:" + value);

            let o = this.$el.querySelector("#" + key + "_Msg");
            o.innerText = value;
            o.style.marginBottom = "25px";
            str = str + value + " ";
          }

          this.$_log("problems str:" + str);
          reject(str);
        }
      });
    },
    onSubmit() {
      const usr = {
        email: this.mCurrentUser.email,
      };

      this.doValidation(usr)
        .then(() => {
          this.$store
            .dispatch("userModule/resetPasswordRequest", usr)
            .then(() => {
              this.$_log(
                "ResetPasswordRequest.vue] onSubmit - then registered email:" +
                  usr.email
              );

              this.$router.push({
                name: "home",
              });
            })
            .catch((error) => {
              this.$_log("ResetPasswordRequest onSubmit error:", error);
            });
        })
        .catch((error) => {
          this.$_log(
            "uups form data did not pass validation .... error:",
            error
          );
        });

      //// >>>
      /*
		if (usr.email == "" || usr.email == "test@test.com") {
		  var n = {
			type: "problem",
			message: "Please fill email filed.",
		  };
		  this.addNotificationMessage(n);
		} else {
		  this.$_log("ResetPasswordRequest.vue] onSubmit - User:", usr);
		  this.$store
			.dispatch("userModule/resetPasswordRequest", usr)
			.then(() => {
			  this.$_log(
				"ResetPasswordRequest.vue] onSubmit - then registered email:" +
				  usr.email
			  );
  
			  this.$router.push({
				name: "home",
			  });
			})
			.catch((error) => {
			  this.$_log("ResetPasswordRequest onSubmit error:", error);
			});
		}
		*/
    },
    //// <<<
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("userModule", ["login"]),
  },
};
</script>

<style scoped>
.rpr_cnt {
  /*margin-top: 420px;*/
  display: flex;
  width: 100%;
}
.rpr_cnt .dlg_form_container {
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 25px;
  width: auto;
}

.rpr_cnt .dlg_form_header {
  text-align: center;
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
}

.rpr_cnt .ms_inputbox_0 {
  position: relative;
  padding-bottom: 21px;
}
.rpr_cnt .ms_inputbox_0a {
  position: relative;
  padding-bottom: 5px;
}

.rpr_cnt .dlg_form_container fieldset {
  inset: 0px 0px 0px;
}

.rpr_cnt .dlg_form_container input {
  background-color: white;
}

.rpr_cnt .dlg_msg {
  margin-bottom: 11px;
}

.rpr_cnt .dlg_msg a {
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  line-height: 18px;
  color: var(--main-sec-color);
  padding-right: 30px;
}
.rpr_cnt .dlg_msg a:visited {
  color: var(--main-sec-color);
}

.rpr_cnt .dlg_lgn_row {
  padding-top: 4px;
  padding-bottom: 8px;

  color: var(--main-gray500-color);
}

.rpr_cnt .dlg_lgn_row p {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  text-decoration: none;

  margin: 9px 0px 16px 0px;
}
</style>
