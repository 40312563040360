<template>
  <div v-if="showNotifications" class="notification-container">
    <NotificationBar
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import NotificationBar from "@/components/notificationBar/NotificationBar.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "notificationBar",
  components: {
    NotificationBar,
  },
  data() {
    return {
      showNotifications: false,
    };
  },
  created() {
    if (this.getUserDevicetype != "mobile") {
      this.showNotifications = true;
    }
  },
  computed: {
    ...mapState("notificationModule", ["notifications"]),
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "currentUsername",
      "currentUserId",
      "currentClientId",
      "getUserDevicetype",
    ]),
  },
};
</script>

<style scoped>
.notification-container {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 40px;
  z-index: 10;
  border: 0px solid var(--main-blue-color);
}
</style>
