<template>
  <div class="modal-container" ref="root_moremoods">
    <div class="modal-body">
      <div class="idf_container">
        <!-- <span class="modal-close" @click="emit('cancel')">🗙</span> -->
        <div class="idf_cl1">
          <p class="idf_header" style="text-align: center">{{ title }}</p>
        </div>
        <div class="idf_cl2">
          <div class="mm_textbox">
            {{ message }} In muzaic.studio free you can use free tags only.
            Upgrade to muzaic.studio premium to <b>use premium moods</b>.
          </div>
          <div class="modal-action">
            <button
              class="idf_submit_btn"
              @click="handleConfirmClick"
              style="width: 100%; max-width: 310px; border-radius: 8px"
            >
              {{ blueOnWhiteButtonMsg }}
            </button>
            <button
              class="idf_cancel_btn"
              :disabled="doNotLetHimGo"
              @click="handleCancelClick"
              style="border: 0px; width: 310px"
            >
              {{ whiteOnBlueButtonMsg }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "UpdateCreation",
  props: {
    //
    title: String,
    message: String,
    blueOnWhiteButtonMsg: String,
    whiteOnBlueButtonMsg: String,
  },
  emits: ["confirm", "cancel"],
  data() {
    return {
      upgradeToPremium: false,

      doNotLetHimGo: false,
      responseData: {},
    };
  },
  setup() {
    const root_moremoods = ref(null);
    return {
      root_moremoods,
    };
  },
  computed: {},
  methods: {
    //...mapActions("notificationModule", { addNotificationMessage: "add" }),

    handleConfirmClick() {
      this.upgradeToPremium = true;
      this.$emit("confirm", { upgrade: this.upgradeToPremium });
    },
    handleCancelClick() {
      this.$emit("cancel");
    },
  },
};
</script>

<style src="@/assets/css/internal_dialogform_0.css"></style>
<style>
.modal-button {
  cursor: pointer;
  height: 30px;
  padding: 0 25px;
  border: 2px solid var(--main-blue-color);
  border-radius: 5px;
  background-color: var(--main-blue-color);
  color: #fff;
}
.modal-close {
  cursor: pointer;
  position: relative;
  align-self: end;
  right: -33px;
  top: -17px;
}

/* ================================== */

.modal-container .mm_textbox {
  font-size: 14px;

  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 14px;
}

.mobileL .modal-body {
  width: 100%;
}
.mobileL .idf_container {
  border-radius: 24px;
  padding: 14px 24px 14px 24px;
}

.mobileL .idf_header {
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;

  margin: 4px 0px 5px 0px;
}

.mobileL .modal-action {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
</style>
