<template>
  <div class="loadingInner">
    <div class="spinner">
      <div class="spinner1">
        <div style="width: 200px; height: 200px">
          <svg
            stroke="#60eed0"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g class="spinner_V8m1">
              <circle
                cx="12"
                cy="12"
                r="9.5"
                fill="none"
                stroke-width="1"
              ></circle>
            </g>
          </svg>
        </div>
        <div class="spinnerText">{{ msg }}</div>
        <span
          v-if="showAdditionalMessage == 'studioLight'"
          class="sl_loader_sub_msg"
          >This may take a moment</span
        >
      </div>
    </div>
    <!-- ++++ >> -->
    <div v-if="showAdditionalMessage == 'studioLight'" class="mmi_cnt_loader">
      <div class="sl_am_-container">
        <div class="modal-body">
          <div class="idf_container">
            <div class="idf_cl1">
              <p class="idf_header">Enjoying muzaic.studio?</p>
              <div class="subTitle">
                <span class="gradient02">Sign up</span> to get even more!
              </div>
            </div>
            <div class="idf_cl2">
              <div class="mm_textbox">
                Add <span class="mm_text_b">premium moods</span> <br />
                Use all <span class="mm_text_b">5 parameters</span> <br />
                <span class="mm_text_b">Share</span> and download<br />
              </div>
              <div class="modal-action">
                <button
                  class="idf_cancel_btn idf_cancel_btn_wide"
                  @click="goSignUpPage"
                  style="width: 100%; max-width: 310px; border-radius: 8px"
                >
                  Sign up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ++++ << -->
    <div v-if="bottomMsg != ''" class="loaderFooterText">{{ bottomMsg }}</div>
  </div>
</template>

<script>
export default {
  name: "loaderComponent",
  props: {
    msg: String,
    bottomMsg: String,
    showAdditionalMessage: String,
  },
  data() {
    return {
      dataReady: true,
    };
  },
  methods: {
    //
    goToProRegistration() {
      this.$_log("goToProRegistration ... A ");
      this.$router.push({
        name: "sl_mob_msSubscriptionsView",
        params: { id: 1 },
      });
    },
    //
    goSignUpPage() {
      this.$_log("ASW lets logout!");

      this.$store
        .dispatch("userModule/logout")
        .then(() => {
          this.$router.push({
            name: "sl_mob_register",
            params: { usageType: "free" },
            replace: true,
          });
        })
        .catch((error) => {
          this.$_log(error);
        });
    },
  },
};
</script>

<style>
.gradient02 {
  background: linear-gradient(90deg, #60eed0 0%, #01b4ff 25.48%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mobileL .idf_container {
  border-radius: 24px;
  padding: 14px 24px 24px 24px;
}
.mobileL div.modal-action {
  gap: 24px 10px;
  height: auto;

  padding-top: 10px;
  padding-bottom: 14px;
}

.loadingInner > .loaderFooterText {
  height: 100px;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--main-bg-color);

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.spinner {
  color: var(--main-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinnerText {
  color: var(--main-bg-color);

  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
}

.spinner1 > svg {
  stroke: var(--main-secLight-color);
}

.spinner_V8m1 {
  transform-origin: center;
  animation: spinner_zKoa 2s linear infinite;

  width: 200px;
  height: 200px;
  stroke: var(--main-secLight-color);
}
.spinner_V8m1 circle {
  stroke-linecap: round;
  animation: spinner_YpZS 1.5s ease-in-out infinite;
}
@keyframes spinner_zKoa {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner_YpZS {
  0% {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }
  47.5% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -16;
  }
  95%,
  100% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -59;
  }
}
/* SL ########################################### */
.mobileL .loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background-color: rgba(67, 80, 96, 0.9);*/
  background-color: rgba(0, 0, 0, 0.8);
  opacity: inherit;
  z-index: 1000;
}
.mobileL .loadingInner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 350px;
  border-top: 1px solid var(--main-bg-color);
  height: 100%;
  width: 100%;
}
.mobileL .spinner1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobileL .spinnerText {
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
}
.mobileL .sl_loader_sub_msg {
  font-weight: 400;
  font-size: 14px;

  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}

/** */

.mobileL .mm_textbox {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  margin: 15px 0px 15px 0px;
}
.mm_text_b {
  color: var(--main-blue-color);
}

.mobileL .modal-body {
  width: 100%;
}

.mmi_cnt_loader {
  padding: 16px 16px 16px 16px;
  border: 0px solid red;

  display: flex;
  justify-content: center;
  align-items: center;
}
.sl_am_-container {
  width: 100%;
}

.mobileL .sl_am_-container .idf_header {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin: 26px 0px 0px 0px;
}

.mobileL .modal-action {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.mobileL .subTitle {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin: 2px 0px 15px 0px;
}
</style>
