<template>
  <div v-if="dataReady" class="dlg_c_0">
    Your account is confirmed. Go to
    <router-link to="/">login page</router-link>
  </div>
  <div v-else>not confirmed yet ....</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import userSrv from "@/services/userService.js"; // access to the APIs

export default {
  name: "confirmAccount",
  components: {},
  props: ["token"],
  data() {
    return {
      dataReady: false,
      accountConfirmed: false,
    };
  },
  created() {
    this.getData((err, r) => {
      if (err) {
        this.$_log(err);
      } else {
        this.$_log("r:", r);
      }
    });
  },
  mounted() {
    if (this.accountConfirmed) {
      this.$router.push({
        name: "login",
      });
    } else {
      // what to do in such a case ???
      setTimeout(() => {
        this.$_log("accountNotConfirmed for some reason ...");
        this.$router.push({ path: "/login/" });
      }, 1000);
    }
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    getData(callback) {
      this.$_log("confirmAccount getData...");

      //
      userSrv
        .confirmUserAccount(this.token)
        .then((response) => {
          this.$_log("asw response.data:", response.data);
          //todo: add notification !!!
          this.accountConfirmed = true;
          var n = {
            type: "success",
            message: "You just correctly confirmed your account.",
          };
          this.addNotificationMessage(n);
          callback(null, "ok");
        })
        .catch((error) => {
          var n;
          this.accountConfirmed = false;
          if (typeof error.response != "undefined") {
            this.$_log("asw error:", error.response.data);
            n = {
              type: "problem",
              message:
                "Account cant be confirmed... error:" + error.response.message,
            };
            this.addNotificationMessage(n);
          } else {
            this.$_log("asw error:", error);
            n = {
              type: "problem",
              message: "Account cant be confirmed.. error:" + error,
            };
            this.addNotificationMessage(n);
          }
        });
      //
    },
  },
};
</script>

<style scoped></style>
