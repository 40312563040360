<template>
  <svg
    v-if="activeTrack == false"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" fill="url(#paint0_linear_1775_780)" />
    <mask
      id="mask0_1775_780"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="64"
      height="64"
    >
      <rect width="64" height="64" fill="url(#paint1_linear_1775_780)" />
    </mask>
    <g mask="url(#mask0_1775_780)">
      <path
        d="M77.8132 -0.476753C80.3264 1.23636 80.3264 5.51915 77.8132 7.23227L3.23976 58.0637C0.72649 59.7768 -2.41509 57.6354 -2.41509 54.2092L-2.41509 -47.4537C-2.41508 -50.8799 0.726502 -53.0213 3.23977 -51.3082L77.8132 -0.476753Z"
        fill="url(#paint2_linear_1775_780)"
        fill-opacity="0.4"
      />
      <path
        d="M45.5113 63.5232C48.0246 65.2364 48.0246 69.5192 45.5113 71.2323L-29.0621 122.064C-31.5754 123.777 -34.717 121.635 -34.717 118.209L-34.717 16.5463C-34.717 13.1201 -31.5754 10.9787 -29.0621 12.6918L45.5113 63.5232Z"
        fill="url(#paint3_linear_1775_780)"
        fill-opacity="0.4"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1775_780"
        x1="-1.58719e-06"
        y1="64"
        x2="72.4735"
        y2="11.7541"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C4CDD5" />
        <stop offset="1" stop-color="#919EAB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1775_780"
        x1="0"
        y1="32"
        x2="64"
        y2="32"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00D8FF" />
        <stop offset="1" stop-color="#60EED0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1775_780"
        x1="112.906"
        y1="-9.42225"
        x2="8.64848"
        y2="1.6071"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F9FAFB" />
        <stop offset="1" stop-color="#F9FAFB" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1775_780"
        x1="80.6038"
        y1="54.5777"
        x2="-23.6534"
        y2="65.6071"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F9FAFB" />
        <stop offset="1" stop-color="#F9FAFB" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>

  <svg
    v-else
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" fill="url(#paint0_linear_1775_1009)" />
    <mask
      id="mask0_1775_1009"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="64"
      height="64"
    >
      <rect width="64" height="64" fill="url(#paint1_linear_1775_1009)" />
    </mask>
    <g mask="url(#mask0_1775_1009)">
      <path
        d="M77.8133 -0.476753C80.3266 1.23636 80.3266 5.51915 77.8133 7.23227L3.23988 58.0637C0.726612 59.7768 -2.41497 57.6354 -2.41497 54.2092L-2.41496 -47.4537C-2.41496 -50.8799 0.726624 -53.0213 3.23989 -51.3082L77.8133 -0.476753Z"
        fill="url(#paint2_linear_1775_1009)"
        fill-opacity="0.4"
      />
      <path
        d="M45.5113 63.5232C48.0246 65.2364 48.0246 69.5192 45.5113 71.2323L-29.0621 122.064C-31.5754 123.777 -34.717 121.635 -34.717 118.209L-34.717 16.5463C-34.717 13.1201 -31.5754 10.9787 -29.0621 12.6918L45.5113 63.5232Z"
        fill="url(#paint3_linear_1775_1009)"
        fill-opacity="0.4"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1775_1009"
        x1="-1.58719e-06"
        y1="64"
        x2="72.4735"
        y2="11.7541"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00D8FF" />
        <stop offset="1" stop-color="#668EFF" stop-opacity="0.79" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1775_1009"
        x1="0"
        y1="32"
        x2="64"
        y2="32"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00D8FF" />
        <stop offset="1" stop-color="#60EED0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1775_1009"
        x1="112.906"
        y1="-9.42225"
        x2="8.6486"
        y2="1.6071"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F9FAFB" />
        <stop offset="1" stop-color="#F9FAFB" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1775_1009"
        x1="80.6038"
        y1="54.5777"
        x2="-23.6534"
        y2="65.6071"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F9FAFB" />
        <stop offset="1" stop-color="#F9FAFB" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "trackIcon",
  props: {
    msg: String,
    activeTrack: Boolean,
  },
  data() {
    return {
      dataReady: true,
    };
  },
};
</script>

<style></style>
