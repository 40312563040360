<template>
  <BaseRadio
    v-for="option in options"
    :key="option.value"
    :label="option.label"
    :value="option.value"
    :name="name"
    :modelValue="modelValue"
    @update:modelValue="$emit('radioUpdated', $event)"
  />
</template>
<script>
import BaseRadio from "@/components/baseFormElements/BaseRadio.vue";

// how to call :
//	<BaseRadioGroup v-model="variableA" :options="OptionsArrayOfObjects" name="variableName"/>
//

export default {
  components: {
    BaseRadio,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>

<style scoped>
.sub_radiogroup > label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.mobileL .sub_radiogroup > label {
  gap: 2px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
