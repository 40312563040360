<template>
  <div class="modal-container">
    <div class="modal-body">
      <div class="idf_container">
        <!-- <span class="modal-close" @click="emit('cancel')">🗙</span> -->
        <h4>{{ question }}</h4>
        <div class="idf_cl2">
          <div class="modal-action">
            <!--
            <button class="modal-button" @click="emit('confirm')">
              Confirm
            </button>
            <button class="modal-button" @click="emit('cancel')">Cancel</button>
		-->
            <button
              id="ec_confirm_btn"
              class="idf_submit_btn"
              @click="emit('confirm')"
              style="width: 83px"
            >
              Confirm
            </button>
            <button
              class="idf_cancel_btn"
              @click="emit('cancel')"
              style="width: 83px"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//import { defineProps, defineEmits } from "vue";
import { defineEmits } from "vue";
const emit = defineEmits(["confirm", "cancel"]);
</script>

<script>
export default {
  name: "ModalDialog",
  props: {
    question: String,
  },
};
</script>

<style src="@/assets/css/internal_dialogform_0.css"></style>
<style>
.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--main-modal-dialog-bkg);
  z-index: 100;
}

.modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.modal-action {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
}
.modal-button {
  cursor: pointer;
  height: 30px;
  padding: 0 25px;
  border: 2px solid var(--main-blue-color);
  border-radius: 5px;
  background-color: var(--main-blue-color);
  color: #fff;
}

.modal-button:disabled {
  background-color: var(--disabled-color);
  border-color: var(--disabled-color);
  box-shadow: none;
  cursor: default;
}

.modal-close {
  cursor: pointer;
  position: relative;
  align-self: end;
  right: -33px;
  top: -17px;
}
</style>
