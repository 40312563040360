import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueGtag from "vue-gtag";
//import "vue3-toastify/dist/index.css";

import posthogPlugin from "./plugins/posthog";
import "nprogress/nprogress.css";
import * as ConfirmDialog from "vuejs-confirm-dialog"; //ASW dialog boxes ...
import asw_toolbox from "@/plugins/toolBox.js";

const vueApp = createApp(App)
  .use(posthogPlugin)
  .use(asw_toolbox)
  .use(ConfirmDialog)
  .use(store)
  .use(router)
  .use(
    VueGtag,
    {
      appName: "MuzaicStudio",
      pageTrackerScreenviewEnabled: true,
      config: { id: "G-VBE97LZ1PC" },
    },
    router
  )
  .mount("#app");

// 2023-03 ASW => im exporting this object to be able to
// access global app variables and functions like '$_log'
// from components router and services...
export { vueApp };
