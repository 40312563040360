<template>
  <nav
    v-if="isUserLoggedIn"
    class="user_prof_nav_sl"
    style="position: relative"
  >
    <dropdownMenu
      name="userProfile_SL"
      :title="title"
      :items="UserProfileServices"
      activeElement="avatar"
    />
  </nav>
</template>

<script>
import dropdownMenu from "@/components/navigation/DropdownMenu_Layout.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserProfileNavBar",
  props: { title: String },
  data() {
    return {
      UserProfileServices: [
        /*
        {
          id: "mysub_upgrade",
          title: "Upgrade", //Subscriptions
          link: "msSubscriptionsView",
          icon: "doc.png",
          fun: () => {
            this.$router.push({
              name: "msSubscriptionsView",
              //params: { id: Number(this.currentUserId) },
              params: { id: 1 }, // id = 1 means context to display data is StudioLight, id=0 -> StudioMedium
            });
          },
        },
        */
        {
          title: "Log Out",
          link: "logout",
          icon: "logout.png", //"Logout.svg",
          displayAtWideScreen: true,
          fun: () => {
            this.$_log("ASW lets logout!");

            this.$store
              .dispatch("userModule/logout")
              .then(() => {
                this.$router.push({
                  //name: "login",
                  name: "sl_mob_welcome",
                  replace: true,
                });
              })
              .catch((error) => {
                this.$_log(error);
              });
          },
        },
      ],
    };
  },
  components: {
    dropdownMenu,
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "userType",
      "currentClientId",
      "currentClientFN",
      "currentClientLN",
    ]),
  },
};
</script>

<style scoped>
.user_prof_nav {
  display: flex;
  color: var(--main-gray900-color);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
