<template>
  <div v-if="this.importingData == true" class="loading">
    <loader
      msg="Composing in progress"
      bottomMsg="Here you will find your new soundtrack"
    />
  </div>

  <div id="videoFullScreenContainer" style="display: none">
    <div id="videoFullScreen_close">
      <a @click="toggleFullScreen()">
        <img src="@/assets/closeWhite.png" />
      </a>
    </div>
    <div id="videoFullScreen_c1">
      <video id="vPlh_fs" ref="vPlh_0_fs">
        <source id="vPlhSrc_fs" src="" type="" />
      </video>
    </div>
  </div>

  <div ref="root_cmp" class="root_cmp">
    <div id="cmpr">
      <template v-if="dataReady">
        <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ >>> -->
        <div class="mc2">
          <!-- +++++ >>> -->
          <form @submit.prevent="onSubmit">
            <div class="cmprHeader">
              <div class="cmprHeaderTitle truncate130px">
                <template v-if="mobileSize">{{ trackName }}</template>
                <template v-else>Composer</template>
              </div>
              <div class="cmprHeaderInfoContainer">
                <div class="trackInfo">
                  <span>Name:</span>
                  <span class="cmprTextValue">{{ trackName }}</span>
                  <span>Duration:</span>
                  <span class="cmprTextValue">{{ trackDuration }}</span>
                </div>

                <a
                  @click="onEditCreation($event, 0, trackName, trackDuration)"
                  alt="edit creation"
                  class="cmprLink"
                  >Edit</a
                >
              </div>
              <div class="cmprHeaderActionsContainer">
                <button class="cmprReset" @click="resetSettings((e, r) => {})">
                  <!-- <img :src="$getImageUrl('Reset.svg')" /> -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="21"
                    viewBox="0 0 22 21"
                    fill="none"
                  >
                    <path
                      d="M1 2V8H7"
                      stroke="#637381"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.51 12.9999C4.15839 14.8403 5.38734 16.4201 7.01166 17.5013C8.63598 18.5825 10.5677 19.1065 12.5157 18.9944C14.4637 18.8823 16.3226 18.1401 17.8121 16.8797C19.3017 15.6193 20.3413 13.9089 20.7742 12.0063C21.2072 10.1037 21.0101 8.11191 20.2126 6.33105C19.4152 4.55019 18.0605 3.07674 16.3528 2.13271C14.6451 1.18868 12.6769 0.825212 10.7447 1.09707C8.81245 1.36892 7.02091 2.26137 5.64 3.63995L1 7.99995"
                      stroke="#637381"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p id="cmprBtnText_Reset">Reset</p>
                </button>
                <button
                  v-if="receivedPlaylistsFlag == true"
                  class="cmprExport"
                  @click="downloadElement()"
                >
                  <!-- <img :src="$getImageUrl('Download.svg')" /> -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13"
                      stroke="#FFFFFF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 8L10 13L15 8"
                      stroke="#FFFFFF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 13V1"
                      stroke="#FFFFFF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p id="cmprBtnText_Export">Export</p>
                </button>

                <button v-else class="cmpre_disabled">
                  <!-- <img :src="$getImageUrl('Download.svg')" /> -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="#919eab"
                  >
                    <path
                      d="M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 8L10 13L15 8"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 13V1"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p id="cmprBtnText_Export">Export</p>
                </button>
              </div>
            </div>
            <hr class="cmprHr" />
            <div class="cmprDetails">
              <div class="cmprDetailsLeft">
                <div class="cmprTags">
                  <div class="cmprTagsHeader">
                    <h6>Mood</h6>
                    <div class="cmprSearchBlock">
                      <div class="cmprSearchText">
                        <input
                          id="tagFilter"
                          placeholder="Search..."
                          type="text"
                          class="cmprTxtInput"
                          @input="filterTags()"
                        /><button class="cmprSearchBtn" @click="filterTags()">
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M6.33333 11.6667C9.27885 11.6667 11.6667 9.27885 11.6667 6.33333C11.6667 3.38781 9.27885 1 6.33333 1C3.38781 1 1 3.38781 1 6.33333C1 9.27885 3.38781 11.6667 6.33333 11.6667Z"
                              stroke="#C4CDD5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M13 13.0001L10.1 10.1001"
                              stroke="#C4CDD5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="streamTagTabContainer">
                    <div class="streamTagElBlk">
                      <template
                        v-for="(tagRow, tr_index) in prepareArrayForTemplate(
                          'tags'
                        )"
                        :key="tr_index"
                      >
                        <template v-for="(t, t_index) in tagRow" :key="t_index">
                          <div class="">
                            <button
                              class="streamButton tags non-clicked"
                              @click="checkTagRelations($event, t.id)"
                              :value="t.id"
                              :id="'btnTag_' + t.id"
                            >
                              <div v-if="alreadySelected(t.id)">
                                <div class="svgContainerClicked">
                                  <svg
                                    width="12"
                                    heigth="9"
                                    viewBox="0 0 12 9"
                                    fill="none"
                                  >
                                    <path
                                      d="M11 0.833252L4.125 8.33325L1 4.92416"
                                      stroke="white"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                              <div v-else>
                                <div class="svgContainer">
                                  <svg
                                    focusable="false"
                                    aria-hidden="true"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                                      stroke="none"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                              {{ capitalize(t.name) }}
                            </button>
                          </div>
                        </template>
                      </template>
                    </div>
                  </div>
                  <div class="showMore">
                    <template v-if="!tagsMore"
                      ><a @click="toggleMore('tags')" class="cmprLink"
                        >Show more</a
                      ></template
                    ><template v-else
                      ><a @click="toggleMore('tags')" class="cmprLink"
                        >Hide</a
                      ></template
                    >
                  </div>
                </div>
                <div class="cmprMotives">
                  <div class="cmprMotivesHeader">
                    <h6>Motive</h6>
                    <div class="cmprSearchBlock">
                      <div class="cmprSearchText">
                        <input
                          id="motiveFilter"
                          placeholder="Search..."
                          type="text"
                          class="cmprTxtInput"
                          @input="filterMotives()"
                        /><button
                          class="cmprSearchBtn"
                          @click="filterMotives()"
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M6.33333 11.6667C9.27885 11.6667 11.6667 9.27885 11.6667 6.33333C11.6667 3.38781 9.27885 1 6.33333 1C3.38781 1 1 3.38781 1 6.33333C1 9.27885 3.38781 11.6667 6.33333 11.6667Z"
                              stroke="#C4CDD5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M13 13.0001L10.1 10.1001"
                              stroke="#C4CDD5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="streamMotiveTabContainer">
                    <!--  <table> -->
                    <div class="streamMotiveElBlk">
                      <template
                        v-for="(tagRow, tr_index) in prepareArrayForTemplate(
                          'motives'
                        )"
                        :key="tr_index"
                      >
                        <!-- <tr> -->

                        <template v-for="(t, t_index) in tagRow" :key="t_index">
                          <!-- <td> -->

                          <div class="motiveButtonContainer">
                            <button
                              :class="
                                'streamButton ' +
                                isMotiveSelected(t.id) +
                                ' motives'
                              "
                              @click="checkEnabledMotives($event, t.id)"
                              :value="t.id"
                              :id="'btnMotive_' + t.id"
                            >
                              <template
                                v-if="isMotiveSelected(t.id) != 'clicked'"
                              >
                                <div>
                                  <div class="disabledAudBtn blue"></div>
                                </div>
                              </template>
                              <template v-else>
                                <aswAudioPlayer
                                  v-if="typeof t.demoFileName != 'undefined'"
                                  :id="t.id"
                                  :audioList="[
                                    {
                                      name: t.demoFileName,
                                      url: t.demoFile,
                                    },
                                  ]"
                                  playerType="miniPlayer"
                                  playerStyle="blue"
                                  playerVolume="0.5"
                                  @playClicked="handlePlayClicked"
                                  @trackEnds="handleTrackEnds"
                                  @playerError="handlePlayerError"
                                />
                              </template>

                              <div class="motiveDescCnt">
                                <div class="motiveDescName">{{ t.name }}</div>
                                <div class="motiveDescStyle">
                                  {{ t.style }}
                                </div>
                              </div>
                            </button>
                          </div>
                          <!-- </td> -->
                        </template>

                        <!-- </tr> -->
                      </template>
                    </div>
                    <!-- </table> -->
                  </div>
                  <div class="showMore">
                    <template v-if="!motivesMore"
                      ><a @click="toggleMore('motives')" class="cmprLink"
                        >Show more</a
                      ></template
                    ><template v-else
                      ><a @click="toggleMore('motives')" class="cmprLink"
                        >Hide</a
                      ></template
                    >
                  </div>
                </div>
              </div>
              <div class="cmprDetailsRight">
                <div class="cmprAdjust">
                  <!-- sliders >> -->
                  <div class="cmprAdjustHeader">
                    <h6>Adjust</h6>
                  </div>
                  <!--	
                	<div class="cmprAdjustTypeTab">
                 		<button>Sliders</button>
                  		<button>Graph</button>
                	</div>
					-->
                  <div class="streamParamTabContainer">
                    <table>
                      <tr>
                        <td></td>
                        <td class="sliderTitle">Intensity</td>
                        <td></td>
                      </tr>
                      <tr class="sliderRow">
                        <td class="sliderDescription sd_l">relaxed</td>
                        <td class="sliderCell">
                          <Slider
                            v-model="intensitySlider"
                            :min="parseInt(1)"
                            :max="parseInt(9)"
                            :tooltips="false"
                            showTooltip="drag"
                          />
                        </td>
                        <td class="sliderDescription sd_r">intense</td>
                      </tr>

                      <tr>
                        <td></td>
                        <td class="sliderTitle">Tempo</td>
                        <td></td>
                      </tr>
                      <tr class="sliderRow">
                        <td class="sliderDescription sd_l">slow</td>
                        <td class="sliderCell">
                          <Slider
                            v-model="tempoSlider"
                            :min="parseInt(1)"
                            :max="parseInt(9)"
                            :tooltips="false"
                            showTooltip="drag"
                          />
                        </td>
                        <td class="sliderDescription sd_r">fast</td>
                      </tr>

                      <tr>
                        <td></td>
                        <td class="sliderTitle">Rhythm</td>

                        <td></td>
                      </tr>
                      <tr class="sliderRow">
                        <td class="sliderDescription sd_l">loose</td>
                        <td class="sliderCell">
                          <Slider
                            v-model="rythmSlider"
                            :min="parseInt(1)"
                            :max="parseInt(9)"
                            :tooltips="false"
                            showTooltip="drag"
                          />
                        </td>
                        <td class="sliderDescription sd_r">rhythmic</td>
                      </tr>

                      <tr>
                        <td></td>
                        <td class="sliderTitle">Tone</td>
                        <td></td>
                      </tr>
                      <tr class="sliderRow">
                        <td class="sliderDescription sd_l">dark</td>
                        <td class="sliderCell">
                          <Slider
                            v-model="toneSlider"
                            :min="parseInt(1)"
                            :max="parseInt(9)"
                            :tooltips="false"
                            showTooltip="drag"
                          />
                        </td>
                        <td class="sliderDescription sd_r">bright</td>
                      </tr>

                      <tr>
                        <td></td>
                        <td class="sliderTitle">Variation</td>
                        <td></td>
                      </tr>
                      <tr class="sliderRow">
                        <td class="sliderDescription sd_l">stable</td>
                        <td class="sliderCell">
                          <Slider
                            v-model="variationSlider"
                            :min="parseInt(1)"
                            :max="parseInt(9)"
                            :tooltips="false"
                            showTooltip="drag"
                          />
                        </td>
                        <td class="sliderDescription sd_r">varied</td>
                      </tr>
                    </table>
                  </div>
                  <!-- sliders << -->
                </div>
                <div class="cmprCompose">
                  <div>
                    <button
                      v-if="showCreateButton == true"
                      class="streamButton non-clicked"
                      @click="getSingleFile($event)"
                      value="getSingleFile"
                      :id="'btnPlt_'"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7.4391 2.84869V15.1431C6.74014 14.7377 5.92969 14.5044 5.06519 14.5044C2.44724 14.5044 0.317368 16.6342 0.317368 19.2522C0.317368 21.8701 2.44724 24 5.06519 24C7.68314 24 9.81301 21.8701 9.81301 19.2522V4.79483L19.3087 2.8957V12.7692C18.6098 12.3638 17.7992 12.1304 16.9347 12.1304C14.3168 12.1304 12.1869 14.2603 12.1869 16.8783C12.1869 19.4962 14.3168 21.6261 16.9347 21.6261C19.5527 21.6261 21.6826 19.4962 21.6826 16.8783V0L7.4391 2.84869ZM5.06519 21.6261C3.75621 21.6261 2.69128 20.5612 2.69128 19.2522C2.69128 17.9432 3.75621 16.8783 5.06519 16.8783C6.37416 16.8783 7.4391 17.9432 7.4391 19.2522C7.4391 20.5612 6.37416 21.6261 5.06519 21.6261ZM16.9347 19.2522C15.6258 19.2522 14.5608 18.1872 14.5608 16.8783C14.5608 15.5693 15.6258 14.5044 16.9347 14.5044C18.2437 14.5044 19.3087 15.5693 19.3087 16.8783C19.3087 18.1872 18.2437 19.2522 16.9347 19.2522Z"
                          fill="white"
                        ></path>
                      </svg>
                      Compose
                    </button>

                    <button
                      v-if="showSaveButton == true"
                      class="streamButton non-clicked"
                      @click="doSubmit()"
                      :id="'btnSv_'"
                    >
                      save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- ###################################### -->
          </form>
          <!-- +++++ <<< -->
        </div>
        <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ <<< -->
      </template>
    </div>
    <!-- ++++++++++++++++++++++++++++++++ >>> -->
    <div id="cmpr_footer" ref="footer_cmpr">
      <div class="mc2">
        <!--

			<FooterPlayer
			
				:videoImage="videoImage"
				:receivedPlaylistsFlag="receivedPlaylistsFlag"
				:trackName="trackName"
				:audioList="audioList"
			/>

		-->
        <!-- !!!! >>>  -->
        <div class="cmprDetailsPlayer">
          <div>
            <div v-if="this.videoImage == ''" id="img_container">
              <trackIcon :activeTrack="receivedPlaylistsFlag" />
            </div>
            <div v-else id="video_container">
              <div id="fs_ico" @click="toggleFullScreen()"></div>
              <video id="vPlh" ref="vPlh_0">
                <source id="vPlhSrc" src="" type="" />
              </video>
            </div>
            <div id="imgc_txt">
              <Popper arrow :hover="true" :content="trackName">
                <div class="truncate130px">{{ trackName }}</div>
              </Popper>
            </div>
          </div>

          <div>
            <aswAudioPlayer
              v-if="receivedPlaylistsFlag"
              id="mainPlayer"
              :audioList="audioList"
              :playerVolume="prepareVolume(volumeSlider)"
              @playClicked="handlePlayClicked"
              @trackEnds="handleTrackEnds"
              @trackPause="handleTrackPause"
              @progressDragged="handleProgressDragged"
              playerStyle="wb"
              playerType="normalPlayer"
            />
            <div v-else id="playerInactive">
              <div id="playInactive"></div>
              <div id="playDetailsInactive">
                <div id="timeStartInactive">--:--:--</div>
                <div id="progressInactive"></div>
                <div id="timeEndInactive">--:--:--</div>
              </div>
            </div>
          </div>
          <div class="trackVolumeController">
            <template v-if="receivedPlaylistsFlag">
              <svg
                id="trackVolumeIco"
                v-if="audioVolume != 0"
                width="28"
                height="24"
                viewBox="0 0 28 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="toggleMute()"
                class="volumeIco"
              >
                <g clip-path="url(#clip0_1775_1000)">
                  <path
                    d="M24.2747 4.29013C24.1663 4.19722 24.0376 4.12352 23.896 4.07323C23.7544 4.02295 23.6026 3.99707 23.4493 3.99707C23.296 3.99707 23.1442 4.02295 23.0026 4.07323C22.861 4.12352 22.7323 4.19722 22.6239 4.29013C22.5155 4.38304 22.4295 4.49334 22.3708 4.61473C22.3122 4.73612 22.282 4.86623 22.282 4.99763C22.282 5.12902 22.3122 5.25913 22.3708 5.38052C22.4295 5.50192 22.5155 5.61222 22.6239 5.70513C24.5687 7.37603 25.6609 9.6399 25.6609 12.0001C25.6609 14.3604 24.5687 16.6242 22.6239 18.2951C22.405 18.4828 22.282 18.7373 22.282 19.0026C22.282 19.268 22.405 19.5225 22.6239 19.7101C22.8428 19.8978 23.1397 20.0032 23.4493 20.0032C23.7589 20.0032 24.0558 19.8978 24.2747 19.7101C26.6568 17.6637 27.9946 14.8909 27.9946 12.0001C27.9946 9.10932 26.6568 6.33656 24.2747 4.29013Z"
                    fill="white"
                  />
                  <path
                    d="M21.1166 7.29092C21.0082 7.19768 20.8795 7.12366 20.7377 7.07307C20.5959 7.02248 20.4439 6.99633 20.2903 6.9961C20.1368 6.99586 19.9847 7.02156 19.8427 7.07172C19.7007 7.12187 19.5716 7.19551 19.4629 7.28842C19.3541 7.38133 19.2677 7.49169 19.2087 7.61321C19.1497 7.73473 19.1192 7.86502 19.1189 7.99665C19.1186 8.12828 19.1486 8.25866 19.2071 8.38036C19.2656 8.50206 19.3515 8.61268 19.4599 8.70592C20.4781 9.58001 21.05 10.7647 21.05 11.9999C21.05 13.2351 20.4781 14.4198 19.4599 15.2939C19.3515 15.3872 19.2656 15.4978 19.2071 15.6195C19.1486 15.7412 19.1186 15.8716 19.1189 16.0032C19.1192 16.1348 19.1497 16.2651 19.2087 16.3866C19.2677 16.5081 19.3541 16.6185 19.4629 16.7114C19.6825 16.8991 19.9802 17.0042 20.2903 17.0037C20.4439 17.0035 20.5959 16.9774 20.7377 16.9268C20.8795 16.8762 21.0082 16.8022 21.1166 16.7089C22.5718 15.4592 23.3891 13.7656 23.3891 11.9999C23.3891 10.2342 22.5718 8.54066 21.1166 7.29092Z"
                    fill="white"
                  />
                  <path
                    d="M16.1233 0.200111C12.5759 0.771379 9.42046 2.48996 7.31033 5.00011H5.83333C4.287 5.00223 2.8047 5.52969 1.71127 6.46691C0.617842 7.40414 0.00246822 8.67468 0 10.0001L0 14.0001C0.00246822 15.3255 0.617842 16.5961 1.71127 17.5333C2.8047 18.4705 4.287 18.998 5.83333 19.0001H7.31033C9.42088 21.5099 12.5761 23.2284 16.1233 23.8001C16.1929 23.8118 16.2636 23.8175 16.3345 23.8171C16.6439 23.8171 16.9407 23.7118 17.1595 23.5242C17.3783 23.3367 17.5012 23.0823 17.5012 22.8171V1.18611C17.5016 1.03925 17.4643 0.894101 17.3919 0.760993C17.3195 0.627886 17.2137 0.510084 17.0822 0.415959C16.9507 0.321834 16.7966 0.253698 16.6308 0.216392C16.4651 0.179087 16.2918 0.173528 16.1233 0.200111ZM15.1667 21.5351C12.5934 20.832 10.3809 19.3914 8.9005 17.4551C8.79473 17.3156 8.65004 17.201 8.47953 17.1215C8.30902 17.0421 8.11808 17.0003 7.924 17.0001H5.83333C4.90508 17.0001 4.01484 16.684 3.35846 16.1214C2.70208 15.5588 2.33333 14.7958 2.33333 14.0001V10.0001C2.33333 9.20446 2.70208 8.4414 3.35846 7.87879C4.01484 7.31618 4.90508 7.00011 5.83333 7.00011H7.93333C8.1272 7.00009 8.318 6.95866 8.48849 6.87957C8.65898 6.80048 8.80379 6.68621 8.90983 6.54711C10.3872 4.61135 12.5964 3.17006 15.1667 2.46511V21.5351Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1775_1000">
                    <rect width="28" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <img
                v-else
                src="@/assets/mute.png"
                @click="toggleMute()"
                class="volumeIco"
                width="28"
                height="24"
              />

              <div id="volumeActive">
                <Slider
                  v-model="volumeSlider"
                  :min="parseInt(0)"
                  :max="parseInt(100)"
                  :tooltips="false"
                  @change="
                    ($event) => {
                      updateAudioVolume($event);
                    }
                  "
                />
              </div>
            </template>
            <template v-else>
              <div id="muteInactive"></div>
              <div id="volumeInactive"></div>
            </template>
          </div>
        </div>
        <!-- !!!! <<<< -->
      </div>
    </div>
    <!-- ++++++++++++++++++++++++++++++++ <<< -->
  </div>
</template>

<script>
import { ref, watch } from "vue";
//import { watchEffect } from "vue";
import { mapGetters, mapActions } from "vuex";
import Slider from "@vueform/slider";

import streamSrv from "@/services/streamService.js";
import aswAudioPlayer from "@/components/elements/audioPlayer.vue";
//import BaseInput from "@/components/baseFormElements/BaseInput.vue";
//import useConfirmBeforeAction from "@/features/useConfirmBeforeAction.js";
import useUpdateCreation_BeforeAction from "@/features/useUpdateCreation_BeforeAction.js";
import trackIcon from "@/components/elements/svg/trackIcon.vue";
import loader from "@/components/elements/loader.vue";
import Popper from "vue3-popper";

import config from "@/config/config.js";

export default {
  name: "createMusicDetails",

  setup() {
    const root_cmp = ref(null);
    return {
      root_cmp,
    };
  },

  components: {
    //BaseInput,
    Slider,
    aswAudioPlayer,
    trackIcon,
    loader,
    Popper,
  },
  data() {
    return {
      trackDuration: "",
      trackName: "",
      videoImage: "",
      video: "",

      // --------------------------------
      // -- audio player
      currentAudioName: "",
      audioList: [],
      // ---
      name: "",
      remarks: "",
      image: "",
      nrTagColumns: 4,
      nrTagRows: 4,
      nrMotiveColumns: 2,
      nrMotiveRows: 2,
      TagsToBeDisplayed: null,
      MotivesToBeDisplayed: null,
      selectedTags: [],
      selectedMotives: [],
      receivedPlaylistsObj: {},
      receivedPlaylistsFlag: false,

      intensitySlider: 5,
      tempoSlider: 5,
      rythmSlider: 5,
      toneSlider: 5,
      variationSlider: 5,

      submitButton: { target: "" },
      dataReady: false,
      ClientId: 0,
      showSaveButton: false,
      showCreateButton: false,
      disabledTags: [],
      importingData: false, // this flag triggers loading icon
      containerDom: null,

      submissionCounter: 0,
      defaultMotiveId: 1,
      filePrepared: false,

      videoIsPlaying: false,
      videoFullScreen: false,
      tagsMore: false,
      motivesMore: false,
      volumeSlider: 50,
      audioVolume: null,
      mobileSize: false,

      audioPlaying: true,
      audioPlayingId: -1,
    };
  },

  created() {
    this.init();

    if (this.trackDuration == "" && this.trackName == "") {
      this.$router.push({
        name: "createMusicHeaderView",
      });
    } else {
      this.$_log("views stream00:add new");
      this.ClientId = this.currentClientId;
      /*
      watchEffect(() => {
        this.$_log("watchEffect ....");
        this.getComponentData((err, r) => {
          if (err) {
            this.$_log("error:",err);
          } else {
            this.postGetComponentData(r);
          }
        });
      });
	  */
      this.getComponentData((err, r) => {
        if (err) {
          this.$_log("error:", err);
        } else {
          this.postGetComponentData(r);
        }
      });

      watch(
        () => [this.selectedTags],
        (new_selectedTags, old_selectedTags) => {
          this.$_log(
            "watch()  old_selectedTags:" +
              old_selectedTags +
              " new_selectedTags:" +
              new_selectedTags
          );
          this.getComponentData((err, r) => {
            if (err) {
              this.$_log("error:", err);
            } else {
              this.postGetComponentData(r);
            }
          });
        }
      );
    }
  },
  mounted() {
    this.$_log("1 this.root_cmp", this.root_cmp);
    this.containerDom = this.root_cmp;
    window.addEventListener("resize", this.asw_WindowResizeHandler);
    this.$_log("OOOOOOk its time to go :)");
  },
  unmounted() {
    window.removeEventListener("resize", this.asw_WindowResizeHandler);
  },
  updated() {
    // UI JUST UPDATED
    this.$_log("UI JUST UPDATED ");
    //if (this.selectedMotives[0] != this.MotivesToBeDisplayed[0][0].id)
    //  this.setDefaultMotive(this.MotivesToBeDisplayed[0][0].id);
  },
  computed: {
    ...mapGetters("userModule", [
      "userToken",
      "currentUserId",
      "currentUsername",
      "currentUserEmail",
      "currentClientId",
    ]),
    ...mapGetters("streamModule", [
      "streamName",
      "streamImage",
      "streamParams",
      "streamTags",
      "streamMotives",
      "streamPlaylists",
      "nowPlayingTrack",
      "receivedTags",
      "receivedMotives",
      "receivedPlaylists",
      "streamDuration",
      "streamVideo",
    ]),
    /*
    ...mapGetters("clientModule", {
      currentClientId: "getCurrentId",
      currentClientName: "getCurrentName",
    }),
	*/
  },

  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    //...mapActions("streamModule", { initStream: "initStream" }),
    ...mapActions("streamModule", [
      "initStream",
      "musicTags",
      "musicMotives",
      "musicPlaylists",
      "setStreamTags",
      "setStreamMotives",
    ]),
    ...mapActions("clientModule", {
      setCurCliId: "setCurrentId",
      setCurCliName: "setCurrentName",
    }),

    // --------------------------------------------------------------------
    asw_WindowResizeHandler(e) {
      console.log("ASW asw_WindowResizeHandler e:", e);
      console.log(
        "ASW asw_WindowResizeHandler clientWidth:" + e.target.outerWidth
      );
      let w = this.getWindowSize();
      console.log("ASW asw_WindowResizeHandler w:" + w);
    },

    // --------------------------------------------------------------------
    getWindowSize() {
      let width = document.body.clientWidth;
      console.log("ASW asw_WindowResizeHandler clientWidth:" + width);
      if (width < 640) {
        this.mobileSize = true;
      } else {
        this.mobileSize = false;
      }
      return width;
    },

    // also in globalFooterPlayer --------------------------------------------------------------------
    toggleFullScreen() {
      this.$_log("lets toggleFullScreen");

      let vFSc = document.querySelector("#videoFullScreenContainer");
      console.log("AAAAAA");
      if (this.videoFullScreen === false) {
        console.log("BBBBBB");
        vFSc.style = "display: grid";
        this.videoFullScreen = true;
        let v = this.containerDom.querySelector("#vPlh");
        let vFS = document.querySelector("#vPlh_fs");
        console.log("CCCCCC vFS:", vFS);
        console.log(
          "CCCCCC vFS.paused:" +
            vFS.paused +
            " vFS.currentTime:" +
            vFS.currentTime +
            " v.currentTime:" +
            v.currentTime
        );

        if (v.currentTime == vFS.currentTime && v.currentTime == 0) {
          this.$_log("lets toggleFullScreen lets load video ....");
          vFS.src = URL.createObjectURL(this.video);
          vFS.load();
        }

        vFS.volume = 0.0;
        vFS.currentTime = v.currentTime;

        this.$_log(
          "lets toggleFullScreen this.videoIsPlaying:" + this.videoIsPlaying
        );
        if (this.videoIsPlaying == true) {
          this.$_log(
            "lets toggleFullScreen lets play video in full screen mode"
          );
          vFS.play();
        }

        //vFS.src = URL.createObjectURL(this.video);
      } else {
        this.videoFullScreen = false;
        vFSc.style = "display: none";
      }
    },
    // globalFooterPlayer --------------------------------------------------------------------
    prepareVolume(vol) {
      if (typeof vol == "undefined") {
        vol = "1.0";
      }
      this.$_log("prepareVolume volume:" + vol);
      let v = parseInt(vol, 10) / 100;
      if (v === 1) {
        v = "1.0";
      }
      if (v === 0) {
        v = "0.0";
      }
      let T = v.toString().split(".");
      v = T[0] + "." + T[1][0];
      return v;
    },

    // globalFooterPlayer --------------------------------------------------------------------
    updateAudioVolume(vol) {
      this.audioVolume = this.prepareVolume(vol);
      let audio = this.containerDom.querySelector("#pab_mainPlayer");

      this.$_log("current volume:" + audio.volume);
      this.$_log("new volume should be:" + this.audioVolume);
      audio.volume = this.audioVolume;
      this.$_log("new volume IS:" + audio.volume);
    },

    // globalFooterPlayer --------------------------------------------------------------------
    toggleMute() {
      let audio = this.containerDom.querySelector("#pab_mainPlayer");
      if (typeof audio.volume == "undefined") {
        audio.volume = 0.5;
      }
      this.$_log("A current volume:" + audio.volume);
      if (parseFloat(audio.volume) > 0) {
        audio.volume = this.prepareVolume(0);
        this.volumeSlider = 0;
      } else {
        audio.volume = this.prepareVolume(50);
        this.volumeSlider = 50;
      }
      this.$_log("B current volume:" + audio.volume);
      this.audioVolume = audio.volume;
    },

    // --------------------------------------------------------------------
    init() {
      this.$_log("this.streamDuration:" + this.streamDuration);
      if (typeof this.streamDuration == "undefined") {
        this.streamDuration = "";
      }

      this.trackDuration =
        this.streamDuration == null ? "" : this.streamDuration;
      this.trackName = this.streamName == null ? "" : this.streamName;
      this.videoImage = this.streamImage;
      this.video = this.streamVideo;

      this.ClientId = this.currentClientId;
      //this.$_log("BBB", this.video.name);
    },

    // --------------------------------------------------------------------
    toggleMore(type) {
      switch (type) {
        case "tags": {
          if (this.tagsMore == false) {
            this.tagsMore = true;
          } else {
            this.tagsMore = false;
          }
          break;
        }
        case "motives": {
          if (this.motivesMore == false) {
            this.motivesMore = true;
          } else {
            this.motivesMore = false;
          }
          break;
        }
      }
    },

    // --------------------------------------------------------------------
    prepareArrayForTemplate(type) {
      let T = [];

      switch (type) {
        case "tags": {
          T = this.TagsToBeDisplayed;
          this.$_log("this.TagsToBeDisplayed:", this.TagsToBeDisplayed);
          this.$_log("prepareArrayForTemplate ++++ Tags ", T);
          if (this.tagsMore == false) {
            T = T.slice(0, this.nrTagRows);
          }

          break;
        }
        case "motives": {
          T = this.MotivesToBeDisplayed;
          this.$_log("prepareArrayForTemplate ++++ Motives ", T);
          if (this.motivesMore == false) {
            T = T.slice(0, this.nrMotiveRows);
          }
          break;
        }
      }
      this.$_log("prepareArrayForTemplate ====>", T);
      return T;
    },

    // --------------------------------------------------------------------
    fillPlayerObj(trackId, trackName, trackUrl) {
      this.$_log(
        "fillPlayerObj config.api_host :" +
          config.api_host +
          " trackUrl:" +
          trackUrl
      );
      let file_prefix = "";

      return [{ id: trackId, name: trackName, url: file_prefix + trackUrl }];
    },

    // --------------------------------------------------------------------
    filterMotives() {
      this.stopCurrentPlayer();
      this.resetSettings((err) => {
        if (err) {
          this.$_log("error:", err);
        } else {
          this.getComponentData((err, r) => {
            if (err) {
              this.$_log("error:", err);
            } else {
              this.$_log("i just received r:", r);
              this.postGetComponentData(r);
            }
          });
        }
      });
    },

    // --------------------------------------------------------------------
    filterTags() {
      this.stopCurrentPlayer();

      this.resetSettings((err) => {
        if (err) {
          this.$_log("error:", err);
        } else {
          this.$_log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
          this.getComponentData((err, r) => {
            if (err) {
              this.$_log("error:", err);
            } else {
              this.$_log("i just received r:", r);
              this.postGetComponentData(r);
            }
          });
        }
      });
    },

    // --------------------------------------------------------------------
    downloadElement() {
      //
      /*
		this.audioList.push({
                name: "track_" + 0,
                url: r.data.mp3,
                mixMp3: r.data.mixMp3,
              });
		*/
      //
      this.$_log("this.audioList:", this.audioList);

      this.$_log(
        "lets download elem as elemName:" +
          this.audioList[0].name +
          " elemUrl:" +
          this.audioList[0].url
      );

      let fO = this.fillPlayerObj(
        this.newStreamId,
        this.trackName, //this.audioList[0].name,
        this.audioList[0].url
      );
      fO[0].name = fO[0].name.replace(/\s/g, "_") + ".mp3";
      this.$_log("fO:", fO);

      streamSrv.getAudioFile(fO[0].url, fO[0].name);
      //streamSrv.getAudioFile(this.audioList[0].url, this.audioList[0].name);

      //return this.audioList[0].url;
    },

    // --------------------------------------------------------------------
    isMotiveSelected(id) {
      if (this.selectedMotives.length > 0) {
        if (this.selectedMotives[0] == id) {
          return "clicked";
        }
      }

      return "non-clicked";
    },

    // globalFooterPlayer ---------------------------------------------------------
    handleTrackEnds(obj) {
      this.$_log("handleTrackEnds received obj:", obj);
      /*
      if (this.videoImage != "") {
        let v = this.containerDom.querySelector("#vPlh");
        v.pause();
        if (this.videoFullScreen == true) {
          let vFS = document.querySelector("#vPlh_fs");
          vFS.pause();
        }
        this.videoIsPlaying = false;
      }
	  */
      this.stopCurrentPlayer();
    },

    // globalFooterPlayer ---------------------------------------------------------
    handleTrackPause(obj) {
      this.$_log("handleTrackPause received obj:", obj);
      /*
      if (this.videoImage != "") {
        let v = this.containerDom.querySelector("#vPlh");
        v.pause();
        if (this.videoFullScreen == true) {
          let vFS = document.querySelector("#vPlh_fs");
          vFS.pause();
        }
        this.videoIsPlaying = false;
      }
	  */
      this.pauseCurrentPlayer();
    },

    // globalFooterPlayer ---------------------------------------------------------
    pauseCurrentPlayer() {
      this.$_log("pauseCurrentPlayer ....... ");
      if (this.audioPlaying == true) {
        //this.$_log("stopCurrentPlayer yes it works so im stopping it ");
        let playingAudio = this.containerDom.querySelector(
          "#pab_" + this.audioPlayingId
        );
        //this.$_log("playingAudio:", playingAudio);

        if (playingAudio != null) {
          playingAudio.click();
        }
      }

      // should we stop related video ?
      if (this.videoImage != "") {
        let v = this.containerDom.querySelector("#vPlh");
        if (this.videoIsPlaying != false) {
          v.pause();

          if (this.videoFullScreen == true) {
            this.$_log(
              "pauseCurrentPlayer [this.videoFullScreen == true] pause "
            );
            let vFS = document.querySelector("#vPlh_fs");
            vFS.pause();
          }
          this.videoIsPlaying = false;
        }
      }
    },

    // globalFooterPlayer ---------------------------------------------------------
    stopCurrentPlayer() {
      this.$_log("stopCurrentPlayer if it works.... ");
      let vFSplaying = this.videoIsPlaying;

      this.pauseCurrentPlayer();
      if (this.videoImage != "") {
        this.$_log("stopCurrentPlayer this.videoImage != ''  .... ");
        let v = this.containerDom.querySelector("#vPlh");
        if (vFSplaying != false) {
          this.$_log("stopCurrentPlayer vFSplaying != false .... ");
          v.currentTime = 0;

          if (this.videoFullScreen == true) {
            this.$_log(
              "stopCurrentPlayer [this.videoFullScreen == true] reset timer "
            );
            let vFS = document.querySelector("#vPlh_fs");
            vFS.currentTime = 0;
          }
        } else {
          this.$_log("stopCurrentPlayer vFSplaying == false .... ");
        }
      } else {
        this.$_log("stopCurrentPlayer this.videoImage == '' .... ");
      }
    },

    // globalFooterPlayer ---------------------------------------------------------
    handleProgressDragged(e) {
      //this.$_log("handleProgressDragged e:", e);

      let v = this.containerDom.querySelector("#vPlh");
      if (v != null) {
        v.currentTime = e.currentTime;
      }
      let vFS = document.querySelector("#vPlh_fs");
      if (vFS != null) {
        vFS.currentTime = e.currentTime;
      }
    },

    // globalFooterPlayer ---------------------------------------------------------
    handlePlayClicked(elem) {
      // stop other playing track or track with video AND register new playing track
      this.$_log("createMusicDetails] handlePlayClicked elem:", elem);
      let trackChanges = false;
      if (this.audioPlayingId != elem.trackId) {
        trackChanges = true;
        this.$_log(
          "createMusicDetails] track changes lets stop first current player....trackChanges:" +
            trackChanges
        );
        //this.stopCurrentPlayer();
      }

      if (elem.action == "play") {
        this.audioPlaying = true;
        this.audioPlayingId = elem.trackId;
      } else {
        this.audioPlaying = false;
        this.audioPlayingId = -1;
      }

      // should we stop related video ?
      if (this.videoImage != "" && elem.elemId == "pab_mainPlayer") {
        // lets play video
        this.$_log(
          "createMusicDetails] handlePlayClicked ... lets play video 0"
        );
        let v = this.containerDom.querySelector("#vPlh");
        if (this.videoIsPlaying == false) {
          this.$_log(
            "createMusicDetails] handlePlayClicked ... lets play video 1"
          );
          if (v.paused === false) {
            v.src = URL.createObjectURL(this.video);

            v.load();
          }
          v.volume = 0.0;
          v.play();
          this.videoIsPlaying = true;
          this.$_log(
            "createMusicDetails] handlePlayClicked ... this.videoIsPlaying:" +
              this.videoIsPlaying
          );

          if (this.videoFullScreen == true) {
            let vFS = document.querySelector("#vPlh_fs");

            this.$_log(
              "createMusicDetails] handlePlayClicked ... vFS.paused:" +
                vFS.paused +
                " vFS.currentTime:" +
                vFS.currentTime
            );
            //if (vFS.paused === false) {
            if (v.currentTime == vFS.currentTime && v.currentTime == 0) {
              this.$_log(
                "createMusicDetails] handlePlayClicked ... lets Load FS video "
              );
              vFS.src = URL.createObjectURL(this.video);
              vFS.load();
            }

            vFS.volume = 0.0;
            vFS.currentTime = v.currentTime;
            this.$_log(
              "createMusicDetails] handlePlayClicked AND LETS PLAY FOM... vFS.currentTime:" +
                vFS.currentTime +
                " v.currentTime:" +
                v.currentTime
            );
            vFS.play();
          }
        } else {
          //this.stopCurrentPlayer();
          this.pauseCurrentPlayer();
        }
      }
    },
    handlePlayerError(obj) {
      this.$_log("handlePlayClicked received obj:", obj);
    },

    getBase64Img(imgData) {
      return "data:image/jpeg;base64," + imgData;
    },
    Base64ToImage(base64img, callback) {
      var img = new Image();
      img.onload = function () {
        callback(img);
      };
      img.src = base64img;
    },
    // =+++++++>
    ///// AUDIO PLAYER ////
    // Something to do before playing
    handleBeforePlay(next) {
      this.currentAudioName =
        this.audioList[this.$refs.audioPlayer.currentPlayIndex].name;

      next(); // Start playing
    },
    aswHandleBeforeNext(next) {
      this.$_log("++++++++++++++++++++++++++++++++++");
      this.$_log(
        "+++" +
          this.$refs.audioPlayer.currentPlayIndex +
          "/" +
          this.audioList.length
      );
      if (
        this.audioList.length ==
        this.$refs.audioPlayer.currentPlayIndex + 1
      ) {
        this.getMusicPlaylists();
      }
      this.$_log("++++++++++++++++++++++++++++++++++");
      next();
    },

    ///////////////////////
    onFn001(v) {
      this.$_log("onFn001 v.name:" + v.name + " v.value:" + v.value);
      //this.setCurCliId = v.value;
      this.ClientId = v.value;
    },
    collectData(trackUrl) {
      let musicParams =
        this.intensitySlider +
        "" +
        this.tempoSlider +
        "" +
        this.rythmSlider +
        "" +
        this.toneSlider +
        "" +
        this.variationSlider;

      let musicTags = [];
      for (const [key, value] of Object.entries(this.selectedTags)) {
        this.$_log(key);
        musicTags.push(value);
      }

      let musicMotives = [];
      for (const [key, value] of Object.entries(this.streamMotives)) {
        this.$_log(key);
        musicMotives.push(value);
      }

      let receivedTracks = [];
      receivedTracks.push(trackUrl);

      return [musicParams, musicTags, musicMotives, receivedTracks];
    },

    // finally save data to the DB .... =>
    doSubmit(r) {
      this.$_log("crateMusicDetails] getSingleFile doSubmit r:", r);
      const [musicParams, musicTags, musicMotives, receivedTracks] =
        this.collectData(r.data.mixMp3);

      let trackName = this.trackName;
      if (this.submissionCounter > 0) {
        let regex = /__\(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\)/g;
        let R = this.trackName.match(regex);
        this.$_log("0 doSubmit R:", R);
        this.$_log("1 doSubmit this.trackName:" + this.trackName);
        if (R !== null) {
          this.trackName = this.trackName.substring(
            0,
            this.trackName.indexOf("__(")
          );
          this.$_log("2 doSubmit this.trackName:" + this.trackName);
        }
        this.trackName =
          this.trackName +
          "__(" +
          this.$formatDate(new Date(), "yyyy-MM-dd hh:mm:ss") +
          ")";
        this.$_log("3 doSubmit this.trackName:" + this.trackName);
      }
      this.submissionCounter++;
      /////////////////////////////////////
      // DATA TO BE SENT
      const obj = {
        // --
        Client_id: this.currentClientId,
        name: trackName,
        remarks: "(created using ASW version of muzaic.studio) " + this.remarks,
        time: this.$time2sec(this.trackDuration, "mm:ss"),
        params: musicParams,
        tags: musicTags,
        motives: musicMotives,
        receivedTracks: receivedTracks,
        user_id: this.currentUserId,

        length: this.trackDuration,
        image: this.videoImage,
        // --
      };
      /////////////////////////////////////

      this.$_log("stream:add new onSubmit - stream:", obj);

      streamSrv
        .createSingleStream(obj)
        .then((response) => {
          this.$_log(
            "createMusicDetails] doSubmit, response.data:",
            response.data
          );
          //todo: add notification !!!
          if (response.data.newStreamId !== null) {
            this.newStreamId = response.data.newStreamId;
            this.$_log(
              "createMusicDetails] doSubmit this.newStreamId:" +
                this.newStreamId
            );

            ///=>
            this.receivedPlaylistsFlag = true;
            this.receivedPlaylistsObj = r.data;

            this.$_log("doSubmit r.data:", r.data);

            // enable sticky footer...
            //this.containerDom.querySelector("#cmpr_footer").style.position ="sticky";
            // change footer background ...
            this.containerDom.querySelector(
              ".cmprDetailsPlayer"
            ).style.backgroundColor = "var(--main-gray900-color)";
            this.containerDom.querySelector("#imgc_txt").style.color =
              "var(--main-bg-color)";

            this.containerDom.querySelector(
              "#cmpr_footer"
            ).style.backgroundColor = "var(--main-gray900-color)";

            // --
            this.$_log("Before 0 this.audioList:", this.audioList);
            this.audioList = [];
            // --
            this.$_log("Before 1 this.audioList:", this.audioList);
            this.audioList.push({
              name: "track_" + 0,
              url: r.data.mp3,
              id: this.newStreamId,
              mixMp3: r.data.mixMp3,
            });
            this.$_log("After this.audioList:", this.audioList);

            let fsIco = this.containerDom.querySelector("#fs_ico");
            //console.log("typeof(fsIco):" + fsIco);
            if (fsIco != null) {
              fsIco.style = "display: block;";
            }
            ///=<
          }
          var n = {
            type: "success",
            message: "You have just correctly created a new stream.",
          };
          this.addNotificationMessage(n);
          /*
          this.$router.push({
            name: "streamsView",
            params: {
              pageNr: 0,
              ClientId: this.currentClientId ? this.currentClientId : 0,
            },
          });
		  */
        })
        .catch((error) => {
          var n;
          if (typeof error.response != "undefined") {
            this.$_log("asw error:", error.response.data);
            n = {
              type: "problem",
              message:
                "New stream cant be created. error" + error.response.message,
            };
            this.addNotificationMessage(n);
          } else {
            this.$_log("asw error:", error);
            n = {
              type: "problem",
              message: "New location cant be created. error:" + error,
            };
            this.addNotificationMessage(n);
          }
        });
    },
    alreadySelected(tagId) {
      //this.$_log("alreadySelected tagId:" + tagId);
      if (Array.isArray(this.selectedTags)) {
        const found = this.selectedTags.find((elem) => elem == tagId);
        return found;
      } else {
        return false;
      }
    },
    alreadySelectedMotive(motiveId) {
      if (Array.isArray(this.selectedMotives)) {
        const found = this.selectedMotives.find((elem) => elem == motiveId);
        return found;
      } else {
        return false;
      }
    },
    getSingleFile(event) {
      this.$_log(
        "getSingleFile [this.trackDuration:" +
          this.trackDuration +
          ", length:" +
          this.$time2sec(this.trackDuration, "mm:ss") +
          "]name -->" +
          event.target.name +
          " value-->" +
          event.target.value
      );

      this.stopCurrentPlayer();

      // change styles >>>
      // disable sticky footer...
      //this.containerDom.querySelector("#cmpr_footer").style.position ="absolute";
      // <<<

      this.receivedPlaylistsFlag = false;
      var jsonD = {
        motiveId: parseInt(this.streamMotives[0], 10),
        params: {
          intensity: parseInt(this.intensitySlider, 10),
          tempo: parseInt(this.tempoSlider, 10),
          rhythm: parseInt(this.rythmSlider, 10),
          tone: parseInt(this.toneSlider, 10),
          variance: parseInt(this.variationSlider, 10),
        },
        //  this.intensitySlider +"" + this.tempoSlider + "" +this.rythmSlider + "" + this.toneSlider + "" + this.variationSlider,
        time: this.$time2sec(this.trackDuration, "mm:ss"),
      };

      this.$_log("streamSrv.createSingleFile:", jsonD);
      this.importingData = true;
      streamSrv
        .createSingleFile(jsonD)
        .then((r) => {
          this.$_log("!!!!!!!! r:", r);
          this.importingData = false;
          if (typeof r.data != "undefined") {
            if (typeof r.data.errors != "undefined") {
              this.$_log("error:" + r.data.errors);
              var n = {
                type: "problem",
                message: "error:" + r.data.errors + "",
              };
              this.addNotificationMessage(n);
            } else {
              this.$_log("crateMusicDetails] getSingleFile before doSubmit");
              this.doSubmit(r); // simply save data in the db ....
              /*
              useConfirmBeforeAction(
                function () {
                  this.$_log("useConfirmBeforeAction onConfirm:" + r.data.name);
                  this.doSubmit();
                }.bind(this),
                function () {
                  this.$_log("useConfirmBeforeAction onCancel:" + r.data.name);
                }.bind(this),
                {
                  question:
                    "OOOk so we just created file:" +
                    r.data.mp3 +
                    "<hr/> Is it ok? :",
                }
              );
			  */
            }
          }
        })
        .finally(() => {
          this.importingData = false;
        });
    },
    getMusicPlaylists(event) {
      this.$_log(
        "getMusicPlaylists name -->" +
          event.target.name +
          " value-->" +
          event.target.value
      );

      var jsonD = {
        motives: this.streamMotives,
        params:
          this.intensitySlider +
          "" +
          this.tempoSlider +
          "" +
          this.rythmSlider +
          "" +
          this.toneSlider +
          "" +
          this.variationSlider,
        limit: 200,
      };
      this.$_log(jsonD);
      this.musicPlaylists(jsonD).then((r) => {
        this.$_log("!!!!!!!! r:", r);
        if (typeof r.data != "undefined") {
          if (typeof r.data.errors != "undefined") {
            this.$_log("error:" + r.data.errors);
            var n = {
              type: "problem",
              message: "error:" + r.data.errors + "",
            };
            this.addNotificationMessage(n);
          } else {
            this.receivedPlaylistsFlag = true;
            this.receivedPlaylistsObj = r.data;

            this.audioList = [];
            for (let x = 0; x < r.data.playlistFromMix.length; x++) {
              this.audioList.push({
                name: "track_" + x,
                url: r.data.playlistFromMix[x],
              });
            }
            this.showSaveButton = true;
          }
        }
      });
    },
    // ------------------------------------------------------------
    checkEnabledMotives(event, motiveId) {
      this.$_log(
        "checkEnabledMotives name -->" +
          event.target.name +
          " value-->" +
          event.target.value +
          " | motiveId:" +
          motiveId
      );
      if (this.isMotiveSelected(motiveId) == "clicked") {
        return;
      }
      this.stopCurrentPlayer();

      let el = this.containerDom.querySelector(
        "#btnMotive_" + parseInt(motiveId, 10)
      );
      el.classList.toggle("non-clicked");
      el.classList.toggle("clicked");

      if (Array.isArray(this.selectedMotives)) {
        const found = this.selectedMotives.find((elem) => elem == motiveId);
        // in case of muzaic.studio only one motive is allowed !!! ==>
        for (let x = 0; x < this.selectedMotives.length; x++) {
          if (this.selectedMotives[x] != motiveId) {
            this.$_log(
              "--- lets unmark already selected #btnMotive_id:" +
                this.selectedMotives[x]
            );
            let el = this.containerDom.querySelector(
              "#btnMotive_" + this.selectedMotives[x]
            );
            if (el != null) {
              el.classList.toggle("clicked");
              el.classList.toggle("non-clicked");
            }
          }
        }
        // <==
        if (!found) {
          this.$_log(
            "ook new motive can be added but first lets remove previous"
          );
          this.selectedMotives = [];
          this.selectedMotives.push(motiveId);
        } else {
          this.$_log("oups already selected ... lets removeit from the list");
          this.selectedMotives = this.selectedMotives.filter(
            (elem) => elem != motiveId
          );
        }
      } else {
        this.selectedMotives.push(motiveId);
      }

      if (this.selectedMotives.length != 0) {
        this.showCreateButton = true;
      } else {
        this.showCreateButton = false;
      }
      this.setStreamMotives(this.selectedMotives);
      this.$_log("this.selectedMotives:", this.selectedMotives);
    },

    // ------------------------------------------------------------
    resetTags() {
      // clear Tags ...
      // clear disabledTags array + clear selectedTags array
      this.$_log("this.disabledTags:", this.disabledTags);
      for (let x = 0; x < this.disabledTags.length; x++) {
        let tagElem = this.containerDom.querySelector(
          "#btnTag_" + this.disabledTags[x].TagId
        );
        this.$_log("disabledTags -> tagElem:", tagElem);
        this.$_log(
          "disabledTags -> tagElem:btnTag_" + this.disabledTags[x].TagId
        );
        this.$_log("disabledTags typeof(tagElem):" + typeof tagElem);
        if (tagElem != null) {
          tagElem.classList.remove("disabled");
          tagElem.disabled = false;
        }
      }
      this.disabledTags = [];
      this.$_log("this.disabledTags:", this.disabledTags);

      this.$_log("this.selectedTags:", this.selectedTags);
      for (let x = 0; x < this.selectedTags.length; x++) {
        let tagElem = this.containerDom.querySelector(
          "#btnTag_" + this.selectedTags[x]
        );
        this.$_log("selectedTags typeof(tagElem):" + typeof tagElem);
        if (tagElem != null) {
          tagElem.classList.toggle("non-clicked");
          tagElem.classList.toggle("clicked");
        }
      }
      this.selectedTags = [];
      this.$_log("this.selectedTags:", this.selectedTags);

      this.setStreamTags(this.selectedTags);
    },
    // ------------------------------------------------------------
    resetMotives() {
      // clear Motives ...
      this.$_log("this.selectedMotives:", this.selectedMotives);
      let tagElem = this.containerDom.querySelector(
        "#btnMotive_" + this.selectedMotives[0]
      );
      this.$_log("selectedMotives typeof(tagElem):", tagElem);
      if (tagElem != null) {
        tagElem.classList.toggle("non-clicked");
        tagElem.classList.toggle("clicked");
      }

      this.selectedMotives = [];
    },
    // ------------------------------------------------------------
    resetSettings(callback) {
      this.$_log("resetSettings...");
      this.resetSliders();

      this.resetTags();
      this.resetMotives();
      this.getMotives([], callback);
    },
    // ------------------------------------------------------------
    resetSliders() {
      this.intensitySlider = 5;
      this.tempoSlider = 5;
      this.rythmSlider = 5;
      this.toneSlider = 5;
      this.variationSlider = 5;
    },

    // ------------------------------------------------------------
    countSliderChange(currentValue, currentChange) {
      //this.$_log("==----->[currentValue]:" + currentValue);
      //this.$_log("==----->[currentChange]:" + currentChange);

      if (currentValue >= 0 && currentChange >= 0) {
        //this.$_log("case 1");
        currentValue = Math.max(parseInt(currentValue, 10), currentChange);
      } else if (
        (currentValue < 0 && currentChange > 0) ||
        (currentValue > 0 && currentChange < 0)
      ) {
        //this.$_log("case 2");
        currentValue = Math.round(
          (parseInt(currentValue, 10) + currentChange) / 2
        );
      } else if (currentValue <= 0 && currentChange <= 0) {
        //this.$_log("case 3");
        currentValue = Math.min(parseInt(currentValue, 10), currentChange);
      }

      //this.$_log("==+++++>[countedValue]:" + currentValue);
      currentValue = currentValue + 4;
      if (currentValue > 9) {
        currentValue = 9;
      }
      if (currentValue < 1) {
        currentValue = 1;
      }
      //this.$_log("==+++++>[countedValue1]:" + currentValue);
      return currentValue;
    },
    // ------------------------------------------------------------
    adjustSlidersFormScratch() {
      this.resetSliders();
      for (let x = 0; x < this.selectedTags.length; x++) {
        this.adjustSliders(this.selectedTags[x], 1);
      }
    },
    // ------------------------------------------------------------
    adjustSliders(tagId, actionDirrection) {
      //let currentValue = 0,
      //  currentChange = 0;
      let clickedTag = [];
      for (let x = 0; x < this.TagsToBeDisplayed.length; x++) {
        for (let y = 0; y < this.TagsToBeDisplayed[x].length; y++) {
          if (this.TagsToBeDisplayed[x][y].id == tagId) {
            clickedTag = this.TagsToBeDisplayed[x][y];
            this.$_log("Mam cię!:", this.TagsToBeDisplayed[x][y]);
          }
        }
      }

      //intensity ==>
      //this.$_log("adjustSliders actionDirrection:" + actionDirrection);
      //this.$_log("--------------------------------------------------");
      //this.$_log("this.intensitySlider");
      this.intensitySlider = this.countSliderChange(
        this.intensitySlider - 4,
        parseInt(clickedTag["int_bonus"], 10) * parseInt(actionDirrection, 10)
      );
      //this.$_log("this.tempoSlider");
      this.tempoSlider = this.countSliderChange(
        this.tempoSlider - 4,
        parseInt(clickedTag["tem_bonus"], 10) * parseInt(actionDirrection, 10)
      );
      //this.$_log("this.rythmSlider");
      this.rythmSlider = this.countSliderChange(
        this.rythmSlider - 4,
        parseInt(clickedTag["rhy_bonus"], 10) * parseInt(actionDirrection, 10)
      );
      //this.$_log("this.toneSlider");
      this.toneSlider = this.countSliderChange(
        this.toneSlider - 4,
        parseInt(clickedTag["ton_bonus"], 10) * parseInt(actionDirrection, 10)
      );
      //this.$_log("this.variationSlider");
      this.variationSlider = this.countSliderChange(
        this.variationSlider - 4,
        parseInt(clickedTag["var_bonus"], 10) * parseInt(actionDirrection, 10)
      );
    },

    // ------------------------------------------------------------
    checkTagRelations(event, tagId) {
      this.$_log(
        "checkTagRelations name -->" +
          event.target.name +
          " value-->" +
          event.target.value +
          " | tagId:" +
          tagId
      );

      this.stopCurrentPlayer();

      event.currentTarget.classList.toggle("non-clicked");
      event.currentTarget.classList.toggle("clicked");

      let changeCounter = 1;
      if (Array.isArray(this.selectedTags)) {
        const found = this.selectedTags.find((elem) => elem == tagId);
        if (!found) {
          // dodaj
          this.selectedTags.push(tagId);
        } else {
          // usuń
          this.selectedTags = this.selectedTags.filter((elem) => elem != tagId);
          changeCounter = -1;
        }
      } else {
        this.selectedTags.push(tagId);
        //this.selectedTags.push(tagId, changeCounter);
      }

      //ustaw suwaczki ...
      this.adjustSlidersFormScratch();

      // wyświetl te tagi które są wybrane oraz zaznacz te które nie mogą być wybrane
      {
        let x = 0,
          tmpObj = null;
        // find tags that has to be disabled/enabled
        for (const [k, t] of Object.entries(this.receivedTags.tagsRelations)) {
          if (k == tagId) {
            x = 1;
            tmpObj = t;
            break;
          }
        }
        if (x == 1) {
          // find elements that has to be disabled
          for (const [k1, rt] of Object.entries(tmpObj)) {
            //
            if (rt == -1) {
              // change disabled Tags counter
              let y = 0;
              let disableElem = 1;
              if (Array.isArray(this.disabledTags)) {
                for (let l = 0; l < this.disabledTags.length; l++) {
                  if (this.disabledTags[l].TagId == k1) {
                    if (changeCounter == 1) {
                      this.disabledTags[l].counter++;
                      y = 1;
                    } else {
                      this.disabledTags[l].counter--;
                      if (this.disabledTags[l].counter == 0) {
                        //remove element form disabledTags
                        this.disabledTags = this.disabledTags.filter(
                          (elem) => elem.TagId != k1
                        );
                        disableElem = 0;
                      }
                      y = 1;
                    }
                    break;
                  }
                }
              }

              if (y == 0) {
                this.disabledTags.push({ TagId: k1, counter: 1 });
              }

              // find DOM element
              let el = this.containerDom.querySelector("#btnTag_" + k1);
              if (el != null) {
                if (disableElem == 1) {
                  el.classList.add("disabled");
                  el.disabled = true;
                } else {
                  el.classList.remove("disabled");
                  el.disabled = false;
                }
              }
            }
          }
        }
        this.$_log("====================================");
        this.$_log("this.disabledTags:", this.disabledTags);
        this.$_log("====================================");
        this.setStreamTags(this.selectedTags);

        // get music motives based on selected tags
        this.getMotives(
          this.selectedTags,
          function (err, r) {
            if (err) {
              this.$_log("error:", err);
            } else {
              this.$_log("r:", r);
              //if (this.selectedMotives[0] != this.MotivesToBeDisplayed[0][0].id)
              //  this.setDefaultMotive(this.MotivesToBeDisplayed[0][0].id);
            }
          }.bind(this)
        );
      }
      //<-
    },

    // ------------------------------------------------------------
    getMotives(Tags, callback) {
      var jsonD = { tags: Tags };
      // set stream motives in store via mutation call
      this.musicMotives(jsonD)
        .then((response) => {
          this.$_log("getMotives response:", response.data.tagsMotives);
          response.data.tagsMotives = this.doMotivesFiltering(
            response.data.tagsMotives
          );
          this.$_log(
            "getMotives after filtering response:",
            response.data.tagsMotives
          );

          this.MotivesToBeDisplayed = this.formatData(
            response.data.tagsMotives,
            this.nrMotiveColumns
          );
          //this.dataReady = true;

          this.$_log("this.MotivesToBeDisplayed:", this.MotivesToBeDisplayed);
          this.$_log(
            "first eement from the motive list:" +
              this.MotivesToBeDisplayed[0][0].id
          );
          //callback(null, "ok");
        })

        .then(() => {
          // todo: setDefaultMotives when all elements are displayed !!!
          setTimeout(() => {
            if (this.selectedMotives[0] != this.MotivesToBeDisplayed[0][0].id)
              this.setDefaultMotive(this.MotivesToBeDisplayed[0][0].id);
          }, 50);

          callback(null, "ok");
        })

        .catch((error) => {
          this.$_log("asw 627 error:", error);
          callback(error);
        });
    },
    // ------------------------------------------------------------
    onEditCreation(event, elemId, elemName, elemDuration) {
      useUpdateCreation_BeforeAction(
        function (creation) {
          this.$_log(
            "action confirmed! id:" +
              creation.id +
              " a:" +
              creation.name +
              " b:" +
              creation.duration
          );
          this.trackName = creation.name;
          this.trackDuration = creation.duration;
        }.bind(this), // context has been changed so i have to "bind(this)"" to the function ....
        function () {
          this.$_log("action canceled ...");
        }.bind(this), // context has been changed so i have to "bind(this)"" to the function ....
        {
          // message to be displayed
          title: "Edit info",
          id: elemId,
          name: elemName,
          duration: elemDuration,
        }
      );
    },

    // ------------------------------------------------------------
    onSubmit() {
      this.$_log("streamAdd  onSubmit .....");
    },
    // ------------------------------------------------------------
    doTagsFiltring(Tags) {
      let el = this.containerDom.querySelector("#tagFilter");
      if (el != null) {
        if (el.value != "") {
          this.$_log("OOK :) we can filter tags list ... by text:" + el.value);

          let filteredTags = Tags.filter(
            (elem) => elem.name.indexOf(el.value) != -1
          );
          //this.$_log("filteredTags:", filteredTags);
          return filteredTags;
        } else {
          return Tags;
        }
      } else {
        return Tags;
      }
    },
    // ------------------------------------------------------------
    doMotivesFiltering(Motives) {
      let el = this.containerDom.querySelector("#motiveFilter");
      if (el != null) {
        if (el.value != "") {
          this.$_log(
            "OOK :) we can filter motive list ... by text:" + el.value
          );

          let filteredMotives = [];
          for (const [k, t] of Object.entries(Motives)) {
            if (typeof k != "undefined") {
              if (t.name.toLowerCase().indexOf(el.value.toLowerCase()) != -1) {
                filteredMotives.push(t);
              }
            }
          }

          //this.$_log("filteredMotives:", filteredMotives);
          return filteredMotives;
        } else {
          return Motives;
        }
      } else {
        return Motives;
      }
    },

    // ------------------------------------------------------------
    capitalize(str) {
      let r = "";
      r = str.charAt(0).toUpperCase() + str.slice(1);
      return r;
    },
    // ------------------------------------------------------------
    getComponentData(callback) {
      this.musicTags()
        .then((response) => {
          //this.$_log("getComponentData tags response.data:", response.data);
          response.data.tags = this.doTagsFiltring(response.data.tags);
          this.$_log("response.data.tags:", response.data.tags);

          this.TagsToBeDisplayed = this.formatData(
            response.data.tags,
            this.nrTagColumns
          );
        })
        .then(() => {
          this.$_log("ASW get musicMotives ....");

          this.getMotives(this.selectedTags, (error, r) => {
            if (error) {
              this.$_log("asw 1683 error:", error);
              callback(error);
            } else {
              callback(null, { status: r });
            }
          });
        })
        .catch((error) => {
          this.$_log("asw 109 error:", error);
          callback(error);
        });
    },

    // ------------------------------------------------------------
    postGetComponentData(r) {
      this.$_log(
        "ASW getComponentData callback[" + r.status + "] context:",
        this.containerDom
      );

      // display image or color icon
      let showImage = 0;
      if (typeof this.videoImage != "undefined") {
        if (this.videoImage != null && this.videoImage != "") {
          showImage = 1;
        }
      }
      if (showImage == 1) {
        let i = document.createElement("img");
        i.src = this.getBase64Img(this.videoImage);
        //ic.appendChild(i);

        ///
        let v = this.containerDom.querySelector("#vPlh");
        v.src = URL.createObjectURL(this.video);
        ///
      } else {
        /* 202330908 ASW old idea rather not needed anymore 
		let ic = this.containerDom.querySelector("#img_container");
        const newDiv = document.createElement("div");
        newDiv.className = "iconPlcHld_one";
        ic.appendChild(newDiv);
		*/
      }

      this.dataReady = true;
      // todo: setDefaultMotives when all elements are displayed

      setTimeout(() => {
        if (this.selectedMotives[0] != this.MotivesToBeDisplayed[0][0].id)
          this.setDefaultMotive(this.MotivesToBeDisplayed[0][0].id);
      }, 50);
    },

    // ------------------------------------------------------------
    setDefaultMotive(MotiveId) {
      //this.resetMotives();
      this.$_log("!!!!!!!!!!!!!!!!!!!!!!>");
      this.containerDom.querySelector("#btnMotive_" + MotiveId).click();
      //.querySelector("#btnMotive_" + this.defaultMotiveId)
      this.$_log("!!!!!!!!!!!!!!!!!!!!!!<");
    },
    // ------------------------------------------------------------
    formatData(srcTab, nrColumns) {
      var respData = [],
        tmpRow = [];
      var i = 1;
      //this.$_log(Object.entries(srcTab).length);

      for (const [k, t] of Object.entries(srcTab)) {
        if (typeof k != "undefined") tmpRow.push(t);
        if (i % nrColumns == 0) {
          respData.push(tmpRow);
          tmpRow = [];
        }
        i++;
      }
      if (tmpRow.length > 0) {
        respData.push(tmpRow);
      }
      return respData;
    },
    goBack() {
      this.$router.go(-1);
    },
    // <+++++++=
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style src="@/assets/css/playerInFooter.css"></style>
<style scoped>
/* >>> media */

@media only screen and (min-width: 640px) {
  .motiveButtonContainer {
    display: flex;
    justify-content: center;
  }

  #cmprBtnText_Reset,
  #cmprBtnText_Export {
    display: block;
    padding-left: 10px;
  }

  .cmprHeaderActionsContainer {
    gap: 24px;
  }
}

@media only screen and (max-width: 640px) {
  .motiveButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #cmprBtnText_Reset,
  #cmprBtnText_Export {
    display: none;
  }
  .cmprHeaderActionsContainer {
    gap: 2px;
  }
  .cmprLink {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 850px) {
  .trackInfo {
    display: inherit;
  }
}

@media only screen and (max-width: 850px) {
  .trackInfo {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .cmprDetails {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 24px;
  }
  .cmprDetailsRight {
    display: grid;
    max-height: 490px;
  }
  .cmprAdjust {
    background-color: var(--main-gray200-color);
    border: 1px solid var(--main-gray200-color);
    border-radius: 8px;
    padding: 0px 24px 0px 24px;
    min-height: 420px;
  }
  .cmprCompose {
    padding: 74px 0px 0px 24px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 640px) {
  .cmprDetails {
    display: fex;
  }
  .cmprDetailsRight {
    display: grid;
    max-height: 550px;
  }
  .cmprAdjust {
    background-color: var(--main-gray200-color);
    border: 1px solid var(--main-gray200-color);
    border-radius: 8px;
    padding: 0px 24px 0px 24px;
    min-height: 420px;
    max-width: 500px;
    min-width: 250px;
  }

  .cmprCompose {
    padding: 30px 0px 60px 0px;

    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}

/* <<< media */

.trackInfo {
  align-items: inherit;
  justify-content: inherit;
  font-size: inherit;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--main-gray900-color);
  opacity: 0.8;
  z-index: 1000;
}

.loadingInner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 89px;
  border-top: 1px solid var(--main-bg-color);

  height: 100%;
  width: 100%;
}

/* ======================================================== */
.streamParamTabContainer {
  display: inline-table;
}
.streamTagTabContainer {
}
.streamTagElBlk {
  display: flex;
  row-gap: 12px;
  column-gap: 8px;
  flex-wrap: wrap;
  width: 100%;
}

.streamMotiveTabContainer {
  display: inline-table;
}
.streamMotiveElBlk {
  display: flex;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
  width: 100%;
}

.streamButton {
  /*background: #68d061 0% 0% no-repeat padding-box;*/
  width: 90px;
  height: 40px;
  /*border: 1px solid var(--main-blue-color);*/
  border-radius: 9px;
  color: white;

  text-align: center;
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;

  cursor: pointer;
}
.streamButton.motives {
  margin: 0px 0px 0px 0px;
}

#btnPlt_:hover {
  border: 1px solid var(--main-warningdark-color);
  background-color: var(--main-warningdark-color);
}

#btnPlt_ {
  display: flex;
  width: 175px;
  height: 60px;

  padding: 18px 24px 18px 24px;
  border-radius: 32px;
  gap: 10px;
  background-color: var(--main-warning-color);
  border: 1px solid var(--main-warning-color);
  color: var(--main-bg-color) !important;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;

  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 24px 0px #ff8e1e33;
}

.clicked {
  /*background-color: var(--main-blue-color);*/
  border: 1px solid var(--main-blue-color);
}

.non-clicked {
  /*background: #68d061 0% 0% no-repeat padding-box;*/
  border: 1px solid var(--main-gray500-color);
  color: var(--main-gray600-color);
}

.disabled {
  color: var(--main-gray500-color);
  border: 1px solid var(--main-gray500-color);
  cursor: not-allowed;
  opacity: 0.4;
}

/* ===================================== */
.root_cmp {
  height: 100%;
  border: 0px solid red;
  display: grid;
  grid-template-rows: 1fr 88px;
  flex-grow: 1;
}

#cmpr {
  display: flex;
  justify-content: center;
  padding: 0px 24px 0px 24px;
}

#cmpr form {
  width: 100%;
  max-width: 1800px;
  padding: 0px 0px 0px 0px;
}

.cmprHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cmprHr {
  margin: 16px 0 24px 0px;
  flex-shrink: 0;
  border-style: solid;
  border-width: 1px;
  border-color: var(--main-gray300-color);
}

.truncate130px {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
}

.cmprHeaderTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding-bottom: 4px;
}
.cmprHeaderInfoContainer {
  /*width: 70%;*/
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--main-gray500-color);
}

.cmprHeaderInfoContainer .cmprTextValue {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 20px;
}

.cmprHeaderActionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 22px;
  font-weight: 400;

  color: var(--main-gray600-color);
}

.cmprHeaderActionsContainer svg {
}
.cmprHeaderActionsContainer p {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  flex-shrink: 0;
}

.cmprDetailsLeft {
  background-color: var(--main-gray200-color);
  padding: 0px 24px 0px 24px;
  /*flex: 1;*/
  border-radius: 8px;

  min-height: 490px;
  max-width: 500px;
  min-width: 250px;
  /*height: 490px;
  overflow-x: auto;*/
}
.cmprTags,
.cmprMotives {
  background-color: var(--main-gray200-color);
  padding-bottom: 24px;
}

.cmprTagsHeader,
.cmprMotivesHeader,
.cmprAdjustHeader {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 20px 0px 10px 0px;
}
.cmprTagsHeader h6,
.cmprMotivesHeader h6,
.cmprAdjustHeader h6 {
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  padding-right: 50px;
}

.cmprAdjustTypeTab {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 20px;
}

.cmprAdjustTypeTab button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;

  font-weight: 500;
  font-size: 0.875rem;
  max-width: 360px;
  min-width: 90px;
  position: relative;
  min-height: 48px;
  flex-shrink: 0;
  padding: 12px 16px;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  flex-direction: column;
  color: var(--main-gray900-color);
  line-height: 24px;
  text-transform: none;
}

.cmprLink {
  color: var(--main-gray900-color);
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.cmprReset {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;

  min-width: 30px;
  padding: 8px 24px 8px 24px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: none;
  /*color: rgb(99, 115, 129);*/
}

.cmprReset:hover {
  text-decoration: none;
  background-color: var(--main-gray300-color); /*rgba(0, 56, 255, 0.04);*/
}

.cmpre_disabled {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: default;

  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;

  min-width: 30px;
  padding: 8px 24px;
  border-radius: 8px;

  color: var(--main-gray500-color);
  background-color: var(--main-gray300-color);

  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: none;
}
.cmprExport {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;

  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;

  min-width: 30px;
  padding: 8px 24px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(249, 250, 251);
  background-color: var(--main-blue-color);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: none;
}

.cmprExport:hover {
  text-decoration: none;
  background-color: var(--main-bluedark-color);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}
.cmprSearchBlock {
  display: inline-flex;
  /* flex-direction: column; */
  /* position: relative; */
  min-width: 0px;
  padding: 0px;
  margin: 0px;
  border: 0px;
  vertical-align: top;
  max-width: 209px;
  height: 23px;
}
.cmprSearchText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0px;

  color: rgb(69, 79, 91);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  border-bottom: 2px solid var(--main-gray400-color);
  transition: border-bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.cmprSearchText:hover {
  border-bottom: 2px solid var(--main-gray900-color);
}

.cmprSearchText:focus-within {
  border-bottom: 2px solid var(--main-blue-color);
}

.cmprTextValue {
  color: var(--main-gray700-color);
}
.cmprTxtInput {
  padding: 4px 0px 5px;
  border: 0px;
  margin: 0px;
  outline: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;

  -webkit-tap-highlight-color: transparent;
  display: block;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--main-gray500-color);
}

.cmprSearchBtn {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.cmprSearchBtn:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.sliderRow {
  height: 40px;
}
.sliderCell {
  width: 200px;
}
.sliderTitle {
  text-align: center;
  margin: 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
}

.sliderDescription {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;

  width: 60px;
}
.sd_l {
  text-align: right;
  padding-right: 24px;
}
.sd_r {
  text-align: left;
  padding-left: 24px;
}
.tags {
  height: 36px;
  width: auto;
  padding-left: 8px;
  padding-right: 16px;

  background-color: var(--main-gray200-color);

  border-radius: 24px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.tags:hover {
  border: 1px solid var(--main-blue-color);
}

.clicked.tags {
  color: var(--main-blue-color);
}

.svgContainerClicked {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  background-color: var(--main-blue-color);
  border: 1px solid var(--main-blue-color);
  border-radius: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.svgContainer {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  /*background-color: rgb(0, 56, 255);*/
  border: 1px solid var(--main-gray500-color);
  border-radius: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.motives {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 320px;
  width: 100%;
  min-width: 240px;
  height: 52px;
  background-color: var(--composer-section-bg-color);
}
.motives:hover {
  border: 1px solid var(--main-blue-color);
}
.clicked.motives {
  color: var(--main-gray900-color);
}

.motiveDescCnt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.motiveDescName {
}
.motiveDescStyle {
  color: rgb(145, 158, 171);
}

/* show more; tags, motives */
.showMore {
  margin-top: 20px;
}
</style>
