<template>
  <div class="lgn_cnt">
    <div :class="'dlg_form_container ' + this.additionalMobileStyle()">
      <div class="dlg_c_l1">
        <p class="dlg_form_header">Sign In</p>
      </div>
      <div class="dlg_c_l2">
        <idProv
          mode="login"
          msg=""
          :lgnGoogle="loginUsingGoogle"
          :lgnApple="loginUsingApple"
          :lgnFacebook="loginUsingFacebook"
        />

        <div class="dlg_lgn_row">or continue with</div>
        <form @submit.prevent="onSubmit">
          <div class="ms_inputbox_0">
            <div class="ms_inputbox_1">
              <input
                v-model="mCurrentUser.email"
                type="text"
                placeholder="Enter E-mail"
              />
              <fieldset aria-hidden="true"></fieldset>
            </div>
          </div>

          <div class="ms_inputbox_0a">
            <div class="ms_inputbox_1">
              <input
                v-model="mCurrentUser.password"
                type="password"
                placeholder="Password"
              />
              <fieldset aria-hidden="true"></fieldset>
            </div>
          </div>

          <div class="dlg_msg">
            <router-link to="/rpr">Forgot password ?</router-link>
          </div>

          <div class="dlg_submit_line">
            <button type="submit" class="dlg_submit_btn sign_in">Login</button>
          </div>
        </form>

        <div class="dlg_lgn_row">
          <p>
            You do not have an account?
            <span
              class="gradient01"
              @click="goToRegistration"
              style="cursor: pointer"
              >Sign up</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import idProv from "@/components/elements/idProviders.vue";

import config from "@/config/config.js";

export default {
  name: "sl_mob_login",
  props: {
    msg: String,
  },
  components: {
    idProv,
  },
  data() {
    return {
      mCurrentUser: {
        username: "",
        email: "",
        password: "",
      },
      //universal-social-auth ==>
      responseData: {},
      loginUsingGoogle: config.UniversalSocialauth_idProviders.google.enabled,
      loginUsingFacebook:
        config.UniversalSocialauth_idProviders.facebook.enabled,
      loginUsingApple: config.UniversalSocialauth_idProviders.apple.enabled,
      //<==
    };
  },
  created() {
    this.log("LoginDialog] ======");
    this.log("LoginDialog] received params:", this.$route.query);
  },
  mounted() {
    this.log("before clearStreamData -->");
    this.clearStreamData();
    this.log("after clearStreamData --<");

    let x = this.$getImageUrl("mobileMainBg01.png");
    this.$replaceMainBackground(x);
    this.log("bg replaced ...");
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
      "getUserDevicetype",
      "currentSubscription",
    ]),
  },

  methods: {
    ...mapActions("notificationModule", ["remove"]),
    ...mapActions("streamModule", {
      clearStreamData: "clearStreamData",
    }),
    ...mapActions("userModule", ["setCurrentUserDeviceType"]),

    //
    log(str, obj) {
      if (typeof obj == "undefined") {
        this.$_log(this.$options.name + "] " + str);
      } else {
        this.$_log(this.$options.name + "] " + str, obj);
      }
    },

    //
    goToRegistration() {
      this.log("sl_mob_login] goToRegistration");
      this.$router.push({
        name: "sl_mob_register",
        params: {
          usageType: "free",
        },
      });
    },

    //
    additionalMobileStyle() {
      if (this.getUserDevicetype == "mobile") {
        return "mobileLgn";
      } else {
        return "";
      }
    },

    // ========================================================
    onSubmit() {
      const usr = {
        ...this.mCurrentUser,
        loginFormId: -10,
      };

      this.log("LoginDialog.vue] onSubmit - User:", usr);
      this.$store
        .dispatch("userModule/login", usr)
        .then(() => {
          this.log(
            "ASW 01 LoginDialog.vue] onSubmit - then logged username:" +
              this.currentUsername +
              " user_id:" +
              this.currentUserId +
              " user.token:" +
              this.userToken
          );

          this.log(
            "SubscriptionLevel:" + this.currentSubscription.SubscriptionLevel
          );
          if (this.currentSubscription.SubscriptionLevel != "Free") {
            this.setCurrentUserDeviceType("desktop");
            //this.$router.push({ name: "createMusicHeaderView" });
            this.$router.push({
              name: "userCreationsView",
              params: {
                pageNr: 0,
                ClientId: this.currentClientId,
              },
            });
          } else {
            this.$router.push({
              name: "sl_mob_uploadVideo",
            });
          }
        })
        .catch((error) => {
          this.log("Error:" + error);
        });
    },
  },
};
</script>

<style scoped>
.lgn_cnt {
  display: flex;
  width: 100%;
}
.lgn_cnt .dlg_form_container {
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 25px;
  width: auto;
}

.lgn_cnt .dlg_form_header {
  text-align: center;
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
}

.lgn_cnt .ms_inputbox_0 {
  position: relative;
  padding-bottom: 21px;
}
.lgn_cnt .ms_inputbox_0a {
  position: relative;
  padding-bottom: 5px;
}

.lgn_cnt .dlg_form_container fieldset {
  inset: 0px 0px 0px;
}

.lgn_cnt .dlg_form_container input {
  background-color: white;
}

.lgn_cnt .dlg_msg {
  margin-bottom: 11px;
}

.lgn_cnt .dlg_msg a {
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  line-height: 18px;
  color: var(--main-sec-color);
  padding-right: 30px;
}
.lgn_cnt .dlg_msg a:visited {
  color: var(--main-sec-color);
}

.lgn_cnt .dlg_lgn_row {
  padding-top: 4px;
  padding-bottom: 8px;

  color: var(--main-gray500-color);
}

.lgn_cnt .dlg_lgn_row p {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  text-decoration: none;

  margin: 9px 0px 16px 0px;
}

.authProvider {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: pointer;
}
</style>
