<template>
  <div class="dlg_form_container">
    <div class="dlg_c_l1">
      <p class="dlg_form_header">{{ msg }}</p>
    </div>
    <div class="dlg_c_l2">
      <!-- ==== -->
      <div>
        <button
          v-if="loginUsingFacebook"
          @click="useAuthProvider('facebook', null)"
          class="authProvider"
        >
          <img :src="$getImageUrl('Facebook.svg')" />
        </button>

        <button
          v-if="loginUsingApple"
          @click="useAuthProvider('apple', null)"
          class="authProvider"
        >
          <img :src="$getImageUrl('Apple.svg')" />
        </button>

        <button
          v-if="loginUsingGoogle"
          @click="useAuthProvider('google', null)"
          class="authProvider"
        >
          <img :src="$getImageUrl('Google.svg')" />
        </button>
      </div>

      <div class="dlg_lgn_row">or continue with</div>

      <!-- ==== -->
      <form @submit.prevent="onSubmit">
        <div class="ms_inputbox_0" style="display: none">
          <label>Enter Name</label>
          <div class="ms_inputbox_1">
            <input
              v-model="mCurrentUser.name"
              @change="chField($event)"
              type="text"
              placeholder="Your Name"
            />
            <fieldset aria-hidden="true">
              <legend><span>Enter Name</span></legend>
            </fieldset>
          </div>
        </div>
        <div id="name_Msg" class="errorMsg"></div>

        <div class="ms_inputbox_0" style="display: none">
          <label>Enter Last name</label>
          <div class="ms_inputbox_1">
            <input
              v-model="mCurrentUser.last_name"
              @change="chField($event)"
              type="text"
              placeholder="Your Last name"
            />
            <fieldset aria-hidden="true">
              <legend><span>Enter Last name</span></legend>
            </fieldset>
          </div>
        </div>
        <div id="last_name_Msg" class="errorMsg"></div>

        <div class="ms_inputbox_0">
          <label>Enter E-mail</label>
          <div class="ms_inputbox_1">
            <input
              v-model="mCurrentUser.email"
              @change="chField($event)"
              type="text"
              placeholder="test@test.com"
            />
            <fieldset aria-hidden="true">
              <legend><span>Enter E-mail</span></legend>
            </fieldset>
          </div>
        </div>
        <div id="email_Msg" class="errorMsg"></div>

        <div class="ms_inputbox_0">
          <label>Enter Password</label>
          <div class="ms_inputbox_1">
            <input
              v-model="mCurrentUser.password"
              @change="chField($event)"
              type="password"
              placeholder="password"
            />
            <fieldset aria-hidden="true">
              <legend><span>Enter Password</span></legend>
            </fieldset>
          </div>
        </div>
        <div id="defaultPassMsg">{{ passwordRequirementMsg }}</div>
        <div id="password_Msg" class="errorMsg"></div>

        <div class="ms_inputbox_0">
          <label>Confirm password</label>
          <div class="ms_inputbox_1">
            <input
              v-model="mCurrentUser.passwordC"
              @change="chField($event)"
              type="password"
              placeholder="password"
            />
            <fieldset aria-hidden="true">
              <legend><span>Confirm password</span></legend>
            </fieldset>
          </div>
        </div>
        <div id="passwordC_Msg" class="errorMsg"></div>

        <div class="dlg_submit_line">
          <button type="submit" class="dlg_submit_btn usr_register">
            Register new account
          </button>
        </div>
      </form>

      <div class="dlg_lgn_row">
        <p>
          Already have an account?
          <router-link to="/login">Log In</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import axios from "axios";
import { Providers } from "universal-social-auth";
import UniversalSocialauth from "universal-social-auth";
import config from "@/config/config.js";

import Validator from "validatorjs";

const USAuth_options = {
  providers: config.UniversalSocialauth_idProviders,
};
const Oauth = new UniversalSocialauth(axios, USAuth_options);

export default {
  name: "UserRegistration",
  props: {
    msg: String,
  },
  data() {
    return {
      mCurrentUser: {
        name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        passwordC: "",
      },
      passwordRequirement: config.passwordRequirement,
      passwordRequirementMsg: config.passwordRequirementMsg,

      responseData: {},
      loginUsingGoogle: config.UniversalSocialauth_idProviders.google.enabled,
      loginUsingFacebook:
        config.UniversalSocialauth_idProviders.facebook.enabled,
      loginUsingApple: config.UniversalSocialauth_idProviders.apple.enabled,
    };
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
      "currentSubscription",
    ]),
  },
  methods: {
    useAuthProvider(provider, proData) {
      const pro = proData;
      this.$_log(
        "RegisterDialog] ====== lets try to register using external authentication provider:" +
          provider
      );
      const ProData = pro || Providers[provider];
      Oauth.authenticate(provider, ProData)
        .then((response) => {
          const rsp = response;
          if (rsp.code) {
            this.responseData.code = rsp.code;
            this.responseData.provider = provider;
            this.useSocialLogin(provider);
          }
        })
        .catch((err) => {
          this.$_log(err);
        });
    },
    useSocialLogin(provider) {
      this.$_log("useSocialLogin to register new user ....");
      let data = {
        provider: this.responseData.provider,
        code: this.responseData.code,
        serviceName: "muzaic.studio",
        redirectUri:
          config.UniversalSocialauth_idProviders[this.responseData.provider]
            .redirectUri,
      };
      switch (provider) {
        case "google": {
          this.registerUsingIdProviderData(data)
            .then(async () => {
              this.postRegAction("/postReg_google")
                .then(() => {
                  this.$_log("postRegAction ok");
                })
                .catch((err) => {
                  this.postRegError(err);
                });
            })
            .catch((err) => {
              this.postRegError(err);
            });

          break;
        }
        case "facebook": {
          this.registerUsingIdProviderData(data)
            .then(() => {
              this.postRegAction("/postReg_facebook")
                .then(() => {
                  this.$_log("postRegAction ok");
                })
                .catch((err) => {
                  this.postRegError(err);
                });
            })
            .catch((err) => {
              this.postRegError(err);
            });
          break;
        }
        case "apple": {
          this.registerUsingIdProviderData(data)
            .then(() => {
              this.postRegAction("/postReg_apple")
                .then(() => {
                  this.$_log("postRegAction ok");
                })
                .catch((err) => {
                  this.postRegError(err);
                });
            })
            .catch((err) => {
              this.postRegError(err);
            });
          break;
        }
      }
    },
    //
    postRegAction(phFullPath) {
      return new Promise((resolve, reject) => {
        try {
          if (typeof process.env.VUE_APP_DISABLE_TRACKERS != "undefined") {
            if (process.env.VUE_APP_DISABLE_TRACKERS == 0) {
              this.$posthog.capture(config.posthog.eventName, {
                fullPath: phFullPath,
              });
            }
          }
          /* user creation list view
          this.$router.push({
            name: "userCreationsView",
            params: {
              pageNr: 0,
              ClientId: this.currentClientId,
            },
          });
		  */
          /*
          this.$router.push({
            name: "createMusicHeaderView",
          });
		  */
          this.$router.push({
            name: "msSubscriptionsView",
            params: { id: 0 },
          });
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },
    //
    postRegError(err) {
      this.$_log("useSocialLogin to register Error:" + err);
    },

    //passCh(event)
    chField(event) {
      const usr = {
        name: this.mCurrentUser.name,
        last_name: this.mCurrentUser.last_name,
        email: this.mCurrentUser.email,
        password: this.mCurrentUser.password,
        passwordC: this.mCurrentUser.passwordC,
        serviceName: "muzaic.studio", // dastination application: 'muzaic.studio', 'muzaic.biz', 'muzaic.app'
      };
      this.doValidation(usr)
        .then(() => {})
        .catch((error) => {
          this.$_log(
            "uups form data did not pass validation .... error:",
            error
          );
          event.target.focus();
        });
    },
    doValidation(usr) {
      return new Promise((resolve, reject) => {
        let data = {
          name: usr.name,
          last_name: usr.last_name,
          email: usr.email,
          password: usr.password,
          passwordC: usr.passwordC,
        };

        this.$_log("data to validate:", data);

        let rules = {
          //name: "required",
          //last_name: "required",
          password: ["required", this.passwordRequirement],
          passwordC: "same:password",
          email: "required|email",
        };

        for (const [key, value] of Object.entries(data)) {
          console.log(`${key}: ${value}`);
          let o = this.$el.querySelector("#" + key + "_Msg");
          o.innerText = "";
        }

        let validation = new Validator(data, rules, {
          "regex.password": this.passwordRequirementMsg,
          "required.password":
            "The password field is required. \n" + this.passwordRequirementMsg,
          "same.passwordC":
            "The password and confirm password fields must match...",
        });

        if (validation.passes()) {
          this.$_log("all good lets go ahead ....");
          resolve(usr);
        } else {
          this.$_log("ook we have problems ...:", validation);
          let str = "";
          let p = 0;
          for (const [key, value] of Object.entries(validation.errors.all())) {
            console.log("error key:" + key + "value:" + value);
            if (key == "password") {
              p = 1;
            }
            let o = this.$el.querySelector("#" + key + "_Msg");
            o.innerText = value;
            o.style.marginBottom = "25px";
            str = str + value + " ";
          }

          let dpm = this.$el.querySelector("#defaultPassMsg");
          if (p == 1) {
            console.log("hide");
            dpm.style.display = "none";
          } else {
            console.log(".....");
            dpm.style.display = "block";
          }

          this.$_log("problems str:" + str);
          reject(str);
        }
      });
    },

    sendFormData(usr) {
      return new Promise((resolve, reject) => {
        this.$_log("UserRegistration.vue] onSubmit - User:", usr);
        this.$store
          .dispatch("userModule/registerTestUser", usr)
          .then(() => {
            this.$_log(
              "UserRegistration.vue] onSubmit - then registered email:" +
                this.currentUsername +
                " user_id:" +
                this.currentUserId +
                " user.token:" +
                this.userToken
            );

            resolve();
          })
          .catch((error) => {
            this.$_log(error);
            reject(error);
          });
      });
    },

    onSubmit() {
      const usr = {
        name: this.mCurrentUser.name,
        //first_name: this.mCurrentUser.name,
        //last_name: this.mCurrentUser.last_name,
        email: this.mCurrentUser.email,
        password: this.mCurrentUser.password,
        passwordC: this.mCurrentUser.passwordC,
        serviceName: "muzaic.studio", // dastination application: 'muzaic.studio', 'muzaic.biz', 'muzaic.app'
      };

      ///
      // do validation ==>
      this.doValidation(usr)
        .then(() => {
          this.sendFormData(usr)
            .then(() => {
              this.$_log("OOOOOK we are done :)");
              this.$router.push({
                name: "postRegistration",
              });
            })
            .catch((error) => {
              this.$_log("uups account was not created .... error:", error);
            });
        })
        .catch((error) => {
          this.$_log(
            "uups form data did not pass validation .... error:",
            error
          );
        });
      // do validation ==<
      ///

      /*
      if (
        usr.name != "" &&
        usr.email != "" &&
        usr.email != "test@test.com" &&
        usr.password != "" &&
        usr.password == usr.passwordC
      ){
        // do validation ==>
        this.doValidation(usr)
          .then(() => {
            this.sendFormData(usr)
              .then(() => {
                this.$_log("OOOOOK we are done :)");
                this.$router.push({
                  name: "postRegistration",
                });
              })
              .catch((error) => {
                this.$_log("uups account was not created .... error:", error);
              });
          })
          .catch((error) => {
            this.$_log(
              "uups form data did not pass validation .... error:",
              error
            );
          });
        // do validation ==<
      } else {
        var n = {
          type: "problem",
          message:
            "The fields, first name, last name, email, and password must be filled in.",
        };
        this.addNotificationMessage(n);
      }
	  */
    },

    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("userModule", ["login", "registerUsingIdProviderData"]),
    //...mapActions("userModule", ["login"]),
  },
};
</script>

<style src="@/assets/css/dialogform_0.css"></style>
<style scoped>
.authProvider {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  padding: 0px 24px;
}

#defaultPassMsg {
  font-size: 12px;
  /*color: red;*/
  text-align: left;
  padding-bottom: 24px;
}
</style>
