<template>
  <nav class="mysub_nav" style="position: relative">
    <dropdownMenu
      name="userProfile"
      :title="title"
      :items="MySubscriptionsServices"
      activeElement="dots"
      :stripeUrl="stripeSessionUrl"
    />
  </nav>
</template>

<script>
import dropdownMenu from "@/components/navigation/DropdownMenu_Layout.vue";
import { mapGetters } from "vuex";

export default {
  name: "MySubNavBar",
  props: { title: String, stripeSessionUrl: String },
  data() {
    return {
      MySubscriptionsServices: [
        {
          id: "mysub_upgrade",
          title: "Upgrade", // !!!!!
          link: "blank", //this.stripeSessionUrl +"/subscriptions/" +this.curentSubscriptionId +"/update",
          icon: "", //note_list_wb.png
          displayAtWideScreen: true,
          fun: () => {
            this.$_log(
              "ASW lets upgrade current subscription ... " +
                this.stripeSessionUrl +
                "/subscriptions/" +
                this.curentSubscriptionId +
                "/update"
            );

            document.location.href =
              this.stripeSessionUrl +
              "/subscriptions/" +
              this.curentSubscriptionId +
              "/update";
          },
        },
        {
          title: "Manage", // !!!!!
          link: "msSubscriptionsView",
          icon: "",
          displayAtWideScreen: true,
          fun: () => {
            this.$_log("ASW lets display current user subscriptions ...");

            this.$router.push({
              name: "msSubscriptionsView",
              params: {
                id: Number(this.currentClientId),
              },
            });
          },
        },
        {
          title: "Cancel", // !!!!!
          link: "blank",
          icon: "", //"Logout.svg",
          displayAtWideScreen: true,
          fun: () => {
            this.$_log("ASW lets cancel subscription!");
            document.location.href =
              this.stripeSessionUrl +
              "/subscriptions/" +
              this.curentSubscriptionId +
              "/cancel";
          },
        },
      ],
    };
  },
  components: {
    dropdownMenu,
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "userType",
      "currentClientId",
      "currentClientFN",
      "currentClientLN",
    ]),
    ...mapGetters("subscriptionsModule", [
      "curentSubscriptionId",
      "subscriptionName",
      "subscriptionImage",
      "subscriptionAllowedTime",
      "subscriptionUsedTime",
      "subscriptionCurrency",
      "subscriptionStartDate",
      "subscriptionStatus",
      "subscriptionsPeriodStart",
      "subscriptionPeriodEnd",
      "subscriptionPrice",
      "subscriptionPriceId",
      "subscriptionProductId",
      "subscriptionInterval",
      "subscriptionStripeCustomerId",
    ]),
  },
};
</script>

<style scoped>
.user_prof_nav {
  display: flex;
  color: var(--main-gray900-color);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
