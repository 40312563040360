<template>
  <template v-if="currentUsername == 'freeaccount@muzaic.studio'">
    {{ msg }}
    <span class="gradient01" @click="goSignUpPage" style="cursor: pointer">
      {{ colorMsg }}
    </span>
  </template>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "signInGoPro",
  props: {
    msg: String,
    colorMsg: String,
  },
  data() {
    return {
      message: "",
    };
  },
  methods: {
    //
    goSignUpPage() {
      this.$_log("ASW lets logout!");

      this.$store
        .dispatch("userModule/logout")
        .then(() => {
          this.$router.push({
            name: "sl_mob_register",
            params: { usageType: "free" },
            replace: true,
          });
        })
        .catch((error) => {
          this.$_log(error);
        });
    },
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "currentUsername",
      "currentUserEmail",
      "userType",
      "currentUserId",
      "currentClientFN",
      "currentClientLN",
      "currentClientId",
      "getUserDevicetype",
      "currentSubscription",
    ]),
  },
};
</script>

<style scoped></style>
