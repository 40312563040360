<template>
  <div class="mc2">
    <div class="uc_container">
      <div v-if="dataReady" class="UserDashboard">
        <div class="ud_1r">
          <p class="udHeader">
            Good morning <br />"{{ currentUsername }}" <br />[{{
              currentUserId
            }}/{{ currentUserTypeId }}]
          </p>
          today is {{ currentTimeString }}
        </div>
        <div v-if="city != ''" class="ur_1r udBigBadge">
          {{ city }} - {{ country }} <br />{{ description }} {{ temp }}C
          {{ wind }}m/s
          <img :src="icon" />
        </div>
      </div>
      <div class="yourProperty">
        <div>You have:</div>
        <div class="udSmallBadge">XYZ</div>
        music creations
        <div class="udSmallBadge">3600</div>
        seconds to be used till the end of the month in your current
        subscription
      </div>
      <div class="yourCreations">
        <div>You can use the library of</div>
        <div class="udSmallBadge">XYZ</div>
        tags
        <div class="udSmallBadge">XYZ</div>
        motives
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import weatherSrv from "@/services/weatherService.js";
import geoLocSrv from "@/services/geoLocationService.js";

export default {
  name: "UserDashboard",
  props: ["id"],
  data() {
    return {
      dataReady: false,
      today: new Date(),
      city: "",
      country: "PL...",
      description: "",
      temp: "",
      wind: "",
      icon: "",
      latitude: "",
      longitude: "",
    };
  },
  components: {
    //UserDashboard,
  },
  created() {
    this.$_log("dashboard just created ...");

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function success(pos) {
      const crd = pos.coords;

      this.$_log("Your current position[" + this.country + "] is:");
      this.$_log("Latitude : " + crd.latitude + "");
      this.$_log("Longitude: " + crd.longitude + "");
      this.$_log(`More or less ${crd.accuracy} meters.`);

      this.getWeatherData(crd.latitude, crd.longitude);
    }

    function error(err) {
      this.$_log("asw => ERROR:" + err.code + " message:" + err.message);
      //console.warn(`asw => ERROR(${err.code}): ${err.message}`);

      ////
      this.getUserPositionByIp()
        .then((r) => {
          this.getWeatherData(r.latitude, r.longitude);
        })
        .catch((error) => {
          this.$_log("get weather for user ip location error:" + error);
          this.$_log("ooook .... so lets get weather for Warsaw :)");
          this.getWeatherData("52.3", "21.07");
        });
      ////
    }

    navigator.geolocation.getCurrentPosition(
      success.bind(this),
      error.bind(this),
      options
    );
  },
  computed: {
    currentTimeString() {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return this.today.toLocaleDateString("en-US", options);
    },
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentUserTypeId",
    ]),
  },
  methods: {
    getUserPositionByIp() {
      return new Promise((resolve, reject) => {
        geoLocSrv
          .getUserPositionByIp()
          .then((result) => {
            //this.latitude = result.response.location.latitude;
            //this.longitude = result.response.location.longitude;
            if (typeof result.response.location != "undefined") {
              resolve(result.response.location);
            } else {
              reject("error");
            }
          })
          .catch((error) => {
            this.$_log("getUserPositionByIp 0 error:" + error);
            reject(error);
          });
      });
    },
    getWeatherData(lat, lon) {
      weatherSrv
        .getCityByPosition(lat, lon)
        .then((result) => {
          this.$_log("where am i??", result.data.response);
          var p = result.data.response;

          this.$_log("where am i??" + p.name + " -- " + p.country);
          weatherSrv
            .getWeatherForCity(p.name)
            .then((r) => {
              this.$_log(r.data.response);
              ///todo: fill local data !!
              this.city = p.name;
              this.country = p.country;
              this.temp = r.data.response.main.temp;
              this.wind = r.data.response.wind.speed;
              this.description = r.data.response.weather[0].description;
              this.icon =
                "https://openweathermap.org/img/wn/" +
                r.data.response.weather[0].icon +
                "@2x.png";

              this.dataReady = true;
            })
            .catch((err) => {
              this.$_log("getWeatherData 1 error:" + err);
            });
        })
        .catch((error) => {
          this.$_log("getWeatherData 0 error:", error);
        });
    },
  },
};
</script>

<style scoped>
.uc_container {
  text-align: center;
  width: 99%;
  padding-top: 200px;
}
.UserDashboard {
  display: flex;
  width: fit-content;
  text-align: left;
  margin: auto;
}

.ud_1r {
  display: inline-block;
  /*border: 2px solid green;*/
}
.udHeader {
  font-size: 47px;
  line-height: 47px;
  font-weight: 600;
  text-decoration: none;

  letter-spacing: 0px;
  color: #000;
}

.udBigBadge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--main-blue-color);
  padding: 10px;
  width: 200px;
  height: 200px;
  margin-left: 40px;

  border: 1px solid var(--main-blue-color);
  background-color: var(--main-blue-color);
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgb(157 96 212 / 50%);
  color: white;
}

.udBigBadge:hover {
  border: 1px solid var(--main-blueDarker-color);
  background-color: var(--main-blueDarker-color);
  color: white;
}

.udSmallBadge {
  /* position: absolute; */
  display: inline-block;
  vertical-align: middle;
  border: 2px solid var(--main-blue-color);
  padding: 10px;
  margin-left: 10px;
  margin-right: 6px;
  min-width: 51px;

  border: 1px solid var(--main-blue-color);
  background-color: var(--main-blue-color);
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgb(157 96 212 / 50%);
  color: white;

  text-align: center;
}

.udSmallBadge:hover {
  border: 1px solid var(--main-blueDarker-color);
  background-color: var(--main-blueDarker-color);
  color: white;
}

.yourProperty {
  text-align: left;
  width: fit-content;

  /*height: 100px;
  border: 2px solid green;*/
  margin: auto;
  padding: 50px 0;
}
.yourProperty div {
  display: inline-block;
}
.yourCreations {
  text-align: left;
  width: fit-content;

  /*height: 100px;
  border: 2px solid blue;*/
  margin: auto;
  padding: 50px 0;
}
.yourCreations div {
  display: inline-block;
}
</style>
