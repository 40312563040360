<template>
  <div :class="'dlg_form_container ' + this.additionalMobileStyle()">
    <div class="dlg_c_l1">
      <p class="dlg_form_header">{{ msg }}</p>
    </div>
    <div class="dlg_c_l2">
      <!-- ==== -->
      <div>
        <button
          v-if="loginUsingFacebook"
          @click="useAuthProvider('facebook', null)"
          class="authProvider"
        >
          <img :src="$getImageUrl('Facebook.svg')" />
        </button>
        <button
          v-if="loginUsingApple"
          @click="useAuthProvider('apple', null)"
          class="authProvider"
        >
          <img :src="$getImageUrl('Apple.svg')" />
        </button>
        <button
          v-if="loginUsingGoogle"
          @click="useAuthProvider('google', null)"
          class="authProvider"
        >
          <img :src="$getImageUrl('Google.svg')" />
        </button>
      </div>

      <div class="dlg_lgn_row">or continue with</div>

      <!--  ====  -->
      <form @submit.prevent="onSubmit">
        <div class="ms_inputbox_0">
          <label>Enter E-mail</label>
          <div class="ms_inputbox_1">
            <input
              v-model="mCurrentUser.email"
              type="text"
              placeholder="test@test.com"
            />
            <fieldset aria-hidden="true">
              <legend><span>Enter E-mail</span></legend>
            </fieldset>
          </div>
        </div>

        <div class="ms_inputbox_0">
          <label>Password</label>
          <div class="ms_inputbox_1">
            <input
              v-model="mCurrentUser.password"
              type="password"
              placeholder="password"
            />
            <fieldset aria-hidden="true">
              <legend><span>Password</span></legend>
            </fieldset>
          </div>
        </div>

        <div class="dlg_msg">
          <router-link to="/rpr">Forgot password ?</router-link>
        </div>

        <div class="dlg_submit_line">
          <button type="submit" class="dlg_submit_btn sign_in">Log In</button>
        </div>
      </form>

      <div class="dlg_lgn_row">
        <p>
          You do not have an account?
          <router-link v-if="getUserDevicetype == 'desktop'" to="/register"
            >Sing up</router-link
          >
          <router-link v-else to="/register/free">Sing up</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import axios from "axios";
import { Providers } from "universal-social-auth";
import UniversalSocialauth from "universal-social-auth";
import config from "@/config/config.js";

const USAuth_options = {
  providers: config.UniversalSocialauth_idProviders,
};
const Oauth = new UniversalSocialauth(axios, USAuth_options);

export default {
  name: "LoginDialog",
  props: {
    msg: String,
  },
  data() {
    return {
      mCurrentUser: {
        username: "",
        email: "",
        password: "",
      },
      //universal-social-auth ==>
      responseData: {},
      loginUsingGoogle: config.UniversalSocialauth_idProviders.google.enabled,
      loginUsingFacebook:
        config.UniversalSocialauth_idProviders.facebook.enabled,
      loginUsingApple: config.UniversalSocialauth_idProviders.apple.enabled,
      //<==
    };
  },
  created() {
    this.$_log("LoginDialog] ======");
    this.$_log("LoginDialog] received params:", this.$route.query);
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
      "getUserDevicetype",
      "currentSubscription",
    ]),
    isItPayWall() {
      if (this.$route.path.indexOf("msSubscriptionsView") != -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    additionalMobileStyle() {
      if (this.getUserDevicetype == "mobile") {
        return "mobileL";
      } else {
        return "";
      }
    },

    // ========================================================
    onSubmit() {
      const usr = {
        ...this.mCurrentUser,
        loginFormId: -10,
      };

      this.$_log("LoginDialog.vue] onSubmit - User:", usr);
      this.$store
        .dispatch("userModule/login", usr)
        .then(() => {
          this.$_log(
            "ASW 01 LoginDialog.vue] onSubmit - then logged username:" +
              this.currentUsername +
              " user_id:" +
              this.currentUserId +
              " user.token:" +
              this.userToken
          );

          this.$router.push({
            name: "userCreationsView",
            params: {
              pageNr: 0,
              ClientId: this.currentClientId,
            },
          });
        })
        .catch((error) => {
          this.$_log("Error:" + error);
        });
    },

    // ========================================================
    useAuthProvider(provider, proData) {
      const pro = proData;
      this.$_log(
        "LoginDialog] ====== lets try to authenticate using external authentication provider:" +
          provider
      );
      const ProData = pro || Providers[provider];
      Oauth.authenticate(provider, ProData)
        .then((response) => {
          const rsp = response;
          this.$_log("Auth provider:" + provider + " response:", response);
          if (rsp.code) {
            this.responseData.code = rsp.code;
            this.responseData.provider = provider;
            this.useSocialLogin(provider);
          }
        })
        .catch((err) => {
          this.$_log("useAuthProvider Error:" + err);
        })
        .finally(() => {
          this.$_log("useAuthProvider finished.");
        });
    },
    // ========================================================
    useSocialLogin(provider) {
      this.$_log("useSocialLogin .... A");

      this.$_log(
        "redirectUri:" +
          config.UniversalSocialauth_idProviders[this.responseData.provider]
            .redirectUri
      );

      this.$_log("redirectUri:" + this.responseData.provider);
      let data = {
        provider: this.responseData.provider,
        code: this.responseData.code,
        serviceName: "muzaic.studio",
        redirectUri:
          config.UniversalSocialauth_idProviders[this.responseData.provider]
            .redirectUri,
      };

      switch (provider) {
        case "google": {
          this.loginUsingIdProviderData(data)
            .then(() => {
              this.postLoginAction("/postLogin_google")
                .then(() => {
                  this.$_log("postLoginAction ok");
                })
                .catch((err) => {
                  this.postLoginError(err);
                });
            })
            .catch((err) => {
              this.postLoginError(err);
            });

          break;
        }
        case "facebook": {
          this.$_log(
            "Ooook so we have code from FB ... lets try to login usng this data..."
          );
          this.loginUsingIdProviderData(data)
            .then(() => {
              this.postLoginAction("/postLogin_facebook")
                .then(() => {
                  this.$_log("postLoginAction ok");
                })
                .catch((err) => {
                  this.postLoginError(err);
                });
            })
            .catch((err) => {
              this.postLoginError(err);
            });
          break;
        }
        case "apple": {
          this.$_log(
            "Ooook so we have data from Apple ... lets try to login usng this data..."
          );
          this.loginUsingIdProviderData(data)
            .then(() => {
              this.postLoginAction("/postLogin_apple")
                .then(() => {
                  this.$_log("postLoginAction ok");
                })
                .catch((err) => {
                  this.postLoginError(err);
                });
            })
            .catch((err) => {
              this.postLoginError(err);
            });

          break;
        }
      }
    },
    postLoginAction(phFullPath) {
      return new Promise((resolve, reject) => {
        try {
          if (typeof process.env.VUE_APP_DISABLE_TRACKERS != "undefined") {
            if (process.env.VUE_APP_DISABLE_TRACKERS == 0) {
              this.$posthog.capture(config.posthog.eventName, {
                fullPath: phFullPath,
              });
            }
          }

          if (this.currentSubscription.SubscriptionLevel == "Free") {
            this.$router.push({
              name: "msSubscriptionsView",
              params: { id: 0 },
            });
          } else {
            /* user creation list view ==> */
            this.$router.push({
              name: "userCreationsView",
              params: {
                pageNr: 0,
                ClientId: this.currentClientId,
              },
            });

            /* "lets begin" page =>
          	this.$router.push({name: "createMusicHeaderView",});
			*/
          }

          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },
    postLoginError(err) {
      this.$_log("useSocialLogin Error:" + err);

      this.$router.push({
        name: "register",
      });
    },

    //...mapActions("notificationModule", ["remove"]),
    ...mapActions("userModule", ["login", "loginUsingIdProviderData"]),
  },
};
</script>

<style src="@/assets/css/dialogform_0.css"></style>
<style scoped>
.authProvider {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  padding: 0px 24px;
}

/* ==== mobile layout ====> */

.dlg_form_container.mobileL {
  margin-top: 204px;
  opacity: 75%;
  border-radius: 25px;
  margin-bottom: 17px;
}
</style>
