import { createRouter, createWebHistory } from "vue-router";
import GStore from "@/store";
import usrSrv from "@/services/userService.js";
//import { mapGetters, mapActions } from "vuex";
import { vueApp } from "@/main.js";
import { event } from "vue-gtag";
import config from "@/config/config.js";

import NProgress from "nprogress";

import loginIdP from "@/views/loginIdP.vue";
import ErrorDisplay from "@/views/ErrorDisplay.vue";

// studioMedium ROUTES =>
// public views
//import HomeView from "@/views/studioMedium/public/HomeView.vue"; //it rather will not be used
import pageNotFoundPage from "@/views/studioMedium/public/pageNotFound.vue";
import LoginView from "@/views/studioMedium/public/LoginView.vue";
import UserRegistration from "@/views/studioMedium/public/RegisterUserView.vue";
import TestView from "@/views/studioMedium/public/TestView.vue";

// protected views
import ResetPasswordRequest from "@/views/studioMedium/protected/authorization/ResetPasswordRequestView.vue";
import ResetPassword from "@/views/studioMedium/protected/authorization/ResetPasswordView.vue";
import confirmAccount from "@/views/studioMedium/protected/authorization/confirmAccount.vue";

//
import UserDashboard from "@/views/studioMedium/protected/user/UserDashboard.vue";
import UserSettings from "@/views/studioMedium/protected/user/UserSettings.vue";
import UserSubscriptions from "@/views/studioMedium/protected/user/UserSubscriptions.vue";

//
import UserCreations from "@/views/studioMedium/protected/myMusic/UserCreations.vue";
import createMusicHeader from "@/views/studioMedium/protected/myMusic/createMusicHeader.vue";
import createMusicDetailsView from "@/views/studioMedium/protected/myMusic/createMusicDetails.vue";

//
import msSubscriptions from "@/views/studioMedium/protected/msSubscriptions.vue";
import userOrderSuccess from "@/views/studioMedium/protected/user/UserOrderSuccess.vue";
import postRegistration from "@/views/studioMedium/protected/authorization/postRegistration.vue";

// studioMedium ROUTES =<

// studioLight ROUTES =>

import sl_MobWelcome from "@/views/studioLight/public/sl_welcome.vue";
import sl_MobLogin from "@/views/studioLight/public/sl_login.vue";
import sl_MobRegister from "@/views/studioLight/public/sl_register.vue";
import sl_postRegistration from "@/views/studioLight/public/sl_postRegistration.vue";
import sl_ResetPasswordRequest from "@/views/studioLight/protected/authorization/sl_ResetPasswordRequestView.vue";
import sl_ResetPassword from "@/views/studioLight/protected/authorization/sl_ResetPasswordView.vue";
import sl_confirmAccount from "@/views/studioLight/protected/authorization/sl_confirmAccount.vue";

import sl_MobUploadVideo from "@/views/studioLight/protected/sl_uploadVideo.vue";
import sl_MobMoods from "@/views/studioLight/protected/sl_moods.vue";
import sl_adjust from "@/views/studioLight/protected/sl_adjust.vue";
import sl_composing from "@/views/studioLight/protected/sl_composing.vue";
import sl_trackIsReady from "@/views/studioLight/protected/sl_trackIsReady.vue";
// studioLight  ROUTES =<

// =============================================================================
// =============================================================================
// =============================================================================
// =============================================================================

const routes = [
  {
    path: "/",
    name: "home",

    redirect: () => {
      let dastination = "";
      let P = {};
      let goAhead = 0;
      //// this is because reddirect is triggered before "beforeEach" ....
      GStore.dispatch("userModule/tryToSetStateBasedOnLocalStorage");
      ////

      ///->
      let cid = GStore.state.userModule.currentLoggedUser.client_id;
      if (typeof cid != "undefined") {
        if (cid != "") {
          goAhead = 1;
        }
      }

      let uDT = getUserDeviceType();
      GStore.state.userModule.currentLoggedUser.userDeviceType = uDT;
      let userSubscriptionLevel =
        GStore.state.userModule.currentLoggedUser.subscription
          .SubscriptionLevel;
      console.log("router] ++++++++++++++++++++++++++++++++++++++++++");
      console.log("router] ++++++++++++++++++++++++++++++++++++++++++");
      console.log(
        "router] uDT:" + uDT + " userSubscriptionLevel:" + userSubscriptionLevel
      );
      console.log("router] ++++++++++++++++++++++++++++++++++++++++++");
      console.log("router] ++++++++++++++++++++++++++++++++++++++++++");
      switch (uDT) {
        case "desktop": {
          if (goAhead == 1) {
            if (userSubscriptionLevel != "Free") {
              dastination = "userCreationsView"; // if already logged in <<<
              P = {
                pageNr: GStore.state.streamModule.currentContext.pageNr,
                ClientId: GStore.state.userModule.currentLoggedUser.client_id,
              };
            } else {
              dastination = "msSubscriptionsView";
              P = { id: 0 };
            }

            /*
            dastination = "userCreationsView"; // if already logged in <<<
            P = {
              pageNr: GStore.state.streamModule.currentContext.pageNr,
              ClientId: GStore.state.userModule.currentLoggedUser.client_id,
            };
			*/
          } else {
            //dastination = "login"; // if not logged in <<<
            dastination = "register"; // if not logged in <<< because most of the used does not have an account yet
          }
          // ----

          break;
        }
        case "mobile": {
          if (goAhead == 1) {
            dastination = "sl_mob_uploadVideo"; // if already logged in <<<
          } else {
            dastination = "sl_mob_welcome";
          }
          break;
        }
        default: {
          console.log("router] hmm where to go ?????");
          dastination = "register";
          break;
        }
      }

      return {
        name: dastination,
        params: P,
      };
      ///-<
    },
  },

  // //////////////////////////////////////////////////////////////////////////
  // Studio Light routes ... ->
  // public routes =>
  {
    path: "/slmWelcome",
    name: "sl_mob_welcome",
    component: sl_MobWelcome,
    meta: {
      requireAuth: false,
      internalViewWithoutLogin: true,
      app_version: "studioLight",
    },
  },
  //
  {
    path: "/slmlogin",
    name: "sl_mob_login",
    //props: true,
    component: sl_MobLogin,
    meta: {
      requireAuth: false,
      internalViewWithoutLogin: true,
      app_version: "studioLight",
    },
  },
  //
  {
    path: "/slmlogin/:provider/callback",
    name: "sl_mob_loginIdP",
    props: true,
    component: loginIdP,
    meta: { idp: true, requireAuth: false, app_version: "studioLight" },
    //meta: { idp: true, requireAuth: true, app_version: "studioLight" },
  },
  //
  {
    path: "/slmregister/:usageType",
    name: "sl_mob_register",
    props: (route) => ({
      usageType:
        typeof route.params.usageType != "undefined"
          ? route.params.usageType
          : "free",
    }),

    component: sl_MobRegister,
    meta: {
      requireAuth: false,
      internalViewWithoutLogin: true,
      app_version: "studioLight",
    },
  },

  //
  {
    path: "/slmregister",
    name: "sl_mob_register_default",
    props: (route) => ({
      usageType:
        typeof route.params.usageType != "undefined"
          ? route.params.usageType
          : "free",
    }),

    component: sl_MobRegister,
    meta: {
      requireAuth: false,
      internalViewWithoutLogin: true,
      app_version: "studioLight",
    },
  },

  //
  {
    path: "/slmpostReg",
    name: "sl_mob_postRegistration",
    component: sl_postRegistration,
    meta: {
      requireAuth: false,
      title: "muzaic.studio - successful registration",
      app_version: "studioMedium",
    },
  },

  //
  {
    path: "/slmrpr",
    name: "sl_mob_resetPasswordRequest",
    component: sl_ResetPasswordRequest,
    meta: {
      requireAuth: false,
      internalViewWithoutLogin: true,
      app_version: "studioMedium",
    },
  },

  //
  {
    path: "/slmrp/:token",
    name: "sl_mob_resetPassword",
    component: sl_ResetPassword,
    props: true,
    meta: {
      requireAuth: false,
      internalViewWithoutLogin: true,
      app_version: "studioMedium",
    },
  },

  //
  {
    path: "/slmconfirmAccount/:token",
    name: "sl_mob_confirmAccount",
    component: sl_confirmAccount,
    props: true,
    meta: {
      requireAuth: false,
      internalViewWithoutLogin: true,
      app_version: "studioMedium",
    },
  },

  //
  {
    path: "/slmmsSubscriptionsView/:id",
    name: "sl_mob_msSubscriptionsView",
    component: msSubscriptions,
    //props: true,
    props: (route) => ({
      id: parseInt(route.params.id) || 1,
    }),
    meta: {
      requireAuth: true,
      contentSeperation: "L",
      app_version: "studioMedium",
    },
  },

  /* ASW 2023-11 test view */
  {
    path: "/slmtest",
    name: "slm_test",
    component: TestView,
    meta: { requireAuth: false, internalViewWithoutLogin: true },
  },

  // public routes =<
  // protected routes =>
  {
    path: "/slmuv",
    name: "sl_mob_uploadVideo",
    component: sl_MobUploadVideo,
    meta: { requireAuth: true, app_version: "studioLight" },
  },

  //
  {
    path: "/slmmoods",
    name: "sl_mob_moods",
    component: sl_MobMoods,
    meta: { requireAuth: true, app_version: "studioLight" },
  },

  //
  {
    path: "/slmadj",
    name: "sl_mob_adjust",
    component: sl_adjust,
    meta: { requireAuth: true, app_version: "studioLight" },
  },

  //
  {
    path: "/slmcmp",
    name: "sl_mob_composing",
    component: sl_composing,
    meta: { requireAuth: true, app_version: "studioLight" },
  },

  //
  {
    path: "/slmtir",
    name: "sl_mob_trackIsReady",
    component: sl_trackIsReady,
    meta: { requireAuth: true, app_version: "studioLight" },
  },

  // protected routes =<
  // Studio Light routes ... -<
  // //////////////////////////////////////////////////////////////////////////

  /* ASW 2023-11 test view */
  {
    path: "/test",
    name: "test",
    component: TestView,
    meta: { requireAuth: false, internalViewWithoutLogin: true },
  },

  /*
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( "../views/studioMedium/public/AboutView.vue"),
  },
  */
  // asw ==>
  {
    path: "/login",
    name: "login",
    //props: true,
    component: LoginView,
    meta: {
      requireAuth: false,
      internalViewWithoutLogin: true,
      app_version: "studioMedium",
    },
  },
  //
  {
    path: "/login/:provider/callback",
    name: "loginIdP",
    props: true,
    component: loginIdP,
    meta: { idp: true, requireAuth: false, app_version: "studioMedium" },
  },
  //
  {
    path: "/register",
    name: "register",
    component: UserRegistration,
    meta: {
      requireAuth: false,
      internalViewWithoutLogin: true,
      app_version: "studioMedium",
    },
  },
  //
  {
    path: "/postReg",
    name: "postRegistration",
    component: postRegistration,
    meta: {
      requireAuth: false,
      title: "muzaic.studio - successful registration",
      app_version: "studioMedium",
    },
  },
  //
  {
    path: "/rpr",
    name: "resetPasswordRequest",
    component: ResetPasswordRequest,
    meta: {
      requireAuth: false,
      internalViewWithoutLogin: true,
      app_version: "studioMedium",
    },
  },
  //
  {
    path: "/rp/:token",
    name: "resetPassword",
    component: ResetPassword,
    props: true,
    meta: {
      requireAuth: false,
      internalViewWithoutLogin: true,
      app_version: "studioMedium",
    },
  },
  //
  {
    path: "/confirmAccount/:token",
    name: "confirmAccount",
    component: confirmAccount,
    props: true,
    meta: {
      requireAuth: false,
      internalViewWithoutLogin: true,
      app_version: "studioMedium",
    },
  },

  // =============================================================
  // P R O T E C T E D   R O U T E S >>>
  {
    path: "/userOrderSuccess",
    name: "userOrderSuccess",
    component: userOrderSuccess,
    props: (route) => ({
      sessionId: route.query.session_id,
      usecaseContext: route.query.ucc,
    }),
    meta: {
      requireAuth: true,
      title: "muzaic.studio - successful order",
      contentSeperation: "L",
      app_version: "studioMedium",
    },
  },

  {
    path: "/userdashboard/",
    name: "UserDashboard",
    component: UserDashboard,

    props: {
      id: GStore.state.userModule.currentLoggedUser.user_id,
    },

    meta: {
      requireAuth: true,
      contentSeperation: "L",
      app_version: "studioMedium",
    },
  },
  //
  {
    path: "/msSubscriptionsView/:id",
    name: "msSubscriptionsView",
    component: msSubscriptions,
    //props: true,
    props: (route) => ({
      id: parseInt(route.params.id) || 0,
    }),
    meta: {
      requireAuth: true,
      contentSeperation: "L",
      app_version: "studioMedium",
    },
  },
  //
  {
    path: "/createMusicHeaderView/",
    name: "createMusicHeaderView",
    component: createMusicHeader,
    props: true,
    meta: {
      requireAuth: true,
      contentSeperation: "L",
      app_version: "studioMedium",
    },
  },
  //
  {
    path: "/createMusicDetailsView/",
    name: "createMusicDetailsView",
    component: createMusicDetailsView,
    props: true,
    meta: {
      requireAuth: true,
      contentSeperation: "S",
      app_version: "studioMedium",
    },
  },

  // profile menu >>>
  // current loggedin user ->
  {
    path: "/userSettings/:UserId",
    name: "userSettingsView",
    component: UserSettings,
    props: true,
    meta: {
      requireAuth: true,
      contentSeperation: "L",
      app_version: "studioMedium",
    },
  },
  //My Music -->
  {
    path: "/userCreations/:pageNr/:ClientId",
    name: "userCreationsView",
    component: UserCreations,

    props: (route) => ({
      pageNr:
        parseInt(route.params.pageNr) ||
        GStore.state.streamModule.currentContext.pageNr,
      ClientId:
        parseInt(route.params.ClientId) ||
        GStore.state.userModule.currentLoggedUser.client_id,
    }),
    meta: {
      requireAuth: true,
      contentSeperation: "L",
      app_version: "studioMedium",
    },
  },
  //My Subscriptions -->
  {
    path: "/userSubscriptions/",
    name: "userSubscriptionsView",
    component: UserSubscriptions,
    props: true,
    meta: {
      requireAuth: true,
      contentSeperation: "L",
      app_version: "studioMedium",
    },
  },
  // <-
  // profile menu <<<
  // P R O T E C T E D   R O U T E S <<<
  // =============================================================

  // -------------------------------------------------------------------------
  {
    path: "/error/:error",
    name: "ErrorDisplay",
    props: true,
    component: ErrorDisplay,
    meta: { app_version: "studioMedium" },
  },

  /* [404] anything else... */
  {
    path: "/:catchAll(.*)",
    component: pageNotFoundPage,
    meta: { msg: "none of the defined paths...", app_version: "studioMedium" },
  },
  /*{ path: "/:catchAll(.*)", redirect: { name: "login" } },*/
  // -------------------------------------------------------------------------
];

// ===========================================================================
// ===========================================================================
// ===========================================================================

const DEFAULT_TITLE =
  "Muzaic Studio – AI composed soundtrack for your videos....";
// create router object ...
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// check if user is using mobile device if not do not display mobile views ...
router.beforeResolve((to, from, next) => {
  vueApp.$_log("router beforeResolve] from:", from);
  vueApp.$_log("router beforeResolve] to:", to);
  let goAhead = 1;
  let userDeviceType = GStore.state.userModule.currentLoggedUser.userDeviceType;
  let userSubscriptionLevel =
    GStore.state.userModule.currentLoggedUser.subscription.SubscriptionLevel;
  // this is request with query string from idProviders ... >>
  let IsItException = 0;

  const regexRule = /\/login\/(google|apple|facebook)\/callback/g;

  if (to.path.match(regexRule) !== null || userSubscriptionLevel != "Free") {
    console.log("!!! EXCEPTION !!!! to.path:" + to.path);
    IsItException = 1;
  }
  // this is request with query string from idProviders ... <<

  if (
    userDeviceType == "mobile" &&
    to.path.indexOf("/slm") == -1 &&
    IsItException == 0
  ) {
    //goAhead = 0;
    console.log(
      "request from MOBILE DEVICE BUT withoult 'slm' prefix ... to.path:" +
        to.path
    );
    let p = to.path.substring(1, to.path.length);
    p = "/slm" + p;
    console.log("to.path:" + p);
    next(p);
  } else {
    console.log("+++++++++++++++++++++++++++++++++++++");
    console.log("+++++++++++++++++++++++++++++++++++++");
    console.log("+++++++++++++++++++++++++++++++++++++");
    if (typeof to.meta != "undefined") {
      vueApp.$_log("router beforeResolve] 1 to.meta:", to.meta);
      if (typeof to.meta.app_version != "undefined") {
        vueApp.$_log(
          "router beforeResolve] 2 to.meta.app_version:" +
            to.meta.app_version +
            " userDeviceType:" +
            userDeviceType
        );
        if (
          to.meta.app_version == "studioLight" &&
          userDeviceType != "mobile"
        ) {
          goAhead = 0;
          vueApp.$_log("router beforeResolve] 3");
          vueApp.$_log(
            "router beforeResolve] lets reddirect to the desktop route ...goAhead:" +
              goAhead
          );
        } else {
          vueApp.$_log("router beforeResolve] 3a");
        }
      } else {
        vueApp.$_log("router beforeResolve] 2a");
      }
    } else {
      vueApp.$_log("router beforeResolve] 1a ");
    }

    if (goAhead == 0) {
      next({ name: "home" });
    } else {
      next();
    }
  }
});

// progress bar + authorization check etc ....
router.beforeEach(async (to, from, next) => {
  //  show progress bar .... (without spinner)
  NProgress.configure({
    template: '	<div class="bar" role="bar"><div class="peg"></div></div>',
  });
  NProgress.start();
  GStore.state.globalTM.pageLoadingStatus = 10; // < at the moment not in use

  vueApp.$_log("router beforeEach] to:" + to.fullPath);
  console.log("router beforeEach] meta:", to.meta);

  vueApp.$_log(GStore.state.userModule.checkingState);
  if (to.meta.idp == true) {
    next();
  } else {
    if (GStore.state.userModule.checkingState == 0) {
      await GStore.dispatch("userModule/isUserLoggedIn_Action_P").then(
        (response) => {
          vueApp.$_log("router beforeEach response:" + response);

          if (response === false && to.meta.requireAuth) {
            const n = {
              type: "error",
              message: "Sorry you are not authorized",
            };
            GStore.dispatch("notificationModule/add", n, { root: true });
            next({ name: "login" });
          }

          if (typeof from.href != "undefined") {
            // <--- If this navigation came from a previous page.
            vueApp.$_log(
              "router beforeEach] from.href IS defined:" + from.href
            );
            //return false;
            next();
          } else {
            // <--- if navigating directly
            vueApp.$_log("router beforeEach] from.href is not defined! ");
            //return { path: "/" }; // <--- Push navigation to the root route.
            next();
          }
        },
        async (error) => {
          vueApp.$_log(
            "router beforeEach] 000 error:",
            error.response.data.error
          );
          if (error.response.data.error == "not valid token") {
            vueApp.$_log(
              "router beforeEach] ooook so lets logout and login again..."
            );

            await GStore.dispatch("userModule/logout")
              .then(() => {
                vueApp.$_log(
                  "router beforeEach] just after logout .... current to:",
                  to
                );
                //return { name: "login" };

                vueApp.$_log(
                  "router beforeEach] ooook so lets reddirect route.... to: home"
                );
                vueApp.$_log("===============================================");
                //next({ name: "home" });
                next({ name: "login" });
              })
              .catch((error) => {
                vueApp.$_log("router beforeEach] dammit ... error:", error);
              });
          }
        }
      );

      //
    } else {
      vueApp.$_log(
        "router beforeEach] somebody is already checking user status...."
      );
      next();
    }
  }
});

//

router.afterEach((to, from) => {
  vueApp.$_log("router afterEach] from:", from);
  vueApp.$_log("router afterEach] to:", to);

  vueApp.$_log(
    "router afterEach] userId:" +
      GStore.state.userModule.currentLoggedUser.user_id +
      " opening page:" +
      to.path
  );

  document.title = to.meta.title || DEFAULT_TITLE;

  NProgress.done();
  GStore.state.globalTM.pageLoadingStatus = 20; // < at the moment not in use

  vueApp.$nextTick(() => {
    vueApp.$_log("router afterEach] nextTick............................>>");
    vueApp.$_log("router afterEach] nextTick.... send signal to posthog...");
    // ---->
    vueApp.$_log(
      "process.env.VUE_APP_DISABLE_TRACKERS:" +
        process.env.VUE_APP_DISABLE_TRACKERS
    );
    if (typeof process.env.VUE_APP_DISABLE_TRACKERS != "undefined") {
      if (process.env.VUE_APP_DISABLE_TRACKERS == 0) {
        vueApp.$posthog.capture(config.posthog.eventName, {
          fullPath: to.fullPath,
        });

        if (typeof event != "undefined") {
          vueApp.$_log("router afterEach] nextTick... and to google ...");
          event("asw-test-pageview", {
            fullPath: to.fullPath,
          });
        }
      }
    }
    if (to.meta.requireAuth === true) {
      vueApp.$_log("register traffic inside the app ...");
      usrSrv.ual({
        User_id: GStore.state.userModule.currentLoggedUser.user_id,
        path: to.path,
        fullpath: to.fullPath,
        remarks: "",
      });
    } else {
      vueApp.$_log(
        "register traffic inside the app ... public page... meta:",
        to.meta
      );
    }

    // ----<
    vueApp.$_log("router afterEach] nextTick............................<<");
  });
});

// ---------------------------------------------------------------
// ---------------------------------------------------------------
function getUserDeviceType() {
  let userDeviceType;
  if (isMobileUserAgent() && hasTouchSupport() && isMobileWidth()) {
    userDeviceType = "mobile";
  } else {
    userDeviceType = "desktop";
  }

  //console.log(">>===== userDeviceType:" + userDeviceType + " =====<<");
  return userDeviceType;
}
function isMobileUserAgent() {
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
}
function hasTouchSupport() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}
function isMobileWidth() {
  const minWidth = 768; // Minimum width for desktop devices
  return window.innerWidth < minWidth || screen.width < minWidth;
}
// ---------------------------------------------------------------
// ---------------------------------------------------------------

export default router;
