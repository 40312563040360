<template>
  <div>
    <button
      v-if="lgnFacebook"
      @click="useAuthProvider('facebook', null, mode)"
      class="authProvider"
    >
      <img :src="$getImageUrl('Facebook.svg')" />
    </button>

    <button
      v-if="lgnApple"
      @click="useAuthProvider('apple', null, mode)"
      class="authProvider"
    >
      <img :src="$getImageUrl('Apple.svg')" />
    </button>

    <button
      v-if="lgnGoogle"
      @click="useAuthProvider('google', null, mode)"
      class="authProvider"
    >
      <img :src="$getImageUrl('Google.svg')" />
    </button>
  </div>
</template>

<script>
import config from "@/config/config.js";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { Providers } from "universal-social-auth";
import UniversalSocialauth from "universal-social-auth";

const USAuth_options = {
  providers: config.UniversalSocialauth_idProviders,
};
const Oauth = new UniversalSocialauth(axios, USAuth_options);

export default {
  name: "idProviders",
  props: {
    mode: { type: String, default: "register" },
    msg: { type: String, default: "" },
    lgnGoogle: { type: Boolean, default: true },
    lgnApple: { type: Boolean, default: true },
    lgnFacebook: { type: Boolean, default: true },
  },
  data() {
    return {
      dataReady: true,
      responseData: {},
    };
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
      "getUserDevicetype",
      "currentSubscription",
    ]),
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("userModule", [
      "login",
      "registerUsingIdProviderData",
      "loginUsingIdProviderData",
      "setCurrentUserDeviceType",
    ]),

    //
    log(str, obj) {
      if (typeof obj == "undefined") {
        this.$_log(this.$options.name + "] " + str);
      } else {
        this.$_log(this.$options.name + "] " + str, obj);
      }
    },

    //
    /*
	 emitter.on('OauthCall', (e) => {
   if (e) {
     responseData.value.code = e
     useSocialLogin()
   }
 })
 */

    // ======================================================================
    useAuthProvider(provider, proData, mode) {
      const pro = proData;
      this.log(
        "RegisterDialog] ====== lets try to register using external authentication provider:" +
          provider
      );
      const ProData = pro || Providers[provider];
      Oauth.authenticate(provider, ProData)
        .then((response) => {
          this.log("useAuthProvider response:", response);
          const rsp = response;
          if (rsp.code) {
            this.log("useAuthProvider response.code:" + rsp.code);
            this.responseData.code = rsp.code;
            this.responseData.provider = provider;
            switch (mode) {
              case "register": {
                this.log("useAuthProvider register....");
                this.useSocialLoginToRegister(provider);
                break;
              }
              case "login": {
                this.log("useAuthProvider login....");
                this.useSocialLoginToLogin(provider);
                break;
              }
            }
          }
        })
        .catch((err) => {
          this.log("useAuthProvider 1 error:", err);
        });
    },

    // ======================================================================
    useSocialLoginToRegister(provider) {
      this.log("useSocialLogin to register new user .... provider:" + provider);
      let data = {
        provider: this.responseData.provider,
        code: this.responseData.code,
        serviceName: "muzaic.studio",
        redirectUri:
          config.UniversalSocialauth_idProviders[this.responseData.provider]
            .redirectUri,
      };

      this.registerUsingIdProviderData(data)
        .then(() => {
          this.postRegistration();
        })
        .catch((err) => {
          this.log("useSocialLogin error:", err);
        });
    },
    postRegistration() {
      if (this.getUserDevicetype == "mobile") {
        this.log(
          "postRegistration getUserDevicetype:" +
            this.getUserDevicetype +
            " SubscriptionLevel:" +
            this.currentSubscription.SubscriptionLevel
        );
        if (this.currentSubscription.SubscriptionLevel != "Free") {
          this.setCurrentUserDeviceType("desktop");
          this.$router.push({ name: "createMusicHeaderView" });
        } else {
          this.$router.push({
            name: "sl_mob_uploadVideo",
          });
        }
      } else {
        this.$router.push({
          name: "userCreationsView",
          params: {
            pageNr: 0,
            ClientId: this.currentClientId,
          },
        });
      }
    },

    // ======================================================================
    useSocialLoginToLogin(provider) {
      this.log("useSocialLoginToLogin ....provider:" + provider);

      this.log("redirectUri:" + this.responseData.provider);
      let data = {
        provider: this.responseData.provider,
        code: this.responseData.code,
        serviceName: "muzaic.studio",
        redirectUri:
          config.UniversalSocialauth_idProviders[this.responseData.provider]
            .redirectUri,
      };

      this.loginUsingIdProviderData(data)
        .then(() => {
          this.postLogin();
        })
        .catch((err) => {
          this.log("useSocialLogin Error:" + err);

          this.$router.push({
            name: "register",
          });
        });
    },
    postLogin() {
      this.log("postLogin this.getUserDevicetype:" + this.getUserDevicetype);

      if (this.getUserDevicetype == "mobile") {
        this.log(
          "postLogin getUserDevicetype:" +
            this.getUserDevicetype +
            " SubscriptionLevel:" +
            this.currentSubscription.SubscriptionLevel
        );
        if (this.currentSubscription.SubscriptionLevel != "Free") {
          this.setCurrentUserDeviceType("desktop");
          this.$router.push({ name: "createMusicHeaderView" });
        } else {
          this.$router.push({ name: "sl_mob_uploadVideo" });
          /*
          this.$router.push({
            name: "userCreationsView",
            params: {
              pageNr: 0,
              ClientId: this.currentClientId,
            },
          });
		  */
        }
      } else {
        this.$router.push({
          name: "userCreationsView",
          params: {
            pageNr: 0,
            ClientId: this.currentClientId,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.authProvider {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  padding: 0px 24px;
}
</style>
