import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiWeather = axios.create({
  baseURL: config.api_host,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  getCityByPosition(lat, lon) {
    vueApp.$_log("===========================");

    const userToken = GStore.getters["userModule/userToken"];
    vueApp.$_log("===========================");
    return apiWeather.get("/pub/w_gcbp/" + lat + "/" + lon + "/", {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  // w_gcw - get weather based on city name
  getWeatherForCity(cityName) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiWeather.get("/pub/w_gcw/" + cityName, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
