<template>
  <div class="idpBbkMsg">
    <p>thanks '{{ provider }}'</p>
    <span>{{ qStr }}</span>
  </div>
</template>

<script>
export default {
  name: "loginIdP",
  props: ["provider"],
  emits: ["OauthCall"],
  data() {
    return {
      qStr: "",
    };
  },
  created() {
    this.log("created ... A");

    this.log("created ... Z");
  },
  mounted() {
    this.log("mounted ... A");
    if (this.$route.query != "") {
      this.log("this.$route.query:", this.$route.query);
    }
    this.log("this.$route:", this.$route);
    this.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    this.qStr = this.$router.query;

    this.log("mounted ... Z");
  },
  methods: {
    //
    log(str, obj) {
      if (typeof obj == "undefined") {
        this.$_log(this.$options.name + "] " + str);
      } else {
        this.$_log(this.$options.name + "] " + str, obj);
      }
    },
  },
};
</script>

<style scoped>
.idpBbkMsg {
  height: 99vh;
  width: 99vw;
  position: relative;
  border: 3px solid var(--main-blue-color);
}
.idpBbkMsg p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
