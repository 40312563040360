<template>
  <div class="gridAdminPanelContainer">
    <globalHeader msg="" />
    <div class="mobile-main-container">
      <div class="mmi_cnt">
        <router-view />
      </div>
    </div>
    <globalFooter msg="" />
  </div>
</template>

<script>
//:style="getCpontentSeparation(this.$route.meta.contentSeperation)"
import globalHeader from "@/components/globalHeader.vue";
import globalFooter from "@/components/globalFooter.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "AdminPanel",
  components: {
    globalHeader,
    globalFooter,
  },
  props: {
    msg: String,
  },
  created() {
    this.$_log(
      "PanelMobile] created 0 ... getPageLoadingStatus:" +
        this.getPageLoadingStatus
    );
    this.setCurrentPLS(1);
    this.$_log(
      "PanelMobile] created 1 ... getPageLoadingStatus:" +
        this.getPageLoadingStatus
    );
  },
  beforeMount() {
    this.$_log(
      "PanelMobile] beforeMount 0 ... getPageLoadingStatus:" +
        this.getPageLoadingStatus
    );
  },
  mounted() {
    this.$_log(
      "PanelMobile] mounted 0 ... getPageLoadingStatus:" +
        this.getPageLoadingStatus
    );
  },
  updated() {
    this.$_log(
      "PanelMobile] updated 0 ... getPageLoadingStatus:" +
        this.getPageLoadingStatus
    );
  },
  computed: {
    ...mapGetters("globalTM", ["getPageLoadingStatus"]),
  },
  methods: {
    ...mapActions("globalTM", {
      setCurrentPLS: "setPageLoadingStatus",
    }),
    getCpontentSeparation(contentSeperation) {
      if (typeof contentSeperation == "undefined") {
        contentSeperation = "L";
      }
      let s = "";
      switch (contentSeperation) {
        case "L": {
          //s = "--menu-content-seperation-L";
          s = "main-container-L";
          break;
        }
        case "S": {
          //s = "--menu-content-seperation-S";
          s = "main-container-S";
          break;
        }
        default: {
          //s = "--menu-content-seperation-L";
          s = "main-container-Default";
          break;
        }
      }
      //return " padding-top: var(" + s + ");";
      return s;
    },
  },
};
</script>

<style src="@/assets/css/dialogform_0.css"></style>
<style>
.mob {
  position: relative;
  z-index: 0;
}
.gridAdminPanelContainer {
  display: grid;
  grid-template-columns: 1fr;

  gap: 0px 0px;
  grid-auto-flow: row dense;

  grid-template-areas:
    "top-placeholder"
    "main-container";

  width: calc(100vw - var(--global-left-margin));

  min-width: 360px;
}

.mobile-main-container {
  grid-area: main-container;
  min-height: 650px;
  display: flex;
  justify-content: center;
  align-items: end;
  height: calc(100vh - 65px);
}

.mmi_cnt {
  margin-top: 0px;
  padding: 16px 16px 16px 16px;
  border: 0px solid red;
  width: 100%;
  display: flex;
  justify-content: center;
}

.mobileL .tpl {
  margin-top: 20px;
  align-items: center;
  height: 45px;
}

.gradient01 {
  background: linear-gradient(270deg, #01ddff 28.32%, #0038ff 42.09%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.mobileL form div.idf_submit_line {
  gap: 24px 10px;
}

/* ========================================================== */
/* ========================================================== */
/* ========================================================== */
</style>
