import { createConfirmDialog } from "vuejs-confirm-dialog";
import UpdateCreation from "@/components/dialogs/modal/UpdateCreation.vue";

const useUpdateCreation_BeforeAction = (action, cancelAction, props) => {
  const { reveal, onConfirm, onCancel } = createConfirmDialog(
    UpdateCreation,
    props
  );

  reveal();

  onConfirm(action);
  onCancel(cancelAction);
};

export default useUpdateCreation_BeforeAction;
