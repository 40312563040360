<template>
  <div
    :id="'audioComponent_' + id"
    :class="'ac ' + playerStyle"
    :ref="'root_cmp' + id"
  >
    <template v-if="showPlayer">
      <!---->

      <audio
        :id="'apObj_' + id"
        style="display: none !important"
        preload
        controls
        controlslist="play timeline volume"
      >
        Your browser does not support the audio tag.
      </audio>
      <!---->
      <div>
        <div class="audioPlayerControls">
          <template v-if="audioList.length > 1 && pT != 'miniPalyer'">
            <div id="audioPrevTrack">&lt;&lt;</div>
            ---
          </template>

          <div id="audioPlayTrack">
            <div
              :id="'pab_' + id"
              :class="'playAudBtn ' + playerStyle"
              @click="onPlayClicked"
            ></div>
          </div>
          <template v-if="audioList.length > 1 && pT != 'miniPalyer'">
            ---
            <div id="audioNextTrack">&gt;&gt;</div>
          </template>
        </div>
      </div>
      <div v-if="pT != 'miniPlayer'" id="currentTrackParams">
        <div id="trackCurrentTime">{{ trackCT }}</div>
        <div class="audioPlayerBarContainer">
          <div class="audioPlayerBuffered">
            <span id="buffered-amount"></span>
          </div>
          <div :class="'audioPlayerProgress ' + playerStyle">
            <span id="progress-amount"></span>
          </div>
          <div id="audioPlayerPosition"></div>
        </div>

        <div id="trackTotalTime">{{ trackTT }}</div>
      </div>
    </template>
    <template v-else
      ><span class="notplayabletrack">track is not playable ...</span>
    </template>
  </div>
</template>

<script>
import { watch } from "vue";
import { mapActions } from "vuex";

export default {
  name: "aswAudioPlayer",

  components: {},
  props: [
    "id",
    "audioList",
    "playerType",
    "playerStyle",
    "playerVolume",
    "playerTimeFormat",
  ],
  emits: [
    "playClicked",
    "trackEnds",
    "trackPause",
    "playerError",
    "progressDragged",
    "playerInitialized",
    "componentInterface",
  ],
  data() {
    return {
      track0: null, //current audio blob
      track1: null, //next audio blob
      getNext: 0,
      currentTrackIdx: 0,
      trackCT: "00:00:00",
      trackTT: "--:--:--",
      showPlayer: true,
      action: "stop",
      pT: typeof this.playerType != "undefined" ? this.playerType : "",
      audioObj: null,
      audioUI: null,
    };
  },

  created() {
    this.log("[this.id=" + this.id + "] just created");
  },
  mounted() {
    this.log(
      "[this.id=" + this.id + "] mounted this.audioList:",
      this.audioList
    );
    //let audioComponent = this.$el;
    //let myAudio = audioComponent.querySelector("#apObj_" + this.id);
    this.audioUI = this.$el;
    this.audioObj = this.audioUI.querySelector("#apObj_" + this.id);

    this.preparePlayer(this.audioObj, 1);
    watch(
      () => [this.id, this.audioList[0]],
      ([new_audioId, new_audioList], [old_audioId, old_audioList]) => {
        this.log(
          "watch() CCCCCC old_audioId:" +
            old_audioId +
            " new_audioId:" +
            new_audioId
        );
        this.log(
          "watch() CCCCCC old_audioList.name:" +
            old_audioList.name +
            " new_audioList.name:" +
            new_audioList.name
        );
        if (
          old_audioId != new_audioId ||
          (old_audioList.name != new_audioList.name &&
            new_audioList.name != "") ||
          (old_audioList.id != new_audioList.id && new_audioList.id != "")
        ) {
          this.log("-=============== *******************");
          if (this.action == "play") {
            // previous track is currently playing so lets stop it first
            this.log(
              "component aswAudioPlayer // previous track is currently playing so lets stop it first"
            );
            this.currentTrack_Rewind(this.audioObj, this.audioUI);
          }

          this.preparePlayer(this.audioObj, 0);
          this.log("-=============== *******************");
        } else {
          this.log("component aswAudioPlayer player will not be updated");
          if (new_audioList.url != "") {
            this.log("component aswAudioPlayer new track url is empty");
          }
        }
      }
    );

    this.emitInterface();
  },
  updated() {
    this.log("updated [this.id=" + this.id + "] just updated");

    this.log("updated this.audioList:", this.audioList);
    //let audioComponent = this.$el;
    //let myAudio = audioComponent.querySelector("#apObj_" + this.id);
    //let audioComponent = this.audioUI;
    let myAudio = this.audioObj;

    if (myAudio !== null) {
      if (myAudio.volume != this.playerVolume) {
        myAudio.volume = this.playerVolume;
      }
    }
  },
  beforeUnmount() {
    this.log("JUST BEFORE UNMOUNTED !!!!!!!!!!!!!!!!!!!!!");
  },
  computed: {},
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),

    //
    log(str, obj) {
      if (typeof obj == "undefined") {
        this.$_log(this.$options.name + "] " + str);
      } else {
        this.$_log(this.$options.name + "] " + str, obj);
      }
    },

    // I N T E R F A C E >>
    // ---------------------------------------------------------
    emitInterface() {
      this.log("emitInterface() ... A");
      this.$emit("componentInterface", {
        pauseTrack: () => this.pauseCurrentTrack(),
        startTrack: () => this.startCurrentTrack(),
        rewindTrack: () => this.rewindCurrentTrack(),
      });
    },

    // TODO: audioComponent and myAudio should be object variable set in mounted !!
    // ------------------------------------------------------------
    pauseCurrentTrack() {
      this.log("pauseCurrentTrack()");

      this.currentTrack_Pause(this.audioObj, this.audioUI);
    },
    // ------------------------------------------------------------
    startCurrentTrack() {
      this.log("startCurrentTrack()");

      this.currentTrack_Play(this.audioObj, this.audioUI);
    },
    // ------------------------------------------------------------
    rewindCurrentTrack() {
      this.log("rewindCurrentTrack()");

      this.currentTrack_Rewind(this.audioObj, this.audioUI);
    },
    // I N T E R F A C E <<

    // ------------------------------------------------------------
    // ------------------------------------------------------------
    // ------------------------------------------------------------
    preparePlayer(myAudio, initialize) {
      this.fetchTrackData(this.currentTrackIdx)
        .then((trackBlob) => {
          this.currentTrack_PrepareData(myAudio, trackBlob, initialize);
        })
        .then(() => {
          if (initialize == 1) {
            this.initializePlayer();
          }
          this.$emit("playerInitialized", {
            elemId: this.id,
            trackName: this.audioList[0].name,
            trackId: this.audioList[0].id,
            action: this.action,
          });
        })
        .catch((err) => {
          this.showPlayer = false;

          this.$emit("playerError", {
            elemId: this.id,
            trackName: this.audioList[0].name,
            trackId: this.audioList[0].id,
            error: err,
          });
        });
    },

    // ------------------------------------------------------------
    //    onPlayClicked(event) {
    onPlayClicked() {
      this.log("onPlayClicked current action:" + this.action);

      /*
      let action = "";

      switch (this.action) {
        case "" || "stop" || "pause": {
          action = "play";
          break;
        }
        case "play": {
          action = "stop";
          break;
        }
      }
	  

      this.$emit("playClicked", {
        elemId: event.target.id,
        trackName: this.audioList[0].name,
        trackId: this.id,
        action: action,
      });
	  */
    },

    // ------------------------------------------------------------
    fetchTrackData(trackIdx) {
      return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        this.log(
          "fetchTrackData url to be played:" + this.audioList[trackIdx].url
        );
        xhr.open("GET", this.audioList[trackIdx].url, true);
        xhr.responseType = "blob";

        xhr.onload = () => {
          if (xhr.status === 200) {
            let audioBlob = xhr.response;
            this.track0 = URL.createObjectURL(audioBlob); // IE10+
            // audio is now downloaded
            // and we can set it as source on the audio element
            resolve(this.track0);
          } else {
            this.log("fetchTrackData req load this.status:" + xhr.status);
            reject("req load this.status:" + xhr.status);
          }
        };

        xhr.onerror = function (error) {
          reject("xhr error:", error);
        };

        xhr.send();
      });
    },

    // ------------------------------------------------------------
    currentTrack_PrepareData(myAudio, trackBlob, initialize) {
      if (initialize == 0) {
        myAudio.src = trackBlob;
        myAudio.load();
      } else {
        this.track1 = trackBlob;
        this.track0 = this.track1;
        myAudio.src = this.track0;
        this.track1 = null;

        myAudio.load();
        myAudio.volume = this.playerVolume;

        this.log("this.playerType:" + this.playerType);
        this.log("myAudio.volume:" + myAudio.volume);
      }
    },

    // ------------------------------------------------------------
    currentTrack_Play(myAudio, audioComponent) {
      myAudio.play();
      this.action = "play";

      this.log(
        "currentTrack_Play ... ooook lets start track myAudio.duration:" +
          myAudio.duration +
          " myAudio.volume:" +
          myAudio.volume +
          " this.id:" +
          this.id
      );

      this.show_startOfTrack(myAudio, audioComponent);

      ////
      this.$emit("playClicked", {
        elemId: "pab_" + this.id,
        trackName: this.audioList[0].name,
        trackId: this.audioList[0].id,
        action: this.action,
      });
    },

    // ------------------------------------------------------------
    currentTrack_Rewind(myAudio, audioComponent) {
      this.log("ooook lets rewind track");

      this.action = "stop";
      myAudio.pause();
      myAudio.currentTime = 0; // rewind to the begining of the track

      this.show_endOfTrack(audioComponent);

      this.$emit("trackEnds", {
        elemId: "pab_" + this.id,
        trackName: this.audioList[0].name,
        trackId: this.audioList[0].id,
        action: "stop",
      });
    },

    // ------------------------------------------------------------
    currentTrack_Pause(myAudio, audioComponent) {
      myAudio.pause();
      this.action = "pause";

      this.log("currentTrack_Pause .... ooook lets pause track");
      this.show_pauseOfTrack(audioComponent);

      this.$emit("trackPause", {
        elemId: "pab_" + this.id,
        trackName: this.audioList[0].name,
        trackId: this.audioList[0].id,
        action: "pause",
      });
    },

    // ------------------------------------------------------------
    formatTime(t) {
      let tstr = "";
      if (this.playerTimeFormat == "mm:ss") {
        tstr = this.$toHHMMSS(t, this.playerTimeFormat);
      } else {
        tstr = this.$toHHMMSS(t);
      }
      return tstr;
    },
    // ------------------------------------------------------------
    show_startOfTrack(myAudio, audioComponent) {
      this.log("show_startOfTrack ... A");
      audioComponent
        .querySelector("#audioPlayTrack > div ")
        .classList.remove("playAudBtn");
      audioComponent
        .querySelector("#audioPlayTrack > div ")
        .classList.add("pauseAudBtn");

      let a = this.action;
      if (this.action != "pause" && myAudio.currentTime != 0) {
        a = "resume_track_time";
      } else {
        a = "reset_track_time";
      }
      this.log(
        "show_startOfTrack myAudio.currentTime:" +
          myAudio.currentTime +
          " a:" +
          a
      );
      if (a == "reset_track_time") {
        this.trackCT = this.formatTime(0);
        this.trackTT = this.formatTime(myAudio.duration);
      }
    },

    // ------------------------------------------------------------
    show_pauseOfTrack(audioComponent) {
      this.log("show_pauseOfTrack ... A");
      audioComponent
        .querySelector("#audioPlayTrack > div ")
        .classList.remove("pauseAudBtn");
      audioComponent
        .querySelector("#audioPlayTrack > div ")
        .classList.add("playAudBtn");
    },

    // ------------------------------------------------------------
    show_endOfTrack(audioComponent) {
      this.log("show_endOfTrack ... A");
      audioComponent
        .querySelector("#audioPlayTrack > div ")
        .classList.remove("pauseAudBtn");
      audioComponent
        .querySelector("#audioPlayTrack > div ")
        .classList.add("playAudBtn");

      this.trackCT = this.formatTime(0); //"00:00:00";
      if (this.pT != "miniPlayer") {
        audioComponent.querySelector("#audioPlayerPosition").style.left = "0px";
        audioComponent.querySelector("#progress-amount").style.width = "0px";
      }

      /*
      this.$emit("trackEnds", {
        elemId: "pab_" + this.id,
        trackName: this.audioList[0].name,
        trackId: this.audioList[0].id,
        action: "stop",
      });
	  */
    },

    // ------------------------------------------------------------
    initializePlayer() {
      // -- AUDIO PLAYER ==>

      this.log(
        "initializePlayer ; lets look for -> #audioComponent_" + this.id
      );
      // this.$el is THIS component not main document DOM
      //let audioComponent = this.$el;
      //let myAudio = audioComponent.querySelector("#apObj_" + this.id);
      let audioComponent = this.audioUI;
      let myAudio = this.audioObj;

      // +++

      myAudio.onloadedmetadata = function () {
        this.trackCT = this.formatTime(0); //"00:00:00";
        this.trackTT = this.formatTime(myAudio.duration); //this.$toHHMMSS(myAudio.duration);

        if (this.pT != "miniPlayer") {
          audioComponent.querySelector("#audioPlayerPosition").style.left =
            "0px";
          audioComponent.querySelector("#progress-amount").style.width = "0px";
        }
      }.bind(this);

      // NEXT TRACK from the list BUTTON ---------------------------------------->
      if (this.audioList.length > 1 && this.pT != "miniPlayer") {
        let nextTrack = audioComponent.querySelector("#audioNextTrack");
        nextTrack.addEventListener("click", () => {
          this.getNext = 1;
          if (this.currentTrackIdx + 1 == this.audioList.length) {
            this.log("oook to juz wszystkie utwory zacznijmy od nowa");
            this.currentTrackIdx = -1;
          }

          this.fetchTrackData(this.currentTrackIdx + 1)
            .then((trackBlob) => {
              let wasPaused = myAudio.paused;
              myAudio.pause();

              this.currentTrack_PrepareData(myAudio, trackBlob, 1);

              this.getNext = 0;
              this.currentTrackIdx++;
              if (wasPaused === false) {
                myAudio.play();
              }
            })
            .catch((err) => {
              var n = {
                type: "problem",
                message: "track could not be downloaded. err:" + err,
              };
              this.addNotificationMessage(n);
            });
        });
      }
      // NEXT TRACK from the list BUTTON ----------------------------------------<

      // PREV TRACK from the list BUTTON ---------------------------------------->
      if (this.audioList.length > 1 && this.pT != "miniPlayer") {
        let prevTrack = audioComponent.querySelector("#audioPrevTrack");
        prevTrack.addEventListener("click", () => {
          this.getNext = 1;
          if (this.currentTrackIdx == 0) {
            this.log(
              "oook to jest pierwszy utwór przejdźmy do koniec listy..."
            );
            this.currentTrackIdx = this.audioList.length;
          }
          this.log(
            "ooook lets take previous track:" +
              this.currentTrackIdx +
              "/" +
              this.audioList.length
          );

          this.fetchTrackData(this.currentTrackIdx - 1)
            .then((trackBlob) => {
              let wasPaused = myAudio.paused;
              myAudio.pause();

              this.currentTrack_PrepareData(myAudio, trackBlob, 1);

              this.getNext = 0;
              this.currentTrackIdx--;
              if (wasPaused === false) {
                myAudio.play();
              }
            })
            .catch((err) => {
              var n = {
                type: "problem",
                message: "track could not be downloaded. err:" + err,
              };
              this.addNotificationMessage(n);
            });
        });
      }
      // PREV TRACK from the list BUTTON ----------------------------------------<

      // CURRENT TRACK BUTTON (play/pause ) ------------------------------------->
      let playTrack = audioComponent.querySelector("#audioPlayTrack");

      playTrack.addEventListener("click", () => {
        console.log(
          "audioPlayer click player icon listener .... current action:" +
            this.action
        );
        //if (myAudio.paused === true) {
        if (this.action != "play") {
          this.currentTrack_Play(myAudio, audioComponent);
        } else {
          //this.currentTrack_Rewind(myAudio, audioComponent);
          this.currentTrack_Pause(myAudio, audioComponent);
        }
      });
      // CURRENT TRACK BUTTON (play/pause ) -------------------------------------<

      // ---------------------------------------------------------------------
      // LOAD PROGRESS
      myAudio.addEventListener("progress", () => {
        let bufferedEnd = myAudio.buffered.end(myAudio.buffered.length - 1);
        let duration = myAudio.duration;

        let p = (bufferedEnd / duration) * 100 + "%";
        this.log("event progress p:" + p);
        if (duration > 0 && this.pT != "miniPlayer") {
          audioComponent.querySelector("#buffered-amount").style.width = p;
        }
      });

      // --------------------------------------------------------------------->
      myAudio.addEventListener("timeupdate", () => {
        let duration = myAudio.duration;
        let p = (myAudio.currentTime / duration) * 100;

        if (duration > 0) {
          if (this.pT != "miniPlayer") {
            audioComponent.querySelector("#progress-amount").style.width =
              p + "%";
            audioComponent.querySelector("#audioPlayerPosition").style.left =
              p + "%";
          }

          this.trackCT = this.formatTime(myAudio.currentTime); //this.$toHHMMSS(myAudio.currentTime);
        }

        if (p > 90 && this.getNext == 0) {
          this.getNext = 1;

          if (this.currentTrackIdx + 1 == this.audioList.length) {
            this.currentTrackIdx = 0;
          } else {
            this.fetchTrackData(this.currentTrackIdx + 1)
              .then((trackBlob) => {
                this.track1 = trackBlob;
              })
              .catch((err) => {
                var n = {
                  type: "problem",
                  message: "track could not be downloaded. err:" + err,
                };
                this.addNotificationMessage(n);
              });
          }
        }

        if (p == 100) {
          this.log("ASW audio  LETS PLAY NEXT TRACK !!!");
          if (this.track1 != null) {
            this.currentTrack_PrepareData(myAudio, this.track1, 1);

            this.getNext = 0;
            this.currentTrackIdx++;

            this.log(
              "ASW audio  LETS PLAY NEXT TRACK !!! volume:" + myAudio.volume
            );

            this.currentTrack_Play(myAudio, audioComponent);
          } else {
            this.log(
              "THIS IS THE END MY FRIEND .... end of track lest replace the icons"
            );
            this.currentTrack_Rewind(myAudio, audioComponent);
          }
        }
      });

      // ---------------------------------------------------------------------
      if (this.pT != "miniPlayer") {
        let slider = audioComponent.querySelector(".audioPlayerBarContainer");
        let thumb = audioComponent.querySelector("#audioPlayerPosition");
        let progressAmount = audioComponent.querySelector("#progress-amount");
        thumb.onmousedown = function (event) {
          event.preventDefault(); // prevent selection start (browser action)

          let shiftX = event.clientX - thumb.getBoundingClientRect().left;
          // shiftY not needed, the thumb moves only horizontally

          //this.onMouseMove = this.onMouseMove.bind(this);

          function onMouseMove_handler(
            event,
            shiftX,
            slider,
            myAudio,
            thumb,
            progressAmount,
            appObject
          ) {
            let newLeft =
              event.clientX - shiftX - slider.getBoundingClientRect().left;

            let rightEdge = slider.offsetWidth - thumb.offsetWidth;

            myAudio.currentTime = (newLeft * myAudio.duration) / rightEdge;
            //p = (myAudio.currentTime / duration) * 100

            // the pointer is out of slider !....
            if (newLeft < 0) {
              newLeft = 0;
            }

            if (newLeft > rightEdge) {
              newLeft = rightEdge;
            }

            thumb.style.left = newLeft + "px";
            progressAmount.style.width = newLeft + "px";

            // emit message to the parrent component ->
            //appObject.$_log("myAudio.currentTime:" + myAudio.currentTime);
            appObject.$emit("progressDragged", {
              elemId: event.target.id,
              trackName: appObject.audioList[0].name,
              trackId: appObject.id,
              currentTime: myAudio.currentTime,
            });
            // emit message to the parrent component -<
          }

          function onMouseUp_handler() {
            if (event.clientX > 0) {
              document.removeEventListener("mouseup", onMouseUp);
              document.removeEventListener("mousemove", onMouseMove);
            }
          }

          var onMouseMove = (event) => {
            onMouseMove_handler(
              event,
              shiftX,
              slider,
              myAudio,
              thumb,
              progressAmount,
              this
            );
          };

          var onMouseUp = (event) => {
            onMouseUp_handler(event);
          };

          document.addEventListener("mousemove", onMouseMove);
          document.addEventListener("mouseup", onMouseUp);
        }.bind(this);

        thumb.ondragstart = function () {
          return false;
        }.bind(this);
      }

      // -- AUDIO PLAYER <==
    },
  },
};
</script>

<style scoped>
/* audio player --> */
@media only screen and (min-width: 850px) {
}

@media only screen and (max-width: 850px) {
  .notplayabletrack {
    display: none;
  }
}

.ac {
  display: flex;
}

.audioPlayerControls {
  position: relative;
  display: flex;
}

#audioPrevTrack {
  cursor: pointer;
}
#audioPlayTrack {
  cursor: pointer;
}
#audioNextTrack {
  cursor: pointer;
}

.audioPlayerBarContainer {
  position: relative;
  display: inline-flex;
  top: -2px;
  /*background: var(--main-gray500-color);*/
  /*width: 100px;*/
}
.audioPlayerBuffered {
  position: absolute;
  /*background: var(--main-gray500-color);*/
  width: 100%;
  height: 10px;
  display: inline-block;
}

#buffered-amount {
  display: block;
  height: 100%;

  /*background-color: var(--main-bg-color);*/
  width: 0;
}
.audioPlayerProgress {
  margin-top: 9px; /** 4px */
  position: absolute;
  background: var(--slider-bg);
  width: 100%;
  height: 4px; /* 2px */
  display: inline-block;
}

#progress-amount {
  display: block;
  height: 100%;
  background-color: var(--main-blue-color);
  width: 0;
}

#audioPlayerPosition {
  width: 16px; /* 6px*/
  height: 16px; /* 6px*/
  position: relative;
  left: 0px;
  top: 2px;

  background: var(--slider-handle-bg, #fff);
  border: var(--slider-handle-border, 0);
  border-radius: var(--slider-handle-radius, 9999px);
  box-shadow: var(
    --slider-handle-shadow,
    0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32)
  );
  cursor: grab;
}

#currentTrackParams {
  justify-content: center;
  align-items: center;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
}

#trackCurrentTime {
  font-size: 13px;
  margin-right: 20px;
  width: 55px;
}

#trackTotalTime {
  font-size: 13px;
  margin-left: 20px;
}

/* audio player --< */

/* play button ==> */
.playAudBtn.wb_studioLight {
  display: none;
}
.playAudBtn {
  background-image: url("@/assets/play-circle-svgrepo-com.svg");
  background-repeat: no-repeat;
  background-position: 50% 0px;

  width: 32px;
  height: 32px;
  /*border: 1px solid gray;*/

  background-size: 32px;
  padding: 0px 10px 0px 10px;
}
.pauseAudBtn {
  background-image: url("@/assets/pause-circle-svgrepo-com.svg");
  background-repeat: no-repeat;
  background-position: 50% 0px;

  width: 32px;
  height: 32px;
  /*border: 1px solid gray;*/

  background-size: 32px;
  padding-left: 10px;
  padding-right: 10px;
}

/* ==> blue */

.playAudBtn.blue:hover {
  background-image: url("@/assets/simplePlayActive.svg");
}

.playAudBtn.blue {
  background-image: url("@/assets/simplePlay.svg");
}
.pauseAudBtn.blue {
  background-image: url("@/assets/simplePause.svg");
}

.disabledAudBtn.blue {
  background-image: url("@/assets/simplePlay.svg");
  background-repeat: no-repeat;
  background-position: 50% 0px;

  width: 32px;
  height: 32px;
  background-size: 32px;
  padding-left: 10px;
  padding-right: 10px;
}
/* ==< */

/* ==> wb - white/black */
.playAudBtn.wb {
  background-image: url("@/assets/wbPlayActive.svg");
  width: 24px;
  height: 24px;
  background-size: 24px;
}
.pauseAudBtn.wb {
  background-image: url("@/assets/wbPauseActive.png");
  width: 15px;
  height: 24px;
  background-size: 15px;
}

.ac.wb {
  color: #fff;
}

.audioPlayerProgress.wb {
  background-color: var(--slider-bg, #d1d5db);
}
/* ==< */

/* play button ==< */
</style>
